import { SINGLE_ADD_STEPS } from '../../../components/pages/Endorsements/SingleAddMembers/constants';
import { ISingelAddSliceState } from './types';

export const initialState: ISingelAddSliceState = {
  currentStep: SINGLE_ADD_STEPS.CHOOSE_POLICY,
  selectedPolicyDetails: {},
  employee: {
    employeeId: '',
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: null,
    dateOfJoining: null,
    email: '',
    mobile: '',
  },
  dependants: [],
  employeeErrors: {
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    dateOfJoining: '',
    email: '',
    mobile: '',
    policyId: '',
  },
  dependantErrors: {
    relationship: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    mobile: '',
    email: '',
  },
};
