import { createAsyncThunk } from '@reduxjs/toolkit';
import PolicyApi from '../../../adaptars/policyApi';
import { getFiltersArray } from './utils';
import LoopApiService from '../../../adaptars/LoopApiService';
import moment from 'moment';
import { IClaimData } from '../../../components/pages/ClaimsList/types';
import { parseResponse } from '../../../utils/common/Utilities';
import { IEndorsements } from '../../../components/pages/Endorsements/ListingPage/types';

export const fetchAllActivePolicies = createAsyncThunk(
  'FETCH_COMPANY_POLICIES',
  async (companyId: string) => {
    try {
      const result = await PolicyApi.getPolicies(companyId);
      let policies = result?.data;
      policies = policies.filter(
        (policy) => policy.policyPlacementStatus == 'Handover to Ops',
      );

      return {
        companyId,
        policies,
        filters: getFiltersArray(policies),
      };
    } catch (error) {
      throw error;
    }
  },
);
export const fetchCDAccounts = createAsyncThunk(
  'FETCH_CD_ACCOUNTS',
  async (companyId: string) => {
    try {
      const result = await LoopApiService.fetchCDAccounts(companyId);
      return {
        cdAccounts: result?.data || [],
      };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchClaimList = createAsyncThunk(
  'FETCH_CLAIM_LIST',
  async (companyId: string) => {
    try {
      const result = await LoopApiService.fetchClaimsAPI(companyId);
      // Only saving claims with active policies in redux state -
      const filteredClaims: IClaimData[] = (
        (result?.data?.claims as IClaimData[]) || []
      )
        .filter((data) => moment(data.policyEndDate).isAfter(moment()))
        .map((data) =>
          data.type === 'cashless' && data.status?.tag !== 'Rejected'
            ? { ...data, status: { finalStatus: 'Claim Paid', tag: 'Settled' } }
            : data,
        );
      return {
        claims: filteredClaims,
      };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchEndorsments = createAsyncThunk(
  'FETCH_ENDORSEMENTS_LIST',
  async (companyId: string) => {
    try {
      const [err, result] = await parseResponse(
        LoopApiService.fetchEndorsements(companyId),
      );
      if (err || !result) {
        throw err || Error('Something went wrong');
      }
      // Sorting endos in latest first order -
      return {
        endorsements: (result.data as IEndorsements[])?.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
      };
    } catch (error) {
      throw error;
    }
  },
);
