import CDAccountCompletedCard from './CDAccountCompletedCard';
import CDAccountInProgressCard from './CDAccountInProgressCard';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCDAccountCompletedPopupClosedUserIds,
  addCDAccountCompletedPopupClosedUserIds,
} from '../../../redux/slices/applicationStateSlice';
import { selectHrUser } from '../../../redux/slices/usersSlice';

const CDAccountProgressCard = () => {
  const dispatch = useDispatch();
  const cdAccountPopupClosedUserIds = useSelector(
    selectCDAccountCompletedPopupClosedUserIds,
  );
  const hrData = useSelector(selectHrUser);
  const { data: policies } = useSelector(selectPoliciesList);
  const policiesWithCdAccountCount =
    policies?.filter((p) => p.isCdAccountPresent)?.length || 0;
  const isCompleted = policiesWithCdAccountCount === policies?.length;
  const isPopupClosed = cdAccountPopupClosedUserIds.includes(
    hrData?.data?.userId || '',
  );

  const onPopupClosed = () => {
    if (hrData?.data?.userId) {
      dispatch(addCDAccountCompletedPopupClosedUserIds(hrData.data.userId));
    }
  };
  if (isPopupClosed) {
    return null;
  }
  if (isCompleted) {
    return <CDAccountCompletedCard onPopupClosed={onPopupClosed} />;
  }
  return <CDAccountInProgressCard />;
};

export default CDAccountProgressCard;
