import styled from 'styled-components';

export const ChatbotMessageContainer = styled.div`
  display: flex;
  padding: 10px 15px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px 0px 10px 10px;
  background: ${(p) => p.theme.colors.surface0};
`;

