import {
  Form,
  LoopBadge,
  LoopButton,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { ChangeEvent, useState } from 'react';
import { IEditedField } from '../../../../pages/Endorsements/BulkEditMembers/types';
import { StyledFitContentWrapper } from '../../../EmpPolicies/styles';
import {
  StyledFieldEditContainer,
  StyledFieldBody,
  StyledFieldBodyContainer,
  StyledFieldButton,
  StyledFieldButtonsContainer,
  StyledFieldContainer,
  StyledFieldDocumentsWrapper,
  StyledFieldBadgeContainer,
  StyledFieldHeaderContainer,
} from '../styles';
import { IEmailEditContainer } from '../types';
import { regexValidations } from '../../../../../utils/hooks/useFormInput';

const EmailEditContainer: React.FC<IEmailEditContainer> = ({
  handleEditFieldSave,
  isBeingEdited,
  oldValue,
  edit,
  setSelectedField,
  updateEditedField,
  validateFieldSave,
  mobileValue,
}) => {
  const [value, setValue] = useState(oldValue || '');
  const [fieldError, setFieldError] = useState<string>('');
  const [isLoading, toggleLoading] = useState(false);

  const handleEditAndCancel = () => {
    setSelectedField(isBeingEdited ? null : 'email');
    setFieldError('');
    setValue(edit ? edit.after || '' : oldValue || '');
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldError('');
    setValue(event.target.value);
  };

  const handleSave = async (clearValue = false) => {
    setFieldError('');
    toggleLoading(true);
    const error = await validateFieldSave('email', clearValue ? null : value);
    if (error) {
      setFieldError(error);
      toggleLoading(false);
      return;
    }
    handleEditFieldSave('email');

    if (edit) {
      const newEdit = {
        ...edit,
        after: clearValue ? null : value,
      };
      updateEditedField(newEdit);
    } else {
      const newEdit: IEditedField = {
        field: 'email',
        before: oldValue,
        after: clearValue ? null : value,
        documents: [],
      };
      updateEditedField(newEdit);
    }
    setSelectedField(null);
    setFieldError('');
    toggleLoading(false);
  };

  const saveEnabled =
    value &&
    oldValue !== value &&
    regexValidations.email.test(value) &&
    !fieldError;

  return (
    <StyledFieldContainer>
      <StyledFieldHeaderContainer>
        <StyledFieldBadgeContainer>
          <Typography variant="medium" weight="medium">
            Email ID
          </Typography>
          {edit ? (
            <LoopBadge
              variant="overflow"
              text="Edited"
              badgeType="success3"
              size="small"
            />
          ) : null}
        </StyledFieldBadgeContainer>
        <StyledFieldButton>
          <LoopButton variant="text" size="large" onClick={handleEditAndCancel}>
            {isBeingEdited ? 'Cancel' : !oldValue && !value ? 'Add' : 'Edit'}
          </LoopButton>
        </StyledFieldButton>
      </StyledFieldHeaderContainer>
      <StyledFieldBodyContainer>
        {isBeingEdited ? (
          <StyledFieldBody>
            <StyledFieldEditContainer>
              <Form.Input
                value={value}
                placeholder="Enter email id"
                type="email"
                onChange={handleChange}
                error={fieldError}
              />
            </StyledFieldEditContainer>
            <StyledFieldButtonsContainer $errored={!!fieldError}>
              <StyledFitContentWrapper>
                <LoopButton
                  variant={saveEnabled ? 'filled' : 'disabled'}
                  onClick={() => handleSave()}
                  isLoading={isLoading}
                >
                  Save
                </LoopButton>
              </StyledFitContentWrapper>
              {mobileValue && oldValue && (
                <StyledFitContentWrapper>
                  <LoopButton
                    variant={isLoading ? "disabled": "outline"}
                    textColor="error"
                    onClick={() => handleSave(true)}
                  >
                    Clear
                  </LoopButton>
                </StyledFitContentWrapper>
              )}
            </StyledFieldButtonsContainer>
          </StyledFieldBody>
        ) : (
          <StyledFieldDocumentsWrapper>
            <Typography variant="medium" color="secondary">
              {edit ? edit.after || 'Not Provided' : oldValue || 'Not Provided'}
            </Typography>
          </StyledFieldDocumentsWrapper>
        )}
      </StyledFieldBodyContainer>
    </StyledFieldContainer>
  );
};

export default EmailEditContainer;
