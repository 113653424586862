import React from 'react';
import { IClaimDetailsData, IClaimTypes } from './types';
import {
  StyledDetailsHeader,
  StyledMainContainer,
  StyledMainWrapper,
  StyledClaimStatusWrapper,
  StyledTimelineContainer,
  StyledDetailContainer,
  StyledClaimChipContainer,
  StyledEmptyContainer,
  StyledNoTimelineSpacing,
  StyledStatusTimelineContainer,
} from './styles';
import {
  EmptyStateOneGif,
  TableContentPurpleIcon,
} from '../../../assets/images';
import { Chip, Typography } from '@loophealth/loop-ui-web-library';
import Loader from '../../atoms/Spinner';

import ClaimDetailsAccordion from './ClaimDetailsAccordion';
import ClaimStatusOverview from '../../containers/ClaimStatus';
import ClaimsTimeline from '../ClaimsTimeline';
import { IPossibleClaimStatus } from '../../../utils/constants';
import ZeroStateCard from '../../atoms/ZeroStateCard';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import { useSelector } from 'react-redux';
import useFetchClaimTimeline from '../../pages/EmployeeDetails/hooks/useFetchClaimTimeline';
import CashlessClaim from './CashlessClaim';
import { finalClaimStatus } from '../ClaimsTimeline/constant';
import { LocationState } from '../../pages/EmployeeDetails/types';
import { useLocation } from 'react-router-dom';
import { processClaimStatus } from './utils';
import { IClaimData } from '../../pages/ClaimsList/types';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { trackClickEvent } from '../../../utils/segment/utils';

const ClaimDetails: React.FunctionComponent<IClaimDetailsData> = ({
  claimDetails,
  claimsLoading,
}) => {
  const location = useLocation<LocationState>();
  const navigatedClaimId = location.state?.claimId;
  const [activeClaim, setActiveClaim] = React.useState<string>(
    navigatedClaimId ?? '',
  );
  const trackClick = useSegment('click');

  const { data } = useSelector(selectPoliciesList);
  const filteredClaim = claimDetails.find(
    (claimData) => claimData.id === activeClaim,
  );

  const handleSelectedClaimChange = (claim: IClaimData, index: number) => {
    const claimProperties: Record<string, unknown> = {
      claim_id: claim.id,
      user_id: claim.userId,
    };
    setActiveClaim(claim.id);
    trackClick(
      trackClickEvent(
        'CLAIM_' + (index + 1),
        location.pathname,
        'HRD EMP Details Page Events',
        claimProperties,
      ),
    );
  };

  let policyDetails = {
    policyType: '',
    id: '',
  };

  if (filteredClaim) {
    const policyToFind = data.find((policy) => policy.id === filteredClaim.id);
    policyDetails = {
      policyType: policyToFind?.policyType ?? '',
      id: policyToFind?.id ?? '',
    };
  }
  const { timelineLoading, claimTimelineData } =
    useFetchClaimTimeline(activeClaim);

  React.useEffect(() => {
    if (claimDetails.length && !navigatedClaimId) {
      setActiveClaim(claimDetails[0].id);
    }
  }, [claimDetails, navigatedClaimId]);

  React.useEffect(() => {
    if (!filteredClaim && claimDetails.length && navigatedClaimId) {
      setActiveClaim(claimDetails[0].id);
    }
  }, [filteredClaim, navigatedClaimId, claimDetails]);

  return (
    <>
      {claimsLoading ? (
        <Loader />
      ) : (
        <StyledMainContainer>
          <StyledDetailsHeader>
            <img src={TableContentPurpleIcon} alt="" />
            <Typography variant="medium" weight="medium" color="primary">
              Track Claims
            </Typography>
            <StyledClaimChipContainer>
              {claimDetails?.map((claim, index) => (
                <Chip
                  key={index}
                  label={`Claim ${index + 1}`}
                  isActive={activeClaim === claim.id}
                  onClick={() => handleSelectedClaimChange(claim, index)}
                />
              ))}
            </StyledClaimChipContainer>
          </StyledDetailsHeader>
          {filteredClaim ? (
            <StyledMainWrapper>
              <StyledStatusTimelineContainer>
                <StyledClaimStatusWrapper>
                  <ClaimStatusOverview
                    status={processClaimStatus(
                      (filteredClaim?.status
                        ?.finalStatus as finalClaimStatus) ??
                        IPossibleClaimStatus.cup,
                      filteredClaim?.type,
                    )}
                    claimData={filteredClaim}
                  />
                </StyledClaimStatusWrapper>

                <StyledTimelineContainer>
                  {filteredClaim.type === IClaimTypes.reimbursement ? (
                    <ClaimsTimeline
                      timelineData={claimTimelineData}
                      approvedAmount={filteredClaim.approvedAmount}
                      timelineLoading={timelineLoading}
                    />
                  ) : (
                    <>
                      {processClaimStatus(
                        (filteredClaim?.status
                          ?.finalStatus as finalClaimStatus) ??
                          IPossibleClaimStatus.cup,
                        filteredClaim?.type,
                      ) !== 'Rejected' ? (
                        <CashlessClaim
                          dischargeDate={filteredClaim.dischargedAt}
                        />
                      ) : (
                        <StyledNoTimelineSpacing />
                      )}
                    </>
                  )}
                </StyledTimelineContainer>
              </StyledStatusTimelineContainer>
              <StyledDetailContainer>
                <ClaimDetailsAccordion
                  claimData={filteredClaim}
                  policyData={policyDetails}
                />
              </StyledDetailContainer>
            </StyledMainWrapper>
          ) : (
            <StyledEmptyContainer>
              <ZeroStateCard
                title="No claims open for this family!"
                subtitle="Kudos for keeping everyone in good health!"
                image={EmptyStateOneGif}
                note="Note: Claims can take 2-3 days to reflect here"
              />
            </StyledEmptyContainer>
          )}
        </StyledMainContainer>
      )}
    </>
  );
};
export default ClaimDetails;
