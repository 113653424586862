import styled from 'styled-components';

export const StyledAnalyticsOverviewCard = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledAnalyticsOverviewCardImage = styled.img`
  width: 44px;
  height: 44px;
  object-fit: contain;
`;

export const StyledAnalyticsOverviewCardData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledAnalyticsOverviewCardDataTitleContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 4px;
`;

export const StyledAnalyticsOverviewCardInfoIcon = styled.img`
  width: 20px;
  height: 20px;
`;
