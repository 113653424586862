import { Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { InfoDarkIcon, InfoLightIcon } from '../../../assets/images';
import {
  StyledIconImg,
  StyledTabWithDetail,
  StyledTabWithDetailColorIcon,
  StyledTabWithDetailText,
  StyledTabWithDetailTextTooltip,
} from './styles';
import { ITabWithDetail } from './types';

const TabWithDetail = ({
  color,
  label,
  count,
  isSelected,
  tooltip,
  onClick,
}: ITabWithDetail) => {
  return (
    <StyledTabWithDetail $isSelected={isSelected} onClick={onClick}>
      <StyledTabWithDetailColorIcon color={color} />
      <StyledTabWithDetailText>
        <StyledTabWithDetailTextTooltip>
          <Typography
            variant="small"
            color={isSelected ? 'tertiary' : 'primary'}
          >
            {label}
          </Typography>
          {tooltip && (
            <Tooltip beak="top-center" type="informative" text={tooltip}>
              <StyledIconImg src={isSelected ? InfoLightIcon : InfoDarkIcon} />
            </Tooltip>
          )}
        </StyledTabWithDetailTextTooltip>
        <Typography
          variant="title3"
          color={isSelected ? 'tertiary' : 'primary'}
        >
          {count}
        </Typography>
      </StyledTabWithDetailText>
    </StyledTabWithDetail>
  );
};

export default TabWithDetail;
