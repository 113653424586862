import { ITableHeaderDef } from '@loophealth/loop-ui-web-library/dist/types/atoms/Table/types';
import { IEndoTableEntry } from './types';
import { StyledClaimIdUnderline } from '../../ClaimsList/styles';
import { Typography } from '@loophealth/loop-ui-web-library';

export const getEndoTableColumns: ITableHeaderDef<IEndoTableEntry> = [
  {
    name: 'Employee Name',
    key: 'employeeName',
    width: 220,
    cell: (value) => {
      return (
        <StyledClaimIdUnderline>
          <Typography color="emerald" variant="small">
            {value as string}
          </Typography>
        </StyledClaimIdUnderline>
      );
    },
  },
  {
    name: 'Employee ID',
    key: 'employeeId',
    width: 160,
  },
  {
    name: 'Relationship',
    key: 'relationship',
    width: 160,
  },
  {
    name: 'Operation',
    key: 'operation',
    width: 160,
  },
  {
    name: 'Source',
    key: 'source',
    width: 180,
  },
  {
    name: 'Added On',
    key: 'addedOn',
    width: 180,
  },
];
