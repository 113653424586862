import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { parseResponse } from '../../../../utils/common/Utilities';
import LoopApiService from '../../../../adaptars/LoopApiService';
import { useSelector } from 'react-redux';
import { IClaimData } from '../../ClaimsList/types';
import { empTabs, filterOpenClaims } from '../utils';
import { ITabItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Tabs/types';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';

const useFetchEmployeeClaims = (empId: string) => {
  const [claimsLoading, toggleLoading] = useState<boolean>(false);
  const [claimData, setClaimData] = useState<IClaimData[] | null>(null);

  const [employeeTabs, setEmpTabs] = useState<ITabItem[]>(empTabs);
  const selectedCompany = useSelector(selectSelectedCompany);

  useEffect(() => {
    let isMounted = true;

    const fetchEmployeeClaimDetails = async () => {
      if (selectedCompany && empId) {
        toggleLoading(true);

        let error = null;
        let result = null;

        [error, result] = await parseResponse(
          LoopApiService.fetchClaimsAPI(selectedCompany.id, empId),
        );

        if (isMounted) {
          toggleLoading(false);
          if (error) {
            toast.error(error.message || 'Something went wrong!');
            console.error(error);
            return;
          }
          const empClaimData = result.data?.claims;
          const updatedTabs = filterOpenClaims(empTabs, empClaimData);
          setEmpTabs(updatedTabs);
          setClaimData(empClaimData);
        }
      }
    };

    fetchEmployeeClaimDetails();

    return () => {
      isMounted = false;
    };
  }, [selectedCompany?.id, empId]);

  const memoizedClaimData = useMemo(() => claimData, [claimData]);

  return { claimsLoading, claimData: memoizedClaimData, employeeTabs };
};

export default useFetchEmployeeClaims;
