import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;
`;
export const StyledWrapperBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px;
  width: 100%;
  margin-bottom: 60px;
`;
export const StyledLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  height: fit-content;
  width: 64%;
  border: 1px solid ${({ theme }) => theme.colors.mintFrost};
`;
export const StyledLeftSectionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.mintFrost};
  height: 120px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 30px;
`;
export const StyledLeftSectionTitleImage = styled.img`
  height: 70px;
  width: auto;
  margin-right: 20px;
`;
export const StyledLeftSectionDescription = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 26px;
`;
export const StyledLeftSectionDescriptionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledLeftSectionLowCD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 10px 20px;
  position: relative;
  z-index: 1;
  margin-bottom: -15px;
  &::before {
    content: '';
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.planRed};
    opacity: 0.1;
    z-index: -1;
  }
`;
export const StyledLeftSectionLowCDImage = styled.div`
  margin-right: 6px;
  margin-top: 4px;
`;
export const StyledLeftSectionDescriptionList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface0};
  padding: 30px;
  border-radius: 12px;
  gap: 30px;
`;
export const StyledLeftSectionDescriptionListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const StyledLeftSectionDescriptionListItemTag = styled.div`
  border-radius: 6px;
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.lavenderBlush};
`;
export const StyledRightSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  gap: 30px;
  width: 32%;
`;
export const StyledRightSectionTop = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  height: 360px;
  padding: 30px;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.mintFrost};
`;

export const StyledRightSectionTopRadial = styled.div`
  background: radial-gradient(
    circle at bottom,
    rgba(0, 164, 115, 0.12) 0%,
    rgba(0, 164, 115, 0) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 250px;
  width: 100%;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;
export const StyledRightSectionTopImage = styled.img`
  margin-top: 30px;
  height: 200px;
  width: auto;
  align-self: center;
  position: absolute;
  bottom: 0;
  z-index: 2;
`;
export const StyledRightSectionBottom = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  padding: 30px;
  gap: 20px;
  border: 1px solid ${({ theme }) => theme.colors.mintFrost};
`;
export const StyledRightSectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const StyledRightSectionTitleIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 6px;
`;
export const StyledRightSectionTnCList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const StyledRightSectionTnCListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
export const StyledRightSectionTnCListItemBullet = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.seafoamGray};
  aspect-ratio: 1;
`;
export const StyledWrapperFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  width: 90%;
  padding: 20px 60px;
  background-color: ${({ theme }) => theme.colors.surface0};
  border-top: 0.5px solid ${({ theme }) => theme.colors.seafoamGray};
`;
export const StyledWrapperFooterButton = styled.div`
  width: 200px;
`;
