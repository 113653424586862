import { Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { ChatbotMessageContainer } from './styles';

const CustomUserMessage = ({ message }: { message: string }) => {
  return (
    <ChatbotMessageContainer>
      <Typography variant="small" color="primary">
        {message}
      </Typography>
    </ChatbotMessageContainer>
  );
};

export default CustomUserMessage;
