import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.mintFrost};
  align-items: center;
  gap: 20px;
  padding: 20px 56px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.seafoamGray};
`;
export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

export const StyledCalendarIcon = styled.img``;
