import { IPolicyListItemType } from '../../../../redux/slices/hrdRevampRedux/types';
import { trackTaskEvent } from '../../../../utils/segment/utils';
import { IEndoCostItem } from '../../../containers/Bulk/SubmitEndo/types';
import { IButtonState } from '../../../containers/Bulk/types';
import { deleteMidtermDocument } from '../../../containers/MidTermAdditionModal/utils';
import { getAddValidationSummaryData } from '../../../containers/ValidationsScreen/utils';
import {
  IBasicUserAddData,
  IBulkAddData,
  IPolicyRejectedEntries,
  IUploadedPolicywiseData,
} from './types';

export const getButtonStates = (
  currentStep: number,
  selectedLength: number,
  finalCorrectData: IUploadedPolicywiseData,
  rejectedEntries: IPolicyRejectedEntries,
): IButtonState => {
  const correctEntriesLength = getCorrectEntriesLength(finalCorrectData);
  switch (currentStep) {
    case 1:
      return {
        isNextEnabled: !!selectedLength,
        isBackEnabled: false,
        buttonText: 'Upload & Proceed',
        backButtonText: '',
      };
    case 2:
      return {
        isNextEnabled: true,
        isBackEnabled: !!correctEntriesLength,
        buttonText: correctEntriesLength
          ? `View Cost of ${correctEntriesLength} Correct Lives `
          : 'Re-Upload all Lives',
        backButtonText: 'Re - Upload all Lives',
      };
    case 3:
      return {
        isNextEnabled: true,
        isBackEnabled: true,
        buttonText: `Submit ${correctEntriesLength} Lives`,
        backButtonText:
          Object.keys(rejectedEntries).length > 0
            ? 'Review Again'
            : 'Re-Upload all Lives',
      };
    default:
      return {
        isNextEnabled: false,
        isBackEnabled: false,
        buttonText: '',
        backButtonText: '',
      };
  }
};

export const deleteMidtermDocumentsOnCancel = (
  uploadedData: IUploadedPolicywiseData,
) => {
  Object.keys(uploadedData).forEach((policyId) => {
    uploadedData[policyId].forEach((member) =>
      deleteMidtermDocument(member.midTermDocumentURL),
    );
  });
};

export const checkIfSameAddUser = (
  user1: IBasicUserAddData,
  user2: IBasicUserAddData,
) => {
  return (
    user1.employee_id?.trim() === user2.employee_id?.trim() &&
    user1.relationship_to_account_holders?.toLowerCase().trim() ===
      user2.relationship_to_account_holders?.toLowerCase().trim() &&
    user1.name?.toLowerCase()?.trim() === user2?.name?.toLowerCase().trim()
  );
};

export const getCorrectEntries = (
  uploadedData: IUploadedPolicywiseData,
  rejectedData: IPolicyRejectedEntries,
): IUploadedPolicywiseData => {
  const correctEntriesData: IUploadedPolicywiseData = {};

  Object.keys(uploadedData).forEach((policyId) => {
    const policyCorrectEntries: IBulkAddData[] = [];
    uploadedData[policyId].forEach((member) => {
      const rejectedEntry = rejectedData[policyId]?.find((rejectedMember) =>
        checkIfSameAddUser(member, rejectedMember),
      );
      if (!rejectedEntry) {
        policyCorrectEntries.push(member);
      }
    });
    if (policyCorrectEntries.length)
      correctEntriesData[policyId] = policyCorrectEntries;
  });
  return correctEntriesData;
};

export const getCorrectEntriesLength = (
  correctData: IUploadedPolicywiseData,
): number => {
  return Object.keys(correctData).reduce((prev, policyId) => {
    return prev + correctData[policyId].length;
  }, 0);
};

export const isCDLowTrue = (dataArray: any[]): boolean => {
  if (dataArray.length > 0) {
    for (const obj of dataArray) {
      if (obj.hasOwnProperty('isCDLow') && obj.isCDLow === true) {
        return true;
      }
    }
  }
  return false;
};

export const trackAddValidationSummary = (
  uploadedData: IUploadedPolicywiseData,
  rejectedEntries: IPolicyRejectedEntries,
  trackTask: ({
    name,
    properties,
  }: {
    name: string;
    properties?: Record<string, unknown>;
  }) => void,
  location: string,
  policies: IPolicyListItemType[],
) => {
  const summaryData = getAddValidationSummaryData(
    uploadedData,
    rejectedEntries,
    policies,
  );
  trackTask(
    trackTaskEvent(`Add_Validation_Summary`, location, '', { ...summaryData }),
  );
};

export const getSegmentCostSummaryData = (endoCostList: IEndoCostItem[]) => {
  const costLiveMap: Record<string, Record<string, number>> = {};
  endoCostList.forEach((policy: IEndoCostItem) => {
    policy.policyData.map((policyData) => {
      costLiveMap[policyData.policyType] = {
        cost:
          (costLiveMap[policyData.policyType]?.cost || 0) +
          policyData.costOfEndorsement,
        lives:
          (costLiveMap[policyData.policyType]?.lives || 0) + policyData.lives,
      };
    });
  });
  return {
    balance: endoCostList.reduce((prev, current) => prev + current.balance, 0),
    ...costLiveMap,
  };
};
