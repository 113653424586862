import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 50px;
`;

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledDetailsHeader = styled.div`
  display: flex;
  gap: 12px;
  padding: 32px 55px;
  align-items: center;
  background-color: ${(p) => p.theme.colors.white};
`;

export const StyledVerticalSpacer = styled.div`
  width: 1px;
  background-color: ${(p) => p.theme.colors.chineseWhite};
  margin: 0 75px;
`;

export const StyledTrWrapper = styled.div`
  display: flex;
  max-width: 240px;
  white-space: pre-wrap;
`;

export const StyledPolicyWrapper = styled.div`
  display: flex;
`;

export const StyledIconAndNameContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  white-space: normal;
`;
export const StyledPolicyOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledEmptyContainer = styled.div`
  display: flex;
  padding: 40px 56px;
  width: 100%;
`;

export const StyledWhiteSpaceWrapper = styled.div`
  white-space: normal;
  & > *{
    white-space: normal;
  }
`;

export const StyledFitContentWrapper = styled.div`
  width: fit-content;
  & > *{
    width: fit-content;
  }
`