import React, { FC, useMemo, memo } from 'react';
import {
  StyledReimbursementClaimsCard,
  StyledReimbursementClaimsSection,
} from './styles';
import Card from '../../../atoms/Card';
import { PieChart, Typography } from '@loophealth/loop-ui-web-library';
import { PieChartContainer } from '../../Employees/Statistics/styles';
import CenteredText from '../../../atoms/PieChart/CenterText';
import CustomLegend from '../../../atoms/PieChart/CustomLegend';
import { IReimbursementClaimsMetricProps } from './types';
import {
  getSettlementAnalysisStatistics,
  getTPAAnalysisStatistics,
} from './utils';
import ZeroStateCard from '../../../atoms/ZeroStateCard';
import { EmptyStateFourGif } from '../../../../assets/images';

const ReimbursementClaimMetric: FC<IReimbursementClaimsMetricProps> = ({
  settlementClosedCount = 0,
  settlementOnGoingCount = 0,
  settlementRejectedCount = 0,
  settlementSettledCount = 0,
  settlementUnderQueryCount = 0,
  zeroToTenDays = 0,
  elevenToTwentyDays = 0,
  moreThanTwentyDays = 0,
}) => {
  const settlementAnalysis = useMemo(() => {
    return getSettlementAnalysisStatistics(
      settlementSettledCount,
      settlementOnGoingCount,
      settlementUnderQueryCount,
      settlementClosedCount,
      settlementRejectedCount,
    );
  }, [
    settlementSettledCount,
    settlementOnGoingCount,
    settlementUnderQueryCount,
    settlementClosedCount,
    settlementRejectedCount,
  ]);

  const tpaAnalysis = useMemo(() => {
    return getTPAAnalysisStatistics(
      zeroToTenDays,
      elevenToTwentyDays,
      moreThanTwentyDays,
    );
  }, [zeroToTenDays, elevenToTwentyDays, moreThanTwentyDays]);

  return (
    <StyledReimbursementClaimsSection>
      <Typography variant="medium" weight="medium">
        Reimbursement Claims
      </Typography>
      {/* TODO: Zero state to be handled properly */}
      {Number(settlementAnalysis.count) !== 0 &&
      Number(tpaAnalysis.count) !== 0 ? (
        <StyledReimbursementClaimsCard>
          <Card joined={true}>
            <PieChartContainer>
              <Typography variant="small" weight="medium" color="secondary">
                Settlement Analysis
              </Typography>
              <PieChart
                data={settlementAnalysis.data}
                centerText={
                  <CenteredText
                    value={settlementAnalysis.count}
                    label="Total Claims"
                  />
                }
                width={180}
                legends="custom"
                renderLegend={(item) => (
                  <CustomLegend
                    color={item.color}
                    count={item.value}
                    percentage={item.percentage}
                    label={item.label}
                  />
                )}
                tooltipText="Claims"
              />
            </PieChartContainer>
          </Card>

          <Card joined={true}>
            <PieChartContainer>
              <Typography variant="small" weight="medium" color="secondary">
                TAT Analysis for Settled Claims
              </Typography>
              <PieChart
                data={tpaAnalysis.data}
                centerText={
                  <CenteredText
                    value={tpaAnalysis.count}
                    label="Claims Settled"
                  />
                }
                width={180}
                legends="custom"
                renderLegend={(item) => (
                  <CustomLegend
                    color={item.color}
                    count={item.value}
                    percentage={item.percentage}
                    label={item.label}
                  />
                )}
                tooltipText="Claims"
              />
            </PieChartContainer>
          </Card>
        </StyledReimbursementClaimsCard>
      ) : (
        <ZeroStateCard
          title="No reimbursement claims filed yet!"
          subtitle="We'll update you as soon as we have one!"
          image={EmptyStateFourGif}
        />
      )}
    </StyledReimbursementClaimsSection>
  );
};

export default memo(ReimbursementClaimMetric);
