import React, { FC, useCallback } from 'react';
import {
  StyledAnalyticsHeader,
  StyledDot,
  StyledDropdownItem,
  StyledFilter,
  StyledLastUpdated,
  StyledLearnMore,
  StyledSubtitleContainer,
  StyledTitle,
  StyledTitleText,
} from './styles';
import { Form, Typography } from '@loophealth/loop-ui-web-library';
import { IAnalyticsHeaderProps } from './types';
import ClaimsLearnMoreModal from '../ClaimsLearnMoreModal';
import { ALL_CLAIMS_CONSTANT } from '../../pages/ClaimsList/constants';
import { textPlaceholder } from '../../../utils/common/Utilities';

const AnalyticsHeader: FC<IAnalyticsHeaderProps> = ({
  title,
  subtitle,
  policiesOptions,
  selectedPolicyFilter,
  setSelectedPolicyFilter,
  showLearnMore = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const renderDropdown = (
    data: Record<string, unknown>,
    isSelected: boolean,
  ) => (
    <StyledDropdownItem $isVisible={!data.isHidden}>
      <Typography
        variant="small"
        weight={isSelected ? 'semiBold' : 'medium'}
        color={isSelected ? 'emerald' : 'primary'}
      >
        {data.name as string}
      </Typography>
      {!data.isHidden && (
        <Typography variant="extraSmall" weight="medium">
          {data.nickName as string}&nbsp;
          <Typography variant="extraSmall" color="secondary">
            | {data.insurer as string} | Policy No: {data.policyNo as string}
          </Typography>
        </Typography>
      )}
    </StyledDropdownItem>
  );

  const renderSubtitle = useCallback(() => {
    if (!subtitle) return <></>;
    return (
      <StyledSubtitleContainer data-testid="subtitle">
        {selectedPolicyFilter !== ALL_CLAIMS_CONSTANT && (
          <>
            <StyledLastUpdated data-testid="last-updated-at">
              <Typography variant="extraSmall" color="secondary">
                Last Updated: {textPlaceholder(subtitle?.lastUpdatedAt)}
              </Typography>
            </StyledLastUpdated>
            <StyledDot />
          </>
        )}
        <StyledLastUpdated data-testid="updated-frequency">
          <Typography variant="extraSmall" color="secondary">
            Updated {subtitle.isRealtime ? 'real time' : 'every 7-10 days'}
          </Typography>
        </StyledLastUpdated>
        {showLearnMore && (
          <StyledLearnMore
            onClick={() => setIsOpen(true)}
            data-testid="learn-more"
          >
            <Typography variant="extraSmall" color="emerald" weight="medium">
              Learn More
            </Typography>
          </StyledLearnMore>
        )}
      </StyledSubtitleContainer>
    );
  }, [subtitle, selectedPolicyFilter, showLearnMore]);

  return (
    <>
      <StyledAnalyticsHeader data-testid="analytics-header">
        <StyledTitle data-testid="analytics-title">
          <StyledTitleText>
            <Typography variant="title3" weight="medium">
              {title}
            </Typography>
            {renderSubtitle()}
          </StyledTitleText>
          <Typography variant="small" color="secondary" weight="medium">
            Showing for
          </Typography>
        </StyledTitle>

        <StyledFilter data-testid="analytics-dropdown">
          <Form.Dropdown
            placeholder="All policies"
            onClick={setSelectedPolicyFilter}
            selectedItem={selectedPolicyFilter}
            listItems={policiesOptions}
            renderItem={renderDropdown}
          />
        </StyledFilter>
      </StyledAnalyticsHeader>
      <ClaimsLearnMoreModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default AnalyticsHeader;
