import React, { FC } from 'react';
import {
  StyledGraphicContainer,
  StyledInfoCard,
  StyledInfoCardButtonContainer,
  StyledInfoCardContent,
  StyledInfoCardMainContent,
  StyledInfoContainer,
  StyledInfoImage,
  StyledInfoSubtitle,
  StyledInfoSubtitleContainer,
  StyledInfoTitleAndSubtitle,
  StyledInfoTitleContainer,
} from './styles';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { IInfoCardProps } from './types';
import { ArrowRightIcon, ClaimRejectedGraphic } from '../../../assets/images';

const InfoCard: FC<IInfoCardProps> = ({
  variant,
  titleText,
  titleCount,
  subtitles,
  buttonText,
  onClick,
}) => {
  return (
    <StyledInfoCard>
      <StyledInfoCardMainContent>
        <StyledInfoCardContent>
          <StyledInfoContainer>
            <StyledInfoTitleAndSubtitle>
              <StyledInfoTitleContainer>
                <Typography variant="title3">
                  {titleCount && (
                    <Typography variant="title3" color={variant}>
                      {titleCount}&nbsp;
                    </Typography>
                  )}
                  {titleText}
                </Typography>
              </StyledInfoTitleContainer>

              <StyledInfoSubtitleContainer>
                {subtitles?.map((subtitle) => (
                  <StyledInfoSubtitle key={subtitle.text}>
                    <Typography variant="small" color="secondary">
                      {subtitle.text}
                    </Typography>

                    <Typography variant="medium" weight="semiBold">
                      {subtitle.value}
                    </Typography>
                  </StyledInfoSubtitle>
                ))}
              </StyledInfoSubtitleContainer>
            </StyledInfoTitleAndSubtitle>
          </StyledInfoContainer>

          <StyledInfoCardButtonContainer>
            <LoopButton
              variant="secondary"
              size="medium"
              iconOrder="right"
              iconSrc={ArrowRightIcon}
              onClick={onClick}
            >
              {buttonText}
            </LoopButton>
          </StyledInfoCardButtonContainer>
        </StyledInfoCardContent>

        <StyledGraphicContainer>
          <StyledInfoImage src={ClaimRejectedGraphic} />
        </StyledGraphicContainer>
      </StyledInfoCardMainContent>
    </StyledInfoCard>
  );
};

export default InfoCard;
