import { GreenBackgroundPattern, GreyBackgroundPattern, ProfileAddIcon, ProfileDeleteIcon, ProfileTickIcon, RedBackgroundPattern } from "../../../assets/images";
import { ICardTypeConfig } from "./types";

export const CARD_TYPE_CONFIG: ICardTypeConfig = {
  'total': {
    title: 'Total Lives Uploaded',
    color: 'consultViolet',
    icon: ProfileAddIcon,
    bg: GreyBackgroundPattern
  },
  'correct': {
    title: 'Can be Endorsed',
    color: 'emerald',
    icon: ProfileTickIcon,
    bg: GreenBackgroundPattern
  },
  'incorrect': {
    title: 'Need to be Reviewed',
    color: 'error',
    icon: ProfileDeleteIcon,
    bg: RedBackgroundPattern
  },
}