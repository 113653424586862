import { IBulkDeleteData } from '../types';
import { IBulkDeleteExcelData } from './types';
import { read, utils } from 'xlsx';
import { Dispatch, SetStateAction } from 'react';
import { formatBackendDate } from '../../BulkAddMembers/UploadAddition/utils';
import { ITrackTask } from '../../../../../utils/segment/hooks/useSegment';
import { trackTaskEvent } from '../../../../../utils/segment/utils';

export const formatExcelDatesForBulkDelete = (
  excelMemberData: IBulkDeleteExcelData,
): IBulkDeleteData => {
  return {
    employeeId: excelMemberData['Employee ID']?.toString() || '',
    dateOfLeaving: formatBackendDate(
      excelMemberData['Date of Leaving (DD/MMM/YYYY)'] || '',
    ),
    reason: excelMemberData['Reason of Leaving']?.toString() || '',
  };
};

export const processFileUpload = async (
  files: Record<string, unknown>[],
  setSheetData: Dispatch<SetStateAction<IBulkDeleteData[]>>,
  setSheetSelected: Dispatch<SetStateAction<Record<string, unknown>[]>>,
  toast: any,
  trackTask: ITrackTask,
  pathname: string,
): Promise<void> => {
  if (!files.length) {
    setSheetSelected([]);
    setSheetData([]);
    return;
  }
  const file = files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    const data = e.target?.result;
    const workbook = read(data, { type: 'binary', cellDates: true});
    const sheetNames = workbook.SheetNames;
    const indexOfDelete = sheetNames.indexOf('Delete from All Policies');
    if (indexOfDelete) {
      const jsonData = utils.sheet_to_json(
        workbook.Sheets[sheetNames[indexOfDelete]],
        {
          range: 1,
          raw: true
        },
      );
      const jsonObject = [...JSON.parse(JSON.stringify(jsonData))];
      const filteredRecords = jsonObject.map((data) =>
        formatExcelDatesForBulkDelete(data as IBulkDeleteExcelData),
      );
      if (filteredRecords.length) {
        setSheetData(filteredRecords);
        trackTask(trackTaskEvent('Load_Upload_Proceed_Button_add', pathname));
      } else {
        setSheetSelected([]);
        toast.error('Empty File');
      }
    } else {
      setSheetSelected([]);
      toast.error('Invalid Format');
    }
  };
  reader.readAsBinaryString(file as unknown as Blob);
  setSheetSelected(files);
};
