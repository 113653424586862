import {
  LABEL_MAPPING,
  POLICY_TERMS_TYPE_MAPPING,
  VALUE_MAPPING,
} from './constants';
import {
  IPolicyTerm,
  IPolicyTermLabel,
  IClassifyPolicyTerms,
  IPolicyType,
} from './../types';
import { IPolicyTermsAndConditions } from './../../../pages/PolicyDetails/types';
import { groupArrayOfObjectsByKey } from '../../../../utils/common/Utilities';

export const classifyPolicyTerms = (
  terms: IPolicyTermsAndConditions,
  policyType: IPolicyType,
) => {
  const entries = Object.entries(terms)
    .map((item) => mapPolicyTerms(item, policyType))
    .filter((item: IPolicyTerm | null): item is IPolicyTerm => !!item)
    .sort((term1, term2) => {
      if (term1 && term2) {
        return term1.label > term2.label
          ? 1
          : term2.label > term1.label
          ? -1
          : 0;
      }
      return 0;
    });

  const incAndExc = groupArrayOfObjectsByKey(
    entries,
    (term: IPolicyTerm) => term.type || '',
  );
  return incAndExc as unknown as IClassifyPolicyTerms;
};

const mapPolicyTerms = (
  term: string[],
  policyType: IPolicyType,
): IPolicyTerm | null => {
  const mapping = POLICY_TERMS_TYPE_MAPPING[term[0] as IPolicyTermLabel];
  if (!mapping?.type || !mapping.displayForType.includes(policyType)) {
    return null;
  }

  if (mapping.type === 'PARTIAL' || VALUE_MAPPING.hasOwnProperty(term[0])) {
    return {
      label: LABEL_MAPPING.hasOwnProperty(term[0])
        ? LABEL_MAPPING[term[0]][term[1].toLowerCase()] ?? mapping.label
        : mapping.label,
      value: VALUE_MAPPING.hasOwnProperty(term[0])
        ? VALUE_MAPPING[term[0]][term[1].toLowerCase()] ?? term[1]
        : term[1],
      type: parseType(term[1]),
    };
  } else {
    return {
      label: LABEL_MAPPING.hasOwnProperty(term[0])
        ? LABEL_MAPPING[term[0]][term[1].toLowerCase()] ?? mapping.label
        : mapping.label,
      type: parseType(term[1]),
    };
  }
};

const parseType = (value: string | number) => {
  const val = value.toString().toLowerCase();
  return val.includes('not') || val.includes('Not') || val === ''
    ? 'exclusion'
    : 'inclusion';
};
