import React from 'react';
import { IGenericIconProps } from './types';

const StatusIcon: React.FC<IGenericIconProps> = ({
  width = 18,
  height = 18,
  fill = '#BCDD33',
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.4" cx="9" cy="8.52942" r="5" fill={fill} />
    <circle cx="9" cy="8.52942" r="3" fill={fill} />
  </svg>
);

export { StatusIcon };
