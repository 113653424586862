import styled from 'styled-components';

export const StyledEmployeeStatistics = styled.section`
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  flex: 1;
`;

export const PieChartContainer = styled.div`
  gap: 24px;
  display: flex;
  padding: 24px 32px;
  max-width: 557px;
  flex-direction: column;
  height: 100%;

  & > :last-child{
    flex: 1;
  }

`;

export const StyledPieChartContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;

  > div {
    flex: 1;
  }
`;
