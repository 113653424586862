import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  padding: 28px 56px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledDivider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.seafoamGray};
`;

export const StyledWrapperDate = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
