import { createSlice } from '@reduxjs/toolkit';
import {
  cdAccountsInitialState,
  claimsInitialState,
  endorsementsInitialState,
  initialState,
  policiesInitialState,
} from './initialState';
import {
  fetchAllActivePolicies,
  fetchCDAccounts,
  fetchClaimList,
  fetchEndorsments,
} from './thunk';
import { RootState } from '../../store';

const hrdRevampRedux = createSlice({
  name: 'hrdRevampRedux',
  initialState: initialState,
  reducers: {
    setSelectedCompany: (state, action) => {
      state.policies.selectedCompany = action.payload.selectedCompany;
      state.policies.data = [];
      state.claims.data = [];
      state.cdAccounts.data = [];
    },
    setDemoMode: (state, action) => {
      if (state.policies.selectedCompany)
        state.policies.selectedCompany.isDemoMode = action.payload;
    },
  },
  extraReducers: {
    [fetchAllActivePolicies.pending.toString()]: (state) => {
      state.policies.status = 'loading';
      state.policies.error = '';
    },
    [fetchAllActivePolicies.fulfilled.toString()]: (state, action) => {
      if (action.payload.companyId === state.policies.selectedCompany?.id) {
        state.policies.data = action.payload.policies;
        state.policies.filters = action.payload.filters;
        state.policies.status = 'succeeded';
      }
    },
    [fetchAllActivePolicies.rejected.toString()]: (state, action) => {
      state.policies.status = 'failed';
      state.policies.data = [];
      state.policies.error = action.error?.message;
      state.policies.filters = [];
    },
    [fetchCDAccounts.pending.toString()]: (state) => {
      state.cdAccounts.status = 'loading';
      state.cdAccounts.error = '';
    },
    [fetchCDAccounts.fulfilled.toString()]: (state, action) => {
      state.cdAccounts.status = 'succeeded';
      state.cdAccounts.data = action.payload.cdAccounts;
    },
    [fetchCDAccounts.rejected.toString()]: (state, action) => {
      state.cdAccounts.status = 'failed';
      state.cdAccounts.error = action.error?.message;
    },
    [fetchClaimList.pending.toString()]: (state) => {
      state.claims.status = 'loading';
      state.claims.error = '';
    },
    [fetchClaimList.fulfilled.toString()]: (state, action) => {
      state.claims.status = 'succeeded';
      state.claims.data = action.payload.claims;
    },
    [fetchClaimList.rejected.toString()]: (state, action) => {
      state.claims.status = 'failed';
      state.claims.error = action.error?.message;
    },
    [fetchEndorsments.pending.toString()]: (state) => {
      state.endorsements.status = 'loading';
      state.endorsements.error = '';
    },
    [fetchEndorsments.fulfilled.toString()]: (state, action) => {
      state.endorsements.status = 'succeeded';
      state.endorsements.data = action.payload.endorsements;
    },
    [fetchEndorsments.rejected.toString()]: (state, action) => {
      state.endorsements.status = 'failed';
      state.endorsements.error = action.error?.message;
    },
    'common/cleanup': (state, action) => {
      state.policies = { ...policiesInitialState };
      state.claims = { ...claimsInitialState };
      state.cdAccounts = { ...cdAccountsInitialState };
      state.endorsements = { ...endorsementsInitialState };
    },
    'common/demoCleanup': (state, action) => {
      state.policies = {
        ...policiesInitialState,
        selectedCompany: state.policies.selectedCompany,
      };
      state.claims = { ...claimsInitialState };
      state.cdAccounts = { ...cdAccountsInitialState };
      state.endorsements = { ...endorsementsInitialState };
    },
  },
});

export const selectSelectedCompany = (state: RootState) =>
  state.hrdRevamp.policies.selectedCompany;
export const selectPoliciesList = (state: RootState) =>
  state.hrdRevamp.policies;
export const selectCDAccountsList = (state: RootState) =>
  state.hrdRevamp.cdAccounts;
export const selectClaimList = (state: RootState) => state.hrdRevamp.claims;
export const selectEndorsementList = (state: RootState) =>
  state.hrdRevamp.endorsements;
export const selectIsDemoMode = (state: RootState) =>
  state.hrdRevamp.policies.selectedCompany?.isDemoMode;

export const { setSelectedCompany, setDemoMode } = hrdRevampRedux.actions;

export default hrdRevampRedux.reducer;
