import React, { FC } from 'react';
import { Modal, Typography } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { IWelcomeModalProps } from './types';
import {
  StyledBodyContainer,
  StyledCrossIcon,
  StyledHighlightNoteContainer,
  StyledImageContainer,
  StyledMainImage,
  StyledModalContainer,
  StyledSubtitleContainer,
} from './styles';
import { WelcomeModalGraphic } from '../../../assets/images';
import CrossOutlineThinIcon from '../../../assets/icons/CrossOutlineThinIcon';

const WelcomeModal: FC<IWelcomeModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isVisible={isOpen} setIsVisible={onClose} disablePadding>
      <WithThemeProvider>
        <StyledModalContainer>
          <StyledImageContainer>
            <StyledMainImage
              data-testid="welcome-graphic"
              src={WelcomeModalGraphic}
              alt="Welcome"
            />
            <StyledCrossIcon data-testid="close-button" onClick={onClose}>
              <CrossOutlineThinIcon />
            </StyledCrossIcon>
          </StyledImageContainer>
          <StyledBodyContainer>
            <Typography variant="title2" weight="medium" color="primary">
              Welcome to your Dashboard!
            </Typography>
            <StyledSubtitleContainer>
              <Typography variant="small" color="secondary" weight="regular">
                Use your dashboard to manage all your insurance information. Get
                timely updates and a full overview of claims, CD balance, policy
                information and e-cards.
              </Typography>
            </StyledSubtitleContainer>
            <StyledHighlightNoteContainer>
              <Typography variant="extraSmall" color="secondary">
                Please note that some information may not be available on the
                dashboard right now. It will be updated shortly.
              </Typography>
            </StyledHighlightNoteContainer>
          </StyledBodyContainer>
        </StyledModalContainer>
      </WithThemeProvider>
    </Modal>
  );
};

export default WelcomeModal;
