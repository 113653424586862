import { SEGMENT_ACTIONS } from '../../../utils/segment/SegmentActionConstants';

export enum LoginErrorCode {
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
  POPUP_CLOSED_BY_USER = 'auth/popup-closed-by-user',
  CANCELLED_POPUP_REQUEST = 'auth/cancelled-popup-request',
  INVALID_VERIFICATION_CODE = 'auth/invalid-verification-code',
}

export type IClickAction = keyof typeof SEGMENT_ACTIONS.CLICK;
export type ITaskAction = keyof typeof SEGMENT_ACTIONS.TASK;