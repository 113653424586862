import {
  StyledNameDetailsContainer,
  StyledNameContainer,
  StyledOverWrapper,
  StyledEmpDetailsContainer,
  StyledSeparator,
  StyleContainer,
} from './styles';

import { Avatar, Typography } from '@loophealth/loop-ui-web-library';
import { capitalizeFirstLetterOfEachWord, textPlaceholder } from '../../../utils/common/Utilities';
import EmpInfoItem from './EmpInfoItem';
import { HealthCareBadgeIcon } from '../../../assets/images';
import { IEmpOverviewDetails } from './types';
import { InsuranceStatusValues } from '../../pages/EmployeeDetails/utils';

const EmployeeDetailsOverview: React.FunctionComponent<IEmpOverviewDetails> = ({
  empDetail,
}) => {
  return (
    <StyledOverWrapper>
      <StyleContainer>
        <StyledNameContainer>
          {empDetail.firstName && (
            <Avatar
              users={[
                {
                  name: empDetail.firstName,
                  imageURL: '',
                },
              ]}
              size="extralarge"
            />
          )}

          <StyledNameDetailsContainer>
            <Typography variant="medium" weight="semiBold" color="primary">
              {textPlaceholder(empDetail.firstName)}
            </Typography>
            <Typography variant="small" color="secondary">
              {`Employee ID: ${textPlaceholder(empDetail.employeeId)}`}
            </Typography>
          </StyledNameDetailsContainer>
        </StyledNameContainer>
        {empDetail.insuranceStatus === InsuranceStatusValues.INSURED ||
        empDetail.insuranceStatus === InsuranceStatusValues.IN_PROGRESS ? (
          <img src={HealthCareBadgeIcon} alt="" />
        ) : null}
      </StyleContainer>

      <StyledEmpDetailsContainer>
        <EmpInfoItem
          label="Gender"
          value={capitalizeFirstLetterOfEachWord(empDetail.gender)}
        />
        <StyledSeparator />
        <EmpInfoItem label="DOB" value={empDetail.dob ?? '--'} />
        <StyledSeparator />
        <EmpInfoItem label="Contact Number" value={empDetail.mobile ?? '--'} />
        <StyledSeparator />
        <EmpInfoItem label="Email" value={empDetail.email ?? '--'} />
      </StyledEmpDetailsContainer>
    </StyledOverWrapper>
  );
};
export default EmployeeDetailsOverview;
