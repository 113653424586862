import styled from 'styled-components';

export const StyledInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  padding: 24px;
  height: 100%;
  max-width: 557px;
  gap: 8px;
  flex: 1;
  background-color: ${(p) => p.theme.colors.surfaceOne};
  box-sizing: border-box;
`;

export const StyledInfoCardMainContent = styled.div`
  position: relative;
  gap: 10px;
  display: flex;
`;

export const StyledInfoCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 216px;
  flex: 1;
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const StyledGraphicContainer = styled.div`
  width: 220px;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.mintFrost};
`;

export const StyledInfoImage = styled.img`
  object-fit: contain;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledInfoTitleAndSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledInfoTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInfoSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 20px;
`;

export const StyledInfoSubtitleDivider = styled.div`
  width: 1px;
  height: 32px;
  border-radius: 8px;
  margin: 0 16px;
  background-color: ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledInfoSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const StyledInfoCardButtonContainer = styled.div`
  max-width: fit-content;
`;
