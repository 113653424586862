import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(p) => p.theme.colors.supportingPlaneGreenDark};

  border-radius: 12px;
`;

export const StyledImage = styled.img``;

export const StyledContentContainer = styled.div`
  display: flex;
  padding: 32px 36px;
  flex-direction: column;
  gap: 8px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 60%;
  gap: 10px;
  margin-top: 40px;
`;
