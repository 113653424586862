import { useEffect, useState } from 'react';
import { parseResponse } from '../../common/Utilities';
import { ISearchResultResponseUserType } from '../../../components/containers/TopNavigation/types';
import GlobalSearchAPI from '../../../adaptars/globalSearchApi';
import { Toast } from '@loophealth/loop-ui-web-library';

export const useFetchGlobalSearchData = (
  companyId: string | undefined,
  searchString: string,
  defaultLoadingState = true,
  includeDependents = false,
  includePolicies = false,
) => {
  const toast = Toast.useToast();
  const [loading, toggleLoading] = useState<boolean>(defaultLoadingState);
  const [searchResults, setSearchResults] = useState<
    ISearchResultResponseUserType[]
  >([]);

  useEffect(() => {
    if (searchString.length >= 3 && companyId) {
      (async function () {
        toggleLoading(true);
        const [error, result] = await parseResponse(
          GlobalSearchAPI.getGlobalSearchData(companyId, searchString, includeDependents, includePolicies),
        );

        if (error) {
          toast?.error((error as Error).message)
          console.error(error);
          return;
        }

        setSearchResults(result?.data.users);
        toggleLoading(false);
      })();
    }
  }, [companyId, searchString]);

  return {
    searchResults,
    loading,
  };
};
