import { LoopBadge, Typography } from '@loophealth/loop-ui-web-library';
import { StyledClaimIdUnderline } from '../../../pages/ClaimsList/styles';
import {
  capitalizeFirstLetterOfEachWord,
  getDisplayTextFromUpper,
} from '../../../../utils/common/Utilities';
import {
  InsuranceStatusValues,
  getBadgeType,
} from '../../../pages/EmployeeDetails/utils';

export const EMPLOYEE_TABLE_HEADERS_WITH_DEPENDENTS = [
  { name: 'Member name', flex: 6 },
  { name: 'Employee ID', flex: 5 },
  { name: 'Employee Name', flex: 6 },
  { name: 'Sum Insured', flex: 4 },
  { name: 'Relationship', flex: 4 },
  { name: 'Insurance Status', flex: 4.1 },
];

export const EMPLOYEE_TABLE_HEADERS = [
  { name: 'Employee Name', flex: 5 },
  { name: 'Employee ID', flex: 4 },
  { name: 'Sum Insured', flex: 3 },
  { name: 'Insurance Status', flex: 2 },
];

const FirstColumnProcessor = {
  cell: function <T>(value: T) {
    return (
      <StyledClaimIdUnderline>
        <Typography color="emerald" variant="small">
          {value as string}
        </Typography>
      </StyledClaimIdUnderline>
    );
  },
};

const InsuranceBadgeProcessor = {
  cell: function <T>(value: T, _: number, data: Record<string, unknown>) {
    const badgeType = getBadgeType(value as InsuranceStatusValues);
    return (
      <>
        {value ? (
          <LoopBadge
            key={data.id as string}
            badgeType={badgeType}
            variant={'overflow'}
            isAnimated={InsuranceStatusValues.INSURED === value}
            text={capitalizeFirstLetterOfEachWord(
              getDisplayTextFromUpper(value as string),
            )}
            fullWidth
          />
        ) : null}
      </>
    );
  },
};

export const EMPLOYEE_TABLE_PROCESSOR = {
  'Employee Name': FirstColumnProcessor,
  'Insurance Status': InsuranceBadgeProcessor,
};

export const EMPLOYEE_TABLE_PROCESSOR_WITH_DEPENDENTS = {
  'Member name': FirstColumnProcessor,
  'Insurance Status': InsuranceBadgeProcessor,
};
