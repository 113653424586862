import styled from 'styled-components';

export const StyledSingleDeleteFaqWrapper = styled.div`
  position: relative;
`;

export const StyledSingleDeleteFaq = styled.div`
  position: absolute;
  bottom: 56px;
  left: 0;
  background-color: ${(p) => p.theme.colors.white};
  width: 400px;
  border-radius: 12px;
  border: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledSingleDeleteFaqContentWrapper = styled.div`
  padding: 24px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledSingleDeleteFaqContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledSingleDeleteFaqHeader = styled.div`
  display: flex;
  gap: 24px;
  align-items: start;
`;

export const StyledSingleDeleteFaqHeaderText = styled.div`
  flex: 1;
`;

export const StyledSingleDeleteFaqList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledSingleDeleteFaqListItem = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledSingleDeleteFaqSeperator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledSingleDeleteFaqNote = styled.div`
  display: flex;
  padding: 8px;
  gap: 12px;
  border-radius: 6px;
  background-color: ${(p) => p.theme.colors.lightSilver};
  align-items: center;
`;

export const StyledSingleDeleteFaqIconWrapper = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledSingleDeleteFaqIcon = styled.img`
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
`;

export const StyledSingleDeleteFaqImportantBadge = styled.div`
  position: absolute;
  width: 91px;
  height: 32px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(p) => p.theme.colors.planRed};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
