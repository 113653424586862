import { Dispatch, SetStateAction } from 'react';

export enum IHRDashboardFilterType {
  All,
  GMC,
  GPA,
  GTL,
}

export interface IPolicyHeaderProps {
  filters: IHRDashboardFilterType[];
  activeFilter: IHRDashboardFilterType;
  setActiveFilter: Dispatch<SetStateAction<IHRDashboardFilterType>>;
}
