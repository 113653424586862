import { Carousel, Typography } from '@loophealth/loop-ui-web-library';
import { useState } from 'react';
import {
  ArrowRightCircleIcon,
  CdBalancePurpleIcon,
  PeoplePurpleIcon,
  PeopleWhiteIcon,
  StarFourIcon,
} from '../../../../assets/images';
import {
  formatCurrencyAmount,
  textPlaceholder,
} from '../../../../utils/common/Utilities';
import { IPolicyCoverageSlabItem, IPolicyCoverageSlabs } from '../types';
import {
  StyledPolicyCoverageNavigation,
  StyledPolicyCoverageNavigationIcon,
  StyledPolicyCoveragePagination,
  StyledPolicyCoverageSlabItem,
  StyledPolicyCoverageSlabs,
  StyledPolicyCoverageSlabsContainer,
  StyledPolicyCoverageSlabsHeader,
  StyledPolicySlabDetails,
  StyledPolicySlabDetailsItem,
  StyledPolicySlabDetailsItemText,
} from './styles';

const PolicyCoverageSlabItem = ({
  slab,
  isCtcMultiplier,
}: IPolicyCoverageSlabItem) => {
  const sumInsured = isCtcMultiplier
    ? `${slab.sumInsured}x CTC`
    : formatCurrencyAmount(slab.sumInsured);
  return (
    <StyledPolicyCoverageSlabItem>
      <Typography variant="small" weight="semiBold">
        Slab&nbsp;{slab.slabId}
      </Typography>
      <StyledPolicySlabDetails>
        <StyledPolicySlabDetailsItem>
            <img src={CdBalancePurpleIcon} />
          <StyledPolicySlabDetailsItemText>
            <Typography variant="small" color='secondary'>Sum Insured</Typography>
            <Typography variant="medium" weight="medium">
              {sumInsured}
            </Typography>
          </StyledPolicySlabDetailsItemText>
        </StyledPolicySlabDetailsItem>
        <StyledPolicySlabDetailsItem>
            <img src={PeoplePurpleIcon} />
          <StyledPolicySlabDetailsItemText>
            <Typography variant="small" color='secondary'>Family Structure</Typography>
            <Typography variant="medium" weight="medium">
              {textPlaceholder(slab.familyDefinition?.value)}
            </Typography>
          </StyledPolicySlabDetailsItemText>
        </StyledPolicySlabDetailsItem>
      </StyledPolicySlabDetails>
    </StyledPolicyCoverageSlabItem>
  );
};

const PolicyCoverageSlabs = ({
  slabs,
  isCtcMuliplier,
}: IPolicyCoverageSlabs) => {
  const [currentStep, setCurrentStep] = useState(0);

  const carouselLength = Math.trunc(slabs.length / 2) + (slabs.length % 2);
  const slabIndex = currentStep * 2;
  const currentSlabs = slabs.slice(slabIndex, slabIndex + 2);

  const isMultiplePages = carouselLength > 1;
  const isPrev = isMultiplePages && currentStep !== 0;
  const isNext = isMultiplePages && currentStep < carouselLength - 1;

  const handleNext = () =>
    isNext && setCurrentStep((currentStep) => currentStep + 1);
  const handlePrev = () =>
    isPrev && setCurrentStep((currentStep) => currentStep - 1);

  return (
    <StyledPolicyCoverageSlabs>
      <StyledPolicyCoverageSlabsHeader>
        <img src={StarFourIcon} />
        <Typography variant="medium" weight="semiBold">
          Slabs&nbsp;&#40;{slabs.length}&#41;
        </Typography>
      </StyledPolicyCoverageSlabsHeader>
      <StyledPolicyCoveragePagination>
        <StyledPolicyCoverageNavigation>
          {isPrev && (
            <StyledPolicyCoverageNavigationIcon
              src={ArrowRightCircleIcon}
              onClick={handlePrev}
              flip
            />
          )}
          <StyledPolicyCoverageSlabsContainer>
            {currentSlabs.map((slab) => (
              <PolicyCoverageSlabItem
                slab={slab}
                key={slab.slabId}
                isCtcMultiplier={isCtcMuliplier}
              />
            ))}
          </StyledPolicyCoverageSlabsContainer>
          {isNext && (
            <StyledPolicyCoverageNavigationIcon
              src={ArrowRightCircleIcon}
              onClick={handleNext}
            />
          )}
        </StyledPolicyCoverageNavigation>
        {isMultiplePages && (
          <Carousel
            steps={carouselLength}
            setStep={setCurrentStep}
            activeStep={currentStep}
            variant="points"
          />
        )}
      </StyledPolicyCoveragePagination>
    </StyledPolicyCoverageSlabs>
  );
};

export default PolicyCoverageSlabs;
