import { IReadMe } from './types';

export const CTC_MULTIPLIER_SCHEMA = [
  { name: 'CTC', type: 'Numerical', allowed: '0-9', isMandatory: 'Mandatory' },
];
export const FLAT_SUM_SCHEMA = [
  {
    name: 'Sum Insured',
    type: 'Numerical',
    allowed: '0-9',
    isMandatory: 'Mandatory',
  },
];
export const GRADED_TIER_SCHEMA = [
  {
    name: 'Designation or Grade',
    type: 'Alphanumeric',
    allowed: 'A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Sum Insured',
    type: 'Numerical',
    allowed: '0-9',
    isMandatory: 'Mandatory',
  },
];

export const CONTACT_DATA_SCHEMA = [
  {
    name: 'Mobile',
    type: 'Numerical (10 digits, exclude country codes)',
    allowed: '0-9',
    isMandatory: 'For app access',
  },
  {
    name: 'Email',
    type: 'Alphanumeric',
    allowed: 'A-Z, a-z, 0-9, @, .',
    isMandatory: 'For app access',
  },
];

export const ERROR_DATA_SCHEMA = {
  name: 'Error Description',
  type: 'Alphanumeric',
  allowed: 'A-Z, a-z, 0-9, @, .',
  isMandatory: 'Informational',
};

export const COMMON_DATA_SCHEMA = [
  {
    name: 'S No.',
    type: 'Numerical',
    allowed: '0-9',
    isMandatory: 'Optional',
  },
  {
    name: 'Employee ID',
    type: 'Alphanumeric',
    allowed: 'Permissible characters: A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Member Name',
    type: 'Alphanumeric',
    allowed: 'Permissible characters: A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Relationship',
    type: 'Alphanumeric',
    allowed: 'Self, Spouse, Child, Parent, Parent in law',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Gender (Male/Female)',
    type: 'Alphanumeric',
    allowed: 'Male, Female',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Date of Joining (DD/MMM/YYYY)',
    type: 'Date in DD/MMM/YYYY (eg. 01/Jan/2024)',
    allowed: 'A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Date of Birth (DD/MMM/YYYY)',
    type: 'Date in DD/MMM/YYYY (eg. 01/Jan/2024)',
    allowed: 'A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
];

export const ADD_READ_ME: IReadMe = {
  INSTRUCTIONS:
    // eslint-disable-next-line max-len
    `We've noted down some general instructions that you should help you while uploading the endorsements data. If you still have any doubts or concerns, please reach out to respective CSM.`,
  DATA_SCHEMA: [
    {
      name: 'S No.',
      type: 'Numerical',
      allowed: '0-9',
      isMandatory: 'Optional',
    },
    {
      name: 'Employee ID',
      type: 'Alphanumeric',
      allowed: 'Permissible characters: A-Z, a-z, 0-9',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Member Name',
      type: 'Alphanumeric',
      allowed: 'Permissible characters: A-Z, a-z, 0-9',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Relationship',
      type: 'Alphanumeric',
      allowed: 'Self, Spouse, Child, Parent, Parent in law',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Gender (Male/Female)',
      type: 'Alphanumeric',
      allowed: 'Male, Female',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Date of Joining (DD/MMM/YYYY)',
      type: 'Date in DD/MMM/YYYY (eg. 01/Jan/2024)',
      allowed: 'A-Z, a-z, 0-9',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Date of Birth (DD/MMM/YYYY)',
      type: 'Date in DD/MMM/YYYY (eg. 01/Jan/2024)',
      allowed: 'A-Z, a-z, 0-9',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Designation or Grade',
      type: 'Alphanumeric',
      allowed: 'A-Z, a-z, 0-9',
      isMandatory: 'Conditional',
    },
    {
      name: 'CTC',
      type: 'Numerical',
      allowed: '0-9',
      isMandatory: 'Conditional',
    },
    {
      name: 'Sum Insured',
      type: 'Numerical',
      allowed: '0-9',
      isMandatory: 'Conditional',
    },
    {
      name: 'Mobile',
      type: 'Numerical (10 digits, exclude country codes)',
      allowed: '0-9',
      isMandatory: 'For app access',
    },
    {
      name: 'Email',
      type: 'Alphanumeric',
      allowed: 'A-Z, a-z, 0-9, @, .',
      isMandatory: 'For app access',
    },
  ],
};

export const HeaderColors: Record<string, Record<string, string>> = {
  Optional: {
    infoColor: 'b45f07',
    infoFillColor: 'fce5cd',
    columnColor: 'b45f07',
  },
  'For app access': {
    infoColor: 'b45f07',
    infoFillColor: 'fce5cd',
    columnColor: 'b45f07',
  },
  Informational: {
    infoColor: 'cc0100',
    infoFillColor: 'f3cccb',
    columnColor: '990001',
  },
  Mandatory: {
    infoColor: '274e12',
    infoFillColor: 'd9ead3',
    columnColor: '274e12',
  },
};
