import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

export const StyledDetailsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 56px;
  gap: 16px;
  border-radius: 12px;
`;
export const StyledPolicyDetailsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    width: auto;
  }
`;
export const StyledPolicyInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
export const StyledCountSummary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
export const StyledAlertText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StyledDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 48px;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  gap: 16px;
`;

export const StyledTimelineWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 60px 56px;
  background-color: ${(p) => p.theme.colors.white};
`;

export const StyledEmptyStateContainer = styled.div`
  padding: 40px 56px;
`;

export const StyledTableContainer = styled.div`
  margin-bottom: 100px;
  text-transform: capitalize;
`;

export const StyledPolicyListItemHeaderInsurerInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledPolicyListItemHeaderInsurerDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
export const StyledBullet = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors?.seafoamGray};
`;

export const StyledBackIcon = styled.img`
  cursor: pointer;
`;

export const StyledDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 20px;
  align-items: center;
  background-color: ${(p) => p.theme.colors.white};
  cursor: pointer;
`;