import React, { useEffect, useMemo } from 'react';
import { IValidationsScreen } from './types';
import {
  StyledChipsContainer,
  StyledHowToFixModal,
  StyledHowToFixModalContent,
  StyledHowToFixModalFooter,
  StyledStepItem,
  StyledStepItemIcon,
  StyledStepItemText,
  StyledStepsContainer,
  StyledSummaryCards,
  StyledValidationSummaryHeader,
  StyledValidationsScreen,
} from './styles';
import ValidationSummaryCard from '../../atoms/ValidationSummaryCard';
import {
  getDeleteValidationSummaryData,
  getAddErrorTableColumns,
  getPolicyListCount,
  getAddValidationSummaryData,
  getSegmentPolicyData,
} from './utils';
import {
  Chip,
  LoopButton,
  Modal,
  PaginatedTable,
  Typography,
} from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { FixErrorsImage } from '../../../assets/images';
import { DELETE_TABLE_COLUMNS, HOW_TO_FIX_STEPS } from './constants';
import { useSelector } from 'react-redux';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';
import { trackTaskEvent } from '../../../utils/segment/utils';

const ValidationsScreen: React.FC<IValidationsScreen> = ({
  operation,
  rejectedEntries,
  uploadedData,
}) => {
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const location = useLocation();
  const [isHowToFixVisible, setHowToFixVisible] = React.useState(false);
  const policiesData = useSelector(selectPoliciesList);

  const POLICY_CHIPS =
    operation === 'DELETE'
      ? []
      : getPolicyListCount(rejectedEntries, policiesData.data);
  const [selectedPolicy, setSelectedPolicy] = React.useState<string>(
    POLICY_CHIPS[0]?.id,
  );

  const summaryData = useMemo(
    () =>
      operation === 'DELETE'
        ? getDeleteValidationSummaryData(uploadedData, rejectedEntries)
        : getAddValidationSummaryData(
            uploadedData,
            rejectedEntries,
            policiesData.data,
          ),
    [uploadedData, rejectedEntries, policiesData],
  );
  const tableProps: any = useMemo(
    () =>
      operation === 'DELETE'
        ? {
            columns: DELETE_TABLE_COLUMNS,
            data: rejectedEntries,
            count: rejectedEntries.length,
          }
        : {
            columns: getAddErrorTableColumns(selectedPolicy, policiesData.data),
            data: rejectedEntries[selectedPolicy] || [],
            count: (rejectedEntries[selectedPolicy] || []).length,
          },
    [selectedPolicy, policiesData],
  );

  const handleHowToFixErrorClick = () => {
    setHowToFixVisible(true);
    if (operation === 'ADD') {
      trackClick(
        trackClickEvent(
          'How_Fix_Error_add',
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
    } else if (operation === 'DELETE') {
      trackClick(
        trackClickEvent(
          'How_Fix_Error_del',
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
    }
  };

  useEffect(() => {
    trackTask(
      trackTaskEvent(
        `Load_Error_Table_${operation.toLowerCase()}`,
        location.pathname,
        '',
        {
          record: undefined,
          ...getSegmentPolicyData(summaryData, rejectedEntries, operation),
        },
      ),
    );
  }, []);

  const onIUnderstandClick = () => {
    setHowToFixVisible(false);
    trackClick(
      trackClickEvent(
        `I_Understand_${operation.toLowerCase()}`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  return (
    <StyledValidationsScreen>
      <StyledSummaryCards>
        <ValidationSummaryCard
          type="total"
          totalLives={summaryData.total.totalLives}
          policyLives={summaryData.total.policyLives}
        />
        <ValidationSummaryCard
          type="correct"
          totalLives={summaryData.correct.totalLives}
          policyLives={summaryData.correct.policyLives}
        />
        <ValidationSummaryCard
          type="incorrect"
          totalLives={summaryData.incorrect.totalLives}
          policyLives={summaryData.incorrect.policyLives}
        />
      </StyledSummaryCards>
      <StyledValidationSummaryHeader>
        <Typography variant="large" weight="medium">
          Need to be Reviewed ({summaryData.incorrect.totalLives})
        </Typography>
        <div>
          <LoopButton
            variant="outline"
            onClick={handleHowToFixErrorClick}
            size="small"
          >
            How to Fix Errors
          </LoopButton>
        </div>
      </StyledValidationSummaryHeader>
      <PaginatedTable
        rowsPerPage={10}
        tableHeader={
          <StyledChipsContainer>
            {POLICY_CHIPS.map((item) => (
              <Chip
                label={item.type}
                key={item.id}
                isActive={selectedPolicy === item.id}
                onClick={() => setSelectedPolicy(item.id)}
              />
            ))}
          </StyledChipsContainer>
        }
        {...tableProps}
        fullWidth
      />
      <Modal
        isVisible={isHowToFixVisible}
        setIsVisible={setHowToFixVisible}
        disablePadding
      >
        <WithThemeProvider>
          <StyledHowToFixModal>
            <StyledHowToFixModalContent>
              <Typography variant="large" weight="medium">
                How to Fix Errors
              </Typography>
              <img src={FixErrorsImage} />
              <StyledStepsContainer>
                {HOW_TO_FIX_STEPS.map((step, index) => (
                  <StyledStepItem key={step.title}>
                    <StyledStepItemIcon $isLast={index === 2}>
                      <Typography
                        variant="extraSmall"
                        weight="medium"
                        color="tertiary"
                      >
                        {index + 1}
                      </Typography>
                    </StyledStepItemIcon>
                    <StyledStepItemText>
                      <Typography variant="medium" weight="medium">
                        {step.title}
                      </Typography>
                      <Typography variant="small" color="secondary">
                        {step.subText}
                      </Typography>
                    </StyledStepItemText>
                  </StyledStepItem>
                ))}
              </StyledStepsContainer>
            </StyledHowToFixModalContent>
            <StyledHowToFixModalFooter>
              <LoopButton
                variant="filled"
                size="large"
                onClick={onIUnderstandClick}
              >
                I Understand
              </LoopButton>
            </StyledHowToFixModalFooter>
          </StyledHowToFixModal>
        </WithThemeProvider>
      </Modal>
    </StyledValidationsScreen>
  );
};

export default ValidationsScreen;
