import {
  OutsideClickWrapper,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { useState } from 'react';
import {
  EditIcon,
  EditOutlinedIcon,
  MoreIcon,
  TrashIcon,
} from '../../../../../assets/images';
import WithThemeProvider from '../../../../../theme/WithThemeProvider';
import {
  StyledActionsMenu,
  StyledActionsMenuIcon,
  StyledActionsMenuItem,
  StyledMenuContainer,
  StyledMoreActionsIcon,
} from './styles';
import { ITableActionMenu } from './types';
import { useLocation } from 'react-router-dom';
import useSegment from '../../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../../utils/segment/utils';

const TableActionMenu: React.FC<ITableActionMenu> = ({
  index,
  onEditClick,
  onDeleteClick,
}) => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const toggleMenu = () => setIsMenuVisible((prev) => !prev);
  const trackSegmentClickEvent = (
    eventName: string,
    properties?: Record<string, any>,
  ) => {
    trackClick(
      trackClickEvent(
        eventName,
        location?.pathname,
        extractPathName(location?.pathname),
        {
          clickProperties: properties,
        },
      ),
    );
  };
  const handleEditClick = () => {
    trackSegmentClickEvent('Action_Bulk_Edit_Page_edit', {
      type: 'edit',
    });
    onEditClick(index);
    setIsMenuVisible(false);
  };
  const handleDeleteClick = () => {
    trackSegmentClickEvent('Action_Bulk_Edit_Page_edit', {
      type: 'delete',
    });
    onDeleteClick(index);
  };
  return (
    <OutsideClickWrapper onClickOutside={() => setIsMenuVisible(false)}>
      <StyledMenuContainer>
        <WithThemeProvider>
          <StyledMoreActionsIcon src={MoreIcon} onClick={toggleMenu} />
          {isMenuVisible && (
            <StyledActionsMenu>
              <StyledActionsMenuItem onClick={handleEditClick}>
                <StyledActionsMenuIcon src={EditOutlinedIcon} />
                <Typography variant="small">Edit</Typography>
              </StyledActionsMenuItem>
              <StyledActionsMenuItem onClick={handleDeleteClick}>
                <StyledActionsMenuIcon src={TrashIcon} />
                <Typography variant="small" color="error">
                  Delete
                </Typography>
              </StyledActionsMenuItem>
            </StyledActionsMenu>
          )}
        </WithThemeProvider>
      </StyledMenuContainer>
    </OutsideClickWrapper>
  );
};

export default TableActionMenu;
