import React from 'react';
import {
  StyledEmpDetailsContainer,
  StyledMainWrapper,
  StyledNameContainer,
  StyledNameDetailsContainer,
  StyledOverWrapper,
  StyledOverviewWrapper,
  StyledSeparator,
  StyledWrapper,
  StyledTableContainer,
} from './styles';
import { ArrowDownEmerald, ArrowUpEmerald } from '../../../../assets/images';
import {
  Avatar,
  LoopButton,
  LoopTable,
  Typography,
} from '@loophealth/loop-ui-web-library';

import EmpInfoItem from '../../EmployeeDetailsOverview/EmpInfoItem';
import { IDependentCardItem } from './types';
import { DEP_POL_LIST_HEADERS } from '../utils';
import { getDepPolTableProcessor } from '../tableElements';
import { capitalizeFirstLetterOfEachWord } from '../../../../utils/common/Utilities';

const DependentCardItem: React.FC<IDependentCardItem> = ({ data }) => {
  const [showDepTable, setShowDepTable] = React.useState<boolean>(false);
  const name = data.details.firstName;

  return (
    <StyledMainWrapper>
      <StyledOverviewWrapper>
        <StyledOverWrapper>
          <StyledWrapper>
            <StyledNameContainer>
              <Avatar
                users={[
                  {
                    name,
                    imageURL: '',
                  },
                ]}
                size="extralarge"
              />

              <StyledNameDetailsContainer>
                <Typography variant="medium" weight="semiBold" color="primary">
                  {`${name}`}
                </Typography>
                <Typography variant="small" color="primary">
                  {data.details.relationship}
                </Typography>
              </StyledNameDetailsContainer>
            </StyledNameContainer>
            <LoopButton
              onClick={() => setShowDepTable(!showDepTable)}
              variant="secondary"
              iconOrder="right"
              size="small"
              iconSrc={showDepTable ? ArrowUpEmerald : ArrowDownEmerald}
            >
              Policy Details
            </LoopButton>
          </StyledWrapper>

          <StyledEmpDetailsContainer>
            <EmpInfoItem label="Gender" value={data.details.gender} />
            <StyledSeparator />
            <EmpInfoItem label="DOB" value={data.details.dob} />
            <StyledSeparator />
            <EmpInfoItem label="Mobile Number" value={data.details.mobile} />
            <StyledSeparator />
            <EmpInfoItem label="Email" value={data.details.email} />
          </StyledEmpDetailsContainer>
        </StyledOverWrapper>
      </StyledOverviewWrapper>

      {showDepTable ? (
        <StyledTableContainer>
          <LoopTable
            headers={DEP_POL_LIST_HEADERS}
            processor={getDepPolTableProcessor(
              capitalizeFirstLetterOfEachWord(name),
            )}
            data={data.policies as unknown as Record<string, unknown>[]}
          />
        </StyledTableContainer>
      ) : null}
    </StyledMainWrapper>
  );
};
export default DependentCardItem;
