import styled from 'styled-components';
import { IPolicyType } from '../../../containers/PolicyDetailsCoverage/types';
import { INoteType } from '../../../atoms/ZeroStateCard/types';

export type IStatus =
  | 'DRAFT'
  | 'IN_PROGRESS'
  | 'SENT_TO_INSURER'
  | 'ENDO_DECLINED'
  | 'ENDO_COMPLETE'
  | 'READY_FOR_TPA'
  | 'RECEIVED_UHIDS'
  | 'ENDO_RECEIVED';

interface ILivesDetails {
  additions: number;
  deletions: number;
  edits: number;
}

export type IEndorsements = {
  id: string;
  cdBalance: number;
  cdAccountNumber: string;
  companyName: string;
  insurerName: string;
  policyId: string;
  policyType: IPolicyType;
  nickName: string;
  policyNumber: string;
  status: IStatus;
  endorsementNumber: number;
  endorsementCycle: string;
  livesCount: number;
  endoCost: number;
  createdAt: string;
  updatedAt: string;
  endoMonth: string;
  insurerLogo: string;
  livesDetails: ILivesDetails;
  isEndoModified: boolean;
  additionCost: number;
  editsCost: number;
  deletionCost: number;
};

export interface IAdapterData {
  data: any | null;
  loading: boolean;
  error: any | null;
}

export interface IError {
  message: string;
}

export interface IEndorsementsSelector extends IAdapterData {
  data: IEndorsements[] | null;
  loading: boolean;
  error: IError | null;
}

export enum EndoType {
  'Ongoing' = 'Ongoing',
  'Completed' = 'Completed',
}

export type IZeroStatesData = {
  [key in EndoType]: {
    title: string;
    subtitle: string;
    image: string;
    note: string;
    noteType: INoteType;
  };
};
