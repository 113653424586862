import { LoopButton, Shadow } from '@loophealth/loop-ui-web-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddSelectedPolicyDetails,
  selectSingleAddCurrentStep,
  updateCurrentStep,
} from '../../../../redux/slices/singleAddSlice';
import { SINGLE_ADD_STEPS } from '../../../pages/Endorsements/SingleAddMembers/constants';
import { StyledBottomNavigation } from '../../../containers/Bulk/styles';
import { useMemo } from 'react';
import { SINGLE_ADD_PRIMARY_BUTTON_TEXT } from '../constants';

const SingleAddMembersFooter: React.FC = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectSingleAddCurrentStep);
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);
  const setCurrentStep = (step: SINGLE_ADD_STEPS) => {
    dispatch(updateCurrentStep(step));
  };

  const handleNextStep = () => {
    switch (currentStep) {
      case SINGLE_ADD_STEPS.CHOOSE_POLICY:
        setCurrentStep(SINGLE_ADD_STEPS.ADD_EMPLOYEE);
        break;
      case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
        setCurrentStep(SINGLE_ADD_STEPS.ADD_DEPENDANTS);
        break;
      case SINGLE_ADD_STEPS.ADD_DEPENDANTS:
        setCurrentStep(SINGLE_ADD_STEPS.ADD_DEPENDANTS);
        break;
    }
  };

  const isNextEnabled = useMemo(() => {
    switch (currentStep) {
      case SINGLE_ADD_STEPS.CHOOSE_POLICY:
        return Object.keys(selectedPolicies).length > 0;
      case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
        break;
      case SINGLE_ADD_STEPS.ADD_DEPENDANTS:
        break;
    }
  }, [currentStep, selectedPolicies]);

  return (
    <Shadow variant="bottom">
      <StyledBottomNavigation>
        <LoopButton
          size="medium"
          variant={isNextEnabled ? 'filled' : 'disabled'}
          onClick={handleNextStep}
        >
          {SINGLE_ADD_PRIMARY_BUTTON_TEXT[currentStep]}
        </LoopButton>
        {currentStep === SINGLE_ADD_STEPS.ADD_EMPLOYEE && (
          <LoopButton size="medium" variant="outline" onClick={handleNextStep}>
            Proceed without dependants
          </LoopButton>
        )}
      </StyledBottomNavigation>
    </Shadow>
  );
};
export default SingleAddMembersFooter;
