import {
  Form,
  LoopBadge,
  LoopButton,
  Modal,
  Shadow,
  Table,
  Toast,
  Typography,
  Loader as LoopLoader,
} from '@loophealth/loop-ui-web-library';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmployeeApi from '../../../../../adaptars/employeeApi';
import {
  InfoDarkIcon,
  InfoIconGray,
  LoopLogo,
  SearchIcon,
  TrashIcon,
} from '../../../../../assets/images';
import { selectSelectedCompany } from '../../../../../redux/slices/hrdRevampRedux';
import WithThemeProvider from '../../../../../theme/WithThemeProvider';
import { parseResponse } from '../../../../../utils/common/Utilities';
import { useFetchGlobalSearchData } from '../../../../../utils/hooks/business-hooks/useFetchGlobalSearchData';
import useDebouncedValue from '../../../../../utils/hooks/useDebouncedValue';
import DropdownEmptyState from '../../../../containers/Bulk/MemberSearch/DropdownEmptyState';
import {
  StyledDropdownLoaderContainer,
  StyledLoopHeader,
  StyledLoopIcon,
  StyledMemberDropdownItem,
  StyledMemberDropdownItemContainer,
  StyledMemberSearch,
  StyledMemberSearchBody,
  StyledMemberSearchContainer,
  StyledMemberSearchHeaderText,
  StyledSearchedDependentsList,
  StyledSearchedDependentsTable,
} from '../../../../containers/Bulk/MemberSearch/styles';
import { StyledBottomNavigation } from '../../../../containers/Bulk/styles';
import { EmployeeDetails, FamilyMember } from '../../../EmployeeDetails/types';
import { StyledUserSelectModalWrapper } from '../../BulkEditMembers/UserSelectModal/styles';
import { SINGLE_DELETE_DEPENDENTS_TABLE_COLUMNS } from '../constants';
import {
  convertSearchResultsToDeleteDropdown,
  getEmpDependentsTableHeader,
} from '../utils';
import {
  StyledSelectedDepedentsHeader,
  StyledSelectedDependentsHeaderIcon,
  StyledSelectedDependentsHeaderNote,
  StyledSelectedDependentsHeaderNoteIcon,
  StyledSelectedDependentsHeaderText,
} from './styles';
import { IUserSelectModal } from './types';
import { formatMemberName } from '../../../../../utils/common/common';
import DateOfLeavingModal from '../../../../containers/Bulk/DateOfLeavingModal';
import Loader from '../../../../atoms/Spinner';
import Backdrop from '../../../../atoms/Backdrop';
import SingleDeleteFaq from '../../../../containers/Bulk/SingleDeleteFaq';
import { selectHrUser } from '../../../../../redux/slices/usersSlice';
import { selectSingleDeleteFaqShownUserIds } from '../../../../../redux/slices/applicationStateSlice';
import EndoSingleExitModal from '../../../../containers/Bulk/EndoAddExitModal';

const DeleteUserSelectModal: React.FC<IUserSelectModal> = ({
  selectedUser,
  setSelectedUser,
  isModalVisible,
  setIsModalVisible,
  setCurrentStep,
  cancelModalVisible,
  setIsCancelModalVisible,
}) => {
  const toast = Toast.useToast();
  const history = useHistory();
  const [memberSearchText, setMemberSearchText] = useState('');
  const companyId = useSelector(selectSelectedCompany)?.id || '';
  const [selectedUserDependents, setSelectedUserDependents] = useState<
    FamilyMember[]
  >([]);
  const [isDependentsLoading, setIsDependentsLoading] = useState(false);
  const [dateOfLeavingModalVisible, setDateOfLeavingModalVisible] =
    useState(false);

  const userId = useSelector(selectHrUser).data?.id ?? '';
  const faqShownUserIds = useSelector(selectSingleDeleteFaqShownUserIds);
  const isFaqAlreadyShown = faqShownUserIds.includes(userId);

  const debouncedSearchString = useDebouncedValue(memberSearchText, 1500);
  const { searchResults, loading: isLoading } = useFetchGlobalSearchData(
    companyId,
    debouncedSearchString,
    false,
    false,
    true,
  );

  const dropdownSearchResults = useMemo(
    () => convertSearchResultsToDeleteDropdown(searchResults),
    [searchResults],
  );

  const dependentsTableHeader = useMemo(
    () => getEmpDependentsTableHeader(selectedUser, selectedUserDependents),
    [selectedUser, selectedUserDependents],
  );

  const handleResultClick = (value: string) => {
    const selectedResult = searchResults.find(
      (result) => result.userId === value,
    );
    if (
      !(selectedResult && selectedResult.userPolicies?.length) ||
      isDependentsLoading
    )
      return;
    setSelectedUser({
      employeeId: selectedResult.employeeId,
      name: formatMemberName(selectedResult.firstName, selectedResult.lastName),
      policies: selectedResult.userPolicies || [],
    });
    if (selectedUser?.employeeId === selectedResult.employeeId) return;
    fetchDependentsForEmployee(selectedResult.userId);
  };

  const fetchDependentsForEmployee = async (employeeId: string) => {
    setIsDependentsLoading(true);
    try {
      const [error, response] = await parseResponse(
        EmployeeApi.getEmployeeDetails(employeeId, false),
      );
      if (error) throw error;
      const policyWiseDetails: EmployeeDetails[] =
        response?.data?.policyWiseFamilyDetails || [];
      const gmcPreferredDetails =
        policyWiseDetails.find((policy) => policy.policyType === 'GMC') ??
        policyWiseDetails[0];
      const familyMembers = gmcPreferredDetails?.family;
      setSelectedUserDependents(familyMembers);
    } catch (e) {
      toast?.error((e as Error).message);
    } finally {
      setIsDependentsLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsCancelModalVisible(true);
  };

  const confirmModalClose = () => {
    history.goBack();
  };

  const isDateInputEnabled =
    selectedUser && dependentsTableHeader.length && !isDependentsLoading;

  const moveToDateInput = () => {
    setDateOfLeavingModalVisible(true);
  };

  const moveToEndoEstimate = (dateOfLeaving: Date) => {
    if (!selectedUser) return;
    setSelectedUser({
      ...selectedUser,
      dateOfLeaving,
    });
    setCurrentStep(2);
    setIsModalVisible(false);
    setDateOfLeavingModalVisible(false);
  };

  return (
    <>
      <Modal
        isVisible={isModalVisible}
        setIsVisible={handleModalClose}
        disablePadding
        isModalClosable={false}
        closeOnBlur={false}
      >
        <WithThemeProvider>
          <StyledUserSelectModalWrapper>
            <StyledMemberSearch>
              <StyledLoopHeader>
                <StyledLoopIcon src={LoopLogo} />
                <div>
                  <LoopButton
                    variant="outline"
                    textColor="tertiary"
                    onClick={handleModalClose}
                    size="small"
                  >
                    Cancel
                  </LoopButton>
                </div>
              </StyledLoopHeader>
              <StyledMemberSearchBody>
                <Shadow variant="nav">
                  <WithThemeProvider>
                    <StyledMemberSearchContainer>
                      <StyledMemberSearchHeaderText>
                        <Typography variant="large" weight="medium">
                          Search and Delete Members
                        </Typography>
                        <Typography variant="medium" color="secondary">
                          Search for the employee name or ID you would like to
                          delete
                        </Typography>
                      </StyledMemberSearchHeaderText>
                      <div>
                        <Form.Dropdown
                          listItems={
                            debouncedSearchString.length >= 3
                              ? dropdownSearchResults
                              : []
                          }
                          selectedItem={selectedUser?.employeeId || ''}
                          placeholder="Enter employee name or ID"
                          searchable
                          icon={SearchIcon}
                          hideArrow
                          maxHeight="470px"
                          onClick={handleResultClick}
                          onSearchChange={setMemberSearchText}
                          searchFilter={(list) => list}
                          isLoading={isLoading}
                          emptyState="Enter employee name or ID"
                          hidePlaceholderOnFocus
                          keyEventsId="single-delete-member-search"
                          noResultsState={(searchText) => {
                            return searchText.length < 3 ? (
                              'Enter at least 3 characters to search'
                            ) : searchText !== debouncedSearchString ? (
                              <StyledDropdownLoaderContainer>
                                <LoopLoader />
                              </StyledDropdownLoaderContainer>
                            ) : (
                              <DropdownEmptyState
                                showSearchText={false}
                                searchText={debouncedSearchString}
                                mode="SINGLE_DELETE"
                              />
                            );
                          }}
                          renderItem={(data) => (
                            <StyledMemberDropdownItem>
                              <StyledMemberDropdownItemContainer>
                                <Typography
                                  variant="small"
                                  weight="medium"
                                  color={
                                    data.isExpired ? 'secondary' : 'primary'
                                  }
                                >
                                  {data.name as string}
                                </Typography>
                                <Typography
                                  variant="extraSmall"
                                  color="secondary"
                                >
                                  {data.employeeId as string}
                                </Typography>
                              </StyledMemberDropdownItemContainer>
                              {data.isExpired ? (
                                <LoopBadge
                                  variant="overflow"
                                  badgeType="errorFill"
                                  text={
                                    data.isDeleting
                                      ? 'Deletion In Progress'
                                      : 'Deleted Member'
                                  }
                                  size="small"
                                />
                              ) : null}
                            </StyledMemberDropdownItem>
                          )}
                        />
                      </div>
                    </StyledMemberSearchContainer>
                  </WithThemeProvider>
                </Shadow>
                {selectedUser ? (
                  isDependentsLoading ? (
                    <Loader />
                  ) : (
                    <StyledSearchedDependentsList>
                      <StyledSelectedDepedentsHeader>
                        <StyledSelectedDependentsHeaderText>
                          <StyledSelectedDependentsHeaderIcon src={TrashIcon} />
                          <Typography variant="small" weight="semiBold">
                            {dependentsTableHeader}
                          </Typography>
                        </StyledSelectedDependentsHeaderText>
                        {selectedUserDependents.length > 1 && (
                          <StyledSelectedDependentsHeaderNote>
                            <StyledSelectedDependentsHeaderNoteIcon
                              src={InfoIconGray}
                            />
                            <Typography
                              variant="extraSmall"
                              color="secondary"
                              weight="semiBold"
                            >
                              Need to delete only a dependant?&nbsp;
                              <Typography
                                variant="extraSmall"
                                color="secondary"
                                weight="regular"
                              >
                                Contact your CSM
                              </Typography>
                            </Typography>
                          </StyledSelectedDependentsHeaderNote>
                        )}
                      </StyledSelectedDepedentsHeader>
                      <Shadow variant="nav">
                        <WithThemeProvider>
                          <StyledSearchedDependentsTable>
                            <Table
                              data={selectedUserDependents}
                              columns={SINGLE_DELETE_DEPENDENTS_TABLE_COLUMNS}
                              fullWidth
                            />
                          </StyledSearchedDependentsTable>
                        </WithThemeProvider>
                      </Shadow>
                    </StyledSearchedDependentsList>
                  )
                ) : null}
              </StyledMemberSearchBody>
            </StyledMemberSearch>
            <Backdrop visible={!isFaqAlreadyShown}>
              <Shadow variant="bottom">
                <StyledBottomNavigation $justify="space-between">
                  <SingleDeleteFaq
                    isFaqAlreadyShown={isFaqAlreadyShown}
                    userId={userId}
                  />
                  <LoopButton
                    size="medium"
                    variant={isDateInputEnabled ? 'filled' : 'disabled'}
                    onClick={moveToDateInput}
                  >
                    Add Date of Leaving
                  </LoopButton>
                </StyledBottomNavigation>
              </Shadow>
            </Backdrop>
          </StyledUserSelectModalWrapper>
        </WithThemeProvider>
      </Modal>
      <EndoSingleExitModal
        isVisible={cancelModalVisible}
        onExit={confirmModalClose}
        onClose={() => setIsCancelModalVisible(false)}
      />
      <DateOfLeavingModal
        isVisible={dateOfLeavingModalVisible}
        onClose={() => setDateOfLeavingModalVisible(false)}
        selectedUser={selectedUser}
        dependents={selectedUserDependents}
        onProceed={moveToEndoEstimate}
      />
    </>
  );
};

export default DeleteUserSelectModal;
