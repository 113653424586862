import React from 'react';
import { ICancellationModal } from './types';
import { Dialog, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { WarningErrorIcon } from '../../../../assets/images';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { StyledModalContent } from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectHrUser } from '../../../../redux/slices/usersSlice';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../utils/segment/utils';

const CancellationModal: React.FC<ICancellationModal> = ({
  isCancelModalVisible,
  setCancelModalVisible,
  onConfirmCancelClick,
  isBackBtnModalVisible,
  setBackBtnModalVisible,
  lowCDModalVisible,
  setLowCDModalVisible,
  isDeleteConfirmationModalVisible,
  setDeleteConfirmationModalVisible,
  onConfirmDeleteClick,
  submitEndorsements,
  isSubmitting,
}) => {
  const history = useHistory();
  const location = useLocation();
  const trackClick = useSegment('track');
  const selectedUser = useSelector(selectHrUser);

  const cancelCdBalanceLowAlert = () => {
    if (isSubmitting) return;
    trackClick(
      trackClickEvent(
        'Click_Cancel_LowCD_Alert_add',
        location.pathname,
        extractPathName(location.pathname),
      ),
    );
    setLowCDModalVisible(false);
  };

  const confirmCdBalanceLowAlertSubmit = () => {
    trackClick(
      trackClickEvent(
        'Click_Submit_LowCD_Alert_add',
        location.pathname,
        extractPathName(location.pathname),
      ),
    );
    submitEndorsements?.();
  };

  return (
    <>
      <Modal
        isVisible={isCancelModalVisible}
        setIsVisible={setCancelModalVisible}
      >
        <Dialog
          variant="vertical"
          title="Pause! Are you about to re-upload complete data?"
          description="Just so you know, if you decide to correct the errors and upload everything again, you'll lose this complete data."
          icon={WarningErrorIcon}
          primaryButtonText="Yes, Re-upload"
          content={
            <WithThemeProvider>
              <StyledModalContent>
                <Typography variant="small" color="secondary">
                  But we'll email you a summary of this data at{' '}
                  {selectedUser.data?.email}.
                </Typography>
              </StyledModalContent>
            </WithThemeProvider>
          }
          onPrimaryButtonClicked={onConfirmCancelClick}
          onSecondaryButtonClicked={() => setCancelModalVisible(false)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>

      <Modal
        isVisible={isBackBtnModalVisible}
        setIsVisible={setBackBtnModalVisible}
      >
        <Dialog
          variant="vertical"
          title="Are you sure you want to exit?"
          description="Hold on! Leaving now will erase your progress, and you'll need to upload the file again."
          icon={WarningErrorIcon}
          primaryButtonText="Yes, Exit"
          onPrimaryButtonClicked={() => history.goBack()}
          onSecondaryButtonClicked={() => setBackBtnModalVisible(false)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>

      <Modal isVisible={lowCDModalVisible} setIsVisible={setLowCDModalVisible}>
        <Dialog
          variant="vertical"
          title="Alert! CD Balance running low!"
          description="Your information will be submitted, but we'll need more funds in your CD account to start processing. Want to move forward?"
          icon={WarningErrorIcon}
          primaryButtonText="Submit & Proceed"
          isLoading={isSubmitting}
          onPrimaryButtonClicked={confirmCdBalanceLowAlertSubmit}
          onSecondaryButtonClicked={cancelCdBalanceLowAlert}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
      {isDeleteConfirmationModalVisible && setDeleteConfirmationModalVisible ? (
        <Modal
          isVisible={isDeleteConfirmationModalVisible}
          setIsVisible={setDeleteConfirmationModalVisible}
        >
          <Dialog
            variant="vertical"
            title="Family will get removed!"
            description="Deleting an employee will also delete their complete family from the policy. "
            content={
              <WithThemeProvider>
                <StyledModalContent>
                  <Typography variant="small" color="secondary">
                    Also, please make sure you’re only entering employee details
                    on the sheet.
                  </Typography>
                </StyledModalContent>
              </WithThemeProvider>
            }
            icon={WarningErrorIcon}
            primaryButtonText="Yes, Proceed"
            onPrimaryButtonClicked={onConfirmDeleteClick}
            onSecondaryButtonClicked={() =>
              setDeleteConfirmationModalVisible?.(false)
            }
            secondaryButtonText="Cancel"
            buttonVariant="error"
          />
        </Modal>
      ) : null}
    </>
  );
};

export default CancellationModal;
