import { createChatBotMessage, createCustomMessage } from 'react-chatbot-kit';
import CustomChatMessage from '../../../atoms/ChatbotElements/ChatbotCustomBotMessage';
import ChatbotHeader from '../../../atoms/ChatbotElements/ChatbotHeader';
import styled from 'styled-components';
import { ChatbotIcon } from '../../../../assets/images';
import CustomUserMessage from '../../../atoms/ChatbotElements/ChatbotCustomUserMessage';

export const StyledChatbotIcon = styled.img`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const getConfig = (onClick : () => void) => {
  return {
    initialMessages: [
      createChatBotMessage(
        `Hello! 👋 I'm PolicyPal, your virtual assistant here to help with all your insurance policy queries. How can I assist you today?`,
        { widget: 'options' },
      ),
    ],
    customComponents: {
      // Replaces the default header
      header: () => (
        <ChatbotHeader
          heading="PolicyPal"
          subHeading="Your virtual policy assistant"
          onClick={onClick}
        />
      ),
      // Replaces the default bot avatar
      botAvatar: () => <StyledChatbotIcon src={ChatbotIcon} />,
      // // Replaces the default bot chat message container
      botChatMessage: (props: any) => <CustomChatMessage {...props} />,
      // // Replaces the default user icon
      userAvatar: () => <></>,
      // // Replaces the default user chat message
      userChatMessage: (props: any) => <CustomUserMessage {...props} />,
    },
  };
};
