import styled from 'styled-components';

export const StyledDateOfLeavingModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

export const StyledDOLModalBody = styled.div`
  flex: 1;
  padding: 48px 32px;
  border-bottom: 1px solid ${(p) => p.theme.colors.seafoamGray};
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 350px;
`;

export const StyledDOLModalFooter = styled.div`
  display: flex;
  gap: 16px;
  padding: 24px 32px 32px;
`;

export const StyledDOLModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledDOLModalSubHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDOLModalBodyContent = styled.div<{ $errored: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => (p.$errored ? '55px' : '26px')};
`;

export const StyledDOLModalBodyContentInfo = styled.div`
  padding: 12px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.colors.mintFrost};
`;
