import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 27px;
`;

export const StyledContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 72px;
`;

export const StyledErrorContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;
`;