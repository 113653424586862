import styled from 'styled-components';

export const StyledClaimListHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 42px;
  padding: 40px 56px 52px;
`;

export const StyledClaimListHeaderTabsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  & > * {
    flex: 1;
  }
`;
