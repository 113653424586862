import {
  TickCircleDarkIcon,
  YellowSearchIcon,
  DisabledArrowIcon,
  DisabledSettledIcon,
  WarningRoundedIcon,
} from '../../../assets/images';
import { ITimelineElement } from './types';
import { IBadgeType } from '@loophealth/loop-ui-web-library/dist/types/atoms/LoopBadge/types';

export const defaultTimeline: ITimelineElement[] = [
  {
    id: 'claim_initiated',
    status: 'claim intimated',
    title: 'Claim Initiated',
    active: false,
    displayDefault: true,
    description: 'Received claim request from employee',
    date: '',
    successIcons: {
      active: TickCircleDarkIcon,
      disabled: TickCircleDarkIcon,
    },
  },
  {
    id: 'doc_submitted',
    title: 'Documents Submission',
    status: 'document submission',
    description: 'Last document received from employee',
    active: false,
    displayDefault: true,
    date: '',
    successIcons: {
      active: TickCircleDarkIcon,
      disabled: TickCircleDarkIcon,
    },
  },
  {
    id: 'cup',
    title: 'Claim Under Review',
    status: 'Under progress',
    active: false,
    displayDefault: true,
    url: '',
    ctaTitle: '',
    description: 'Under review by TPA. It usually takes upto 15 days',
    successIcons: {
      active: TickCircleDarkIcon,
      disabled: YellowSearchIcon,
    },
  },
  {
    id: 'cuq',
    title: 'Claim Under Query',
    status: 'Under progress - query',
    active: false,
    displayDefault: false,
    description: '',
    url: '',
    ctaTitle: 'View query letter',
    successIcons: {
      active: WarningRoundedIcon,
      disabled: YellowSearchIcon,
    },
  },
  {
    id: 'processed',
    title: 'Sent for Payment',
    status: 'Processed',
    description: 'Claim request forwarded to Insurer',
    active: false,
    displayDefault: true,
    successIcons: {
      active: TickCircleDarkIcon,
      disabled: DisabledArrowIcon,
    },
  },
  {
    id: 'claim_settled',
    title: 'Claim Settled',
    status: 'Claim Paid',
    active: false,
    displayDefault: true,
    description: 'Claim amount reimbursed to employee',
    url: '',
    ctaTitle: 'View Settlement Letter',
    successIcons: {
      active: TickCircleDarkIcon,
      disabled: DisabledSettledIcon,
    },
  },
  {
    id: 'rejected',
    title: 'Claim Rejected',
    status: 'Rejected',
    active: false,
    displayDefault: false,
    description: '',
    url: '',
    ctaTitle: 'View rejection letter',
    successIcons: {
      active: WarningRoundedIcon,
      disabled: DisabledSettledIcon,
    },
  },
];

export type finalClaimStatus =
  | 'claim intimated'
  | 'document submission'
  | 'Rejected'
  | 'Claim Paid'
  | 'Processed'
  | 'Under progress - query'
  | 'Under progress';

export const statusBadgeType: Partial<Record<finalClaimStatus, IBadgeType>> = {
  'claim intimated': 'success',
  'document submission': 'warning',
  'Claim Paid': 'success2',
  'Under progress - query': 'warning',
  'Under progress': 'warning',
  Rejected: 'error',
  Processed: 'success',
};
