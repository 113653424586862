import styled from 'styled-components';

export const StyledPolicyActiveRoster = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 56px;
  gap: 24px;
`;

export const StyledPolicyActiveRosterItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > :nth-child(2) {
    height: 100%;
  }
`;

export const StyledPolicyActiveRosterItemHeader = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;
export const StyledPolicyItemBodyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const StyledPolicyActiveRosterLogo = styled.img`
  width: 36px;
  height: 36px;
  aspect-ratio: 1;
  background: ${(p) => p.theme.colors.coverYellow};
  border-radius: 50%;
`;

export const StyledPolicyActiveRosterItemBody = styled.div`
  display: flex;
  gap: 24px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  background: ${(p) => p.theme.colors.surfaceOne};
  height: 100%;
`;

export const StyledPolicyActiveRosterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const StyledPolicyActiveRosterContainerSeperator = styled.div`
  width: 1px;
  height: 45px;
  background: ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledPolicyActiveRosterContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledPolicyActiveRosterContainerRow = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledPolicyActiveRosterCDContainerRow = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  gap: 12px;
`;

export const StyledPolicyCDAccountEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
