import { useEffect, useState } from 'react';
import {
  downloadFile,
  parseResponse,
} from '../../../../utils/common/Utilities';
import EmployeeApi from '../../../../adaptars/employeeApi';
import { toast } from 'react-toastify';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../utils/segment/utils';
function useECardDownload(
  name: string,
  policyNumber: string,
  userId: string,
  policyId: string,
) {
  const trackClick = useSegment('click');
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadECard = async () => {
    setIsDownloading(true);
    try {
      const [error, result] = await parseResponse(
        EmployeeApi.fetchECard(userId, policyId),
      );
      if (error) {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const pdfFileData = result.data.pdfFileData;
        const base64Data = pdfFileData.split(',')[1];
        downloadFile(
          'data:application/octet-stream;charset=utf-16le;base64,' + base64Data,
          `${name}_${policyNumber}.pdf`,
        );
        toast.success('E-Card Downloaded', {
          position: toast.POSITION.TOP_RIGHT,
        });
        trackClick(
          trackClickEvent(
            'employee_downloadecard_CTA_' +
              extractPathName(window.location.pathname),
            window.location?.pathname,
            'Viewd policies for selected member',
            {
              clickProperties: {},
              ...{
                name,
                policyNumber,
                userId,
                policyId,
              },
            },
          ),
        );
      }
    } catch (error) {
      toast.error('An error occurred while fetching the E-card.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (isDownloading) {
      downloadECard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloading]); // Include downloadECard as a dependency

  const startDownload = () => {
    setIsDownloading(true);
  };

  return {
    startDownload,
    isDownloading,
  };
}

export default useECardDownload;
