export const FAQData = [
  {
    index: 1,
    title: 'How and when does the claim status update?',
    description:
      'Claim status updates are received periodically from the insurer. This process can take some time, so please allow for delays in updates.',
  },
  {
    index: 2,
    title: 'Can I track all types of claims on the dashboard?',
    description:
      'All GMC claims can be tracked on the dashboard, but GPA and super top-up claims (if applicable) are not available for tracking here.',
  },
  {
    index: 3,
    title: 'Why aren’t new claims visible immediately?',
    description:
      'New claims will be available to track once the insurer updates us on the status. If you need help tracking a claim that isn’t visible yet, please contact your CSM.',
  },
];
