import React from 'react';
import { IHightlightHOC } from './types';
import { StyledHightlightHOCWrapper } from './styles';

const HightlightHOC: React.FC<IHightlightHOC> = ({
  children,
  isHighlighted,
}) => {
  return (
    <StyledHightlightHOCWrapper $isHighlighted={isHighlighted}>
      {children}
    </StyledHightlightHOCWrapper>
  );
};

export default HightlightHOC;
