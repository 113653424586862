export enum ICenteredTextType {
  doughnut = 'doughnut',
  semiDoughnut = 'semi-doughnut',
}
export interface ICenteredText {
  value: string;
  label: string;
  type?: ICenteredTextType;
}

export interface IStyledPieChartCenteredTextContainerProps {
  type: ICenteredTextType;
}
