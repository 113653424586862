export enum IPolicyTypes {
  GMI = 'Group Medical Insurance',
  GPA = 'Group Personal Accidental',
  GTL = 'Group Term Life',
  GMC = 'Group Medical Coverage',
}

export const EMP_POL_LIST_HEADERS = [
  { name: 'Policy Name', flex: 5 },
  { name: 'Allowed Family', flex: 4 },
  { name: 'Sum Insured', flex: 2 },
  { name: 'Valid Till', flex: 2 },
  { name: 'Insurance Status', flex: 2 },
  { name: 'E-Card', flex: 2 },
];

export type IClaimStatus =
  | 'Claim Intimated'
  | 'Claim Under Query'
  | 'Claim Under Review'
  | 'Claim Paid'
  | 'Claim Closed Disabled'
  | 'Claim Under Review Disabled'
  | 'Claim Closed'
  | 'Claim Processed'
  | 'Claim Rejected';

export enum PolicyTypeConstants {
  SUPER_TOP_UP = 'SUPER_TOP_UP',
  GMC = 'GMC',
  GPA = 'GPA',
  COVID = 'COVID',
  GTL = 'GTL',
  PARENTAL = 'PARENTAL',
}

export enum RelationConstants {
  self = 'self',
  spouse = 'spouse',
  child = 'child',
  parent = 'parent',
  'parent-in-law' = 'parent-in-law',
  All = 'All',
}

export enum IPossibleClaimStatus {
  claim_intimated = 'Claim Intimated',
  document_submission = 'Document Submission',
  cup = 'Under Progress',
  cuq = 'Under Progress - query',
  processed = 'Processed',
  rejected = 'Rejected',
  closed = 'Closed',
  claim_paid = 'Claim Paid',
}

export enum PolicyTypeFullForms {
  GMC = 'Group Medical Coverage',
  GPA = 'Group Personal Accident',
  COVID = 'Covid-19 Group Health',
  GTL = 'Group Term Life',
  PARENTAL = 'Parental Policy',
  SUPER_TOP_UP = 'Super TopUp Policy',
}

export const REALTIME_TPA = [
  'ICICI Lombard',
  'Medi Assist',
  'Care Health Insurance',
  'Health India',
  'East West Assist',
];

export const HRD_PERMISSION = process.env.REACT_APP_HRD_PERMISSION_ID;
