import styled from 'styled-components';

export const StyledFullPageLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
`;

export const StyledLoadingContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 36px 40px;
  gap: 24px;
  background-color: ${(p) => p.theme.colors.white};
`;

export const StyledSubText = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
export const StyledIconContainer = styled.div`
  width: 27px;
`;

export const StyledLoaderIcon = styled.img`
  width: 27px;
  aspect-ratio: 1;
`;

export const StyledDogIcon = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 90%;
`;
