class LoopEndpoints {
  userPolicyDetails = () => `/hrRequest/fetchUserPolicyPlans`;
  // `/fetchUserPolicyDetails?userId=${userId}`;
  userPolicyFamilyDetails = (userId: string) =>
    `/fetchPolicyWiseFamilyDetails?userId=${userId}`;

  fetchCompanyPoliciesList = (employerId: string) =>
    `/fetchCompanyPolicyDetails?companyId=${employerId}`;

  fetchEmployeeList = (
    companyId: string,
    nextPaginationValue = '',
    prevPaginationValue = '',
    searchParam = '',
    pageSize = 10,
  ) =>
    `/hrDashboard/fetchCompanyEmployeeList?companyId=${companyId}&nextPaginationValue=${nextPaginationValue}&prevPaginationValue=${prevPaginationValue}&searchParam=${searchParam}&pageSize=${pageSize}`;

  fetchEmployeeListDash = (
    companyId: string,
    nextPaginationValue = '',
    prevPaginationValue = '',
    searchParam = '',
    pageSize = 10,
  ) =>
    `/hrDashboard/fetchCompanyEmployeeList?companyId=${companyId}&nextPaginationValue=${nextPaginationValue}&prevPaginationValue=${prevPaginationValue}&searchParam=${searchParam}&pageSize=${pageSize}&timeperiod=30`;

  fetchCompanyPoliciesDetails = (companyId: string, policyId: string) =>
    `/fetchPolicyEmployeeDetails=${companyId}&policyId=${policyId}`;

  fetchEmpCount = (companyId: string) =>
    `hrRequest/employeeAndDependentCount?companyId=${companyId}`;
  fetchPolicyEmpCount = (companyId: string, policyId: string) =>
    `/fetchPolicyEmployeeCount?companyId=${companyId}&policyId=${policyId}`;
  fetchDependentList = (employerId: string) =>
    `/user/fetchUserAndDependantsByEmployeeId/${employerId}`;

  savaEmpDetails = () => `/hrRequest/addEmployee`;

  savaDepDetails = () => `/hrRequest/addDependent`;

  updateEmpDetails = () => `/hrRequest/editUserRequest`;

  fetchUserDetails = (userId: string) =>
    `/hrRequest/fetchUser?userId=${userId}`;

  fetchFamilyDetails = () => `/hrRequest/fetchFamilyDetails`;

  // deleteUser = () => `/hrRequest/deleteUserRequest`;

  enrollPolicy = () => '/hrRequest/enrolUserToPolicy';

  deletePolicy = () => '/hrRequest/deleteUserFromPolicy';

  fetchPolicyPreference = (userId: string, relationship: string) =>
    `/hrRequest/fetchPolicyPreference?userId=${userId}&relationship=${relationship}`;

  //v1.2
  policyPlan = '/policyPlan';
  policyPlanForEndo = '/policyPlan/hrd';
  companyDetails = (companyId: string) =>
    `/company/${companyId}?getLifeCount=true`;
  companyConfig = (companyId: string) => `/company/config/${companyId}`;
  claimsAnalytics = `/claimsReport/hrdData`;
  user = '/user';
  userV2 = '/user/v2';
  updateHrData = '/hr/updateHrData';
  fetchHrDetails = (loginType: string, value: string) =>
    `/hr/fetchHrByLoginType?loginType=${loginType}&value=${value}`;
  fetchUser = (key: string, value: string) =>
    `/user/fetchUsers?key=${key}&value=${value}`;
  fetchUsersAndDependentsByCompany = (companyId: string) =>
    `/user/fetchUserAndDependantsByCompanyId/${companyId}`;
  fetchUserById = (userId: string) => `/user/${userId}`;
  fetchUserPoliciesByUserId = (userId: string) => `/userPolicies/${userId}`;
  fetchPolicyPreferenceByUserIdAndRel = (
    userId: string,
    relationship: string,
  ) => `/user/policyPreferences/${userId}/${relationship}`;
  addUserPolicies = (userId: string, policyId: string) =>
    `/userPolicies/${userId}/${policyId}`;
  offboardUser = `/bulk/offboardUsers`;
  changeRequest = '/userPolicyCR';

  // hrd
  fetchEndorsements = (companyId: string) =>
    `/endorsement/listAllEndorsementsForHRD/${companyId}`;
  fetchEndorsementById = (id: string) => `/endorsement/hrd/${id}`;
  claimsFilters = '/claims/filters';
  claimsList = '/claims/v2/list';

  // Claim List final API
  claimList = (companyId: string, employeeId: string) =>
    `/claims?companyId=${companyId}&employeeId=${employeeId}`;

  sendClaimsRecordEmail = '/claims/email';

  claimDetails = (id: string) => `/claims/hrd/detail?claimId=${id}`;
  downloadTemplate = (policyId: string) =>
    `/excel/getHRDTemplate?policyId=${policyId}`;
  getECard = (userId: string, policyId: string) =>
    `/userPolicies/generateECard/${userId}/${policyId}`;

  fetchPolicyPlan = (policyId: string) => `/policyPlan/${policyId}`;

  fetchPolicies = (companyId: string) => `/policyPlan/active/${companyId}`;
  downloadPolicyDocument = (policyId: string, fileIdentifier: string) =>
    `/policyPlan/${policyId}/policyDocument/${fileIdentifier}`;

  fetchEmpDependents = (employeeId: string) =>
    `/userPolicies/${employeeId}/withDependants`;

  fetchEmpFamilyDetails = (employeeId: string, isInactiveRequired: boolean) =>
    `/userPolicies/allFamilyDetails/${employeeId}?isInactiveRequired=${isInactiveRequired}`;

  fetchECardDetails = (userId: string, policyId: string) =>
    `/userPolicies/generateECard/${userId}/${policyId}`;
  cdAccounts = (companyId: string) => `/cdAccount?companyId=${companyId}`;
  downloadCDStatement = (cdAccountId: string) =>
    `/cdAccount/${cdAccountId}/cdAccountStatement`;

  fetchCompanyEmployeesByPolicy = (companyId: string, policyId: string) =>
    `/user/fetchMembersByPolicy/${companyId}/${policyId}`;

  fetchGlobalSearchData = (
    companyId: string,
    searchString: string,
    includeDependents: boolean,
    includePolicies: boolean,
  ) =>
    `/user/v2?employer=${companyId}&searchValue=${searchString}&includeDependents=${includeDependents}&includePoliciesAndIsEditableFlag=${includePolicies}`;
  fetchCompanyList = () => `/hr/mapping`;

  claimTimeline = (id: string) => `/claims/timeline/${id}`;

  askPolicy = `/assistant/policyQa/askQuestion`;
  uploadDocument = `/midTermAndKycDocuments/processDocument`;
  deleteDocument = `/midTermAndKycDocuments/processDocument`;
  downloadDocument = (fileName: string) =>
    `/midTermAndKycDocuments/processDocument/${fileName}`;
  identifyMidTerms = '/midTermAdditions/identifyMidTermAdditions';
  validateMidTerms = '/midTermAdditions/validateMidtermAdditions';
  validateBulkAddition = `/hrd/validate/addMembers`;
  validateBulkEdition = `/hrd/validate/editMember`;
  validateBulkDeletion = `/hrd/validate/offboardMembers`;
  estimateEndoCost = `/premiumRater/estimate`;
  submitBulkAddRequest = `/registration/hrd/add`;
  submitBulkEditRequest = `/registration/hrd/edit`;
  submitBulkDeleteRequest = `/registration/hrd/offboard`;
  feedbackSubmitMail = `/hrd/sendFeedbackMail`;
  lowCDEndoSubmissionEmailTrigger = (companyId: string) =>
    `/hrd/notifications/sendEndoSubmissionEmailCdLow/?companyId=${companyId}`;
  endoSubmissionEmailTrigger = (companyId: string) =>
    `/hrd/notifications/sendEndoSubmissionEmail/?companyId=${companyId}`;
  endoStep2ExitTrigger = (companyId: string) =>
    `/hrd/notifications/sendEndoStep2ExitMail/?companyId=${companyId}`;
  endoStep3ExitTrigger = (companyId: string) =>
    `/hrd/notifications/sendEndoStep3ExitMail/?companyId=${companyId}`;

  fetchEndoDateDetails = (companyId: string) =>
    `/endorsement/getEndorsementEndDate/${companyId}`;

  downloadAddTemplate = (companyId: string) =>
    `/registration/hrd/downloadADDTemplate/${companyId}`;
  downloadDeleteTemplate = (companyId: string) =>
    `/registration/hrd/downloadDELETETemplate/${companyId}`;
}

export default new LoopEndpoints();
