import React, { FC } from 'react';
import { StyledSummarySection } from './styles';
import HorizontalInfoCard from '../../../atoms/HorizontalInfoCard';
import { ISummarySection } from './types';
import { MentalHealthIcon, PeopleIcon } from '../../../../assets/images';
import { StyledTooltipListContainer } from '../../ClaimAlertItem/styles';

const SummarySection: FC<ISummarySection> = ({
  activeEmployees,
  activeLives,
  eCardsAvailableCount,
  employeeWithEcard,
  insuredEmployees,
  isGPAorGTL,
}) => {
  const showDependantSection = !isGPAorGTL;

  const customLivesTooltip = (
    <StyledTooltipListContainer>
      <p>
        Includes all lives that have been accepted by Loop to be added to the
        policy
      </p>
      <p>
        How is it calculated? (lives that are currently insured) + (lives that
        are in progress and will be insured soon)
      </p>
    </StyledTooltipListContainer>
  );

  const customEmployeeTooltip = (
    <StyledTooltipListContainer>
      <p>
        Includes all employees that have been accepted by Loop to be added to
        the policy
      </p>
      <p>
        How is it calculated? (employees that are currently insured) +
        (employees that are in progress and will be insured soon)
      </p>
    </StyledTooltipListContainer>
  );

  return (
    <StyledSummarySection>
      {showDependantSection && (
        <HorizontalInfoCard
          icon={PeopleIcon}
          information={[
            {
              title: 'Total Lives',
              value: activeLives,
              tooltipText: customLivesTooltip,
            },
            {
              title: 'Lives with E-cards',
              value: eCardsAvailableCount,
            },
          ]}
        />
      )}

      <HorizontalInfoCard
        icon={showDependantSection ? MentalHealthIcon : PeopleIcon}
        information={[
          {
            title: 'Total Employees',
            value: activeEmployees,
            tooltipText: customEmployeeTooltip,
          },
          {
            title: isGPAorGTL ? 'Insured Employees' : 'Employees with E-Cards',
            value: isGPAorGTL ? insuredEmployees : employeeWithEcard,
          },
        ]}
      />
    </StyledSummarySection>
  );
};

export default SummarySection;
