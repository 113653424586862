import React from 'react';
import { Typography } from '@loophealth/loop-ui-web-library';

import {
  StyledTimelineContainer,
  StyledStatusLineContainer,
  StyledStepContainer,
  StyledStepDescriptionContainer,
  StyledStepsOuterContainer,
  StyledSuccessIcon,
  StyledTimeLine,
  StyledWrapper,
} from './styles';
import { EndoTimelineProps, IEndoTimelineElement } from './types';
import theme from '../../../theme';

import Loader from '../../atoms/Spinner';
import { StyledNoTimelineSpacing } from '../ClaimDetails/styles';

const EndoTimeline: React.FC<EndoTimelineProps> = ({
  timelineData,
  timelineLoading,
}) => {
  return timelineLoading ? (
    <Loader />
  ) : timelineData ? (
    <StyledTimelineContainer>
      <StyledStepsOuterContainer>
        {timelineData?.map((element: IEndoTimelineElement, index: number) => {
          return (
            <React.Fragment key={element.id}>
              <StyledStepContainer>
                <StyledStatusLineContainer>
                  <StyledSuccessIcon
                    src={
                      element.displayActive
                        ? element.successIcons.active
                        : element.active
                        ? element.successIcons.success
                        : element.successIcons.disabled
                    }
                  />
                  {index < timelineData.length - 1 && (
                    <StyledTimeLine
                      $backgroundColor={
                        element.active
                          ? theme.colors.loopEmerald
                          : theme.colors.seafoamGray
                      }
                    />
                  )}
                </StyledStatusLineContainer>
                <StyledStepDescriptionContainer>
                  <Typography variant="small" weight="medium" color="primary">
                    {element.title}
                  </Typography>
                  <StyledWrapper>
                    {element.id === 'endo_rejected' ? (
                      <Typography variant="small" weight="medium" color="error">
                        Endorsement Declined!
                      </Typography>
                    ) : null}
                    <Typography
                      variant="extraSmall"
                      weight="medium"
                      color="secondary"
                    >
                      {element.description}{' '}
                      {['data_submission', 'loop_review'].includes(
                        element.id,
                      ) && element.date ? (
                        <Typography
                          variant="extraSmall"
                          weight="bold"
                          color="emerald"
                        >
                          {element.date}
                        </Typography>
                      ) : null}
                    </Typography>
                  </StyledWrapper>
                </StyledStepDescriptionContainer>
              </StyledStepContainer>
            </React.Fragment>
          );
        })}
      </StyledStepsOuterContainer>
    </StyledTimelineContainer>
  ) : (
    <StyledNoTimelineSpacing />
  );
};

export default EndoTimeline;
