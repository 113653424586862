import React, {FC, Fragment} from 'react';
import {
  StyledIcon,
  StyledIconContainer,
  StyledInformationContainer,
  StyledInformationContainerDivider,
  StyledInformationItem,
  StyledInformationItemTitle,
  StyledInformationItemTooltipIcon,
  StylesHorizontalInfoCard,
} from './styles';
import { IHorizontalInfoCard } from './types';
import { Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import { InfoDarkIcon } from '../../../assets/images';

const HorizontalInfoCard: FC<IHorizontalInfoCard> = ({ icon, information }) => {
  return (
    <StylesHorizontalInfoCard>
      <StyledIconContainer>
        <StyledIcon src={icon} />
      </StyledIconContainer>

      <StyledInformationContainer>
        {information.map((info, index) => (
          <Fragment key={index}>
            <StyledInformationItem>
              <StyledInformationItemTitle>
                <Typography variant="small">{info.title}</Typography>

                {info.tooltipText && (
                  <Tooltip
                    beak="top-center"
                    text={info.tooltipText}
                    type="informative"
                  >
                    <StyledInformationItemTooltipIcon src={InfoDarkIcon} />
                  </Tooltip>
                )}
              </StyledInformationItemTitle>

              <Typography variant="title3" color="emerald">
                {info.value}
              </Typography>
            </StyledInformationItem>

            {index + 1 !== information.length && (
              <StyledInformationContainerDivider />
            )}
          </Fragment>
        ))}
      </StyledInformationContainer>
    </StylesHorizontalInfoCard>
  );
};

export default HorizontalInfoCard;
