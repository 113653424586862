import {
  LoopButton,
  Shadow,
  Stepper,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../../assets/images';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import FullPageLoader from '../../../atoms/Loader';
import { IFullPageLoader } from '../../../atoms/Loader/types';
import MidTermAdditionModal from '../../../containers/MidTermAdditionModal';
import { IPolicyRejectedEntries, IUploadedPolicywiseData } from './types';
import {
  checkMidtermPresent,
  getRejectedEntriesOnSkip,
  identifyPolicyWiseMidTermAdditions,
  updateFilePathForMidTerms,
  validateMidTerms,
} from '../../../containers/MidTermAdditionModal/utils';
import UploadAddition from './UploadAddition';
import {
  StyledBackIcon,
  StyledBottomNavigation,
  StyledContainer,
  StyledDetailsHeader,
  StyledDetailsWrapper,
  StyledStepperWrapper,
} from '../../../containers/Bulk/styles';
import ValidationsScreen from '../../../containers/ValidationsScreen';
import {
  concatMidtermAndValidationErrors,
  getSubmitEventType,
  processValidationPayloadData,
  submitBulkAddEndorsement,
  validateAddData,
} from '../../../containers/Bulk/SubmitEndo/utils';
import {
  deleteMidtermDocumentsOnCancel,
  getButtonStates,
  getCorrectEntries,
  getCorrectEntriesLength,
  getSegmentCostSummaryData,
  isCDLowTrue,
  trackAddValidationSummary,
} from './utils';
import {
  extractPathName,
  trackClickEvent,
  trackPageEvent,
} from '../../../../utils/segment/utils';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import useFetchPoliciesFromRedux from '../../../containers/Policies/hooks/useFetchPoliciesFromRedux';
import { generateErrorTemplate } from './ErrorSheet/utils';
import CancellationModal from '../../../containers/Bulk/CancellationModal';
import { IIdentifiedMidtermsData } from '../../../containers/MidTermAdditionModal/types';
import {
  LOADING_CONSTANTS,
  STEPPER_DATA,
} from '../../../containers/Bulk/constants';
import useEstimateEndorsementCost from '../../../containers/Bulk/hooks/useEstimateEndorsementCost';
import { downloadFile, parseResponse } from '../../../../utils/common/Utilities';
import LoopApiService from '../../../../adaptars/LoopApiService';
import { getAddValidationSummaryData } from '../../../containers/ValidationsScreen/utils';
import { write } from 'xlsx-js-style';
import EndoSuccessSummary from '../../../containers/Bulk/EndoSuccessSummary';
import useTrackPage from '../../../../utils/segment/hooks/useTrackPage';
import SubmitEndo from '../../../containers/Bulk/SubmitEndo';

const BulkAddMembers = () => {
  const trackPage = useSegment('page');
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const { policies } = useFetchPoliciesFromRedux();
  const history = useHistory();
  const toast = Toast.useToast();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<IFullPageLoader | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const selectedCompany = useSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id || '';
  const [currentStep, setCurrentStep] = useState(1);
  const [sheetSelected, setSheetSelected] = useState<Record<string, unknown>[]>(
    [],
  );
  const [uploadedData, setUploadedData] = useState<IUploadedPolicywiseData>({});
  const [midTermModalVisible, setMidTermModalVisible] = useState(false);
  const [lowCDModalVisible, setLowCDModalVisible] = useState(false);
  const [identifiedMidterms, setIdentifedMidterms] =
    useState<IIdentifiedMidtermsData>({});

  const [rejectedEntries, setRejectedEntries] =
    useState<IPolicyRejectedEntries>({});

  const [finalCorrectData, setFinalCorrectData] =
    useState<IUploadedPolicywiseData>({});
  const [isCancelModalVisible, setCancelModalVisible] = useState(false);

  const [isBackBtnModalVisible, setBackBtnModalVisible] = useState(false);
  const { isNextEnabled, isBackEnabled, buttonText, backButtonText } = useMemo(
    () =>
      getButtonStates(
        currentStep,
        sheetSelected.length,
        finalCorrectData,
        rejectedEntries,
      ),
    [currentStep, sheetSelected, finalCorrectData, rejectedEntries],
  );

  const {
    endoCostList,
    totalCost,
    totalLives,
    isLoading: isEndoCostLoading,
  } = useEstimateEndorsementCost(
    finalCorrectData as IUploadedPolicywiseData,
    currentStep,
    'ADD',
  );
  const onSetCancelModalClick = () => {
    setCancelModalVisible(false);
    trackClick(
      trackClickEvent(
        'Cancel_Error_Table_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const moveToSubmitEndorsementPage = () => {
    setCurrentStep(3);
    trackClick(
      trackClickEvent(
        'View_Cost_Correct_Lives_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const errorWorkbook = useRef<File | null>(null);
  const onBackClick = () => {
    const correctEntriesLength = getCorrectEntriesLength(finalCorrectData);
    const haveErrors = Object.keys(rejectedEntries).length;
    switch (currentStep) {
      case 1:
        if (sheetSelected.length) {
          setBackBtnModalVisible(true);
        } else {
          history.goBack();
        }
        break;
      case 2:
        if (correctEntriesLength) {
          setCancelModalVisible(true);
          trackClick(
            trackClickEvent(
              'Click_Go_Back_Error_Table_add',
              location?.pathname,
              extractPathName(location?.pathname),
            ),
          );
        } else {
          setCurrentStep(1);
          setSheetSelected([]);
        }
        break;
      case 3:
        if (haveErrors) setCurrentStep(2);
        else setCancelModalVisible(true);
    }
  };
  const submitEndorsements = async () => {
    selectedCompany?.isNotificationsEnabled && triggerEndoSubmitEmails();
    setIsSubmitting(true);
    const response = await submitBulkAddEndorsement(
      companyId,
      finalCorrectData,
    );
    setIsSubmitting(false);
    if (!response)
      toast?.error('Something went wrong', '', {
        closeOnClick: false,
        variant: 'light',
      });
    else setCurrentStep(4);
  };
  const onProceedClicked = () => {
    const correctEntriesLength = getCorrectEntriesLength(finalCorrectData);
    switch (currentStep) {
      case 1:
        checkMidTermAdditions();
        trackClick(
          trackClickEvent(
            'Upload_Proceed_add',
            location?.pathname,
            extractPathName(location?.pathname),
          ),
        );
        break;
      case 2:
        if (correctEntriesLength) {
          moveToSubmitEndorsementPage();
        } else {
          trackClick(
            trackClickEvent(
              'Go_Reupload_add',
              location?.pathname,
              extractPathName(location?.pathname),
            ),
          );
          clearStates();
        }
        break;
      case 3:
        if (isCDLowTrue(endoCostList)) {
          setLowCDModalVisible(true);
        } else {
          submitEndorsements();
          trackClick(
            trackClickEvent(
              'Clicked_Submit_Lives_add',
              location?.pathname,
              extractPathName(location?.pathname),
              {
                clickProperties: {
                  event: getSubmitEventType(endoCostList),
                  no_of_lives: totalLives,
                  ...getSegmentCostSummaryData(endoCostList),
                },
              },
            ),
          );
        }
        break;
    }
  };

  const validateData = async (
    rejectedMidterms: IPolicyRejectedEntries,
    updatedUploadedData: IUploadedPolicywiseData,
  ) => {
    const correctEntries = getCorrectEntries(
      updatedUploadedData,
      rejectedMidterms,
    );
    const payload = processValidationPayloadData(
      correctEntries,
      selectedCompany?.id || '',
    );
    try {
      const [uploadedDataSet, validationRejections] = await validateAddData(
        payload,
      );
      const totalRejectedEntries = concatMidtermAndValidationErrors(
        Object.keys(updatedUploadedData),
        rejectedMidterms,
        validationRejections,
      );
      setRejectedEntries(totalRejectedEntries);
      const haveErrors = Object.keys(totalRejectedEntries).length;
      const finalCorrectEntries = getCorrectEntries(
        uploadedDataSet,
        totalRejectedEntries,
      );
      setFinalCorrectData(finalCorrectEntries);
      trackAddValidationSummary(
        updatedUploadedData,
        totalRejectedEntries,
        trackTask,
        location.pathname,
        policies,
      );
      if (haveErrors) {
        const workbook = generateErrorTemplate(
          totalRejectedEntries,
          policies,
        );
        const fileBuffer = write(workbook, {
          type: 'array',
          bookType: 'xlsx',
        });
        const errorFile = new File([fileBuffer], 'workbook.xlsx', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        errorWorkbook.current = errorFile;
        downloadFile(errorFile, 'Error-Report.xlsx');
        toast?.success(
          '"Error Report" has been successfully saved in your device.',
          '',
          {
            closeOnClick: false,
            variant: 'light',
          },
        );
        trackPage(trackPageEvent('/bulk-add-errors'));
        setIsLoading(LOADING_CONSTANTS.VALIDATION_HAS_ERRORS);
        setTimeout(() => {
          setIsLoading(null);
          trackPage(trackPageEvent('/upload-unsuccessful-add'));
        }, 3000);
      } else {
        moveToSubmitEndorsementPage();
        setIsLoading(LOADING_CONSTANTS.VALIDATION_HAS_NO_ERRORS);
        setTimeout(() => {
          setIsLoading(null);
          trackPage(trackPageEvent('/upload-successful-add'));
        }, 3000);
      }
    } catch (err) {
      toast?.error((err as Error).message);
    }
  };

  const checkMidTermAdditions = async () => {
    setCurrentStep(2);
    const startTime = new Date().getTime();
    setIsLoading(LOADING_CONSTANTS.VALIDATE_MEMBERS);
    const identifiedMidtermResponse = await identifyPolicyWiseMidTermAdditions(
      uploadedData,
      selectedCompany?.id,
    );
    const endTime = new Date().getTime();
    setIdentifedMidterms(identifiedMidtermResponse);
    const { isChildMidtermPresent, isSpouseMidtermPresent } =
      checkMidtermPresent(identifiedMidtermResponse);
    const midTermModalVisible = () => {
      setMidTermModalVisible(true);
      trackPage(trackPageEvent('/midterm-found-modal-add'));
    };
    if (isSpouseMidtermPresent) setTimeout(midTermModalVisible, 2000);
    else if (isChildMidtermPresent) {
      const { rejectedEntries, updatedAcceptedEntries } =
        await validateMidTerms(identifiedMidtermResponse, []);
      const updatedUploadedData = updateFilePathForMidTerms(
        uploadedData,
        updatedAcceptedEntries,
      );
      setRejectedEntries(rejectedEntries);
      setTimeout(
        () => validateData(rejectedEntries, updatedUploadedData),
        endTime - startTime < 1500 ? 1500 : 0,
      );
    } else {
      const rejectedEntries = getRejectedEntriesOnSkip(
        identifiedMidtermResponse,
      );
      setRejectedEntries(rejectedEntries);
      setTimeout(
        () => validateData(rejectedEntries, uploadedData),
        endTime - startTime < 1500 ? 1500 : 0,
      );
    }
  };

  const triggerCancelEmails = useCallback(() => {
    if (isLoading || !selectedCompany?.isNotificationsEnabled) return;
    const summaryData = getAddValidationSummaryData(
      uploadedData,
      rejectedEntries,
      policies,
    );
    switch (currentStep) {
      case 2:
        LoopApiService.endoStep2ExitTrigger(
          errorWorkbook?.current,
          summaryData.correct.totalLives?.toString(),
          summaryData.incorrect.totalLives?.toString(),
          summaryData.total.totalLives?.toString(),
          companyId,
        );
        break;
      case 3:
        LoopApiService.endoStep3ExitEmailTrigger(
          sheetSelected,
          Object.keys(finalCorrectData)?.length?.toString(),
          totalCost.toString(),
          summaryData.total.totalLives?.toString(),
          summaryData.correct.totalLives?.toString(),
          summaryData.incorrect.totalLives?.toString(),
          companyId,
        );
        break;
    }
  }, [
    isLoading,
    uploadedData,
    rejectedEntries,
    policies,
    currentStep,
    companyId,
    sheetSelected,
    finalCorrectData,
    totalCost,
    totalLives,
  ]);

  const onConfirmCancelClick = async () => {
    if (isCancelModalVisible && errorWorkbook.current) {
      triggerCancelEmails();
    }
    trackClick(
      trackClickEvent(
        'Yes_Leave_Error_Table_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    clearStates();
  };

  const clearStates = () => {
    setCurrentStep(1);
    setRejectedEntries({});
    setFinalCorrectData({});
    setIdentifedMidterms({});
    deleteMidtermDocumentsOnCancel(uploadedData);
    setCancelModalVisible(false);
    setSheetSelected([]);
    setUploadedData({});
  };

  const isEndoInProgress = useMemo(
    () => [2, 3].includes(currentStep),
    [currentStep],
  );
  useEffect(() => {
    if (isEndoInProgress) {
      const beforeUnloadCallback = async (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return true;
      };
      window.addEventListener('beforeunload', beforeUnloadCallback);
      window.addEventListener('unload', triggerCancelEmails);
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadCallback);
        window.removeEventListener('unload', triggerCancelEmails);
      };
    }
  }, [isEndoInProgress, triggerCancelEmails]);

  const triggerEndoSubmitEmails = async () => {
    const policies = endoCostList
      .map((cdAccountCostData) => {
        return cdAccountCostData.policyData.map((policy: any) => ({
          policyName: `${cdAccountCostData.insurer}_${policy.policyType}`,
          livesSubmitted: policy.lives,
          costOfEndo: policy.costOfEndorsement,
          cdBalance: cdAccountCostData.balance,
          cdStatus: cdAccountCostData.isCDLow ? 'Insufficient' : 'Adequate',
        }));
      })
      .flat();
    await parseResponse(
      lowCDModalVisible
        ? LoopApiService.lowCDEndoSubmissionEmailTrigger(
            sheetSelected,
            policies,
            companyId,
          )
        : LoopApiService.endoSubmissionEmailTrigger(
            sheetSelected,
            policies,
            companyId,
          ),
    );
  };

  useTrackPage();

  return (
    <>
      <Prompt
        when={isEndoInProgress}
        message="Wait! Are you sure you want to leave? if you leave from here, you'll lose this complete data."
      />
      {currentStep === 4 ? (
        <EndoSuccessSummary
          endoCostList={endoCostList}
          submittedData={finalCorrectData}
          mode="ADD"
        />
      ) : (
        <StyledContainer>
          <StyledDetailsWrapper>
            <StyledDetailsHeader onClick={onBackClick}>
              <StyledBackIcon src={ArrowLeftIcon} />
              <Typography variant="medium" weight="medium">
                Bulk Add Members
              </Typography>
            </StyledDetailsHeader>

            <StyledStepperWrapper>
              <Stepper
                steps={STEPPER_DATA.ADD}
                currentStep={currentStep}
                variant="locked"
                onStepperClicked={() => {}}
              />
            </StyledStepperWrapper>
          </StyledDetailsWrapper>
          {isLoading ? (
            <FullPageLoader {...isLoading} />
          ) : (
            <>
              {currentStep === 1 && (
                <UploadAddition
                  setSheetData={setUploadedData}
                  setSheetSelected={setSheetSelected}
                  sheetSelected={sheetSelected}
                />
              )}
              {currentStep === 2 && (
                <ValidationsScreen
                  operation="ADD"
                  rejectedEntries={rejectedEntries}
                  uploadedData={uploadedData}
                />
              )}
              {currentStep === 3 && (
                <SubmitEndo
                  mode="ADD"
                  isLoading={isEndoCostLoading}
                  endoCostList={endoCostList}
                  totalCost={totalCost}
                  totalLives={totalLives}
                />
              )}
              <Shadow variant="bottom">
                <StyledBottomNavigation>
                  {isBackEnabled && (
                    <LoopButton
                      size="medium"
                      variant="outline"
                      onClick={onBackClick}
                    >
                      {backButtonText}
                    </LoopButton>
                  )}
                  <LoopButton
                    size="medium"
                    isLoading={isSubmitting || isEndoCostLoading}
                    variant={isNextEnabled ? 'filled' : 'disabled'}
                    onClick={onProceedClicked}
                  >
                    {buttonText}
                  </LoopButton>
                </StyledBottomNavigation>
              </Shadow>
            </>
          )}
          <MidTermAdditionModal
            isVisible={midTermModalVisible}
            setIsVisible={setMidTermModalVisible}
            identifedMidterms={identifiedMidterms}
            validateData={validateData}
            setRejectedEntries={setRejectedEntries}
            uploadedData={uploadedData}
            setLoading={setIsLoading}
          />
          <CancellationModal
            isBackBtnModalVisible={isBackBtnModalVisible}
            isCancelModalVisible={isCancelModalVisible}
            setCancelModalVisible={onSetCancelModalClick}
            setBackBtnModalVisible={setBackBtnModalVisible}
            onConfirmCancelClick={onConfirmCancelClick}
            lowCDModalVisible={lowCDModalVisible}
            setLowCDModalVisible={setLowCDModalVisible}
            submitEndorsements={submitEndorsements}
            isSubmitting={isSubmitting}
          />
        </StyledContainer>
      )}
    </>
  );
};

export default BulkAddMembers;
