import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import {
  capitalizeFirstLetterOfEachWord,
  formatCurrencyAmount,
  textPlaceholder,
} from '../../../../utils/common/Utilities';
import { StyledLink } from './styles';
import { IClaimData } from '../../../pages/ClaimsList/types';

export const getClaimAccordionObjectFromClaimData = (
  claimData: IClaimData,
  policyData: {
    policyType: string;
    id: string;
  },
) => {
  if (!claimData) return [];
  const policyLink = 'policy-details?policyId=' + policyData.id;
  const hospitalCity = claimData.hospital.split(',');
  const formatDate = new DateFormatUtils().formatDate;
  const formatString = (text: string) => capitalizeFirstLetterOfEachWord(text);
  const sanitizeHospitalName = (hospitalName: string) =>
    ['na, na', 'na'].includes(hospitalName?.trim()?.toLowerCase())
      ? 'NA'
      : formatString(hospitalName);
  return [
    {
      title: 'Overview',
      data: {
        left: {
          'Patient name': formatString(claimData.benificiaryName),
          'Patient gender': formatString(claimData.benificiaryGender),
          'Sum insured': textPlaceholder(
            formatCurrencyAmount(claimData.sumInsured),
          ),
        },
        center: {
          'Employee name': formatString(claimData.employeeName),
          'Last document submitted on:': formatDate(claimData.claimReceivedAt),
          'Policy type': (
            <StyledLink to={policyLink}>{policyData.policyType}</StyledLink>
          ),
        },
        right: {
          'Claim type': formatString(claimData.type),
          'Claim amount': textPlaceholder(
            formatCurrencyAmount(claimData.claimAmount),
          ),
          'Policy no.': textPlaceholder(claimData.policyNo),
        },
      },
    },
    {
      title: 'Payment details',
      data: {
        left: {
          'Claimed amount': formatCurrencyAmount(claimData.claimAmount),
        },
        center: {
          'Paid amount':
            claimData.approvedAmount > 0
              ? formatCurrencyAmount(claimData.approvedAmount)
              : '--',
        },
        right: {},
      },
    },
    {
      title: 'Patient details',
      data: {
        left: {
          'Patient name': formatString(claimData.benificiaryName),
          Relation: formatString(claimData.relationship),
        },
        center: {
          Gender: formatString(claimData.benificiaryGender),
        },
        right: {
          Age: textPlaceholder(claimData.benificiaryAge) + ` Years`,
        },
      },
    },
    {
      title: 'Intimation details',
      data: {
        left: {
          'Intimation date': claimData.claimIntimatedAt
            ? formatDate(claimData.claimIntimatedAt)
            : '--',
        },
        center: {
          UHID: textPlaceholder(claimData.uhid),
        },
        right: {},
      },
    },
    {
      title: 'Admission details',
      data: {
        left: {
          'Admission date': formatDate(claimData.admissionAt),
          'Discharge date': formatDate(claimData.dischargedAt),
          'Hospital city': hospitalCity[hospitalCity.length - 1].trim() ?? '--',
        },
        center: {
          'Hospital name': sanitizeHospitalName(claimData.hospital),
        },
        right: {
          'Primary ailment': formatString(claimData.disease),
        },
      },
    },
    {
      title: 'Insurer & TPA details',
      data: {
        left: {
          'Insurer name': formatString(claimData.insuranceName),
        },
        center: {
          'TPA name': formatString(claimData.tpaName),
        },
        right: {},
      },
    },
  ];
};
