import {
  LoopButton,
  Shadow,
  Stepper,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../../assets/images';
import FullPageLoader from '../../../atoms/Loader';
import {
  LOADING_CONSTANTS,
  STEPPER_DATA,
} from '../../../containers/Bulk/constants';
import EndoSuccessSummary from '../../../containers/Bulk/EndoSuccessSummary';
import useEstimateEndorsementCost from '../../../containers/Bulk/hooks/useEstimateEndorsementCost';
import {
  StyledBackIcon,
  StyledBottomNavigation,
  StyledContainer,
  StyledDetailsHeader,
  StyledDetailsWrapper,
  StyledEditStepperWrapper,
} from '../../../containers/Bulk/styles';
import SubmitEndo from '../../../containers/Bulk/SubmitEndo';
import DeleteUserSelectModal from './DeleteUserSelectModal';
import { ISingleDeleteEntry } from './types';
import { submitBulkDeleteEndorsement } from '../../../containers/Bulk/SubmitEndo/utils';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import moment from 'moment';

const SingleDeleteMembers: React.FC = () => {
  const toast = Toast.useToast();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedUser, setSelectedUser] = useState<ISingleDeleteEntry | null>(
    null,
  );
  const [isSearchModalVisible, setIsSearchModalVisible] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cancelModalVisible, setIsCancelModalVisible] = useState(false);

  const companyId = useSelector(selectSelectedCompany)?.id || '';
  const {
    endoCostList,
    totalCost,
    totalLives,
    isLoading: isEndoCostLoading,
  } = useEstimateEndorsementCost([selectedUser!], currentStep, 'SINGLE_DELETE');

  const isEndoInProgress = !!selectedUser && currentStep !== 3 && !cancelModalVisible;

  useEffect(() => {
    if (currentStep === 1) setIsSearchModalVisible(true);
  }, [currentStep]);

  const onBackClick = () => {
    if (isSubmitting) return;
    setCurrentStep(1);
  };

  const onProceedClicked = async () => {
    setIsSubmitting(true);
    const payload = {
      employeeId: selectedUser?.employeeId,
      dateOfLeaving: moment(selectedUser?.dateOfLeaving).format('DD/MM/YYYY'),
    };
    try {
      const response = await submitBulkDeleteEndorsement(companyId, [payload]);
      setIsSubmitting(false);
      if (!response) throw new Error('Something went wrong');
      setCurrentStep(3);
    } catch (e) {
      toast?.error((e as Error).message, '', {
        closeOnClick: false,
        variant: 'light',
      });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Prompt
        when={isEndoInProgress}
        message="Wait! Are you sure you want to leave? if you leave from here, you'll lose this complete data."
      />
      {currentStep === 3 ? (
        <EndoSuccessSummary
          endoCostList={endoCostList}
          submittedData={[selectedUser!]}
          mode="SINGLE_DELETE"
        />
      ) : (
        <StyledContainer>
          <StyledDetailsWrapper>
            <StyledDetailsHeader onClick={onBackClick}>
              <StyledBackIcon src={ArrowLeftIcon} />
              <Typography variant="medium" weight="medium">
                Delete Members
              </Typography>
            </StyledDetailsHeader>

            <StyledEditStepperWrapper>
              <Stepper
                steps={STEPPER_DATA.SINGLE_DELETE}
                currentStep={currentStep}
                variant="locked"
                onStepperClicked={() => {}}
              />
            </StyledEditStepperWrapper>
          </StyledDetailsWrapper>
          {isEndoCostLoading ? (
            <FullPageLoader {...LOADING_CONSTANTS.CHECKING_DELETE_ENDO_COST} />
          ) : (
            <>
              {currentStep === 2 && (
                <SubmitEndo
                  mode="SINGLE_DELETE"
                  isLoading={isEndoCostLoading}
                  endoCostList={endoCostList}
                  totalCost={totalCost}
                  totalLives={totalLives}
                />
              )}
              <Shadow variant="bottom">
                <StyledBottomNavigation>
                  {currentStep === 2 && (
                    <LoopButton
                      size="medium"
                      variant="outline"
                      onClick={onBackClick}
                    >
                      Review Again
                    </LoopButton>
                  )}
                  <LoopButton
                    size="medium"
                    isLoading={isSubmitting}
                    variant="filled"
                    onClick={onProceedClicked}
                  >
                    Submit {totalLives} Lives
                  </LoopButton>
                </StyledBottomNavigation>
              </Shadow>
            </>
          )}
        </StyledContainer>
      )}
      <DeleteUserSelectModal
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        setIsModalVisible={setIsSearchModalVisible}
        isModalVisible={isSearchModalVisible}
        setCurrentStep={setCurrentStep}
        cancelModalVisible={cancelModalVisible}
        setIsCancelModalVisible={setIsCancelModalVisible}
      />
    </>
  );
};

export default SingleDeleteMembers;
