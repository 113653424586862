import styled from 'styled-components';
import { isEndoBulkEditEnabled } from '../../../utils/featureFlag';

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledNotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  padding: 14px 20px;
  max-width: ${isEndoBulkEditEnabled? '740px' : '504px'};
  gap: 16px;
  margin: 24px 0;
  background-color: ${(p) => p.theme.colors.mintFrost};
`;

export const StyledEndoActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
export const StyledEndoActionImage = styled.img`
  margin-bottom: 16px;
`;
export const StyledCrossIcon = styled.img`
  cursor: pointer;
`;
export const StyledEndoActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  gap: 8px;
  cursor: pointer;
  max-width: 236px;
  padding: 24px 20px;
  border-radius: 12px;
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};

  :hover {
    background-color: ${(p) => p.theme.colors.mintFrost};
    border: 1px solid ${(p) => p.theme.colors.supportingPlaneGreenDark};
  }
`;
