import { ALL_CLAIMS_CONSTANT } from '../../pages/ClaimsList/constants';
import { IClaimListHeaderTabs } from './types';
import theme from '../../../theme';

export const CLAIMS_LIST_HEADER_TABS: IClaimListHeaderTabs[] = [
  {
    label: 'All Claims',
    color: theme.colors.consultViolet,
    status: ALL_CLAIMS_CONSTANT,
  },
  { label: 'Settled', color: theme.colors.turquoise, status: 'Settled' },
  { label: 'Ongoing', color: theme.colors.coverYellow, status: 'Ongoing' },
  {
    label: 'Under Query',
    color: theme.colors.planRed,
    status: 'Under Query',
    tooltip: 'Claims that require additional documents from the employee',
  },
  {
    label: 'Closed',
    color: theme.colors.planRed,
    status: 'Closed',
    tooltip:
      'Claims closed by the insurer due to documents not being submitted on time',
  },
  {
    label: 'Rejected',
    color: theme.colors.planRed,
    status: 'Rejected',
    tooltip: 'Claims rejected by the insurer',
  },
];
