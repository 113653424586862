import styled from 'styled-components';

export const StyledAccountsContainer = styled.div`
  margin-top: 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;

export const StyledAccountsSubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledLinkagePolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledPoliciesContainer = styled.div`
  display: flex;
  padding: 36px 56px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  background: ${(p) => p.theme.colors.white};
`;

export const StyledPolicyCards = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 40px;
  flex-wrap: wrap;
`;

export const StyledVerticalSpacer = styled.div`
  width: 1px;
  background-color: ${(p) => p.theme.colors.chineseWhite};
`;

export const StyledPolicyContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  max-width: 100%;
  align-items: center;
`;
