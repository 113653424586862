/*
Note: If you change any constants variable of UPDATE_DEPENDENT, ADD_DEPENDENT And DELETE_DEPENDENT then you also need to 
update the CHECK_DEPENDENT becuase in ViewEmployeeData.js file,
 we have added a check to find the dependent string which check wheter the operation is for dependent.
*/

export const AppConstants = {
  EMAIL_FOR_SIGNIN: 'emailForSignIn',
  FIR_DOC_ID: 'firDocId',
};
