import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import { mapToPolicyTypeFullForm } from './../../../../utils/common/mapping';
import moment from 'moment';

export const fileDownload = (fileName: string): void => {
  const cacheBuster = Date.now(); // Generate a unique value based on the current timestamp
  const templateURL = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${fileName}`;
  const cacheBustedUrl = `${templateURL}?cache=${cacheBuster}`;
  const link = document.createElement('a');
  link.href = cacheBustedUrl;
  link.download = `${fileName.split('.').slice(0, -1).join('.')}`;
  link.click();
  link.remove();
};

export const preparePolicyFilter = (policyPlans: any[]): IDropdownItem[] => {
  return policyPlans.map((policy: any) => {
    return {
      name: policy?.policyType,
      value: policy.id,
      data: {
        title: `${mapToPolicyTypeFullForm(policy?.policyType)} (${
          policy?.policyType
        })`,
        subtitle: policy?.insurerName,
        key: 'Policy no: ',
        value: policy?.policyNumber,
      },
    };
  });
};

export const processDateFormat = (date: string): string => {
  const parsedDate = moment(date, 'DD MMM YYYY');
  if (!parsedDate.isValid()) {
    return 'Invalid date';
  }
  const formattedDate = parsedDate.format('Do MMM’YY');
  return formattedDate;
};

export const extractDate = (inputString: string): string => {
  const result = inputString.length > 5 ? inputString.substring(0, 4) : '';
  return result;
};
