import styled from 'styled-components';

export const StyledCDBalanceStatus = styled.div`
  display: flex;
  gap: 4px;
  padding: 6px 8px;
  width: fit-content;
  background-color: ${(p) => p.theme.colors.white};
  align-items: center;
  width: 100%;
`;

export const StyledCDStatusTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledCDStatusSubText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;