import styled from 'styled-components';

export const StyledUserSelectModalWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    ${(p) =>
      `${p.theme.colors.loopEmerald} 250px, ${p.theme.colors.surface0} 250px, ${p.theme.colors.surface0} calc(100% - 250px)`}
  );
  overflow-x: hidden;
`;
