import React from 'react';
import {
  Typography,
  LoopBadge,
  DateFormatUtils,
} from '@loophealth/loop-ui-web-library';
import {
  StyledBullet,
  StyledClaimStatusWidget,
  StyledContainer,
  StyledInfoWrapper,
} from './styles';
import { ClaimStatusProps } from './types';

import moment from 'moment';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/common/Utilities';
import { IClaimTypes } from '../ClaimDetails/types';
import { statusBadgeType } from '../ClaimsTimeline/constant';
import { toTitleCase } from '../../../utils/common/common';

const ClaimStatusOverview: React.FC<ClaimStatusProps> = ({
  status,
  claimData,
}) => {
  const claimAge =
    claimData.lastStatusDate && claimData.claimReceivedAt
      ? moment(claimData.lastStatusDate || new Date()).diff(
          moment(claimData.claimReceivedAt),
          'days',
        ) + ` Days`
      : '--';
  const formatDate = new DateFormatUtils().formatDate;
  return (
    <StyledClaimStatusWidget>
      <StyledContainer>
        <Typography variant="large" weight="regular">
          Claim Status
        </Typography>
        <LoopBadge
          badgeType={statusBadgeType[status] || 'warning'}
          variant="overflow"
          text={toTitleCase(status)}
        />
      </StyledContainer>
      <StyledContainer>
        <StyledInfoWrapper>
          <Typography variant="small" weight="medium">
            {claimData.tpaClaimId}
          </Typography>
          <Typography
            variant="small"
            color="secondary"
          >{`(Claim ID)`}</Typography>
        </StyledInfoWrapper>
        <StyledBullet />
        <StyledInfoWrapper>
          <Typography variant="small" weight="medium">
            {`${capitalizeFirstLetterOfEachWord(claimData.benificiaryName)}`}
          </Typography>
          <Typography
            variant="small"
            color="secondary"
          >{`(Patient)`}</Typography>
        </StyledInfoWrapper>
        <StyledBullet />
        <StyledInfoWrapper>
          <Typography variant="small" weight="medium">
            {claimData.relationship}
          </Typography>
        </StyledInfoWrapper>
        <StyledBullet />
        <StyledInfoWrapper>
          <Typography variant="small" weight="medium">
            {`${claimData.type} Claim`}
          </Typography>
        </StyledInfoWrapper>
        {claimData.type === IClaimTypes.reimbursement ? (
          <>
            <StyledBullet />
            <StyledInfoWrapper>
              <Typography variant="small" weight="medium">
                {claimAge}
              </Typography>
              <Typography
                variant="small"
                color="secondary"
              >{`(Claim Age)`}</Typography>
            </StyledInfoWrapper>
          </>
        ) : (
          <>
            {status === 'Rejected' ? (
              <>
                <StyledBullet />
                <StyledInfoWrapper>
                  <Typography variant="small" weight="medium">
                    {formatDate(claimData.dischargedAt)}
                  </Typography>
                  <Typography
                    variant="small"
                    color="secondary"
                  >{`(Discharge date)`}</Typography>
                </StyledInfoWrapper>
              </>
            ) : null}
          </>
        )}
      </StyledContainer>
    </StyledClaimStatusWidget>
  );
};

export default ClaimStatusOverview;
