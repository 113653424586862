import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import LoopApiService from '../../../../adaptars/LoopApiService';
import {
  capitalizeFirstLetter,
  parseResponse,
} from '../../../../utils/common/Utilities';
import {
  IBulkEditData,
  IEditableFields,
} from '../../../pages/Endorsements/BulkEditMembers/types';
import { IRelationship } from '../types';
import { IRelationshipFileSelectionItem } from './types';
import { EDIT_FIELD_NAME_MAP } from '../constants';

export const validateEditedUser = async (
  selectedUser: IBulkEditData,
): Promise<Record<string, string>> => {
  const payload = {
    companyId: selectedUser.companyId,
    memberData: {
      userId: selectedUser.userId,
      name: selectedUser.name,
      relationship_to_account_holders: selectedUser.relationship,
      gender: selectedUser.gender,
      mobile: selectedUser.mobile,
      email_address: selectedUser.email,
      date_of_birth: new DateFormatUtils().excelToBackendDateFormat(
        selectedUser.dob,
      ),
      ctc: selectedUser.ctc,
    },
  };
  const [error, response] = await parseResponse(
    LoopApiService.validateBulkEditionData(payload),
  );
  if (error) {
    throw error;
  }
  const invalidMemberDetails = response?.data?.members[0];
  if (invalidMemberDetails?.errors) {
    try {
      const errors = JSON.parse(invalidMemberDetails.errors);
      return errors;
    } catch (e) {
      throw new Error(invalidMemberDetails.errors);
    }
  }
  return {};
};

export const getFileUploadRequiredforRelationship = (
  firstName: string,
  selfName: string,
  realtionship: IRelationship,
): IRelationshipFileSelectionItem[] => {
  switch (realtionship) {
    case 'self':
      return [];
    case 'child':
      return [
        {
          title: 'Upload Birth Certificate',
          subtitle:
            'Please make sure your details, like child birth date, are clearly visible.',
        },
      ];
    case 'spouse':
      return [
        {
          title: 'Upload Marriage Certificate',
          subtitle:
            'Please make sure your details, like your marriage date, are clearly visible.',
        },
      ];
    case 'parent':
    case 'parent-in-law':
      return [
        {
          title: `Upload ${capitalizeFirstLetter(selfName)}’s Adhaar Card`,
          subtitle:
            'Your details like photo, name, DOB & Aadhar number should be clearly visible.',
        },
        {
          title: `Upload ${capitalizeFirstLetter(firstName)}’s Adhaar Card`,
          subtitle:
            'Your details like photo, name, DOB & Aadhar number should be clearly visible.',
        },
      ];
  }
};

export const formatValuesByField = (
  field: IEditableFields,
  value: string | null,
) => {
  if (!value) return 'Not Provided';
  switch (field) {
    case 'mobile':
      return value ? `+91-${value}` : 'Not Provided';
    default:
      return value;
  }
};

export const getIndividualTrackingFieldsObject = (
  field: IEditableFields | IEditableFields[],
) => {
  const trackingFields: Record<string, boolean> = {
    Name: false,
    Gender: false,
    'Date of Birth': false,
    Relationship: false,
    'Mobile Number': false,
    'Email Id': false,
  };
  if (Array.isArray(field)) {
    field.forEach((f) => {
      trackingFields[EDIT_FIELD_NAME_MAP[f]] = true;
    });
  } else {
    trackingFields[EDIT_FIELD_NAME_MAP[field]] = true;
  }
  return trackingFields;
};
