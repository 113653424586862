import styled, { css } from 'styled-components';
import { ICardProps } from './types';

export const StyledCard = styled.div<ICardProps>`
  flex: 1;
  width: 100%;
  min-height: 200px;
  border-radius: ${({ rounded }) => (rounded ? '12px' : 'none')};
  background-color: ${(p) => p.theme.colors.surfaceOne};

  ${({ joined }) =>
    joined
      ? css`
          border-radius: 0;
          :first-child {
            border-radius: 12px 0 0 12px;
            border-right: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
          }
          :last-child {
            border-radius: 0 12px 12px 0;
            border-left: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
          }
          :not(:first-child, :last-child) {
            border-left: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
            border-right: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
          }
        `
      : ''}
`;
