import { IPolicyListItemSlab } from '../../../../redux/slices/hrdRevampRedux/types';
import { formatCurrencyAmountAbbr } from '../../../../utils/common/Utilities';

export const converPolicySlabsIntoDropdownItems = (
  policySlabs?: IPolicyListItemSlab[],
) => {
  return (
    policySlabs?.map((slab) => {
      const sumInsured = formatCurrencyAmountAbbr(slab.sumInsured);
      const name = slab.grade
        ? `Sum Insured - ${sumInsured}, Employee Grade - ${slab.grade}`
        : `Sum Insured - ${sumInsured}, Family Structure - ${slab.familyDefinition}`;
      return {
        name,
        value: slab.slabId,
      };
    }) ?? []
  );
};
