import styled from 'styled-components';

export const StyledClaimsList = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;

export const StyledClaimAgeHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  
  & > div {
    white-space: normal;
    text-transform: none;
  }
  
  img {
    width: 20px;
    height: 20px;
  }
`;
export const StyledClaimIdUnderline = styled.div`
  text-decoration: underline;
  text-underline-offset: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledEmptyStateContainer = styled.div`
  padding: 40px 56px;
`;