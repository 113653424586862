import { feedbackData } from './constants';
import { IFeedbackStepOneProps } from './types';
import FeedbackChildItem from './FeedbackChildItem';

const FeedbackStepOne: React.FC<IFeedbackStepOneProps> = ({formData, setFormData}) => {
    return (
        <div>
            {
                feedbackData.map((feedbackItem) => {
                    return (
                        <FeedbackChildItem
                            key={feedbackItem.name}
                            formData={formData}
                            setFormData={setFormData}
                            question={feedbackItem.question}
                            name={feedbackItem.name}
                            label={feedbackItem.label}
                            icon={feedbackItem.icon}
                        />
                    )
                })
            }
        </div>
    );
}

export default FeedbackStepOne;