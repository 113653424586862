import { Typography } from '@loophealth/loop-ui-web-library';
import { formatCurrencyAmount } from '../../../utils/common/Utilities';
import { StyledCDStatusSubText, StyledCDStatusTextWrapper } from './styles';
import { ICdBalanceStatus } from './types';
import { ICDAccountUI } from '../../pages/CDAccounts/types';

export const getCDBalanceStatus = (
  cdAccount: ICDAccountUI,
): ICdBalanceStatus => {
  if (!cdAccount.endosInProgress) {
    return {
      statusText: 'Current balance after last endorsements were completed.',
      title: 'Current CD Balance',
      subTitle:
        'Current CD balance shown is the final amount after your last endorsements were completed.',
      redirectType: 'Completed',
    };
  }
  if (cdAccount.blockedAmount >= 0) {
    return {
      title: 'Endorsement in Progress',
      redirectType: 'Ongoing',
      statusText: (
        <StyledCDStatusTextWrapper>
          <Typography variant="extraSmall" color="error">
            *
          </Typography>
          <Typography variant="extraSmall" color="primary" weight="semiBold">
            {formatCurrencyAmount(cdAccount.blockedAmount)} blocked
          </Typography>
          &nbsp;for processing ongoing endorsements.
        </StyledCDStatusTextWrapper>
      ),
      subTitle: (
        <StyledCDStatusSubText>
          <div>
            An amount of {formatCurrencyAmount(cdAccount.blockedAmount)} has
            been blocked for processing ongoing endorsements.
          </div>
          <div>
            Final CD balance will update once endorsement is processed by
            insurer.
          </div>
        </StyledCDStatusSubText>
      ),
    };
  }
  return {
    title: 'Endorsement in Progress',
    redirectType: 'Ongoing',
    statusText: (
      <StyledCDStatusTextWrapper>
        <Typography variant="extraSmall" color="error">
          *
        </Typography>
        Approx.&nbsp;
        <Typography variant="extraSmall" color="primary" weight="semiBold">
          refund of {formatCurrencyAmount(Math.abs(cdAccount.blockedAmount))}
        </Typography>
        &nbsp;for ongoing endorsements.
      </StyledCDStatusTextWrapper>
    ),
    subTitle: (
      <StyledCDStatusSubText>
        <div>
          An approximate amount of{' '}
          {formatCurrencyAmount(Math.abs(cdAccount.blockedAmount))} will be
          refunded after processing ongoing endorsements.
        </div>
        <div>
          Final CD balance will update once the endorsement is processed by
          insurer.
        </div>
      </StyledCDStatusSubText>
    ),
  };
};
