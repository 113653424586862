import React from 'react';

export type ITrackTask = ({
  name,
  properties,
}: {
  name: string;
  properties?: Record<string, unknown> | undefined;
}) => void;

interface ISegmentValues {
  timezone: string;
  traits: Record<string, unknown>;
  screen: Record<string, unknown>;
}
const initialState = {
  traits: {
    user_id: '',
    employer_id: '',
    employer_name: '',
    user_type: 'HR',
  },
  timezone: '',
  screen: {
    height: 0,
    width: 0,
  },
} as ISegmentValues;

const SegmentContext = React.createContext<ISegmentValues>(initialState);

type ISegmentType = 'page' | 'click' | 'track' | 'identify' | 'typed';

const useSegment = (
  type: ISegmentType,
  commonProperties?: Record<string, unknown>,
): (({
  name,
  properties,
}: {
  name: string;
  properties?: Record<string, unknown>;
}) => void) => {
  const userAgent = window.navigator.userAgent;
  const propertiesFromContext =
    React.useContext<ISegmentValues>(SegmentContext);

  if (type === 'page') {
    return ({
      name,
      properties,
    }: {
      name: string;
      properties?: Record<string, unknown>;
    }) => {
      window.analytics.track(
        {
          event: 'Viewed_' + name,
          type: 'View',
          properties: {
            ...properties,
            ...commonProperties,
            page_title: name,
            platform: 'hrd',
            device_id: userAgent,
          },
        },
        propertiesFromContext,
      );
    };
  } else if (type === 'click') {
    return ({
      name,
      properties,
    }: {
      name: string;
      properties?: Record<string, unknown>;
    }) =>
      window.analytics.track(
        {
          event: 'Clicked_' + name,
          type: 'Click',
          properties: {
            ...properties,
            ...commonProperties,
            button_name: name,
            form_name: name,
            platform: 'hrd',
            device_id: userAgent,
          },
        },
        propertiesFromContext,
      );
  } else if (type === 'track') {
    return ({
      name,
      properties,
    }: {
      name: string;
      properties?: Record<string, unknown>;
    }) =>
      window.analytics.track(
        {
          event: 'Task_' + name,
          type: 'Task',
          properties: {
            ...properties,
            ...commonProperties,
            name,
            platform: 'hrd',
            device_id: userAgent,
          },
        },
        propertiesFromContext,
      );
  } else if (type === 'typed') {
    return ({
      name,
      properties,
    }: {
      name: string;
      properties?: Record<string, unknown>;
    }) =>
      window.analytics.track(
        {
          event: 'Typed_' + name,
          type: 'Typed',
          properties: {
            ...properties,
            ...commonProperties,
            name,
            platform: 'hrd',
            device_id: userAgent,
          },
        },
        propertiesFromContext,
      );
  } else if (type === 'identify') {
    return ({
      name,
      properties,
    }: {
      name: string;
      properties?: Record<string, unknown>;
    }) => {
      window.analytics.identify(
        properties?.user_id ?? propertiesFromContext?.traits?.user_id ?? '',
        {
          ...properties,
        },
        {
          ...propertiesFromContext,
          user_id: propertiesFromContext.traits.user_id,
          platform: 'hrd',
          event: 'IdentifyUser',
          type: 'Identify',
          name,
          device_id: userAgent,
        },
      );
    };
  }
  return () => null;
};

export const SegmentProvider = SegmentContext.Provider;
export type { ISegmentValues };
export default useSegment;
