import {
  convertToNearestThousand,
  groupArrayOfObjectsByKey,
} from '../../../utils/common/Utilities';
import { EndoState } from '../Employees/types';
import { IEndorsements } from '../Endorsements/ListingPage/types';
import { ICDAccount, ICDAccountUI } from './types';

export const isCDBalanceLow = (accountData: ICDAccount) => {
  return accountData.balance < getRecommendedCDBalance(accountData);
};

export const getRecommendedCDBalance = (accountData: ICDAccount) => {
  const accountsFinalPremium = accountData.policyData.reduce(
    (totalPremium, policy) => totalPremium + policy.finalPremium,
    0,
  );
  // 10% of final premiums -
  return convertToNearestThousand(accountsFinalPremium * 0.1);
};

export const getFirstOngoingEndoCDAccount = (
  cdAccounts: ICDAccount[],
  endorsements: IEndorsements[],
) => {
  const policyGroupedEndo = groupArrayOfObjectsByKey(
    endorsements?.filter((endo) => endo.status !== EndoState.ENDO_COMPLETE) ||
      [],
    (endo) => endo.policyId,
  );
  for (let cdAccount of cdAccounts) {
    if (
      cdAccount.policies.find((policyId) => policyGroupedEndo[policyId]?.length)
    ) {
      return cdAccount.id;
    }
  }
  return cdAccounts[0]?.id;
};

export const transformCdAccountsUI = (
  cdAccounts: ICDAccount[],
  endorsements: IEndorsements[],
): ICDAccountUI[] => {
  return cdAccounts.map((cdAccount) => {
    const endos = endorsements.filter(
      (endo) =>
        cdAccount.policies.includes(endo.policyId) &&
        endo.status !== EndoState.ENDO_COMPLETE,
    );

    let totalCost = 0,
      deductedAmount = 0;

    endos.forEach((endo) => {
      const endoCost = endo.additionCost + endo.editsCost - endo.deletionCost;
      if (!['DRAFT', 'IN_PROGRESS'].includes(endo.status) && endoCost >= 0)
        deductedAmount += endoCost;
      totalCost += endoCost;
    });

    const unblockedCdBalance = cdAccount.balance + deductedAmount;
    return {
      ...cdAccount,
      netBalance: unblockedCdBalance,
      blockedAmount: totalCost,
      endosInProgress: endos.length,
    };
  });
};
