import React, { FC, memo } from 'react';
import {
  StyledSumInsuredUtilizedTableSection,
  StyledTable,
  StyledTableBody,
  StyledTableBottomHead,
  StyledTableData,
  StyledTableHead,
  StyledTableSingleHead,
  StyledTableTopHead,
  StyledTitleContainer,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { IAmountCell, ISiProps } from './types';
import { EMPTY_CELL } from './constants';
import { convertNumberWithSeprator } from '../../../../utils/common/Utilities';

const SumInsuredUtilizedTable: FC<ISiProps> = ({ data }) => {

  const FormattedTableCell: FC<IAmountCell> = ({ amount }) => (
    <StyledTableData>
      <Typography variant="small">
        {convertNumberWithSeprator(amount) ?? EMPTY_CELL}
      </Typography>
    </StyledTableData>
  );

  return (
    <StyledSumInsuredUtilizedTableSection>
      <StyledTitleContainer>
        <Typography variant="medium" weight="medium">
          Sum Insured Utilized
        </Typography>
      </StyledTitleContainer>

      <StyledTable>
        <colgroup span={2} />
        <colgroup span={2} />
        <colgroup span={2} />

        <StyledTableHead>
          <tr>
            <StyledTableSingleHead rowSpan={2}></StyledTableSingleHead>
            <StyledTableSingleHead rowSpan={2}>
              <Typography variant="small" color="emerald" weight="semiBold">
                SI Band
              </Typography>
            </StyledTableSingleHead>
            <StyledTableTopHead colSpan={2} scope="colgroup">
              <Typography variant="small" color="emerald" weight="semiBold">
                &lt;50%
              </Typography>
            </StyledTableTopHead>
            <StyledTableTopHead colSpan={2} scope="colgroup">
              <Typography variant="small" color="emerald" weight="semiBold">
                50-75%
              </Typography>
            </StyledTableTopHead>
            <StyledTableTopHead colSpan={2} scope="colgroup">
              <Typography variant="small" color="emerald" weight="semiBold">
                &gt;75%
              </Typography>
            </StyledTableTopHead>
            <StyledTableSingleHead rowSpan={2}></StyledTableSingleHead>
          </tr>

          <tr>
            <StyledTableBottomHead scope="col">
              <Typography variant="small" color="emerald" weight="semiBold">
                No of Employees
              </Typography>
            </StyledTableBottomHead>
            <StyledTableBottomHead scope="col">
              <Typography variant="small" color="emerald" weight="semiBold">
                Incurred Amount
              </Typography>
            </StyledTableBottomHead>
            <StyledTableBottomHead scope="col">
              <Typography variant="small" color="emerald" weight="semiBold">
                No of Employees
              </Typography>
            </StyledTableBottomHead>
            <StyledTableBottomHead scope="col">
              <Typography variant="small" color="emerald" weight="semiBold">
                Incurred Amount
              </Typography>
            </StyledTableBottomHead>
            <StyledTableBottomHead scope="col">
              <Typography variant="small" color="emerald" weight="semiBold">
                No of Employees
              </Typography>
            </StyledTableBottomHead>
            <StyledTableBottomHead scope="col">
              <Typography variant="small" color="emerald" weight="semiBold">
                Incurred Amount
              </Typography>
            </StyledTableBottomHead>
          </tr>
        </StyledTableHead>

        <StyledTableBody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <StyledTableData></StyledTableData>
                <FormattedTableCell amount={item.siBand} />
                <StyledTableData>
                  <Typography variant="small">
                    {item.result?.low?.numberOfEmployees ?? EMPTY_CELL}
                  </Typography>
                </StyledTableData>
                <FormattedTableCell amount={item.result?.low?.incurredAmount} />
                <StyledTableData>
                  <Typography variant="small">
                    {item.result?.mid?.numberOfEmployees ?? EMPTY_CELL}
                  </Typography>
                </StyledTableData>
                <FormattedTableCell amount={item.result?.mid?.incurredAmount} />
                <StyledTableData>
                  <Typography variant="small">
                    {item.result?.high?.numberOfEmployees ?? EMPTY_CELL}
                  </Typography>
                </StyledTableData>
                <FormattedTableCell
                  amount={item.result?.high?.incurredAmount}
                />
                <StyledTableData></StyledTableData>
              </tr>
            ))}
        </StyledTableBody>
      </StyledTable>
    </StyledSumInsuredUtilizedTableSection>
  );
};

export default memo(SumInsuredUtilizedTable);
