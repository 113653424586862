import styled from 'styled-components';

export const StyledPolicyFilterContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 56px;
`;

export const StyledPolicyFilters = styled.section`
  display: flex;
  gap: 15px;
`;
