import styled from 'styled-components';



export const StyledOverWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
`;

export const StyledNameContainer = styled.div`
  display: flex;
  gap: 16px;
  & > div {
    padding: 0;
  }
`;

export const StyledEmpDetailsContainer = styled.div`
  display: flex;
  gap: 23px;
  align-items: center;
`;

export const StyledNameDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  gap: 4px;
`;

export const StyledSeparator = styled.span`
  background-color: ${(p) => p.theme.colors.seafoamGray};
  width: 1px;
  height: 80%;
`;

export const StyleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
