import React, { FC, useState } from 'react';
import {
  Chip,
  LoopButton,
  LoopPaginatedTable,
} from '@loophealth/loop-ui-web-library';
import {
  getLabels,
  getTableHeader,
  getTableHeaders,
  getTableZeroStates,
} from './utils';
import {
  StyledEmptyContainer,
  StyledLoopButtonContainer,
  StyledTableHeaderContainer,
  StyledTableWrapper,
  StylesTabContainer,
} from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { DownloadIcon } from '../../../../assets/images';
import { IActiveRoasterTableProps, IEmpTable, IEmpTableTabs } from './types';
import {
  EMPLOYEE_TABLE_PROCESSOR,
  EMPLOYEE_TABLE_PROCESSOR_WITH_DEPENDENTS,
} from './tableElements';
import { checkIfGPAorGTLPolicy } from '../../../pages/Employees/utils';
import { ICategorizedData } from '../../../pages/Employees/types';
import ZeroStateCard from '../../../atoms/ZeroStateCard';
import { roasterExcelDownload } from './roasterToExcelService';
import {
  PolicyTypeConstants,
  RelationConstants,
} from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../utils/segment/utils';
import useSegment from '../../../../utils/segment/hooks/useSegment';

const ActiveRoasterTable: FC<IActiveRoasterTableProps> = ({
  selectedPolicyType,
  tableData,
}) => {
  const selectedCompany = useSelector(selectSelectedCompany);
  const trackClick = useSegment('click');
  const location = useLocation();
  const downloadRoaster = () => {
    trackClick(
      trackClickEvent(
        'DOWNLOAD_ACTIVE_ROASTER_CTA_EMPLOYEES_PAGE',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    roasterExcelDownload(tableData, selectedCompany?.name);
  };

  const history = useHistory();
  const [tab, setTab] = useState<keyof ICategorizedData>(
    IEmpTableTabs.Employees,
  );
  const tableHeader = getTableHeader(
    tab,
    tableData[tab].length,
    selectedPolicyType,
  );
  const { activeChipLabel, deletedChipLabel, rejectedChipLabel } =
    getLabels(selectedPolicyType);

  const onTableRowClicked = (rowData: Record<string, unknown>) => {
    const EmpProperties: Record<string, unknown> = {
      selected_emp_user_id: rowData.id ?? '',
      selected_emp_relationship: rowData.Relationship ?? '',
      selected_emp_coverage_start_Date: rowData['Coverage Start Date'] ?? '',
      selected_emp_coverage_end_Date: rowData['Coverage End Date'] ?? '',
      selected_emp_email: rowData.email,
      selected_emp_name: rowData['Employee Name'],
      selected_emp_member_name: rowData['Member name'],
    };
    trackClick(
      trackClickEvent(
        'EMPLOYEE_DETAILS',
        location.pathname,
        'HRD Employee Listing Page Events',
        EmpProperties,
      ),
    );
    history.push(`employee-details?id=${rowData.id}`, {
      referrerPage: 'Employee',
      showTab:
        rowData.Relationship === RelationConstants.self
          ? RelationConstants.self
          : RelationConstants.All,
    });
  };

  const handleTabChange = (tab: keyof ICategorizedData) => {
    setTab(tab);
    trackClick(
      trackClickEvent(
        tab + '_LIVES',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const onPageNavigation = (previousPage: number, currentPage: number) => {
    trackClick(
      trackClickEvent(
        previousPage < currentPage ? 'Next_LivesTable' : 'Previous_LivesTable',
        'Browse through list view',
        extractPathName(location?.pathname),
        {
          clickProperties: {},
          ...{
            previousPage,
            currentPage,
          },
        },
      ),
    );
  };

  const empTableHeaders = getTableHeaders(tab, selectedPolicyType);
  const empTableProcessors = checkIfGPAorGTLPolicy(selectedPolicyType)
    ? EMPLOYEE_TABLE_PROCESSOR
    : tab === IEmpTableTabs.Employees
    ? EMPLOYEE_TABLE_PROCESSOR
    : EMPLOYEE_TABLE_PROCESSOR_WITH_DEPENDENTS;
  const filteredData = tableData[tab];
  const tableZeroStates = getTableZeroStates();

  return (
    <>
      <StyledTableHeaderContainer>
        <StylesTabContainer>
          <Chip
            label={rejectedChipLabel}
            isActive={tab === IEmpTableTabs.Employees}
            onClick={() => handleTabChange(IEmpTableTabs.Employees)}
          />
          {selectedPolicyType === PolicyTypeConstants.GMC ? (
            <Chip
              label={activeChipLabel}
              isActive={tab === IEmpTableTabs.Active}
              onClick={() => handleTabChange(IEmpTableTabs.Active)}
            />
          ) : null}
          <Chip
            label={deletedChipLabel}
            isActive={tab === IEmpTableTabs.Deleted}
            onClick={() => handleTabChange(IEmpTableTabs.Deleted)}
          />
        </StylesTabContainer>

        <StyledLoopButtonContainer>
          <LoopButton
            variant={filteredData.length ? 'filled' : 'disabled'}
            onClick={() => downloadRoaster()}
            isLoading={false}
            size="medium"
            iconSrc={DownloadIcon}
            iconOrder="left"
          >
            Download Active Roster
          </LoopButton>
        </StyledLoopButtonContainer>
      </StyledTableHeaderContainer>

      <StyledTableWrapper>
        {filteredData.length ? (
          <LoopPaginatedTable
            tableHeader={tableHeader}
            count={filteredData.length}
            rowsPerPage={10}
            headers={empTableHeaders}
            processor={empTableProcessors}
            onTableRowClicked={onTableRowClicked}
            data={filteredData as unknown as Record<string, unknown>[]}
            onPageNavigation={onPageNavigation}
          />
        ) : (
          <StyledEmptyContainer>
            <ZeroStateCard
              title={tableZeroStates[tab].title}
              subtitle={tableZeroStates[tab].subtitle}
              image={tableZeroStates[tab].image}
              note={tableZeroStates[tab].note}
              noteType={tableZeroStates[tab].noteType}
            />
          </StyledEmptyContainer>
        )}
      </StyledTableWrapper>
    </>
  );
};

export default ActiveRoasterTable;
