import { ITableHeaderDef } from '@loophealth/loop-ui-web-library/dist/types/atoms/Table/types';
import {
  IBulkEditTableEntity,
  IEditableFields,
  IEditEntityEdits,
} from '../types';
import TableActionMenu from './TableActionMenu';
import { EDIT_FIELD_NAME_MAP } from '../../../../containers/Bulk/constants';

export const getEditTableColumns = (
  onEditClick: (index: number) => void,
  onDeleteClick: (index: number) => void,
): ITableHeaderDef<IBulkEditTableEntity> => {
  return [
    {
      key: 'employeeId',
      name: 'Employee ID',
      width: 100,
    },
    {
      key: 'name',
      name: 'Name',
      width: 160,
    },
    {
      key: 'relationship',
      name: 'Relationship to Employee',
      width: 160,
    },
    {
      key: 'edits',
      name: 'Edited Fields',
      width: 200,
      cell: (value) =>
        Object.keys(value as IEditEntityEdits)
          .map((field) => EDIT_FIELD_NAME_MAP[field as IEditableFields])
          .join(', '),
    },
    {
      key: 'actions',
      name: 'Actions',
      width: 40,
      cell: (_value, index) => {
        return (
          <TableActionMenu
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            index={index}
          />
        );
      },
    },
  ];
};
