import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  gap: 16px;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.white};
`;
export const StyledHeaderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    white-space: nowrap;
  }
`;

export const StyledTitleContainer = styled.div`
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HorizontalSeparator = styled.div`
  background: ${(p) => p.theme.colors.border};
  height: 1px;
  width: 100%;
`;
