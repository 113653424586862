import React, { ChangeEvent } from 'react';
import {
  StyledLoginPage,
  StyledLoginPageControls,
  StyledLoginPageControlsMobileForm,
  StyledLoginPageControlsSection,
  StyledLoginPageControlsSectionDivider,
  StyledLoginPageErrorAndSSOContainer,
  StyledLoginPageErrorComponent,
  StyledLoginPageHeader,
  StyledLoginPageSSOControls,
  StyledOtpContainer,
} from './styles';
import SideLayout from './SideLayout';
import { GoogleLogo, OutlookLogo } from '../../../../assets/images';
import { Form, LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { getErrorText } from './utils';
import Backdrop from '../../../atoms/Backdrop';
import useLogin from './hooks/useLogin';
import useTrackPage from '../../../../utils/segment/hooks/useTrackPage';

const Login = () => {
  const {
    error,
    hrData,
    loading,
    mobileNumber,
    mobileNumberError,
    setMobileNumber,
    signInWithGoogle,
    signInWithOutlook,
    signInWithMobileOtp,
  } = useLogin();

  const handleMobileNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value.startsWith('+91-')) value = value.substring(4);
    if (value === '') setMobileNumber('');
    else if (value.length > 10) return;
    else if (value.match(/^[0-9]*$/g)) setMobileNumber(`+91-${value}`);
  };
  
  useTrackPage();

  return (
    <StyledLoginPage>
      <SideLayout />

      <StyledLoginPageControlsSection>
        <StyledLoginPageControls>
          <StyledLoginPageHeader>
            <Typography variant="title1">Get started</Typography>

            <Typography variant="medium">
              Login to manage all your company's insurance affairs, effortlessly
            </Typography>
          </StyledLoginPageHeader>

          <StyledLoginPageErrorAndSSOContainer>
            {(error || mobileNumberError) && (
              <StyledLoginPageErrorComponent>
                <Typography variant="small" color="error" weight="medium">
                  {error ?? mobileNumberError}
                </Typography>
                <Typography variant="small" color="primary" weight="medium">
                  {getErrorText(error ?? mobileNumberError)}
                </Typography>
              </StyledLoginPageErrorComponent>
            )}

            <StyledLoginPageSSOControls>
              <LoopButton
                variant="secondary"
                size="medium"
                iconSrc={GoogleLogo}
                onClick={signInWithGoogle}
              >
                Continue with Google
              </LoopButton>

              <LoopButton
                variant="secondary"
                size="medium"
                iconSrc={OutlookLogo}
                onClick={signInWithOutlook}
              >
                Continue with Outlook
              </LoopButton>
            </StyledLoginPageSSOControls>
          </StyledLoginPageErrorAndSSOContainer>

          <StyledLoginPageControlsSectionDivider>
            <Typography variant="extraSmall">Or</Typography>
          </StyledLoginPageControlsSectionDivider>

          <StyledLoginPageControlsMobileForm>
            <Form.Input
              placeholder="Enter phone number"
              onChange={handleMobileNumberChange}
              value={mobileNumber}
              type="tel"
            />
            <LoopButton
              size="medium"
              onClick={signInWithMobileOtp}
              variant={mobileNumber.length < 14 ? 'disabled' : 'filled'}
            >
              Get OTP
            </LoopButton>
            <StyledOtpContainer id="otp-button"/>
          </StyledLoginPageControlsMobileForm>
        </StyledLoginPageControls>
      </StyledLoginPageControlsSection>
      {(loading || hrData?.state === 'loading') && <Backdrop />}
    </StyledLoginPage>
  );
};

export default Login;
