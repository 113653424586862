import { IClaimData } from '../../pages/ClaimsList/types';

export interface IClaimDetailsData {
  claimDetails: IClaimData[];
  claimsLoading: boolean;
}

export interface IClaimTimelineStatus {
  status: string;
  tag: string;
  text: string;
  date?: string;
  url?: string;
}

export enum IClaimTypes {
  reimbursement = 'reimbursement',
  cashless = 'cashless',
}
