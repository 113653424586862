import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { initialState } from './initialState';

const singleAddSlice = createSlice({
  name: 'singleAdd',
  initialState: initialState,
  reducers: {
    updateCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setAddSelectedPolicies: (state, action) => {
      state.selectedPolicyDetails = action.payload;
    },
    resetSingleAddState: (state) => {
      state = { ...initialState };
    },
  },
});

export const selectSingleAddCurrentStep = (state: RootState) =>
  state.singleAdd.currentStep;

export const selectAddEmployeeDetails = (state: RootState) =>
  state.singleAdd.employee;

export const selectAddSelectedPolicyDetails = (state: RootState) =>
  state.singleAdd.selectedPolicyDetails || [];

export const selectAddDependantDetails = (state: RootState) =>
  state.singleAdd.dependants;

export const {
  updateCurrentStep,
  setAddSelectedPolicies,
  resetSingleAddState,
} = singleAddSlice.actions;

export default singleAddSlice.reducer;
