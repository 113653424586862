import { Typography } from '@loophealth/loop-ui-web-library';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import { StyledEmptyDependantCard } from './styles';
import { IEmptyDependantCardProps } from './types';

const EmptyDependantCard: React.FC<IEmptyDependantCardProps> = ({
  label,
  onClick,
}) => {
  return (
    <StyledEmptyDependantCard onClick={onClick}>
      <AddIcon />{' '}
      <Typography variant="small" color="emerald" weight="semiBold">
        {label}
      </Typography>
    </StyledEmptyDependantCard>
  );
};

export default EmptyDependantCard;
