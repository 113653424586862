import {
  StyledMainContainer,
  StyledContainerWrapper,
  StyledErrorContainer,
} from './styles';
import Loader from '../../atoms/Spinner';
import useFetchPolicyDetails from './hooks/useFetchPolicyDetails';
import PolicyOverview from '../../containers/PolicyOverview';
import { Typography } from '@loophealth/loop-ui-web-library';
import useSearchQuery from '../ClaimsList/hooks/useSearchQuery';
import PolicyActiveRoster from '../../containers/PolicyActiveRoster';
import PolicyDetailsCoverage from '../../containers/PolicyDetailsCoverage';
import { Redirect } from 'react-router-dom';
import useTrackPage from '../../../utils/segment/hooks/useTrackPage';

const PolicyDetails = () => {
  const [policyId] = useSearchQuery('policyId');
  const { isLoading, policyDetail, basePolicyDetail, isLoadingBasePol } =
    useFetchPolicyDetails(policyId);
  useTrackPage(
    {
      policy_number: policyDetail?.policyNumber,
      policy_type: policyDetail?.policyType,
      policy_id: policyId,
    },
    !isLoading && !!policyDetail,
  );

  if (!policyId) return <Redirect to="/policies" />;

  return (
    <StyledMainContainer>
      {isLoading || isLoadingBasePol ? (
        <Loader />
      ) : policyDetail ? (
        <StyledContainerWrapper>
          <PolicyOverview policyDetails={policyDetail} />
          <PolicyActiveRoster policyDetails={policyDetail} />
          <PolicyDetailsCoverage
            policyDetails={policyDetail}
            basePolicyDetails={basePolicyDetail}
          />
        </StyledContainerWrapper>
      ) : (
        <StyledErrorContainer>
          <Typography variant="medium">
            Some error occurred. Please refresh the page and try again
          </Typography>
        </StyledErrorContainer>
      )}
    </StyledMainContainer>
  );
};
export default PolicyDetails;
