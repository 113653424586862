import { createSlice } from '@reduxjs/toolkit';
import { initialState, apiState } from './initialState';
import { RootState } from '../../store';
import { fetchHrUser, fetchHrUserVerify, updateHrData } from './thunks';

const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    setConfirmationResult(state, action) {
      state.confirmationResult = action.payload.data;
    },
    setClearHrData(state) {
      state.userData = {
        ...apiState,
        loginMethod: '',
      };
    },
    clearHrUpdate(state) {
      state.hrUpdate = { ...apiState };
    },
  },
  extraReducers: {
    [fetchHrUser.pending.toString()]: (state) => {
      state.userData.state = 'loading';
      state.userData.error = '';
      state.userData.loginMethod = '';
    },
    [fetchHrUser.fulfilled.toString()]: (state, action) => {
      state.userData.data = action.payload.data;
      state.userData.state = 'succeeded';
      state.userData.loginMethod = action.payload.loginMethod;
      state.userData.error =
        action.payload.methodType === 'mobile' && !action.payload.data
          ? 'MOBILE_NOT_FOUND'
          : action.payload.methodType === 'email' && !action.payload.data
          ? 'EMAIL_NOT_FOUND'
          : '';
    },
    [fetchHrUser.rejected.toString()]: (state, action) => {
      state.userData.state = 'failed';
      state.userData.loginMethod = action.error.loginMethod;
      state.userData.error = action.error.message;
    },
    [fetchHrUserVerify.pending.toString()]: (state) => {
      state.userData.state = 'loading';
      state.userData.error = '';
      state.userData.loginMethod = '';
    },
    [fetchHrUserVerify.fulfilled.toString()]: (state, action) => {
      state.userData.state = 'succeeded';
      state.userData.loginMethod = action.payload.loginMethod;
      state.userData.error =
        action.payload.methodType === 'mobile' && !action.payload.data
          ? 'MOBILE_NOT_FOUND'
          : action.payload.methodType === 'email' && !action.payload.data
          ? 'EMAIL_NOT_FOUND'
          : '';
    },
    [fetchHrUserVerify.rejected.toString()]: (state, action) => {
      state.userData.state = 'failed';
      state.userData.loginMethod = action.error.loginMethod;
      state.userData.error = action.error.message;
    },
    [updateHrData.pending.toString()]: (state) => {
      state.hrUpdate.state = 'loading';
      state.hrUpdate.error = '';
    },
    [updateHrData.fulfilled.toString()]: (state, action) => {
      state.hrUpdate.data = action.payload.data;
      state.hrUpdate.state = 'succeeded';
    },
    [updateHrData.rejected.toString()]: (state, action) => {
      state.hrUpdate.state = 'failed';
      state.hrUpdate.error = action.error.message;
    },
    'common/cleanup': (state) => {
      state.userData = { ...apiState, loginMethod: '' };
      state.hrUpdate = { ...apiState };
      state.error = '';
      state.state = 'idle';
    },
  },
});

export const {
  setConfirmationResult,
  setClearHrData,
  clearHrUpdate,
} = usersSlice.actions;

export const selectConfirmationResult = (state: RootState) =>
  state.users.confirmationResult;
export const selectHrUser = (state: RootState) => state.users.userData;
export const selectUpdateUser = (state: RootState) => state.users.hrUpdate;

export default usersSlice.reducer;