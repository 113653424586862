import {
  LoopButton,
  Shadow,
  Stepper,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../../assets/images';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import FullPageLoader from '../../../atoms/Loader';
import { IFullPageLoader } from '../../../atoms/Loader/types';
import {
  StyledBackIcon,
  StyledBottomNavigation,
  StyledContainer,
  StyledDetailsHeader,
  StyledDetailsWrapper,
  StyledStepperWrapper,
} from '../../../containers/Bulk/styles';
import ValidationsScreen from '../../../containers/ValidationsScreen';
import { IBulkDeleteData, IRejectedDeleteEntity } from './types';
import UploadDeletion from './UploadDeletion';
import {
  getButtonStates,
  trackDeleteValidationSummary,
  validateDeleteData,
} from './utils';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import useTrackPage from '../../../../utils/segment/hooks/useTrackPage';
import {
  extractPathName,
  trackClickEvent,
  trackPageEvent,
} from '../../../../utils/segment/utils';
import CancellationModal from '../../../containers/Bulk/CancellationModal';
import {
  LOADING_CONSTANTS,
  STEPPER_DATA,
} from '../../../containers/Bulk/constants';
import EndoSuccessSummary from '../../../containers/Bulk/EndoSuccessSummary';
import useEstimateEndorsementCost from '../../../containers/Bulk/hooks/useEstimateEndorsementCost';
import SubmitEndo from '../../../containers/Bulk/SubmitEndo';
import { submitBulkDeleteEndorsement } from '../../../containers/Bulk/SubmitEndo/utils';
import { generateErrorTemplate } from './ErrorSheet/utils';
import useFetchPoliciesFromRedux from '../../../containers/Policies/hooks/useFetchPoliciesFromRedux';
import { WorkBook, writeFile } from 'xlsx-js-style';

const BulkDeleteMembers = () => {
  const trackClick = useSegment('click');
  const trackPage = useSegment('page');
  const trackTask = useSegment('track');
  const location = useLocation();
  const history = useHistory();
  const toast = Toast.useToast();
  const selectedCompany = useSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id || '';
  const { policies } = useFetchPoliciesFromRedux();
  const errorWorkbook = useRef<WorkBook | null>(null);

  const [isLoading, setIsLoading] = useState<IFullPageLoader | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [lowCDModalVisible, setLowCDModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isCancelModalVisible, setCancelModalVisible] = useState(false);
  const [isDeleteConfirmationModalVisible, setDeleteConfirmationModalVisible] =
    useState(false);
  const [isBackBtnModalVisible, setBackBtnModalVisible] = useState(false);
  const onSetCancelModalClick = () => {
    setCancelModalVisible(false);
    trackClick(
      trackClickEvent(
        'Cancel_Error_Table_del',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };
  const [sheetSelected, setSheetSelected] = useState<Record<string, unknown>[]>(
    [],
  );
  const [uploadedData, setUploadedData] = useState<IBulkDeleteData[]>([]);
  const [rejectedEntries, setRejectedEntries] = useState<
    IRejectedDeleteEntity[]
  >([]);
  const [finalCorrectData, setFinalCorrectData] = useState<IBulkDeleteData[]>(
    [],
  );
  const {
    endoCostList,
    totalCost,
    totalLives,
    isLoading: isEndoCostLoading,
  } = useEstimateEndorsementCost(finalCorrectData, currentStep, 'DELETE');
  const { isNextEnabled, isBackEnabled, buttonText, backButtonText } = useMemo(
    () =>
      getButtonStates(
        currentStep,
        sheetSelected.length,
        finalCorrectData,
        rejectedEntries,
      ),
    [currentStep, sheetSelected, finalCorrectData],
  );

  const onBackClick = () => {
    const haveErrors = Object.keys(rejectedEntries).length;
    switch (currentStep) {
      case 1:
        if (sheetSelected.length) {
          setBackBtnModalVisible(true);
        } else {
          history.goBack();
        }
        break;
      case 2:
        if (finalCorrectData.length) {
          setCancelModalVisible(true);
          trackClick(
            trackClickEvent(
              'Go_Back_Error_Table_del',
              location?.pathname,
              extractPathName(location?.pathname),
            ),
          );
        } else {
          setCurrentStep(1);
        }
        break;
      case 3:
        if (haveErrors) setCurrentStep(2);
        else setCancelModalVisible(true);
    }
  };

  const onProceedClicked = async () => {
    switch (currentStep) {
      case 1:
        trackClick(
          trackClickEvent(
            'Upload_Proceed_del',
            location?.pathname,
            extractPathName(location?.pathname),
          ),
        );
        setDeleteConfirmationModalVisible(true);
        break;
      case 2:
        if (finalCorrectData.length) {
          setCurrentStep(3);
          trackClick(
            trackClickEvent(
              'View_Refund_Correct_Lives_del',
              location?.pathname,
              extractPathName(location?.pathname),
            ),
          );
        } else {
          trackClick(
            trackClickEvent(
              'Go_Reupload_del',
              location?.pathname,
              extractPathName(location?.pathname),
            ),
          );
          clearStates();
        }
        break;
      case 3:
        setIsSubmitting(true);
        const response = await submitBulkDeleteEndorsement(
          companyId,
          finalCorrectData,
        );
        trackClick(
          trackClickEvent(
            'Submit_Lives_del',
            location?.pathname,
            extractPathName(location?.pathname),
            {
              clickProperties: {
                no_of_lives: finalCorrectData.length,
                cost: totalCost,
              },
            },
          ),
        );
        setIsSubmitting(false);
        if (!response)
          toast?.error('Something went wrong', '', {
            closeOnClick: false,
            variant: 'light',
          });
        else setCurrentStep(4);
        break;
    }
  };

  const validateData = async () => {
    setCurrentStep(2);
    setIsLoading(LOADING_CONSTANTS.VALIDATE_MEMBERS);
    try {
      const { correctLives, rejectedLives } = await validateDeleteData(
        uploadedData,
        companyId,
      );
      setRejectedEntries(rejectedLives);
      setFinalCorrectData(correctLives);
      trackDeleteValidationSummary(
        uploadedData,
        rejectedLives,
        trackTask,
        location.pathname,
      );
      if (rejectedLives.length) {
        errorWorkbook.current = generateErrorTemplate(rejectedLives, policies);
        writeFile(errorWorkbook.current, 'Error-Report.xlsx');
        toast?.success(
          '"Error Report" has been successfully saved in your device.',
          '',
          {
            closeOnClick: false,
            variant: 'light',
          },
        );
        trackPage(trackPageEvent('bulk-delete-errors'));
        setIsLoading(LOADING_CONSTANTS.VALIDATION_HAS_ERRORS);
        setTimeout(() => setIsLoading(null), 3000);
      } else {
        setCurrentStep(3);
        trackClick(
          trackClickEvent(
            'View_Refund_Correct_Lives_del',
            location?.pathname,
            extractPathName(location?.pathname),
          ),
        );
        setIsLoading(LOADING_CONSTANTS.VALIDATION_HAS_NO_ERRORS);
        setTimeout(() => setIsLoading(null), 3000);
      }
    } catch (err) {
      toast?.error((err as Error).message);
    }
  };

  const onConfirmCancelClick = async () => {
    trackClick(
      trackClickEvent(
        'Yes_Leave_Error_Table_del',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    clearStates();
  };

  const onConfirmDeleteClick = () => {
    setDeleteConfirmationModalVisible(false);
    validateData();
  };

  const clearStates = () => {
    setCurrentStep(1);
    setRejectedEntries([]);
    setFinalCorrectData([]);
    setCancelModalVisible(false);
    setSheetSelected([]);
    setUploadedData([]);
  };

  const isEndoInProgress = useMemo(
    () => [2, 3].includes(currentStep),
    [currentStep],
  );
  useEffect(() => {
    if (isEndoInProgress) {
      const unloadCallback = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return true;
      };
      window.addEventListener('beforeunload', unloadCallback);
      return () => window.removeEventListener('beforeunload', unloadCallback);
    }
  }, [isEndoInProgress]);

  useTrackPage();

  return (
    <>
      <Prompt
        when={isEndoInProgress}
        message="Wait! Are you sure you want to leave? if you leave from here, you'll lose this complete data."
      />
      {currentStep === 4 ? (
        <EndoSuccessSummary mode="DELETE" submittedData={finalCorrectData} />
      ) : (
        <StyledContainer>
          <StyledDetailsWrapper>
            <StyledDetailsHeader onClick={onBackClick}>
              <StyledBackIcon src={ArrowLeftIcon} />
              <Typography variant="medium" weight="medium">
                Bulk Delete Members
              </Typography>
            </StyledDetailsHeader>

            <StyledStepperWrapper>
              <Stepper
                steps={STEPPER_DATA.DELETE}
                currentStep={currentStep}
                variant="locked"
                onStepperClicked={() => {}}
              />
            </StyledStepperWrapper>
          </StyledDetailsWrapper>
          {isLoading ? (
            <FullPageLoader {...isLoading} />
          ) : (
            <>
              {currentStep === 1 && (
                <UploadDeletion
                  setSheetData={setUploadedData}
                  setSheetSelected={setSheetSelected}
                  sheetSelected={sheetSelected}
                />
              )}
              {currentStep === 2 && (
                <ValidationsScreen
                  operation="DELETE"
                  rejectedEntries={rejectedEntries}
                  uploadedData={uploadedData}
                />
              )}
              {currentStep === 3 && (
                <SubmitEndo
                  mode="DELETE"
                  isLoading={isEndoCostLoading}
                  endoCostList={endoCostList}
                  totalCost={totalCost}
                  totalLives={totalLives}
                />
              )}
              <Shadow variant="bottom">
                <StyledBottomNavigation>
                  {isBackEnabled && (
                    <LoopButton
                      size="medium"
                      variant="outline"
                      onClick={onBackClick}
                    >
                      {backButtonText}
                    </LoopButton>
                  )}
                  <LoopButton
                    size="medium"
                    isLoading={isSubmitting || isEndoCostLoading}
                    variant={isNextEnabled ? 'filled' : 'disabled'}
                    onClick={onProceedClicked}
                  >
                    {buttonText}
                  </LoopButton>
                </StyledBottomNavigation>
              </Shadow>
            </>
          )}
          <CancellationModal
            isBackBtnModalVisible={isBackBtnModalVisible}
            isCancelModalVisible={isCancelModalVisible}
            setCancelModalVisible={onSetCancelModalClick}
            setBackBtnModalVisible={setBackBtnModalVisible}
            onConfirmCancelClick={onConfirmCancelClick}
            lowCDModalVisible={lowCDModalVisible}
            setLowCDModalVisible={setLowCDModalVisible}
            isDeleteConfirmationModalVisible={isDeleteConfirmationModalVisible}
            setDeleteConfirmationModalVisible={
              setDeleteConfirmationModalVisible
            }
            onConfirmDeleteClick={onConfirmDeleteClick}
          />
        </StyledContainer>
      )}
    </>
  );
};

export default BulkDeleteMembers;
