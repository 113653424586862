export const initialEmployeeFormData = {
  employeeId: '',
  firstName: '',
  lastName: '',
  gender: '',
  dateOfBirth: null,
  dateOfJoining: null,
  email: '',
  mobile: '',
};

export const initialEmployeeFormErrorData = {
  employeeId: '',
  firstName: '',
  lastName: '',
  gender: '',
  dateOfBirth: '',
  dateOfJoining: '',
  email: '',
  mobile: '',
};
