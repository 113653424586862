import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const StyledColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  span {
    white-space: nowrap;
  }
`;

export const StyledPolicyInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
`;

export const StyledSumInsured = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;
