import LoopEndpoints from './LoopEndpoints';
import { get } from './providers';

const GlobalSearchAPI = {
  getGlobalSearchData: (
    companyId: string,
    searchString: string,
    includeDependents: boolean,
    includePolicies: boolean,
  ) =>
    get(
      LoopEndpoints.fetchGlobalSearchData(
        companyId,
        searchString,
        includeDependents,
        includePolicies,
      ),
    ),

  getCompanyList: () => get(LoopEndpoints.fetchCompanyList()),
};

export default GlobalSearchAPI;
