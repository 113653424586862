import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledDetailContainer = styled.div`
  width: 100%;
  margin: 12px 0 160px 0px;
`;

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 0;
`;
export const StyledDetailsHeader = styled.div`
  display: flex;
  gap: 12px;
  padding: 32px 55px;
  align-items: center;
  background-color: ${(p) => p.theme.colors.white};
`;
export const StyledBackIcon = styled.img`
  cursor: pointer;
`;

export const StyledDetailsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
export const StyledDataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    flex: 1;

    :last-child {
      text-align: right;
    }
  }
`;

export const StyledVerticalSpacer = styled.div`
  width: 1px;
  background-color: ${(p) => p.theme.colors.chineseWhite};
  margin: 0 75px;
`;

export const StyledTimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledClaimStatusWrapper = styled.div`
  display: flex;
  padding: 48px 56px 0;
  width: 100%;
  justify-content: space-between;
  background-color: ${(p) => p.theme.colors.white};
`;
export const StyledClaimDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 56px 32px 56px;
`;

export const StyledTimelineContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
`;

export const HorizontalSeparator = styled.div`
  background: ${(p) => p.theme.colors.seafoamGray};
  height: 1px;
  width: 100%;
`;

export const StyledClaimChipContainer = styled.div`
  display: flex;
  gap: 16px;
`;
export const StyledEmptyContainer = styled.div`
  display: flex;
  padding: 40px 56px;
  width: 100%;
`;

export const StyledNoTimelineSpacing = styled.div`
  height: 28px;
`;

export const StyledStatusTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${(p) => p.theme.colors.surfaceOne};
`;