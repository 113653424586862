import { trackTaskEvent } from '../../../utils/segment/utils';
import { ITrackTask } from '../../../utils/segment/hooks/useSegment';
import { IBulkOperation } from './types';
import {
  IEndoCostItem,
  IGroupedPolicyData,
  ISummarizedPolicyData,
} from './SubmitEndo/types';

export const trackFileUploadErrors = (
  errorType: string,
  operation: IBulkOperation,
  pathname: string,
  trackTask: ITrackTask,
) => {
  if (errorType.includes('file formats')) {
    trackTask(
      trackTaskEvent(
        `Load_Incorrect_Format_Banner_${operation.toLowerCase()}`,
        pathname,
      ),
    );
  } else if (errorType.includes('file greater than')) {
    trackTask(
      trackTaskEvent(
        `Load_Incorrect_File_Size_Banner_${operation.toLowerCase()}`,
        pathname,
      ),
    );
  }
};

export const groupByPolicyType = (
  endoCostList: IEndoCostItem[],
): IGroupedPolicyData[] => {
  const summarizedData = endoCostList.reduce((acc, endoCost) => {
    endoCost.policyData.forEach((policy) => {
      if (!acc[policy.policyType]) {
        acc[policy.policyType] = {
          lives: 0,
          isCDLow: false,
        };
      }
      acc[policy.policyType] = {
        lives: acc[policy.policyType].lives + policy.lives,
        isCDLow: acc[policy.policyType].isCDLow || endoCost.isCDLow,
      };
    });
    return acc;
  }, {} as Record<string, ISummarizedPolicyData>);

  return Object.entries(summarizedData).map(([policyType, data]) => ({
    policyType,
    ...data,
  }));
};
