import styled from 'styled-components';

export const StyledTitleContainer = styled.div`
  padding-left: 53px;
`;

export const StyledSumInsuredUtilizedTableSection = styled.section`
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 41px;
  box-sizing: border-box;
`;

export const StyledTable = styled.table`
  border-radius: 12px;
  border-collapse: collapse;
  border-bottom: 1px solid ${(p) => p.theme.colors.seafoamGray};
  padding-left: 53px;
`;

export const StyledTableHead = styled.thead`
  border-style: hidden;
  background-color: ${(p) => p.theme.colors.mintFrost};
`;

export const StyledTableSingleHead = styled.th`
  padding: 16px 25px;

  :nth-child(1) {
    border-right: 1px solid ${(p) => p.theme.colors.seafoamGray};
  }
`;

export const StyledTableTopHead = styled.th`
  text-align: center;
  padding: 8px 12px;
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledTableBottomHead = styled.th`
  padding: 20px 16px;
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};

  &:nth-child(odd) {
    border-right: none;
  }
  &:nth-child(even) {
    border-left: none;
  }
`;

export const StyledTableBody = styled.tbody`
  background-color: ${(p) => p.theme.colors.surfaceOne};
`;

export const StyledTableData = styled.td`
  padding: 20px;
`;
