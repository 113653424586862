import { formatMemberName } from '../../../utils/common/common';
import {
  INavigationSearchResultsType,
  ISearchResultResponseUserType,
} from './types';

export const getFormattedSearchResults = (
  users: ISearchResultResponseUserType[],
): INavigationSearchResultsType[] =>
  users.map((searchResult) => ({
    title: formatMemberName(searchResult.firstName, searchResult.lastName),
    subtitle: `Employee ID: ${searchResult.employeeId}`,
    link: '',
  }));
