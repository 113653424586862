import PolicyApi from '../../../../adaptars/policyApi';
import { parseResponse } from '../../../../utils/common/Utilities';
import { downloadSignedFile } from '../../../../utils/common/common';

export const downloadPolicyDocumentFile = async (
  policyId: string,
  fileName: string,
): Promise<void> => {
  try {
    const [error, response] = await parseResponse(
      PolicyApi.downloadPolicyDocument(policyId, fileName),
    );
    if (error) throw error;

    const data = response.data;
    await downloadSignedFile(data.signedURL, data.method, fileName);
  } catch (error) {
    throw error;
  }
};
