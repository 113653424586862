import { Dispatch } from '@reduxjs/toolkit';
import { default as Firebase } from 'firebase';
import LoopApiService from '../../adaptars/LoopApiService';
import firebase, { auth } from '../../firebase';
import { fetchHrUser } from '../../redux/slices/usersSlice/thunks';
import { AppConstants } from './AppConstants';

export const verifyEmailLink = (setLoading: (value: boolean) => void, dispatch: Dispatch<any>) => {
  setLoading(true);
  let email: string, id: string;
  if (auth.isSignInWithEmailLink(window.location.href)) {
    email = window.localStorage.getItem(AppConstants.EMAIL_FOR_SIGNIN) || '';
    id = window.localStorage.getItem(AppConstants.FIR_DOC_ID) || '';
    if (email) {
      //TODO: Set email here to the database
      auth
        .signInWithEmailLink(email, window.location.href)
        .then(async (result) => {
          setLoading(false);
          try {
            await LoopApiService.updateHrData({ id, data: { email } });
            dispatch(
              fetchHrUser({
                methodType: 'email',
                userId: email,
                loginMethod: 'outlook',
              }),
            );
            window.localStorage.removeItem(AppConstants.FIR_DOC_ID);
          } catch (error) {
            console.log('email update err:', error);
            throw error;
          }
          window.localStorage.removeItem(AppConstants.EMAIL_FOR_SIGNIN);
        })
        .catch((error) => {
          setLoading(false);
          console.log('error after signin:', error.message);
          throw error.message;
        });
    } else {
      setLoading(false);
    }
  }
  setLoading(false);
};

export const setLoopIdToken = (userId: string) => {
  let currentUser = firebase.auth().currentUser;

  let callback: ((data: Firebase.database.DataSnapshot) => void) | null = null;
  let metadataRef: Firebase.database.Reference | null = null;
  firebase.auth().onAuthStateChanged(async (user) => {
    // Remove previous listener.
    if (callback) {
      metadataRef?.off('value', callback);
    }
    // On user login add new listener.
    if (user) {
      // Check if refresh is required.
      metadataRef = firebase
        .database()
        .ref('metadata/' + user.uid + '/refreshTime');
      callback = (snapshot) => {
        // Force refresh to pick up the latest custom claims changes.
        // Note this is always triggered on first call. Further optimization could be
        // added to avoid the initial trigger when the token is issued and already contains
        // the latest claims.
        user.getIdToken(true);
      };
      // Subscribe new listener to changes on that node.
      metadataRef.on('value', callback);
    }
  });

  firebase
    .functions('asia-south1')
    .httpsCallable('applicationService-addHRLoopId')
    .call({ userId: userId })
    .then(async (item) => {
      //Note: need to create a token in store in userSlice and set the token from a action created in userSlice.
      // let currentUser = firebase.auth().currentUser;
      // currentUser.reload();
      if (LoopApiService.auth.currentUser)
        await LoopApiService.auth.currentUser.getIdToken(true);
      if (item) {
        currentUser && currentUser.reload();
      }
    })
    .catch((error) => {
      console.log('add loop error:', error);
      throw error;
    });
};

export const sentEmailVerificationLink = (
  email: string,
  setLoading: (value: boolean) => void,
  setVerificatonSent: (value: boolean) => void,
  docId: string,
) => {
  let actionCodeSettings: Firebase.auth.ActionCodeSettings = {
    url: process.env.REACT_APP_REDIRECT_URL!,
    handleCodeInApp: true,
  };
  auth
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      setLoading(false);
      setVerificatonSent(true);
      window.localStorage.setItem(AppConstants.EMAIL_FOR_SIGNIN, email);
      window.localStorage.setItem(AppConstants.FIR_DOC_ID, docId);
    })
    .catch((error) => {
      setLoading(false);
      console.log('error code:', error.code, ' error msg:', error.message);
    });
};
