import styled from 'styled-components';

export const StyledClaimAnalyticsPage = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
`;

export const StyledClaimAnalyticsPagePaddedSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 42px;
  padding: 40px 56px 52px;
`;

export const StyledClaimAnalyticsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const StyledChartsPadding = styled.div`
  display: flex;
  flex-direction: column;
  gap: 52px;
`;