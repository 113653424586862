import styled from 'styled-components';
import { IStyledKnowMoreIconCircleProps } from './types';

export const StyledKnowMoreModalBodyContainer = styled.div`
  padding: 20px;
  width: 600px;
`;
export const StyledKnowMoreModalBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.surface0};
  border-radius: 12px;
  padding: 28px 24px;
`;
export const StyledKnowMoreItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
`;
export const StyledKnowMoreItemIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.lightGrey};
  color: ${(p) => p.theme.colors.white};
`;
export const StyledKnowMoreItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 90%;
`;
export const StyledKnowMoreItemTextTitle = styled.div`
  display: flex;
`;
export const StyledKnowMoreItemTextDescription = styled.div`
  color: ${(p) => p.theme.colors.lightGrey};
`;
export const StyledKnowMoreModalFooter = styled.div`
  display: flex;
  padding: 16px 20px;
  border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;
export const StyledKnowMoreIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  position: relative;
  margin-left: 6px;
  margin-top: 1px;
`;
export const StyledKnowMoreIconCircle = styled.div<IStyledKnowMoreIconCircleProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.4;
  position: absolute;
  z-index: 0;
  background-color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.loopEmerald : theme.colors.planRed};
`;
