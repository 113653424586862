import LoopApiService from '../../../adaptars/LoopApiService';
import { parseResponse } from '../../../utils/common/Utilities';
import { downloadSignedFile } from '../../../utils/common/common';

export const downloadCDStatementFile = async (
  cdAccountId: string,
  fileName: string,
): Promise<void> => {
  try {
    const [error, response] = await parseResponse(
      LoopApiService.downloadCDStatement(cdAccountId),
    );
    if (error) throw error;

    const data = response.data;
    await downloadSignedFile(data.signedURL, data.method, fileName);
  } catch (error) {
    throw error;
  }
};
