import styled from 'styled-components';
import { IFeedbackChildItemStyleProps, IStyledSingleEmojiLabel } from './types';

export const FeedbackChildItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
  padding: 8px 0;
  margin: 16px 0;
`;
export const FeedbackChildItemHeader = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: ${(p) => (p.isExpanded ? 'default' : 'pointer')};
`;
export const FeedbackChildItemHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  color: ${(p) => p.theme.colors.beyondGrey};
`;
export const IconContainer = styled.img``;
export const FeedbackGiven = styled.div`
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.colors.surface0};
  font-size: ${(p) => p.theme.fontSizes.label};
  font-weight: 600;
  color: ${(p) => p.theme.colors.lightGrey};
  display: flex;
  gap: 4px;
  align-items: center;
`;
export const SelectedEmoji = styled.img`
  width: 20px;
  height: 20px;
`;

export const FeedbackChildItemBody = styled.div<IFeedbackChildItemStyleProps>`
  display: flex;
  flex-direction: column;
  padding: ${(p) => (p.isExpanded ? '0 0 16px 0' : '0')};
  border-radius: 8px;
  margin-top: 8px;
  height: ${(p) =>
    p.isExpanded
      ? p.hasQnA
        ? !p.emojiPanelOpen
          ? '100px'
          : '240px'
        : '200px'
      : '0'};
  overflow: hidden;
  transition: height 0.5s ease;
`;
export const EmojiContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 20px;
  margin: 18px 0 8px 0;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.surface0};
  cursor: pointer;
`;
export const SingleEmojiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 80px;
`;
export const SingleEmoji = styled.img`
  width: 80%;
  height: auto;
`;
export const SingleEmojiLabel = styled.div<IStyledSingleEmojiLabel>`
  color: ${(p) =>
    p.isSelected ? p.theme.colors.beyondGrey : p.theme.colors.lightGrey};
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: 400;
`;
export const FeedbackTextArea = styled.textarea`
  border-radius: 8px;
  width: 100%;
  margin-top: 12px;
  padding: 12px 20px 12px 20px;
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
  resize: none;
  color: ${(p) => p.theme.colors.beyondGrey};
  ::placeholder {
    font-weight: 300;
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.lightGrey};
  }
`;
export const StyledWellnessButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 14px 0 8px 0;
  & > div {
    width: 48%;
  }
`;
