import React from 'react';
import { StyledIdsLoader, StyledLoaderContainer } from './styles';

const Loader = () => {
  return (
    <StyledLoaderContainer>
      <StyledIdsLoader>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </StyledIdsLoader>
    </StyledLoaderContainer>
  );
};
export default Loader;
