import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PolicyApi from '../../../../adaptars/policyApi';
import { parseResponse } from '../../../../utils/common/Utilities';
import { IEmployeeData } from '../../../pages/Employees/types';
import {selectSelectedCompany} from "../../../../redux/slices/hrdRevampRedux";

const usePolicyMembers = (policyId: string) => {
    const selectedCompany = useSelector(selectSelectedCompany);
    const [policyMembers, setPolicyMembers] = useState<IEmployeeData[] | null>(
        null,
    );
    const [isLoading, toggleLoading] = useState(false);

    useEffect(() => {
        const fetchPolicyMemberData = async () => {
            toggleLoading(true);
            const [error, result] = await parseResponse(
                PolicyApi.getPolicyMembers(policyId, selectedCompany?.id || ''),
            );
            toggleLoading(false);
            if (error) {
                console.error(error);
                return;
            }
            const polData = result?.data?.users;
            setPolicyMembers(polData);
        };
        if (policyId && !isLoading) fetchPolicyMemberData();
    }, [policyId]);

    return { isLoading, policyMembers };
};

export default usePolicyMembers;
