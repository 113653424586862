import React, { useMemo } from 'react';
import { IFullPageLoader } from './types';
import {
  StyledDogIcon,
  StyledFullPageLoader,
  StyledIconContainer,
  StyledLoaderIcon,
  StyledLoadingContent,
  StyledSubText,
} from './styles';
import { Loader, Typography } from '@loophealth/loop-ui-web-library';
import { CrossCircleIcon, TickCircleImage } from '../../../assets/images';

const FullPageLoader = ({ title, subtitle, type, image }: IFullPageLoader) => {
  const LoaderElement = useMemo(() => {
    switch (type) {
      case 'loading':
        return <Loader />;
      case 'success':
        return <StyledLoaderIcon src={TickCircleImage} />;
      case 'error':
        return <StyledLoaderIcon src={CrossCircleIcon} />;
    }
  }, [type]);
  return (
    <StyledFullPageLoader>
      <StyledLoadingContent>
        <StyledDogIcon src={image} />
        <Typography variant="medium" color="secondary">
          {title}
        </Typography>
        <StyledSubText>
          <StyledIconContainer>{LoaderElement}</StyledIconContainer>
          <Typography variant="medium" weight="medium">
            {subtitle}
          </Typography>
        </StyledSubText>
      </StyledLoadingContent>
    </StyledFullPageLoader>
  );
};

export default FullPageLoader;
