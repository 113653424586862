import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCDAccountsList,
  selectEndorsementList,
  selectSelectedCompany,
} from '../../../../redux/slices/hrdRevampRedux';
import { fetchCDAccounts } from '../../../../redux/slices/hrdRevampRedux/thunk';
import { transformCdAccountsUI } from '../utils';

const useFetchCDAccounts = () => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectSelectedCompany);
  const { data, error, status } = useSelector(selectCDAccountsList);
  const { data: endos } = useSelector(selectEndorsementList);
  const isLoading = status === 'loading';
  const cdAccounts = useMemo(
    () => transformCdAccountsUI(data, endos),
    [data, endos],
  );

  useEffect(() => {
    if (selectedCompany && status !== 'succeeded') {
      dispatch(fetchCDAccounts(selectedCompany.id));
    }
  }, [selectedCompany?.id]);

  return { cdAccounts, isLoading, error };
};
export default useFetchCDAccounts;
