import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { selectHrUser } from '../../redux/slices/usersSlice';
import { IPrivateRoute } from './types';

export function PrivateRoute({ component: Component, ...rest }: IPrivateRoute) {
  const userData = useSelector(selectHrUser);

  return (
    <Route
      exact
      {...rest}
      render={(props) => {
        return userData &&
          userData.data &&
          (userData.data.email || userData.data.mobile) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
}
