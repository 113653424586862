import {
  Dialog,
  LoopButton,
  Modal,
  Shadow,
  Stepper,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useEffect, useMemo, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { ArrowLeftIcon, ExitErrorIcon } from '../../../../assets/images';
import FullPageLoader from '../../../atoms/Loader';
import {
  LOADING_CONSTANTS,
  STEPPER_DATA,
} from '../../../containers/Bulk/constants';
import {
  StyledBackIcon,
  StyledBottomNavigation,
  StyledContainer,
  StyledDetailsHeader,
  StyledDetailsWrapper,
  StyledEditStepperWrapper,
} from '../../../containers/Bulk/styles';
import EmployeeEditSelectionTable from './EmployeeEditSelectionTable';
import { getButtonStates, submitBulkEditEndorsement } from './utils';
import { IBulkEditEntity } from './types';
import UserSelectModal from './UserSelectModal';
import useEstimateEndorsementCost from '../../../containers/Bulk/hooks/useEstimateEndorsementCost';
import SubmitEndo from '../../../containers/Bulk/SubmitEndo';
import EndoSuccessSummary from '../../../containers/Bulk/EndoSuccessSummary';
import { isCDLowTrue } from '../BulkAddMembers/utils';
import CancellationModal from '../../../containers/Bulk/CancellationModal';
import useTrackPage from '../../../../utils/segment/hooks/useTrackPage';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
  trackPageEvent,
} from '../../../../utils/segment/utils';
import { useSelector } from 'react-redux';
import { selectIsDemoMode } from '../../../../redux/slices/hrdRevampRedux';

const BulkEditMembers = () => {
  const trackClick = useSegment('click');
  const trackPage = useSegment('page');
  const location = useLocation();
  const history = useHistory();
  const toast = Toast.useToast();
  const isDemoMode = useSelector(selectIsDemoMode);

  const [currentStep, setCurrentStep] = useState(1);
  const [editedUsers, setEditedUsers] = useState<IBulkEditEntity[]>([]);
  const [userSelectModalVisible, setUserSelectModalVisible] = useState(false);
  const [backButtonModalVisible, setBackBtnModalVisible] = useState(false);
  const [lowCDModalVisible, setLowCDModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IBulkEditEntity | null>(
    null,
  );

  const {
    endoCostList,
    totalCost,
    totalLives,
    isLoading: isEndoCostLoading,
    totalCostIncuringLives,
  } = useEstimateEndorsementCost(editedUsers, currentStep, 'EDIT');

  const { isNextEnabled, isBackEnabled, buttonText, backButtonText } = useMemo(
    () => getButtonStates(currentStep, editedUsers, isEndoCostLoading),
    [currentStep, editedUsers, isEndoCostLoading],
  );

  const trackSegmentClickEvent = (
    eventName: string,
    properties?: Record<string, any>,
  ) => {
    trackClick(
      trackClickEvent(
        eventName,
        location?.pathname,
        extractPathName(location?.pathname),
        {
          clickProperties: properties,
        },
      ),
    );
  };

  const onBackClick = (isActionButton: boolean) => {
    switch (currentStep) {
      case 1:
        trackSegmentClickEvent('Back_Members_Bulk_Edit_Page_edit');
        if (editedUsers.length) {
          setBackBtnModalVisible(true);
        } else {
          history.goBack();
        }
        break;
      case 2:
        if (isActionButton) {
          trackSegmentClickEvent('Review_Again_View_Cost_Estimates_edit');
        } else {
          trackSegmentClickEvent('Back_View_Cost_Estimates_edit');
        }
        setCurrentStep(1);
        break;
    }
  };

  const onProceedClicked = async () => {
    switch (currentStep) {
      case 1:
        trackSegmentClickEvent('View_Cost_Bulk_Edit_Page_edit', {
          number_of_lives: editedUsers.length,
        });
        trackPage(trackPageEvent('/view-cost-estimates-edit'));
        setCurrentStep(2);
        break;
      case 2:
        trackSegmentClickEvent('Submit_Member_View_Cost_Estimates_edit', {
          number_of_lives: editedUsers.length,
        });
        if (isCDLowTrue(endoCostList)) {
          setLowCDModalVisible(true);
        } else {
          submitEndorsements();
        }
        break;
    }
  };

  const submitEndorsements = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await submitBulkEditEndorsement(editedUsers, isDemoMode);
      setCurrentStep(3);
    } catch (e) {
      toast?.error((e as Error).message || 'Something went wrong', '', {
        closeOnClick: false,
        variant: 'light',
      });
    } finally {
      setIsSubmitting(false);
      setLowCDModalVisible(false);
    }
  };

  const isEndoInProgress = useMemo(
    () => (!!editedUsers.length && currentStep !== 3) || userSelectModalVisible,
    [currentStep, editedUsers, userSelectModalVisible],
  );

  useEffect(() => {
    if (isEndoInProgress) {
      const unloadCallback = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return true;
      };
      window.addEventListener('beforeunload', unloadCallback);
      return () => window.removeEventListener('beforeunload', unloadCallback);
    }
  }, [isEndoInProgress]);

  useTrackPage({
    number_of_lives: totalLives,
  });

  return (
    <>
      <Prompt
        when={isEndoInProgress}
        message="Wait! Are you sure you want to leave? if you leave from here, you'll lose this complete data."
      />
      {currentStep === 3 ? (
        <EndoSuccessSummary
          endoCostList={endoCostList}
          mode="EDIT"
          submittedData={editedUsers}
        />
      ) : (
        <StyledContainer>
          <StyledDetailsWrapper>
            <StyledDetailsHeader onClick={() => onBackClick(false)}>
              <StyledBackIcon src={ArrowLeftIcon} />
              <Typography variant="medium" weight="medium">
                Edit Members
              </Typography>
            </StyledDetailsHeader>

            <StyledEditStepperWrapper>
              <Stepper
                steps={STEPPER_DATA.EDIT}
                currentStep={currentStep}
                variant="locked"
                onStepperClicked={() => {}}
              />
            </StyledEditStepperWrapper>
          </StyledDetailsWrapper>
          {isEndoCostLoading ? (
            <FullPageLoader {...LOADING_CONSTANTS.CHECKING_ENDO_COST} />
          ) : (
            <>
              {currentStep === 1 && (
                <EmployeeEditSelectionTable
                  setEditedUsers={setEditedUsers}
                  editedUsers={editedUsers}
                  setSelectedUser={setSelectedUser}
                  setIsModalVisible={setUserSelectModalVisible}
                />
              )}
              {currentStep === 2 && (
                <SubmitEndo
                  mode="EDIT"
                  isLoading={isEndoCostLoading}
                  endoCostList={endoCostList}
                  totalCost={totalCost}
                  totalLives={totalLives}
                  totalCostIncuringLives={totalCostIncuringLives}
                />
              )}
              <UserSelectModal
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                setIsModalVisible={setUserSelectModalVisible}
                isModalVisible={userSelectModalVisible}
                setEditedUsers={setEditedUsers}
                editedUsers={editedUsers}
              />
              <Shadow variant="bottom">
                <StyledBottomNavigation>
                  {isBackEnabled && (
                    <LoopButton
                      size="medium"
                      variant="outline"
                      onClick={() => onBackClick(true)}
                    >
                      {backButtonText}
                    </LoopButton>
                  )}
                  <LoopButton
                    size="medium"
                    isLoading={isSubmitting}
                    variant={isNextEnabled ? 'filled' : 'disabled'}
                    onClick={onProceedClicked}
                  >
                    {buttonText}
                  </LoopButton>
                </StyledBottomNavigation>
              </Shadow>
            </>
          )}
        </StyledContainer>
      )}
      <CancellationModal
        isBackBtnModalVisible={false}
        isCancelModalVisible={false}
        setCancelModalVisible={() => {}}
        setBackBtnModalVisible={() => {}}
        onConfirmCancelClick={() => {}}
        lowCDModalVisible={lowCDModalVisible}
        setLowCDModalVisible={setLowCDModalVisible}
        submitEndorsements={submitEndorsements}
        isSubmitting={isSubmitting}
      />
      <Modal
        isVisible={backButtonModalVisible}
        setIsVisible={setBackBtnModalVisible}
      >
        <Dialog
          variant="vertical"
          title="Leaving without submitting?"
          description="If you leave now your progress will not be saved. "
          icon={ExitErrorIcon}
          primaryButtonText="Yes, Leave"
          onPrimaryButtonClicked={() => history.goBack()}
          onSecondaryButtonClicked={() => setBackBtnModalVisible(false)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
    </>
  );
};

export default BulkEditMembers;
