import theme from '../../../../theme';

export const getSettlementAnalysisStatistics = (
  settlementSettledCount: number,
  settlementOnGoingCount: number,
  settlementUnderQueryCount: number,
  settlementClosedCount: number,
  settlementRejectedCount: number,
) => {
  const totalSettlementCount = (
    settlementSettledCount +
    settlementRejectedCount +
    settlementUnderQueryCount +
    settlementClosedCount +
    settlementOnGoingCount
  ).toLocaleString();

  const settlementAnalysisData = [
    {
      color: theme.colors.supportingPlaneGreenDark,
      label: 'Settled',
      value: settlementSettledCount,
    },
    {
      color: theme.colors.planRed,
      label: 'Rejected',
      value: settlementRejectedCount,
    },
    {
      color: theme.colors.turquoise,
      label: 'Under Query',
      value: settlementUnderQueryCount,
    },
    {
      color: theme.colors.consultViolet,
      label: 'Closed',
      value: settlementClosedCount,
    },
    {
      color: theme.colors.coverYellow,
      label: 'Ongoing',
      value: settlementOnGoingCount,
    },
  ];

  return {
    data: settlementAnalysisData,
    count: totalSettlementCount,
  };
};

export const getTPAAnalysisStatistics = (
  zeroToTenDays: number,
  elevenToTwentyDays: number,
  moreThanTwentyDays: number,
) => {
  const totalTPAAnalysisCount = (
    zeroToTenDays +
    elevenToTwentyDays +
    moreThanTwentyDays
  ).toLocaleString();

  const tpaAnalysisData = [
    {
      color: theme.colors.supportingPlaneGreenDark,
      label: '0-10 Days',
      value: zeroToTenDays,
    },
    {
      color: theme.colors.coverYellow,
      label: '11-20 Days',
      value: elevenToTwentyDays,
    },
    {
      color: theme.colors.planRed,
      label: '20+ Days',
      value: moreThanTwentyDays,
    },
  ];

  return {
    data: tpaAnalysisData,
    count: totalTPAAnalysisCount,
  };
};
