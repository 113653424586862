import { capitalizeFirstLetterOfEachWord } from '../../../../utils/common/Utilities';
import {
  EMPLOYEE_TABLE_HEADERS,
  EMPLOYEE_TABLE_HEADERS_WITH_DEPENDENTS,
} from './tableElements';
import {
  EmptyStateFourGif,
  WritingOnBoardGraphic,
} from '../../../../assets/images';
import { IEmpTableTabs, IZeroState } from './types';

export const getTableHeader = (
  tab: string,
  length: number,
  selectedPolicyFilter: string,
) =>
  `${capitalizeFirstLetterOfEachWord(tab)} ${
    selectedPolicyFilter === 'GMC' ? 'Lives' : 'Employees'
  } (${length})`;

export const getLabels = (selectedPolicyFilter: string) => {
  const labelSuffix = selectedPolicyFilter === 'GMC' ? 'Lives' : 'Employees';
  return {
    activeChipLabel: `Total ${labelSuffix}`,
    deletedChipLabel: `Deleted ${labelSuffix}`,
    rejectedChipLabel: `Total Employees`,
  };
};

export const getTableHeaders = (tab: string, selectedPolicyFilter: string) => {
  if (tab === IEmpTableTabs.Employees) {
    const headers = [...EMPLOYEE_TABLE_HEADERS];
    headers.splice(headers.length - 1, 0, {
      name: 'Coverage Start Date',
      flex: 3,
    });
    return headers;
  }

  if (tab === IEmpTableTabs.Active) {
    if (selectedPolicyFilter === 'GMC') {
      const headers = [...EMPLOYEE_TABLE_HEADERS_WITH_DEPENDENTS];
      headers.splice(headers.length - 2, 0, {
        name: 'Coverage Start Date',
        flex: 5,
      });
      return headers;
    } else {
      const headers = [...EMPLOYEE_TABLE_HEADERS];
      headers.splice(headers.length - 2, 0, {
        name: 'Coverage End Date',
        flex: 3,
      });
      return headers;
    }
  }
  if (selectedPolicyFilter === 'GMC') {
    const headers = [...EMPLOYEE_TABLE_HEADERS_WITH_DEPENDENTS];
    headers.splice(5, 1, { name: 'Insurance Status', flex: 3.4 });
    return headers;
  } else {
    return EMPLOYEE_TABLE_HEADERS;
  }
};

export const getTableZeroStates = (): IZeroState => {
  return {
    Active: {
      title: 'Dependant data is on the way!',
      subtitle:
        'We’re working on adding your employee and dependant data. If there are any delays or you have questions, please contact your CSM.',
      image: WritingOnBoardGraphic,
      note: 'Set up takes 30-35 days from policy start date.',
      noteType: 'info',
    },
    Deleted: {
      title: 'No lives deleted!',
      subtitle: 'We haven’t deleted any lives from this policy',
      image: EmptyStateFourGif,
      note: 'Note: If there’s an endorsement in works, kindly wait for it to wrap up. Then, any lives removed will show up here',
      noteType: 'note',
    },
    Employees: {
      title: 'Employee and dependant data is on the way!',
      subtitle:
        'We’re working on adding your employee and dependant data. In the meantime, reach out to your CSM for any questions.',
      image: WritingOnBoardGraphic,
      note: 'Set up takes 30-35 days from policy start date.',
      noteType: 'info',
    },
  };
};
