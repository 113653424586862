import { createSlice } from '@reduxjs/toolkit';
import { apiState, initialState } from './initialState';
import { RootState } from '../../store';
import {
  fetchCompanyById,
  fetchCompanyConfig,
} from './thunks';

const companySlice = createSlice({
  name: 'companyProfile',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchCompanyById.pending.toString()]: (state) => {
      state.companyDetails.status = 'loading';
      state.companyDetails.error = '';
    },
    [fetchCompanyById.fulfilled.toString()]: (state, action) => {
      state.companyDetails.status = 'succeeded';
      state.companyDetails.data = action.payload.data;
    },
    [fetchCompanyById.rejected.toString()]: (state, action) => {
      state.companyDetails.status = 'failed';
      state.companyDetails.error = action.error.message;
    },
    [fetchCompanyConfig.pending.toString()]: (state) => {
      state.companyConfig.status = 'loading';
      state.companyConfig.error = '';
    },
    [fetchCompanyConfig.fulfilled.toString()]: (state, action) => {
      state.companyConfig.status = 'succeeded';
      state.companyConfig.data = action.payload.data;
    },
    [fetchCompanyConfig.rejected.toString()]: (state, action) => {
      state.companyConfig.status = 'failed';
      state.companyConfig.error = action.error.message;
    },

    'common/cleanup': (state, action) => {
      state.companyDetails = {
        ...apiState,
      };
      state.companyConfig = {
        ...apiState,
      };
    },
  },
});

export const selectCompanyData = (state: RootState) =>
  state.companyProfile.companyDetails;

export const selectCompanyConfig = (state: RootState) =>
  state.companyProfile.companyConfig?.data;

export default companySlice.reducer;
