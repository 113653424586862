import styled from 'styled-components';
import {
  ICenteredTextType,
  IStyledPieChartCenteredTextContainerProps,
} from './types';

export const StyledPieChartCenteredTextContainer = styled.div<IStyledPieChartCenteredTextContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 4px;

  margin-top: ${({ type }) =>
    type === ICenteredTextType.doughnut ? '0' : '35px'};
`;
