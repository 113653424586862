import React, { FC } from 'react';
import { IPoliciesListProps } from './types';
import { StyledPolicies } from './styles';
import PolicyListItem from '../PolicyListItem';

export const Policies: FC<IPoliciesListProps> = ({ policyPlans }) => {
  return (
    <StyledPolicies>
      {policyPlans.map((policyPlan) => (
        <PolicyListItem policy={policyPlan} key={policyPlan.id} />
      ))}
    </StyledPolicies>
  );
};
