import React, { useMemo } from 'react';
import {
  StyledChartsPadding,
  StyledClaimAnalyticsPage,
  StyledClaimAnalyticsPagePaddedSection,
} from './styles';
import ClaimsHeaderDropdown from '../../containers/ClaimsHeaderDropdown';
import AnalyticsOverview from '../../atoms/AnalyticsOverview';
import CashlessClaims from '../../containers/Analytics/CashlessClaims';
import ReimbursementClaimMetric from '../../containers/Analytics/ReimbursementClaimMetric';
import SumInsuredUtilizedTable from '../../containers/Analytics/SumInsuredUtilizedTable';
import useClaimsAnalysis from './hooks/useClaimsAnalysis';
import useFetchClaimList from '../ClaimsList/hooks/useFetchClaimList';
import Loader from '../../atoms/Spinner';
import { useSelector } from 'react-redux';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import useSearchQuery from '../ClaimsList/hooks/useSearchQuery';
import {
  isSelectedPolicyRealtime,
  getPolicyListForClaimsDropDown,
  getClaimsLastUpdatedAt,
} from '../ClaimsList/utils';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { useLocation } from 'react-router-dom';
import useTrackPage from '../../../utils/segment/hooks/useTrackPage';

const Analytics = () => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const { data } = useSelector(selectPoliciesList);
  const dropDownFilters = useMemo(() => {
    return getPolicyListForClaimsDropDown(data, false);
  }, [data]);

  const [policyId, setSelectedPolicyFilter] = useSearchQuery(
    'policyId',
    dropDownFilters?.length && dropDownFilters[0].value,
  );
  const selectedPolicy = data.find((policy) => policy.id === policyId);
  const { isLoading, claims } = useFetchClaimList();
  const filteredClaims = useMemo(() => {
    return claims?.filter(
      (row) => row.policyNo === selectedPolicy?.policyNumber,
    );
  }, [claims, selectedPolicy]);

  const claimAnalticsData = useClaimsAnalysis(filteredClaims);
  const {
    claimsValue,
    csr,
    totalClaimed,
    cashlessDataSet,
    reimburseDataSet,
    siUtilization,
  } = claimAnalticsData || {};

  const subtitleData = useMemo(() => {
    return {
      lastUpdatedAt: getClaimsLastUpdatedAt(
        selectedPolicy ? filteredClaims : claims,
      ),
      isRealtime: isSelectedPolicyRealtime(data, policyId.toString()),
    };
  }, [claims, selectedPolicy, policyId, data, filteredClaims]);

  const handlePolicyChange = (selectedPolicyFilter: string) => {
    setSelectedPolicyFilter(selectedPolicyFilter);
    const policyProperties: Record<string, unknown> = {
      policy_number: selectedPolicyFilter,
    };
    trackClick(
      trackClickEvent(
        'POLICY_SECTION_DROPDOWN',
        location?.pathname,
        extractPathName(location?.pathname),
        policyProperties,
      ),
    );
  };
  useTrackPage(
    {
      policy_number: selectedPolicy?.policyNumber,
      policy_type: selectedPolicy?.policyType,
      claim_count: filteredClaims.length,
    },
    !isLoading,
  );

  return (
    <StyledClaimAnalyticsPage>
      <StyledClaimAnalyticsPagePaddedSection>
        <ClaimsHeaderDropdown
          title="Claims Analytics"
          subtitle={subtitleData}
          policiesOptions={dropDownFilters}
          selectedPolicyFilter={policyId || ''}
          setSelectedPolicyFilter={handlePolicyChange}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <StyledChartsPadding>
            <AnalyticsOverview
              totalClaimed={totalClaimed}
              csr={csr}
              claimValue={claimsValue}
            />
            <ReimbursementClaimMetric {...reimburseDataSet} />
            <CashlessClaims {...cashlessDataSet} policyId={policyId} />
          </StyledChartsPadding>
        )}
      </StyledClaimAnalyticsPagePaddedSection>
      {!isLoading && <SumInsuredUtilizedTable data={siUtilization} />}
    </StyledClaimAnalyticsPage>
  );
};

export default Analytics;
