import React from 'react';
import { IGenericIconProps } from './types';

const BulletTypeOne: React.FC<IGenericIconProps> = ({
  height = 14,
  width = 14,
  fill = '#595959',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.01682 13.6663C6.80657 13.6663 6.62883 13.5141 6.57791 13.3101C6.4156 12.6599 6.10488 11.9684 5.64575 11.2358C5.10177 10.3562 4.32631 9.54018 3.31936 8.78787C2.44358 8.12594 1.56779 7.67457 0.692011 7.43376C0.484589 7.37673 0.333252 7.19281 0.333252 6.97769C0.333252 6.76678 0.478767 6.58497 0.681567 6.52707C1.54022 6.28191 2.3674 5.88388 3.16311 5.33301C4.07747 4.69643 4.84135 3.93254 5.45478 3.04134C5.99745 2.24744 6.36997 1.46226 6.57234 0.685812C6.62535 0.482404 6.80442 0.333008 7.01463 0.333008C7.2272 0.333008 7.40753 0.485713 7.45926 0.691895C7.57603 1.15737 7.75857 1.63381 8.00686 2.1212C8.31936 2.72305 8.71867 3.30176 9.20478 3.85731C9.70247 4.40129 10.258 4.89319 10.8714 5.33301C11.673 5.90121 12.4879 6.30041 13.3162 6.53061C13.5194 6.58707 13.6666 6.76797 13.6666 6.97883C13.6666 7.19285 13.5149 7.37528 13.3085 7.43187C12.7835 7.57582 12.2431 7.80791 11.6874 8.12815C11.0161 8.52166 10.3911 8.99042 9.81242 9.5344C9.23372 10.0668 8.75918 10.6281 8.38881 11.2184C7.92878 11.9525 7.61774 12.6494 7.4557 13.3089C7.40544 13.5135 7.22747 13.6663 7.01682 13.6663Z"
      fill={fill}
    />
  </svg>
);

export { BulletTypeOne };
