import styled from 'styled-components';

export const FeedbackSidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 24px 120px 24px;
`;
export const PreloadImage = styled.img`
    display: none;
`
