import React, { FC } from 'react';
import {
  StyledAnalyticsOverviewCard,
  StyledAnalyticsOverviewCardData,
  StyledAnalyticsOverviewCardDataTitleContainer,
  StyledAnalyticsOverviewCardImage,
  StyledAnalyticsOverviewCardInfoIcon,
} from './styles';
import { IOverviewCardProps } from './types';
import { Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import { InfoDarkIcon } from '../../../assets/images';

const OverviewCard: FC<IOverviewCardProps> = ({
  image,
  title,
  stats,
  tooltipInfo,
}) => {
  return (
    <StyledAnalyticsOverviewCard data-testid="analytics-overview-card">
      <StyledAnalyticsOverviewCardImage
        src={image}
        data-testid="analytics-overview-card-image"
      />

      <StyledAnalyticsOverviewCardData data-testid="analytics-overview-card-data">
        <StyledAnalyticsOverviewCardDataTitleContainer data-testid="analytics-overview-card-title-section">
          <Typography variant="small">{title}</Typography>

          {tooltipInfo && (
            <Tooltip type="informative" text={tooltipInfo} beak="top-center">
              <StyledAnalyticsOverviewCardInfoIcon
                src={InfoDarkIcon}
                data-testid="analytics-overview-card-info-icon"
              />
            </Tooltip>
          )}
        </StyledAnalyticsOverviewCardDataTitleContainer>
        <Typography variant="title3" color="emerald">
          {stats}
        </Typography>
      </StyledAnalyticsOverviewCardData>
    </StyledAnalyticsOverviewCard>
  );
};

export default OverviewCard;
