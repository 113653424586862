import React from 'react';
import { IKnowMoreModalProps } from './types';
import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import {
  StyledKnowMoreIconCircle,
  StyledKnowMoreIconContainer,
  StyledKnowMoreItem,
  StyledKnowMoreItemIndex,
  StyledKnowMoreItemText,
  StyledKnowMoreItemTextDescription,
  StyledKnowMoreItemTextTitle,
  StyledKnowMoreModalBody,
  StyledKnowMoreModalBodyContainer,
  StyledKnowMoreModalFooter,
} from './styles';
import theme from '../../../../theme';
import {
  CheckOutlineNewIcon,
  CrossOutlineNewIcon,
} from '../../../../assets/icons';

const KnowMoreModal: React.FC<IKnowMoreModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal
      isVisible={isOpen}
      setIsVisible={onClose}
      title="How does the refund process work?"
      disablePadding
    >
      <WithThemeProvider>
        <StyledKnowMoreModalBodyContainer>
          <StyledKnowMoreModalBody>
            <StyledKnowMoreItem>
              <StyledKnowMoreItemIndex>
                <Typography variant="extraSmall" color="tertiary">
                  1
                </Typography>
              </StyledKnowMoreItemIndex>
              <StyledKnowMoreItemText>
                <StyledKnowMoreItemTextTitle>
                  <Typography variant="small">Ongoing claim :</Typography>
                  <Typography variant="small" color="error" weight="bold">
                    &nbsp;No Refund
                  </Typography>
                  <StyledKnowMoreIconContainer>
                    <StyledKnowMoreIconCircle></StyledKnowMoreIconCircle>
                    <CrossOutlineNewIcon fill={theme.colors.planRed} />
                  </StyledKnowMoreIconContainer>
                </StyledKnowMoreItemTextTitle>
                <StyledKnowMoreItemTextDescription>
                  <Typography variant="small" color="secondary">
                    If an employee or any of their dependants have an ongoing
                    claim, no refund will be issued for the entire family.
                  </Typography>
                </StyledKnowMoreItemTextDescription>
              </StyledKnowMoreItemText>
            </StyledKnowMoreItem>
            <StyledKnowMoreItem>
              <StyledKnowMoreItemIndex>
                <Typography variant="extraSmall" color="tertiary">
                  2
                </Typography>
              </StyledKnowMoreItemIndex>
              <StyledKnowMoreItemText>
                <StyledKnowMoreItemTextTitle>
                  <Typography variant="small">
                    Past claim during policy term :
                  </Typography>
                  <Typography variant="small" color="error" weight="bold">
                    &nbsp;No Refund
                  </Typography>
                  <StyledKnowMoreIconContainer>
                    <StyledKnowMoreIconCircle></StyledKnowMoreIconCircle>
                    <CrossOutlineNewIcon fill={theme.colors.planRed} />
                  </StyledKnowMoreIconContainer>
                </StyledKnowMoreItemTextTitle>
                <StyledKnowMoreItemTextDescription>
                  <Typography variant="small" color="secondary">
                    If an employee or any of their dependants had a claim during
                    the policy term, no refund will be issued for the entire
                    family.
                  </Typography>
                </StyledKnowMoreItemTextDescription>
              </StyledKnowMoreItemText>
            </StyledKnowMoreItem>
            <StyledKnowMoreItem>
              <StyledKnowMoreItemIndex>
                <Typography variant="extraSmall" color="tertiary">
                  3
                </Typography>
              </StyledKnowMoreItemIndex>
              <StyledKnowMoreItemText>
                <StyledKnowMoreItemTextTitle>
                  <Typography variant="small">
                    No past or ongoing claim :
                  </Typography>
                  <Typography variant="small" color="emerald" weight="bold">
                    &nbsp;Refund
                  </Typography>
                  <StyledKnowMoreIconContainer>
                    <StyledKnowMoreIconCircle
                      isCorrect
                    ></StyledKnowMoreIconCircle>
                    <CheckOutlineNewIcon fill={theme.colors.loopEmerald} />
                  </StyledKnowMoreIconContainer>
                </StyledKnowMoreItemTextTitle>
                <StyledKnowMoreItemTextDescription>
                  <Typography variant="small" color="secondary">
                    If no past or ongoing claims exist for an employee or their
                    dependants during the policy term, a refund will be issued
                    for the entire family.
                  </Typography>
                </StyledKnowMoreItemTextDescription>
              </StyledKnowMoreItemText>
            </StyledKnowMoreItem>
          </StyledKnowMoreModalBody>
        </StyledKnowMoreModalBodyContainer>
        <StyledKnowMoreModalFooter>
          <LoopButton
            size="medium"
            variant="filled"
            fontWeight="semiBold"
            backgroundColor={theme.colors.coverUpGreen}
            textColor="emerald"
            onClick={onClose}
          >
            I Understand
          </LoopButton>
        </StyledKnowMoreModalFooter>
      </WithThemeProvider>
    </Modal>
  );
};

export default KnowMoreModal;
