import { IPolicyTypeMapping } from './../types';

export const POLICY_TERMS_TYPE_MAPPING: IPolicyTypeMapping = {
  sumInsuredStructure: {
    label: 'Sum Insured Structure',
    displayForType: ['GMC', 'GPA', 'GTL', 'COVID'],
  },
  numberOfSlabs: {
    label: 'Number of slabs in policy',
    displayForType: ['GMC', 'GPA', 'GTL', 'COVID'],
  },
  sumInsuredApproach: {
    label: 'Sum Insured Approach',
    displayForType: ['GMC'],
  },
  ageLimit: {
    label: 'Age Limit',
    displayForType: ['GMC', 'GPA', 'GTL', 'COVID'],
  },
  waitingPeriod: {
    label: '30 days waiting period',
    displayForType: ['GMC'],
  },
  yearWaitingPeriod: {
    label: '1st, 2nd, 3rd, 4th Year Waiting Period',
    displayForType: ['GMC'],
  },
  preExistingDiseaseBenefit: {
    label: 'Pre Existing Disease Benefit',
    displayForType: ['GMC'],
    type: 'CLASSIFY',
  },
  nineMonthsWaitingPeriod: {
    label: 'Maternity Waiting Period',
    displayForType: ['GMC'],
    type: 'CLASSIFY',
  },
  newBornBaby: {
    label: 'New Born Baby',
    displayForType: ['GMC'],
    type: 'CLASSIFY',
  },
  preHospitalizationExpenses: {
    label: 'Pre Hospitalisation Expenses',
    displayForType: ['GMC'],
    type: 'CLASSIFY',
  },
  postHospitalizationExpenses: {
    label: 'Post Hospitalisation Expenses',
    displayForType: ['GMC'],
    type: 'CLASSIFY',
  },
  dayCareProcedures: {
    label: 'Day Care Procedures',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  internalCongenitalDisease: {
    label: 'Internal congenital disease',
    displayForType: ['GMC'],
    type: 'CLASSIFY',
  },
  externalCongenitalDisease: {
    label: 'External congenital disease',
    displayForType: ['GMC'],
    type: 'CLASSIFY',
  },
  hospitalizationArisingOutOfTerrorism: {
    label: 'Hospitalization arising out of Terrorism',
    displayForType: ['GMC'],
    type: 'CLASSIFY',
  },
  lasikSurgery: {
    label: 'Lasik Surgery',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  animalAttack: {
    label: 'Animal / Serpent Attack',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  dentalTreatment: {
    label: 'Dental Treatment',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  ayushTreatment: {
    label: 'AYUSH Treatment',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  subLimit: {
    label: 'Sub Limits / Ailment Capping',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  hospitalizationArisingOutOfPsychiatricAilments: {
    label: 'Hospitalization arising out of psychiatric ailments',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  functionalEndoscopicSinusSurgery: {
    label: 'Functional Endoscopic Sinus Surgery',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  cyberKnifeTreatmentOrStemCellTransplantation: {
    label: 'Cyber knife treatment / stem cell transplantation',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  cochlearImplantTreatment: {
    label: 'Cochlear implant treatment',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  oralChemotherapy: {
    label: 'Oral Chemotherapy',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  homeCareTreatmentExpenses: {
    label: 'Home Care Treatment Expenses / Home Quarantine / Isolation',
    displayForType: ['GMC'],
    type: 'PARTIAL',
  },
  additionsOrDeletionsRequest: {
    label: 'Additions / Deletions Request',
    displayForType: ['GMC', 'GPA'],
    type: 'PARTIAL',
  },
  coverageForNewJoinersAndDep: {
    label: 'Coverage for New Joiners and Dep. / Leavers',
    displayForType: ['GMC', 'GPA'],
  },
  premiumOnNewJoinersAndDep: {
    label: 'Premium on new joiners and dep. / leavers',
    displayForType: ['GMC', 'GPA'],
  },
  claimsIntimation: {
    label: 'Claims Intimation',
    displayForType: ['GMC', 'GPA'],
  },
  claimsSubmission: {
    label: 'Claims Submission',
    displayForType: ['GMC', 'GPA'],
  },
  terrorismExclusionClause: {
    label: 'Terrorism Exclusion Clause',
    displayForType: ['GPA'],
    type: 'PARTIAL',
  },
  aoaLimit: {
    label: 'AOA Limit',
    displayForType: ['GPA'],
    type: 'PARTIAL',
  },
  permanentPartialDisability: {
    label: 'Permanent Partial Disability',
    displayForType: ['GPA'],
    type: 'PARTIAL',
  },
  permanentTotalDisability: {
    label: 'Permanent Total Disability',
    displayForType: ['GPA'],
    type: 'PARTIAL',
  },
  accidentalDeath: {
    label: 'Accidental Death',
    displayForType: ['GPA'],
    type: 'PARTIAL',
  },
  specialCondition1: {
    label: 'Special Condition 1',
    displayForType: ['GMC', 'GPA'],
  },
  specialCondition2: {
    label: 'Special Condition 2',
    displayForType: ['GMC', 'GPA'],
  },
  specialCondition3: {
    label: 'Special Condition 3',
    displayForType: ['GMC', 'GPA'],
  },
  specialCondition4: {
    label: 'Special Condition 4',
    displayForType: ['GMC', 'GPA'],
  },
  specialCondition5: {
    label: 'Special Condition 5',
    displayForType: ['GMC', 'GPA'],
  },
};

export const VALUE_MAPPING: Record<string, Record<string, string>> = {
  postHospitalizationExpenses: {
    '60 days': 'Expenses 60 days after hospitalisation',
    '90 days': 'Expenses 90 days after hospitalisation',
    '120 days': 'Expenses 120 days after hospitalisation',
  },
  preHospitalizationExpenses: {
    '30 days': 'Expenses 30 days before hospitalisation',
    '60 days': 'Expenses 60 days before hospitalisation',
  },
  nineMonthsWaitingPeriod: {
    'waived off': 'No Maternity Waiting Period',
    applicable: '9 month waiting period applicable',
  },
};

export const LABEL_MAPPING: Record<string, Record<string, string>> = {
  waitingPeriod: {
    'waived off': 'No waiting period',
    applicable: '30-day waiting period',
  },
  newBornBaby: {
    'covered from day 1': 'New Born Baby covered from Day 1',
    'covered from day 90': 'New Born Baby covered from Day 90',
    'not covered': 'New Born Baby Expenses',
  },
};
