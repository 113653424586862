import styled from 'styled-components';

export const StyledOverviewCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 28px 24px;
  background-color: ${(p) => p.theme.colors.surface0};
  border-radius: 8px;
`;

export const StyledCardHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 14px 24px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 8px;
`;

export const StyledCardBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
`;

export const StyledCardInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 14px;
`;

export const StyledCardInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
`;

export const StyledIcon = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
`;

export const SyledHighlightDescription = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 20px;
  text-align: left;
  background-color: ${(p) => p.theme.colors.white};
  border-right-top-radius: 8px;
  border-right-bottom-radius: 8px;
  border-left: 2px solid ${(p) => p.theme.colors.seafoamGray};
  font-style: italic;
`;
