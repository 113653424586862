import { Avatar, Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledActionButtonImage,
  StyledDependantContainer,
  StyledSectionCard,
  StyledSectionCardContainer,
  StyledSectionCardFooter,
  StyledSectionCardFooterItem,
  StyledSectionCardHeader,
  StyledSectionCardHeaderActionButtons,
  StyledSectionCardHeaderProfile,
  StyledSectionCardHeaderProfileDetails,
  StyledSectionContainer,
  StyledSectionLabel,
} from './styles';
import {
  DeleteOutlinedIcon,
  EditOutlinedIcon,
} from '../../../../assets/images';
import EmptyDependantCard from '../EmptyDependantCard';
import FilledDependantCard from '../FilledDependantCard';

const AddDependantDetails = () => {
  return (
    <StyledDependantContainer>
      <StyledSectionContainer>
        <StyledSectionLabel>
          <Typography variant="small" weight="semiBold">
            Self + Spouse
          </Typography>
        </StyledSectionLabel>
        <StyledSectionCardContainer>
          <FilledDependantCard
            dependant={{
              firstName: 'Varun',
              lastName: 'Kumar',
              relationship: 'self',
              dateOfBirth: new Date(),
              gender: 'male',
              mobile: '7278039864',
            }}
          />
          <FilledDependantCard
            dependant={{
              firstName: 'Ruchi',
              lastName: 'Kumar',
              relationship: 'spouse',
              dateOfBirth: new Date(),
              gender: 'female',
              mobile: '7278039864',
            }}
          />
        </StyledSectionCardContainer>
      </StyledSectionContainer>
      <StyledSectionContainer>
        <StyledSectionLabel>
          <Typography variant="small" weight="semiBold">
            2 Children{' '}
            <Typography variant="small" color="secondary" weight="regular">
              (This policy allows insuring 2 children upto the age of 25 years)
            </Typography>
          </Typography>
        </StyledSectionLabel>
        <StyledSectionCardContainer>
          <EmptyDependantCard
            label="Add Child 1"
            onClick={() => console.log('Add Dependant')}
          />
          <EmptyDependantCard
            label="Add Child 2"
            onClick={() => console.log('Add Dependant')}
          />
        </StyledSectionCardContainer>
      </StyledSectionContainer>
      <StyledSectionContainer>
        <StyledSectionLabel>
          <Typography variant="small" weight="semiBold">
            Parents OR Parents - in - law{' '}
            <Typography variant="small" color="secondary" weight="regular">
              (This policy allows insuring either your parents or your parents
              in law)
            </Typography>
          </Typography>
        </StyledSectionLabel>
        <StyledSectionCardContainer>
          <EmptyDependantCard
            label="Add Parent"
            onClick={() => console.log('Add Parent 1')}
          />
          <EmptyDependantCard
            label="Add Parent"
            onClick={() => console.log('Add Parent 2')}
          />
        </StyledSectionCardContainer>
      </StyledSectionContainer>
    </StyledDependantContainer>
  );
};

export default AddDependantDetails;
