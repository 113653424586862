import { IHRDashboardFilterType } from './types';

export const HR_DASHBOARD_FILTER: IHRDashboardFilterType[] = [
  IHRDashboardFilterType.All,
  IHRDashboardFilterType.GMC,
  IHRDashboardFilterType.GPA,
  IHRDashboardFilterType.GTL,
];

export const FILTER_ACRONYM = {
  [IHRDashboardFilterType.All]: 'All',
  [IHRDashboardFilterType.GMC]: 'GMC',
  [IHRDashboardFilterType.GPA]: 'GPA',
  [IHRDashboardFilterType.GTL]: 'GTL',
};

export const FILTER_TEXT = {
  [IHRDashboardFilterType.All]: 'All',
  [IHRDashboardFilterType.GMC]: 'Group Medical Cover',
  [IHRDashboardFilterType.GPA]: 'Group Personal Accidental',
  [IHRDashboardFilterType.GTL]: 'Group Term Life',
};
