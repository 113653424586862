import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { IUpdateHrUser } from '../redux/slices/usersSlice/types';
import { removeEmpty } from '../utils/common/Utilities';
import LoopEndpoints from './LoopEndpoints';
import {
  del,
  FirebaseAuth,
  FirebaseCaptcha,
  Firestore,
  get,
  post,
  put,
} from './providers';
import { IIdentifiedMidtermResponse } from '../components/containers/MidTermAdditionModal/types';
import { ICostEstimation } from '../components/containers/Bulk/SubmitEndo/types';
import { IBulkPayload } from '../components/pages/Endorsements/BulkAddMembers/types';
import { IFeedbackRequestBody } from '../components/containers/HRFeedback/FeedbackSidebar/types';
import { IBulkEditSubmitRequest } from '../components/pages/Endorsements/BulkEditMembers/types';
import { ISuccessResponse } from './types';

export const fieldValue = firebase.firestore.FieldValue;
export const timeStamp = firebase.firestore.Timestamp;

class LoopApiService {
  db: firebase.firestore.Firestore;
  auth: firebase.auth.Auth;
  captcha: typeof firebase.auth;
  constructor() {
    this.db = Firestore;
    this.auth = FirebaseAuth;
    this.captcha = FirebaseCaptcha;
  }

  getCompanyById = async (companyId: string) => {
    try {
      const response = await get<
        ISuccessResponse<{ company: Record<string, string> }>
      >(LoopEndpoints.companyDetails(companyId));
      if (!response?.data) {
        throw new Error(response.message ?? 'getCompanyById failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };
  getCompanyConfigById = async (companyId: string) => {
    try {
      const response = await get(LoopEndpoints.companyConfig(companyId));
      if (!response?.data) {
        throw new Error(response.message ?? 'getCompanyConfigById failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  fetchClaimsAnalyticsData = async () => {
    try {
      const response = await get(LoopEndpoints.claimsAnalytics);
      if (!response?.data) {
        throw new Error(response.message ?? 'Unable to fetch claim data');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getEmployeeData = async (params: Record<string, unknown>) => {
    try {
      const searchParams = new URLSearchParams(
        removeEmpty(params) as Record<string, string>,
      ).toString();
      const endpoint =
        searchParams.length > 0
          ? LoopEndpoints.userV2 + '?' + searchParams
          : LoopEndpoints.userV2;
      const response = await get(endpoint);
      if (!response.data) {
        throw new Error(response.message ?? 'getEmployeeData /user failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  fetchPolicyPreferenceByUserIdAndRel = async ({
    userId,
    relationship,
  }: Record<string, string>) => {
    try {
      const endpoint = LoopEndpoints.fetchPolicyPreferenceByUserIdAndRel(
        userId,
        relationship,
      );
      const response = await get(endpoint);
      if (!response.data) {
        throw new Error(response.message ?? 'fetchPolicyPreference failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  fetchAllChangeRequestsAPI = async (
    filterChangeRequestOptions: Record<string, unknown>,
  ) => {
    try {
      const paramsString = Object.entries(filterChangeRequestOptions)
        .map(([k, v]) =>
          Array.isArray(v)
            ? `${k}=${v.join(',')}`
            : typeof v === 'string' || typeof v === 'number'
            ? `${k}=${v}`
            : ``,
        )
        .filter(Boolean)
        .join('&');
      const endpoint =
        paramsString !== ''
          ? LoopEndpoints.changeRequest + '?' + paramsString
          : LoopEndpoints.changeRequest;
      const response = await get(endpoint);
      if (!response.data) {
        throw new Error(response.message ?? 'ChangeRequestsAPI failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getHRDetails = async (
    userId: string,
    methodType: string,
    loginMethod: string,
  ) => {
    // NOTE: Method type like how the user going to login e.g. using phone or email.
    const response = await get<Record<string, unknown>>(
      LoopEndpoints.fetchUser(methodType, userId),
    );
    if (!response) throw new Error('Cannot fetch user details');
    return response;
  };

  updateHrData = async (data: IUpdateHrUser) => {
    const response = await post(LoopEndpoints.updateHrData, data);
    if (!response) throw new Error('Cannot update details');
    return { data: response.data };
  };
  addEmpHrRequest = async (data: Record<string, unknown>) => {
    return await post(LoopEndpoints.user, data);
  };
  addDepHrRequest = async (data: Record<string, unknown>) => {
    return await post(LoopEndpoints.savaDepDetails(), data);
  };

  enrolUserToPolicy = async (data: Record<string, unknown>) => {
    return await post(LoopEndpoints.enrollPolicy(), data);
  };

  updateUser = async (data: Record<string, string>) => {
    return await put(LoopEndpoints.fetchUserById(data.userId), data);
  };

  fetchDependentApi = async (employerId: string) => {
    return await get(LoopEndpoints.fetchDependentList(employerId));
  };

  getUserDetails = async (userId: string) => {
    return await get(LoopEndpoints.fetchUserById(userId));
  };

  getUserPoliciesByUserId = async (userId: string) => {
    return await get(LoopEndpoints.fetchUserPoliciesByUserId(userId));
  };

  fetchClaimsAPI = async (companyId: string, employeeId = '') => {
    return await get(LoopEndpoints.claimList(companyId, employeeId));
  };

  fetchClaimsFiltersAPI = async () => {
    return await get<ISuccessResponse<any>>(LoopEndpoints.claimsFilters);
  };

  fetchClaimsListAPI = async (data: Record<string, unknown>) => {
    return await post(LoopEndpoints.claimsList, data);
  };

  fetchClaimDetailsAPI = async (claimId: string) => {
    return await get(LoopEndpoints.claimDetails(claimId));
  };

  fetchClaimTimelineAPI = async (claimId: string) => {
    return await get(LoopEndpoints.claimTimeline(claimId));
  };
  sendClaimsRecordEmail = async () => {
    return await post(LoopEndpoints.sendClaimsRecordEmail, {});
  };

  getCompanyPolicyPlans = async (params: Record<string, unknown>) => {
    try {
      const searchParams = new URLSearchParams(
        removeEmpty(params) as Record<string, string>,
      ).toString();
      const endpoint =
        searchParams.length > 0
          ? LoopEndpoints.policyPlan + '?' + searchParams
          : LoopEndpoints.policyPlan;
      const response = await get<ISuccessResponse<any>>(endpoint);
      if (!response.data) {
        throw new Error(response.message ?? 'getCompanyPolicyPlans failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getCompanyPolicyDetails = async (companyId: string, policyId: string) => {
    return await get(
      LoopEndpoints.fetchCompanyPoliciesDetails(companyId, policyId),
    );
  };
  // Function to get Employee & dependant count of particular Policy
  getPolicyEmpCount = async (companyId: string, policyId: string) => {
    return await get(LoopEndpoints.fetchPolicyEmpCount(companyId, policyId));
  };

  fetchEndorsements = async (companyId: string) => {
    return await get(LoopEndpoints.fetchEndorsements(companyId));
  };
  fetchEndorsementById = async (id: string) => {
    return await get(LoopEndpoints.fetchEndorsementById(id));
  };
  fetchPolicyListForEndorsement = async () => {
    return await get(LoopEndpoints.policyPlanForEndo);
  };

  fetchCDAccounts = async (companyId: string) => {
    return await get(LoopEndpoints.cdAccounts(companyId));
  };

  downloadCDStatement = async (cdAccountId: string) => {
    return await get(LoopEndpoints.downloadCDStatement(cdAccountId));
  };

  askPolicy = async (question: string) => {
    return await post(LoopEndpoints.askPolicy, {
      question,
    });
  };

  getHRDTemplate = async (policyId: string) => {
    try {
      const response = await get(LoopEndpoints.downloadTemplate(policyId));
      if (!response) {
        throw new Error('Something went wrong');
      }
      return response;
    } catch (e) {
      return e;
    }
  };

  // Employees Related APIs
  fetchEmployeesListAPI = async (companyId: string, policyId: string) => {
    return await get(
      LoopEndpoints.fetchCompanyEmployeesByPolicy(companyId, policyId),
    );
  };

  fetchECard = async (userId: string, policyId: string) => {
    try {
      const response = await get(LoopEndpoints.getECard(userId, policyId));
      if (!response) {
        throw new Error('Something went wrong');
      }
      return response;
    } catch (e) {
      throw e;
    }
  };

  uploadDocument = async (
    employeeId: string,
    name: string,
    fileName: string,
  ) => {
    return await put(LoopEndpoints.uploadDocument, {
      employeeId,
      name,
      fileName,
    });
  };

  deleteDocument = async (filePath: string) => {
    return await del(LoopEndpoints.deleteDocument, {
      filePath,
    });
  };

  downloadDocument = async (filePath: string) => {
    return await get(LoopEndpoints.downloadDocument(filePath));
  };

  validateBulkAdditionData = async (data: IBulkPayload) => {
    return await post(LoopEndpoints.validateBulkAddition, data);
  };

  validateBulkEditionData = async (data: Record<string, unknown>) => {
    return await post(LoopEndpoints.validateBulkEdition, data);
  };

  validateBulkDeletionData = async (data: Record<string, unknown>) => {
    return await post(LoopEndpoints.validateBulkDeletion, data);
  };

  submitBulkAddRequest = async (data: Record<string, unknown>) => {
    return await post(LoopEndpoints.submitBulkAddRequest, data);
  };
  submitBulkEditRequest = async (data: IBulkEditSubmitRequest[]) => {
    return await post(LoopEndpoints.submitBulkEditRequest, data);
  };
  submitBulkDeleteRequest = async (data: Record<string, unknown>) => {
    return await post(LoopEndpoints.submitBulkDeleteRequest, data);
  };

  downloadBulkAddTemplate = async (companyId: string) => {
    return await get(LoopEndpoints.downloadAddTemplate(companyId));
  };
  downloadBulkDeleteTemplate = async (companyId: string) => {
    return await get(LoopEndpoints.downloadDeleteTemplate(companyId));
  };
  estimateEndoCost = (payload: Record<string, unknown>) => {
    return post<ICostEstimation>(LoopEndpoints.estimateEndoCost, payload);
  };

  identifyMidterms = async (
    users: Record<string, unknown>,
    policyId: string,
    companyId: string,
  ) => {
    return await post<ISuccessResponse<IIdentifiedMidtermResponse>>(
      LoopEndpoints.identifyMidTerms,
      {
        users,
        policyId,
        companyId,
      },
    );
  };

  validateMidterms = async (
    users: Record<string, unknown>,
    policyId: string,
  ) => {
    return await post<ISuccessResponse<IIdentifiedMidtermResponse>>(
      LoopEndpoints.validateMidTerms,
      { users, policyId },
    );
  };

  fetchEndorsementsEndDate = async (companyId: string) => {
    return await get(LoopEndpoints.fetchEndoDateDetails(companyId));
  };
  endoStep2ExitTrigger = async (
    errorFile: File | null,
    validLives: string,
    invalidLives: string,
    totalLives: string,
    companyId: string,
  ) => {
    try {
      if (!errorFile) return;
      const formData = new FormData();
      formData.append('attachment', errorFile, 'Error-Sheet.xlsx');
      formData.append('validLives', validLives);
      formData.append('invalidLives', invalidLives);
      formData.append('totalLives', totalLives);
      post(LoopEndpoints.endoStep2ExitTrigger(companyId), formData);
    } catch (e) {
      console.log(e);
    }
  };

  lowCDEndoSubmissionEmailTrigger = async (
    uploadedFile: Record<string, unknown>[],
    policies: Record<string, unknown>[],
    companyId: string,
  ) => {
    const formData = new FormData();
    formData.append('attachment', uploadedFile[0] as unknown as File);
    formData.append('policies', JSON.stringify(policies));
    post(LoopEndpoints.lowCDEndoSubmissionEmailTrigger(companyId), formData);
  };

  endoSubmissionEmailTrigger = async (
    uploadedFile: Record<string, unknown>[],
    policies: Record<string, unknown>[],
    companyId: string,
  ) => {
    const formData = new FormData();
    formData.append('attachment', uploadedFile[0] as unknown as File);
    formData.append('policies', JSON.stringify(policies));
    post(LoopEndpoints.endoSubmissionEmailTrigger(companyId), formData);
  };

  endoStep3ExitEmailTrigger = async (
    uploadedFile: Record<string, unknown>[],
    policiesToBeUpdated: string,
    endoCost: string,
    totalLives: string,
    validLives: string,
    invalidLives: string,
    companyId: string,
  ) => {
    const formData = new FormData();
    formData.append('attachment', uploadedFile[0] as unknown as File);
    formData.append('policiesToBeUpdated', policiesToBeUpdated);
    formData.append('endoCost', endoCost);
    formData.append('totalLives', totalLives);
    formData.append('validLives', validLives);
    formData.append('invalidLives', invalidLives);
    post(LoopEndpoints.endoStep3ExitTrigger(companyId), formData);
  };

  triggerFeedbackSubmitMail = async (data: IFeedbackRequestBody) => {
    return await post(LoopEndpoints.feedbackSubmitMail, data);
  };
}

export default new LoopApiService();
