import React, { FC } from 'react';
import {
  StyledLearnMoreModalContainer,
  StyledLearnMoreModalFooter,
  StyledLearnMoreModalList,
  StyledLearnMoreModalListItem,
  StyledLearnMoreModalListItemContent,
  StyledLearnMoreModalListItemIndex,
  StyledLearnMoreModalListItemIndexContainer,
} from './styles';
import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { IClaimsLearnMoreModal } from './types';
import { FAQData } from './constants';
import theme from '../../../theme';

const ClaimsLearnMoreModal: FC<IClaimsLearnMoreModal> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      isVisible={isOpen}
      setIsVisible={onClose}
      title="Claims Status FAQ"
      hideHeaderBorder
      disablePadding
    >
      <WithThemeProvider>
        <StyledLearnMoreModalContainer>
          <StyledLearnMoreModalList data-testid="faq-container">
            {FAQData.map((item) => (
              <StyledLearnMoreModalListItem key={item.index}>
                <StyledLearnMoreModalListItemIndexContainer>
                  <StyledLearnMoreModalListItemIndex>
                    <Typography variant="extraSmall" color="tertiary">
                      {item.index}
                    </Typography>
                  </StyledLearnMoreModalListItemIndex>
                </StyledLearnMoreModalListItemIndexContainer>
                <StyledLearnMoreModalListItemContent>
                  <Typography variant="small" weight="semiBold">
                    {item.title}
                  </Typography>
                  <Typography
                    variant="small"
                    color="secondary"
                    weight="regular"
                  >
                    {item.description}
                  </Typography>
                </StyledLearnMoreModalListItemContent>
              </StyledLearnMoreModalListItem>
            ))}
          </StyledLearnMoreModalList>
        </StyledLearnMoreModalContainer>
        <StyledLearnMoreModalFooter data-testid="close-button-wrapper">
          <LoopButton
            size="medium"
            variant="filled"
            fontWeight="semiBold"
            backgroundColor={theme.colors.coverUpGreen}
            textColor="emerald"
            onClick={onClose}
          >
            I Understand
          </LoopButton>
        </StyledLearnMoreModalFooter>
      </WithThemeProvider>
    </Modal>
  );
};

export default ClaimsLearnMoreModal;
