import styled from 'styled-components';

export const StyledBanner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 32px;
  border-radius: 8px;
  background: ${(p) => p.theme.colors.surfaceOne};
`;

export const StyledBannerImage = styled.img`
  width: 100px;
  height: 50px;
  aspect-ratio: 1;
`;

export const StyledBannerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background: ${(p) => p.theme.colors.surface0};
  border-radius: 8px;
  width: 100%;
`;
