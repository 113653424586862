import LoopApiService from '../../../../adaptars/LoopApiService';
import { parseResponse } from '../../../../utils/common/Utilities';
import {
  IBulkPayload,
  IUploadedPolicywiseData,
  PolicyWiseValidationRequest,
  PolicyWiseValidationResponse,
  IPolicyRejectedEntries,
} from './../../../pages/Endorsements/BulkAddMembers/types';
import { ICDAccountUI } from '../../../pages/CDAccounts/types';
import {
  ICostEstimation,
  ICostEstimationResponseOperationTypes,
  IEndoCostItem,
  IOperationEstimate,
} from './types';
import { IBulkOperation } from '../types';
import {
  IBulkEditData,
  IBulkEditEntity,
} from '../../../pages/Endorsements/BulkEditMembers/types';
import { transformEditEntityIntoData } from '../../../pages/Endorsements/BulkEditMembers/utils';

export const processValidationPayloadData = (
  correctEntries: IUploadedPolicywiseData,
  companyId: string,
): IBulkPayload => {
  const policies: PolicyWiseValidationRequest[] = Object.keys(
    correctEntries,
  ).reduce(
    (prev, policyId) =>
      prev.concat({
        policyId,
        membersList: correctEntries[policyId],
      }),
    [] as PolicyWiseValidationRequest[],
  );
  return {
    companyId,
    policies,
  };
};

export const validateAddData = async (
  bulkAddData: IBulkPayload,
): Promise<
  [uploadedDataSet: any, validationRejections: IPolicyRejectedEntries]
> => {
  const [error, response] = await parseResponse(
    LoopApiService.validateBulkAdditionData(bulkAddData),
  );
  if (error) throw error;
  const uploadedDataSet: any = {};
  response.data?.allMembers?.forEach(
    (data: any) => (uploadedDataSet[data.policyId] = data.membersList),
  );
  const validationRejections = (
    response.data?.invalidMembersPerPolicy as PolicyWiseValidationResponse[]
  ).reduce(
    (prev, current) => ({
      ...prev,
      [current.policyId]: current.invalidMembers.map((member) => ({
        ...member,
        error: member.errors,
      })),
    }),
    {} as IPolicyRejectedEntries,
  );
  return [uploadedDataSet, validationRejections];
};

export const concatMidtermAndValidationErrors = (
  policies: string[],
  rejectedMidterms: IPolicyRejectedEntries,
  rejectedValidations: IPolicyRejectedEntries,
) => {
  const finalRejections: IPolicyRejectedEntries = {};
  policies.forEach((policyId) => {
    const policyRejections = [];
    if (rejectedMidterms[policyId])
      policyRejections.push(...rejectedMidterms[policyId]);
    if (rejectedValidations[policyId])
      policyRejections.push(...rejectedValidations[policyId]);
    if (policyRejections.length) finalRejections[policyId] = policyRejections;
  });
  return finalRejections;
};
export const submitBulkAddEndorsement = async (
  companyId: string,
  correctData: IUploadedPolicywiseData,
) => {
  const policies: any = [];
  Object.keys(correctData).forEach((policy) => {
    const membersList = correctData[policy];
    policies.push({ policyId: policy, membersList });
  });
  const [error, response] = await parseResponse(
    LoopApiService.submitBulkAddRequest({
      companyId,
      policies: policies,
    }),
  );
  if (error || !response) {
    return;
  }
  return response;
};
export const submitBulkDeleteEndorsement = async (
  companyId: string,
  correctData: Object,
) => {
  const [error, response] = await parseResponse(
    LoopApiService.submitBulkDeleteRequest({
      companyId,
      memberList: correctData,
    }),
  );
  if (error || !response) {
    return;
  }
  return response;
};
export const sanitizeCostEstimationData = (
  costEstimationData: ICostEstimation[],
  mode: IBulkOperation,
) => {
  const sanitisedData: Record<string, IOperationEstimate> = {};
  const listedPolicies: string[] = [];
  const responseMode = ['DELETE', 'SINGLE_DELETE'].includes(mode)
    ? 'OFFBOARD'
    : (mode as ICostEstimationResponseOperationTypes);
  costEstimationData.forEach((data) => {
    Object.keys(data).forEach((policyID) => {
      listedPolicies.push(policyID);
      sanitisedData[policyID] = data[policyID][responseMode];
    });
  });
  return { listedPolicies, sanitisedData };
};

export const prepareCostEndoData = (
  cdAccounts: ICDAccountUI[],
  costEstimationData: ICostEstimation[],
  mode: IBulkOperation,
  originalLivesCount: number,
) => {
  const data: IEndoCostItem[] = [];
  let totalCost = 0;
  let totalLives = 0;
  let totalCostIncuringLives = 0;
  const { listedPolicies, sanitisedData } = sanitizeCostEstimationData(
    costEstimationData,
    mode,
  );
  cdAccounts.forEach((account) => {
    const hasMatchingPolicies = account.policies.some((policy) =>
      listedPolicies.includes(policy),
    );

    if (hasMatchingPolicies) {
      let accountWiseCost = 0;
      const policyData = account.policyData
        .map(({ policyType, insurerLogo, id }) => ({
          policyType,
          insurerLogo,
          ...sanitisedData[id],
        }))
        .filter((policyData) => !!policyData.lives);
      policyData.forEach(({ lives, costOfEndorsement, policyType }) => {
        if (
          (mode === 'SINGLE_DELETE' && policyType === 'GMC') ||
          mode !== 'SINGLE_DELETE'
        )
          totalLives += lives;
        totalCost += costOfEndorsement;
        accountWiseCost += costOfEndorsement;
        if (costOfEndorsement > 0) totalCostIncuringLives += lives;
      });
      data.push({
        balance: account.netBalance,
        isCDLow: ['ADD', 'EDIT'].includes(mode)
          ? accountWiseCost > account.netBalance
          : false,
        insurer: account.insurer,
        insurerLogo: account.policyData[0]?.insurerLogo,
        policyData,
      });
    }
  });

  return {
    endoCostList: data,
    totalCost,
    totalLives: ['ADD', 'DELETE', 'SINGLE_DELETE'].includes(mode)
      ? totalLives
      : originalLivesCount,
    totalCostIncuringLives,
  };
};

export const getSubmitEventType = (endoCostData: IEndoCostItem[]) => {
  const hasMultipleCDs = endoCostData.length > 1;
  const hasMultiplePolicies = endoCostData.some(
    (item) => item.policyData.length > 1,
  );
  const hasLowCD = endoCostData.some((item) => item.isCDLow);

  if (hasMultiplePolicies) {
    if (hasMultipleCDs) {
      return hasLowCD
        ? 'Load_MultP_MultCD_Low_add'
        : 'Load_MultP_MultCD_Suff_add';
    } else {
      return hasLowCD
        ? 'Load_MultP_SingleCD_Low_add'
        : 'Load_MultP_SingleCD_Suff_add';
    }
  } else {
    if (hasMultipleCDs) {
      return hasLowCD
        ? 'Load_SingleP_MultCD_Low_add'
        : 'Load_SingleP_MultCD_Suff_add';
    } else {
      return hasLowCD
        ? 'Load_SingleP_SingleCD_Low_add'
        : 'Load_SingleP_SingleCD_Suff_add';
    }
  }
};

export const transformEditDataForEstimateRequest = (
  editedUsers: IBulkEditEntity[],
) => {
  const policyWiseEditedUsers: Record<string, IBulkEditData[]> = {};
  editedUsers.forEach((editedUser) => {
    const editData = transformEditEntityIntoData(editedUser);
    editedUser.policies.forEach((policy) => {
      const newMember = { ...editData, slabId: policy.slabId };
      if (policyWiseEditedUsers[policy.id])
        policyWiseEditedUsers[policy.id].push(newMember);
      else policyWiseEditedUsers[policy.id] = [newMember];
    });
  });
  return policyWiseEditedUsers;
};
