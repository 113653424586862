import styled from 'styled-components';

export const StyledOverviewWrapper = styled.div`
  padding: 40px 56px;
  background: ${(p) => p.theme.colors.surfaceOne};
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const StyledBackIcon = styled.img`
  cursor: pointer;
`;

export const StyledBackBtnContainer = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  align-items: center;
`;

export const StyledPolicyOverviewHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const StyledPolicyOverviewHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

export const StyledPolicyOverviewHeaderTitle = styled.div`
  display: flex;
  gap: 20px;
`;

export const StyledPolicyOverviewHeaderDesc = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledPolicyOverviewHeaderDescSeperator = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.lightSilver};
`;
