import React from 'react';
import {
  DateFormatUtils,
  LoopButton,
  Typography,
} from '@loophealth/loop-ui-web-library';

import {
  StyledButtonContainer,
  StyledClaimsTimelineContainer,
  StyledStatusLineContainer,
  StyledStepContainer,
  StyledStepDescriptionContainer,
  StyledStepsOuterContainer,
  StyledSuccessIcon,
  StyledTimeLine,
} from './styles';
import { ClaimStatusTimelineProps, ITimelineElement } from './types';
import theme from '../../../theme';
import { AmountBadge } from './AmountBadge';
import Loader from '../../atoms/Spinner';
import { StyledNoTimelineSpacing } from '../ClaimDetails/styles';
import { redirectToUrl } from '../../../utils/common/common';
import { ArrowRightIcon } from '../../../assets/images';

const ClaimsTimeline: React.FC<ClaimStatusTimelineProps> = ({
  timelineData,
  approvedAmount,
  timelineLoading,
}) => {
  const formatDate = new DateFormatUtils().formatDateString;
  return timelineLoading ? (
    <Loader />
  ) : timelineData ? (
    <StyledClaimsTimelineContainer>
      <StyledStepsOuterContainer>
        {timelineData?.map((element: ITimelineElement, index: number) => {
          return (
            <React.Fragment key={element.id}>
              <StyledStepContainer>
                <StyledStatusLineContainer>
                  <StyledSuccessIcon
                    src={
                      element.active
                        ? element.successIcons.active
                        : element.successIcons.disabled
                    }
                  />
                  {index < timelineData.length - 1 && (
                    <StyledTimeLine
                      $backgroundColor={
                        element.active
                          ? theme.colors.loopEmerald
                          : theme.colors.seafoamGray
                      }
                    />
                  )}
                </StyledStatusLineContainer>
                <StyledStepDescriptionContainer>
                  <Typography variant="small" weight="medium" color="primary">
                    {element.title}
                  </Typography>
                  <Typography
                    variant="extraSmall"
                    weight="regular"
                    color="secondary"
                  >
                    {element.description}{' '}
                    {(element.id === 'claim_initiated' ||
                      element.id === 'doc_submitted') &&
                    element.date
                      ? ' on ' + formatDate(element.date)
                      : null}
                  </Typography>
                </StyledStepDescriptionContainer>
                {element.id === 'claim_settled' &&
                element.active &&
                approvedAmount ? (
                  <AmountBadge approvedAmount={approvedAmount} />
                ) : null}
                {element.url ? (
                  <StyledButtonContainer>
                    <LoopButton
                      variant="secondary"
                      onClick={() => redirectToUrl(element.url ?? '')}
                      isLoading={false}
                      size="small"
                      iconSrc={ArrowRightIcon}
                      iconOrder="right"
                    >
                      {element.ctaTitle}
                    </LoopButton>
                  </StyledButtonContainer>
                ) : null}
              </StyledStepContainer>
            </React.Fragment>
          );
        })}
      </StyledStepsOuterContainer>
    </StyledClaimsTimelineContainer>
  ) : (
    <StyledNoTimelineSpacing />
  );
};

export default ClaimsTimeline;
