import {
  DownloadPill,
  LoopButton,
  Tooltip,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useState } from 'react';
import {
  ArrowDownDisabled,
  ArrowDownEmerald,
  NoteIcon,
} from '../../../../assets/images';
import { IPolicyCoverageDocumentsProps } from '../types';
import {
  StyledPolicyCoverageDocuments,
  StyledPolicyCoverageDocumentsModal,
  StyledPolicyCoverageDocumentsText,
} from './styles';
import { downloadPolicyDocumentFile } from './utils';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../utils/segment/utils';
import { toast } from 'react-toastify';

const PolicyCoverageDocuments = ({
  id,
  policyName,
  policyDocuments,
}: IPolicyCoverageDocumentsProps) => {
  const trackClick = useSegment('click');
  const [isVisible, setIsVisible] = useState(false);
  const [downloadingIndex, setDownloadingIndex] = useState<number>();

  const isDownloadEnabled = policyDocuments.length > 0;
  const documents = policyDocuments.map((document, index) => ({
    label: document,
    onDownload: () => downloadDocument(document, index),
  }));

  const downloadDocument = async (
    documentIdentifier: string,
    index: number,
  ) => {
    setDownloadingIndex(index);

    try {
      await downloadPolicyDocumentFile(id, documentIdentifier);
    } catch (error) {
      toast.error((error as Error)?.message);
    }

    trackClick(
      trackClickEvent(
        `Download_Policy_Document_CTA_${policyName ?? id}`,
        window.location?.pathname,
        extractPathName(window.location?.pathname),
        {
          clickProperties: {},
          ...{
            documentDownloaded: documentIdentifier,
          },
        },
      ),
    );
    setDownloadingIndex(undefined);
  };

  const toggleVisible = () =>
    isDownloadEnabled && setIsVisible((prev) => !prev);

  return (
    <StyledPolicyCoverageDocuments>
      <StyledPolicyCoverageDocumentsText>
        <img src={NoteIcon} />
        <Typography variant="small" weight="medium">
          Policy Documents
        </Typography>
      </StyledPolicyCoverageDocumentsText>
      {isDownloadEnabled ? (
        <LoopButton
          variant="secondary"
          iconSrc={ArrowDownEmerald}
          iconOrder="right"
          size="small"
          onClick={toggleVisible}
        >
          Download
        </LoopButton>
      ) : (
        <Tooltip
          beak="top-center"
          type="informative"
          text="Policy copy and other policy related documents will be available 30-35 days from policy start date."
        >
          <LoopButton
            variant="disabled"
            iconSrc={ArrowDownDisabled}
            iconOrder="right"
            size="small"
            onClick={toggleVisible}
          >
            Download
          </LoopButton>
        </Tooltip>
      )}
      {isVisible && (
        <StyledPolicyCoverageDocumentsModal>
          <DownloadPill
            documents={documents}
            onClose={toggleVisible}
            title="Download Policy Documents"
            loadingIndex={downloadingIndex}
          />
        </StyledPolicyCoverageDocumentsModal>
      )}
    </StyledPolicyCoverageDocuments>
  );
};

export default PolicyCoverageDocuments;
