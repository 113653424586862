import { LoopBadge, LoopButton } from '@loophealth/loop-ui-web-library';
import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import { ITableHeader } from '@loophealth/loop-ui-web-library/dist/types/atoms/Table/types';
import { EditOutlinedEmeraldIcon } from '../../../../assets/images';
import { ISearchResultResponseUserType } from '../../TopNavigation/types';
import { StyledCapitalizedWords } from '../../ValidationsScreen/styles';
import { StyledNameColumnWrapper } from './styles';
import { formatMemberName } from '../../../../utils/common/common';

export const convertSearchResultsToDropdown = (
  searchResults: ISearchResultResponseUserType[],
): IDropdownItem[] => {
  return searchResults
    .map((result) => {
      const name = formatMemberName(result.firstName, result.lastName);
      return {
        name,
        value: result.userId,
        data: {
          name,
          employeeId: result.employeeId,
          isEditable: result.isEditable,
          isExpired: !result.userPolicies?.length,
          isDeleting: result.deletionInProgress,
          memberType:
            result.relationship?.toLowerCase() === 'self'
              ? 'EMPLOYEES'
              : 'DEPENDANTS',
        },
      };
    })
    .sort((a, b) =>
      a.data.memberType === 'EMPLOYEES'
        ? -1
        : b.data.memberType === 'EMPLOYEES'
        ? 1
        : 0,
    );
};

export const getEmpDependentsTableColumns = (
  handleSelectUserForEdit: (
    searchResult: ISearchResultResponseUserType,
  ) => void,
): ITableHeader<ISearchResultResponseUserType>[] => [
  {
    name: 'Employee ID',
    key: 'employeeId',
    width: 200,
  },
  {
    name: 'Name',
    key: 'firstName',
    width: 200,
    cell: (_rowValue, _index, rowData) => (
      <StyledNameColumnWrapper>
        <StyledCapitalizedWords>
          {formatMemberName(rowData.firstName, rowData.lastName)}
        </StyledCapitalizedWords>
        {!rowData.userPolicies?.length ? (
          <LoopBadge
            variant="overflow"
            badgeType="errorFill"
            text={
              rowData.deletionInProgress
                ? 'Deletion In Progress'
                : 'Deleted Member'
            }
            size="small"
          />
        ) : !rowData.isEditable ? (
          <LoopBadge
            variant="overflow"
            badgeType="errorFill"
            text="Endorsement In Progress"
            size="small"
          />
        ) : null}
      </StyledNameColumnWrapper>
    ),
  },
  {
    name: 'Relationship to Employee',
    key: 'relationship',
    width: 250,
    cell: (rowValue) => (
      <StyledCapitalizedWords>{rowValue as string}</StyledCapitalizedWords>
    ),
  },
  {
    name: 'Action',
    key: 'userPolicies',
    width: 120,
    cell: (_rowValue, _index, rowData) =>
      rowData.isEditable && rowData.userPolicies?.length ? (
        <LoopButton
          variant="text"
          onClick={() => handleSelectUserForEdit(rowData)}
          iconSrc={EditOutlinedEmeraldIcon}
        >
          Edit
        </LoopButton>
      ) : null,
  },
];
