import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { IFeedbackChildItemProps } from './types';
import {
  EmojiContainer,
  FeedbackChildItemBody,
  FeedbackChildItemContainer,
  FeedbackChildItemHeader,
  FeedbackChildItemHeaderTitle,
  FeedbackGiven,
  FeedbackTextArea,
  IconContainer,
  SelectedEmoji,
  SingleEmoji,
  SingleEmojiLabel,
  SingleEmojiWrapper,
  StyledWellnessButtons,
} from './styles';
import { Chip, Typography } from '@loophealth/loop-ui-web-library';
import { IFormdataKey } from '../FeedbackSidebar/types';
import {
  FeedbackQnANames,
  SELECTED_EMOJI_INDEXED_ARRAY,
  emojiData,
} from './constants';

const FeedbackChildItem: React.FC<IFeedbackChildItemProps> = ({
  formData,
  setFormData,
  name,
  label,
  icon,
  question,
}) => {
  const currentValue = formData[name as IFormdataKey];
  const isFeedbackGiven = currentValue !== 0;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isEmojipanelOpen, setIsEmojipanelOpen] = useState<boolean>(
    !FeedbackQnANames.includes(name),
  );
  const [qnaAnswered, setQnaAnswered] = useState<boolean>(false);

  useEffect(() => {
    if (currentValue === 0) {
      setIsExpanded(true);
      setIsEmojipanelOpen(!FeedbackQnANames.includes(name));
      setQnaAnswered(false);
    }
  }, [currentValue, name]);

  const handleFeedbackChange = (value: number) => {
    if (value === currentValue) {
      setIsExpanded(false);
    } else {
      setTimeout(() => {
        setIsExpanded(false);
      }, 1800);
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const handleShowOptions = (show: boolean, isActive: boolean) => {
    if (isActive) return;
    if (!show) {
      setFormData({
        ...formData,
        wellnessServices: 5,
      });
      setTimeout(() => {
        setIsExpanded(false);
      }, 1800);
    } else {
      setFormData({
        ...formData,
        wellnessServices: 6,
      });
    }
    setIsEmojipanelOpen(show);
    setQnaAnswered(true);
  };

  const renderSelectedEmoji = useMemo(() => {
    if (name === 'feedbackOrSuggestions') return null;
    return (
      <>
        {SELECTED_EMOJI_INDEXED_ARRAY[currentValue as number].icon && (
          <SelectedEmoji
            src={SELECTED_EMOJI_INDEXED_ARRAY[currentValue as number].icon}
            alt="Terrible"
          />
        )}
        <div>{SELECTED_EMOJI_INDEXED_ARRAY[currentValue as number].label}</div>
      </>
    );
  }, [currentValue, name]);

  if (name === 'feedbackOrSuggestions') {
    return (
      <FeedbackTextArea
        placeholder="Any other feedback or suggestions"
        value={formData.feedbackOrSuggestions}
        rows={4}
        onChange={handleTextChange}
      />
    );
  }

  return (
    <FeedbackChildItemContainer>
      <FeedbackChildItemHeader
        onClick={() => setIsExpanded(true)}
        isExpanded={isExpanded}
      >
        <FeedbackChildItemHeaderTitle>
          <IconContainer src={icon} />
          <div>{label}</div>
        </FeedbackChildItemHeaderTitle>
        {isFeedbackGiven && !isExpanded ? (
          <FeedbackGiven>{renderSelectedEmoji}</FeedbackGiven>
        ) : null}
      </FeedbackChildItemHeader>
      <FeedbackChildItemBody
        isExpanded={isExpanded}
        hasQnA={FeedbackQnANames.includes(name)}
        emojiPanelOpen={isEmojipanelOpen}
      >
        <Typography variant="small" color="secondary" weight="regular">
          {question}
        </Typography>
        {FeedbackQnANames.includes(name) && (
          <StyledWellnessButtons>
            <Chip
              label="Yes"
              isActive={qnaAnswered && isEmojipanelOpen}
              onClick={() =>
                handleShowOptions(true, qnaAnswered && isEmojipanelOpen)
              }
            />
            <Chip
              label="No"
              isActive={qnaAnswered && !isEmojipanelOpen}
              variant="error"
              onClick={() =>
                handleShowOptions(false, qnaAnswered && !isEmojipanelOpen)
              }
            />
          </StyledWellnessButtons>
        )}
        {isEmojipanelOpen && (
          <EmojiContainer>
            {emojiData.map(({ value, gif, icon, label }) => (
              <SingleEmojiWrapper
                key={value}
                onClick={() => handleFeedbackChange(value)}
              >
                <SingleEmoji
                  src={currentValue === value ? gif : icon}
                ></SingleEmoji>
                <SingleEmojiLabel isSelected={currentValue === value}>
                  {label}
                </SingleEmojiLabel>
              </SingleEmojiWrapper>
            ))}
          </EmojiContainer>
        )}
      </FeedbackChildItemBody>
    </FeedbackChildItemContainer>
  );
};

export default FeedbackChildItem;
