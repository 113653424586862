import styled from 'styled-components';

export const StyledEmployeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 12px;
`;
export const StyledEmployeeFormContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const StyledEmployeeFormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 30%;
`;
export const StyledChipContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
