import styled from 'styled-components';

export const StyledSubtitleContainer = styled.div`
  gap: 6px;
  display: flex;
  align-items: center;
`;

export const StyledControls = styled.div`
  gap: 40px;
  display: flex;
  flex-direction: column;
`;

export const StyledOTPContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const StyledLabelledButtonContainer = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const StyledButtonContainer = styled.div`
  max-width: fit-content;
`;

export const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.seafoamGray};
`;

export const StyledHeaderTitle = styled.div`
  gap: 26px;
  width: 100%;
  display: flex;
  align-items: center;
`;
