import {
  Dialog,
  Form,
  LoopBadge,
  LoopButton,
  Modal,
  Toast,
  Typography,
  UploadPillSelection,
} from '@loophealth/loop-ui-web-library';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  BookmarkEmeraldIcon,
  TrashErrorIcon,
} from '../../../../../assets/images';
import { IEditedField } from '../../../../pages/Endorsements/BulkEditMembers/types';
import { StyledFitContentWrapper } from '../../../EmpPolicies/styles';
import {
  StyledDocumentItem,
  StyledDocumentsList,
  StyledFieldBody,
  StyledFieldBodyContainer,
  StyledFieldButton,
  StyledFieldContainer,
  StyledFieldDocumentsWrapper,
  StyledFieldHeaderContainer,
  StyledFieldBadgeContainer,
  StyledFileSelectionContainer,
  StyledFileSelectionFooter,
  StyledFieldEditContainer,
} from '../styles';
import { capitalizeFirstLetter } from '../../../../../utils/common/Utilities';
import { IEditFieldWithFirstName } from '../types';
import { openFileInNewTab } from '../../../../../utils/common/common';

const NameEditContainer: React.FC<IEditFieldWithFirstName> = ({
  handleEditFieldSave,
  firstName,
  isBeingEdited,
  oldValue,
  edit,
  setSelectedField,
  updateEditedField,
  validateFieldSave,
  commonDoc,
  isDocumentRequired,
}) => {
  const toast = Toast.useToast();
  const [value, setValue] = useState(oldValue);
  const [selectedFiles, setSelectedFiles] = useState<Record<string, unknown>[]>(
    [],
  );
  const [deleteFileIndex, setDeleteFileIndex] = useState<number>();
  const [fieldError, setFieldError] = useState<string>('');
  const [isLoading, toggleLoading] = useState(false);

  const handleEditAndCancel = () => {
    setSelectedField(isBeingEdited ? null : 'name');
    setFieldError('');
    setValue(edit?.after || oldValue);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldError('');
    setValue(event.target.value);
  };

  const handleSave = async () => {
    setFieldError('');
    toggleLoading(true);
    const error = await validateFieldSave('name', value);
    if (error) {
      setFieldError(error);
      toggleLoading(false);
      return;
    }
    handleEditFieldSave('name');

    const newEdit: IEditedField = {
      ...edit,
      field: 'name',
      before: oldValue,
      after: value,
      documents: selectedFiles as unknown as File[],
    };
    updateEditedField(newEdit);
    setSelectedField(null);
    toggleLoading(false);
  };

  const confirmDocumentDelete = () => {
    setSelectedFiles([]);
    setDeleteFileIndex(undefined);
  };

  useEffect(() => {
    if (selectedFiles.length) return;
    if (edit?.documents.length) {
      setSelectedFiles(edit.documents as unknown as Record<string, unknown>[]);
    } else if (commonDoc) {
      setSelectedFiles([commonDoc as unknown as Record<string, unknown>]);
    }
  }, [commonDoc]);

  const saveEnabled =
    value &&
    oldValue !== value &&
    (!isDocumentRequired || selectedFiles.length) &&
    !fieldError;

  return (
    <StyledFieldContainer>
      <StyledFieldHeaderContainer>
        <StyledFieldBadgeContainer>
          <Typography variant="medium" weight="medium">
            Name
          </Typography>
          {edit ? (
            <LoopBadge
              variant="overflow"
              text="Edited"
              badgeType="success3"
              size="small"
            />
          ) : null}
        </StyledFieldBadgeContainer>
        <StyledFieldButton>
          <LoopButton variant="text" size="large" onClick={handleEditAndCancel}>
            {isBeingEdited ? 'Cancel' : 'Edit'}
          </LoopButton>
        </StyledFieldButton>
      </StyledFieldHeaderContainer>
      <StyledFieldBodyContainer>
        {isBeingEdited ? (
          <StyledFieldBody>
            <StyledFieldEditContainer>
              <Form.Input
                value={value}
                onChange={handleChange}
                error={fieldError}
              />
              {isDocumentRequired && (
                <StyledFileSelectionContainer $errored={!!fieldError}>
                  <UploadPillSelection
                    onUpload={setSelectedFiles}
                    formats={['.png', '.jpg', '.jpeg', '.pdf']}
                    selected={selectedFiles}
                    maxFiles={1}
                    maxSize={25000000}
                    displayTooltip={false}
                    onError={(e) => toast?.error(e.message)}
                    onDelete={() => setDeleteFileIndex(0)}
                    variant="inline"
                    title="Upload KYC Document"
                    subtitle={`Just upload any one of the ${capitalizeFirstLetter(
                      firstName,
                    )}'s documents: Passport, Driving licence, Aadhaar card, Voter ID, or PAN card.`}
                  />
                  <StyledFileSelectionFooter>
                    <Typography variant="extraSmall" color="secondary">
                      Supported Formats: PNG, JPG, PDF
                    </Typography>
                    <Typography variant="extraSmall" color="secondary">
                      Maximum Size: 25MB
                    </Typography>
                  </StyledFileSelectionFooter>
                </StyledFileSelectionContainer>
              )}
            </StyledFieldEditContainer>
            <StyledFitContentWrapper>
              <LoopButton
                variant={saveEnabled ? 'filled' : 'disabled'}
                onClick={handleSave}
                isLoading={isLoading}
              >
                Save
              </LoopButton>
            </StyledFitContentWrapper>
          </StyledFieldBody>
        ) : (
          <StyledFieldDocumentsWrapper>
            <Typography variant="medium" color="secondary">
              {edit?.after || oldValue}
            </Typography>
            {edit?.documents?.length ? (
              <StyledDocumentsList>
                {edit.documents.map((document) => (
                  <StyledDocumentItem
                    key={document.name}
                    onClick={() => openFileInNewTab(document)}
                  >
                    <img src={BookmarkEmeraldIcon} alt="document-icon" />
                    <Typography variant="small">{document.name}</Typography>
                  </StyledDocumentItem>
                ))}
              </StyledDocumentsList>
            ) : null}
          </StyledFieldDocumentsWrapper>
        )}
      </StyledFieldBodyContainer>
      <Modal
        isVisible={deleteFileIndex !== undefined}
        setIsVisible={() => setDeleteFileIndex(undefined)}
      >
        <Dialog
          variant="vertical"
          title={`Deleting “${selectedFiles[deleteFileIndex!]?.name}”?`}
          description="Are you sure you want to delete this file? You’ll have to re-upload it."
          icon={TrashErrorIcon}
          primaryButtonText="Yes, Delete"
          onPrimaryButtonClicked={confirmDocumentDelete}
          onSecondaryButtonClicked={() => setDeleteFileIndex(undefined)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
    </StyledFieldContainer>
  );
};

export default NameEditContainer;
