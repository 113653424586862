import styled from 'styled-components';

export const StyledReimbursementClaimsSection = styled.section`
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledReimbursementClaimsCard = styled.div`
  display: flex;
`;
