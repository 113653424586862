export const FEEDBACK_VALUE = [
  '',
  'Terrible',
  'Bad',
  'Good',
  'Excellent',
  'NA',
  'NA',
];

export const initialFormData = {
  claimsAndSupport: 0,
  endorsements: 0,
  medicalAdvisors: 0,
  wellnessServices: 0,
  csm: 0,
  loophealth: 0,
  feedbackOrSuggestions: '',
};
