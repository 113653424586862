import {
  CardIcon,
  LoopBadge,
  LoopButton,
  Typography,
} from '@loophealth/loop-ui-web-library';
import {
  capitalizeFirstLetterOfEachWord,
  getDisplayTextFromUpper,
} from '../../../utils/common/Utilities';
import { DownloadIcon } from '../../../assets/images';
import {
  StyledFitContentWrapper,
  StyledIconAndNameContainer,
  StyledPolicyOverviewContainer,
  StyledTrWrapper,
  StyledWhiteSpaceWrapper,
} from './styles';

import { IPolicyTypes } from '../../../utils/constants';
import { IPolicyInformation } from '../EmpDependents/types';
import { ECardInfo } from '../../pages/EmployeeDetails/types';
import useECardDownload from '../../pages/EmployeeDetails/hooks/useDownloadECard';
import {
  InsuranceStatusValues,
  getBadgeType,
} from '../../pages/EmployeeDetails/utils';
import { BadgeContainer } from '../EmpDependents/styles';

export const getEmpPolTableProcessor = (name: string) => ({
  'Policy Name': {
    cell: function <T>(value: T) {
      const typedValue = value as unknown as IPolicyInformation;
      const policyName =
        IPolicyTypes[typedValue.policyType as keyof typeof IPolicyTypes];
      return (
        <StyledIconAndNameContainer>
          <CardIcon imageUrl={typedValue.policyImage as string}></CardIcon>
          <StyledPolicyOverviewContainer>
            <Typography variant="small" weight="medium" color="primary">
              {policyName}
            </Typography>
            <Typography variant="extraSmall" color="secondary">
              {typedValue.insurerName as string}
            </Typography>
          </StyledPolicyOverviewContainer>
        </StyledIconAndNameContainer>
      );
    },
  },
  'Allowed Family': {
    cell: function <T>(value: T) {
      return (
        <StyledTrWrapper>
          <Typography color="primary" variant="small">
            {value as string}
          </Typography>
        </StyledTrWrapper>
      );
    },
  },
  'Insurance Status': {
    cell: function <T>(value: T) {
      const badgeType = getBadgeType(value as InsuranceStatusValues);
      return (
        <>
          {value ? (
            <BadgeContainer>
              <LoopBadge
                badgeType={badgeType}
                variant={'overflow'}
                text={capitalizeFirstLetterOfEachWord(
                  getDisplayTextFromUpper(value as string),
                )}
                fullWidth
              />
            </BadgeContainer>
          ) : null}
        </>
      );
    },
  },
  'E-Card': {
    cell: function <T>(value: T, _: number, data: Record<string, unknown>) {
      const typedValue = value as unknown as ECardInfo;
      if (typedValue.uhid) {
        const { startDownload, isDownloading } =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useECardDownload(
            name,
            (data.policyNumber ?? '') as string,
            typedValue.userId as string,
            typedValue.policyId as string,
          );
        return (
          <StyledFitContentWrapper>
            <LoopButton
              variant={isDownloading ? 'disabled' : 'secondary'}
              onClick={startDownload}
              isLoading={isDownloading ? true : false}
              size="small"
              iconSrc={DownloadIcon}
              iconOrder="left"
            >
              Download
            </LoopButton>
          </StyledFitContentWrapper>
        );
      } else {
        return (
          <StyledWhiteSpaceWrapper>
            <Typography color="primary" variant="small">
              {`E-card not available for ${typedValue.policyType}`}
            </Typography>
          </StyledWhiteSpaceWrapper>
        );
      }
    },
  },
});
