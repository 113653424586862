import styled from 'styled-components';

export const StyledValidationsScreen = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  background-color: ${(p) => p.theme.colors.white};
  width: 100%;
`;

export const StyledSummaryCards = styled.div`
  display: flex;
  padding: 0 48px;
  gap: 24px;
  justify-content: center;
`;

export const StyledValidationSummaryHeader = styled.div`
  padding: 48px 48px 20px 48px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledHowToFixModal = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHowToFixModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  border-bottom: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.surface0};
  padding: 28px 24px;
  gap: 40px;
`;

export const StyledStepItem = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledStepItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledStepItemIcon = styled.div<{$isLast: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.supportingPlaneGreenDark};
  align-self: flex-start;
  position: relative;

  &::after {
    width: 1px;
    background-color: ${(p) => p.theme.colors.seafoamGray};
    content: 'a';
    color: transparent;
    position: absolute;
    top: calc(100% + 1px);
    height: 58px;
    display: ${(p) => p.$isLast? 'none': 'unset'};
  }
`;

export const StyledHowToFixModalFooter = styled.div`
  padding: 20px 40px 24px;
`;

export const StyledErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledChipsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledCapitalizedWords = styled.div`
  text-transform: capitalize;
`;