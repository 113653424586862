import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { useState } from 'react';
import {
  ArrowDownEmerald,
  ArrowUpEmerald,
  CheckIcon,
  CrossIcon,
  StarFourIcon,
} from '../../../../assets/images';
import { IPolicyCoverageIncExc } from '../types';
import PolicyCoverageIncExcItem from './PolicyCoverageIncExcItem';
import {
  SryledPolicyIncExcBodySeperator,
  StyledPolicyCoverageIncExc,
  StyledPolicyIncExcBody,
  StyledPolicyIncExcBodyItem,
  StyledPolicyIncExcBodyItemHeader,
  StyledPolicyIncExcExpansion,
  StyledPolicyIncExcHeader,
  StyledPolicyIncExcHeaderTextContainer,
  StyledPolicyIncExcList,
  StyledPolicyIncExcTitleIcon,
  StyledStarIcon,
} from './styles';
import { classifyPolicyTerms } from './utils';

const PolicyCoverageIncExc = ({ terms, policyType }: IPolicyCoverageIncExc) => {
  const { inclusion = [], exclusion = [] } = classifyPolicyTerms(
    terms,
    policyType,
  );

  return (
    <StyledPolicyCoverageIncExc>
      <StyledPolicyIncExcHeader>
        <StyledStarIcon src={StarFourIcon} />
        <StyledPolicyIncExcHeaderTextContainer>
          <Typography variant="medium" weight="semiBold">
            Inclusions and Exclusions
          </Typography>
          <Typography variant="small" color="secondary">
            For a detailed list please refer to the policy copy
          </Typography>
        </StyledPolicyIncExcHeaderTextContainer>
      </StyledPolicyIncExcHeader>
      <StyledPolicyIncExcExpansion>
        <StyledPolicyIncExcBody>
          <StyledPolicyIncExcBodyItem>
            <StyledPolicyIncExcBodyItemHeader>
              <StyledPolicyIncExcTitleIcon src={CheckIcon} />
              <Typography variant="small" weight="semiBold">
                Inclusions
              </Typography>
            </StyledPolicyIncExcBodyItemHeader>
            {inclusion.length ? (
              <StyledPolicyIncExcList>
                {inclusion.map((term) => (
                  <PolicyCoverageIncExcItem key={term.label} term={term} />
                ))}
              </StyledPolicyIncExcList>
            ) : (
              <Typography variant="small">No inclusions to show</Typography>
            )}
          </StyledPolicyIncExcBodyItem>
          <SryledPolicyIncExcBodySeperator />
          <StyledPolicyIncExcBodyItem>
            <StyledPolicyIncExcBodyItemHeader>
              <StyledPolicyIncExcTitleIcon src={CrossIcon} />
              <Typography variant="small" weight="semiBold">
                Exclusions
              </Typography>
            </StyledPolicyIncExcBodyItemHeader>
            {exclusion.length ? (
              <StyledPolicyIncExcList>
                {exclusion.map((term) => (
                  <PolicyCoverageIncExcItem
                    key={term.label}
                    isExclusion={true}
                    term={term}
                  />
                ))}
              </StyledPolicyIncExcList>
            ) : (
              <Typography variant="small">No exclusions to show</Typography>
            )}
          </StyledPolicyIncExcBodyItem>
        </StyledPolicyIncExcBody>
      </StyledPolicyIncExcExpansion>
    </StyledPolicyCoverageIncExc>
  );
};

export default PolicyCoverageIncExc;
