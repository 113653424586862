import React, { FC, useState } from 'react';
import Chatbot from 'react-chatbot-kit';
import { ChatbotIcon } from '../../../assets/images';
import ChatbotGreetingCard from '../../atoms/ChatbotElements/ChatbotGreetingCard';
import {
  ChatbotIconContainer,
  ChatbotModalContainer,
  StyledChatbotIcon,
} from './styles';
import { IChatbotComponentProps } from './types';

const ChatbotComponent: FC<IChatbotComponentProps> = ({
  config,
  MessageParser,
  ActionProvider,
  greetingMessage,
  headerText,
  placeholderText
}) => {
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const [isGreetingVisible, setIsGreetingVisible] = useState(true);

  const toggleChatbot = () => {
    setIsChatbotVisible(!isChatbotVisible);
  };

  const validator = (input: string) => {
    if (input?.toString().trim().length > 0) return true;
    return false;
  };

  return (
    <>
      <ChatbotGreetingCard
        message={greetingMessage}
        isVisible={isGreetingVisible && !isChatbotVisible}
        setIsVisible={setIsGreetingVisible}
      />
      <ChatbotIconContainer onClick={toggleChatbot}>
        <StyledChatbotIcon src={ChatbotIcon} />
      </ChatbotIconContainer>

      {isChatbotVisible && (
        <ChatbotModalContainer>
          <Chatbot
            config={config(toggleChatbot)}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            headerText={headerText}
            placeholderText={placeholderText}
            validator={validator}
          />
        </ChatbotModalContainer>
      )}
    </>
  );
};

export default ChatbotComponent;
