import React from 'react';
import {
  StyledContainer,
  StyledHeaderRow,
  StyledTitleContainer,
  HorizontalSeparator,
  StyledWrapper,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { IEndoSummaryCountComponentProps } from './types';

const EndoSummaryCountComponent: React.FC<IEndoSummaryCountComponentProps> = ({
  title,
  icon,
  totalCount,
  details,
  headerLabel,
}) => {
  return (
    <StyledWrapper>
      <StyledHeaderRow>
        <StyledTitleContainer>
          <img src={icon} alt="summary" />
          <Typography variant="medium">{title}</Typography>
        </StyledTitleContainer>
      </StyledHeaderRow>
      <StyledContainer>
        <StyledHeaderRow>
          <StyledTitleContainer>
            <Typography variant="extraSmall" color="secondary">
              {headerLabel}
            </Typography>
          </StyledTitleContainer>
          <Typography variant="medium" weight="medium">
            {totalCount}
          </Typography>
        </StyledHeaderRow>
        <HorizontalSeparator />
        {Object.keys(details).map((dataKey, index) => (
          <StyledHeaderRow key={index}>
            <Typography variant="extraSmall" color="secondary">
              {dataKey}
            </Typography>
            <Typography variant="small" weight="semiBold">
              {details[dataKey]}
            </Typography>
          </StyledHeaderRow>
        ))}
      </StyledContainer>
    </StyledWrapper>
  );
};

export default EndoSummaryCountComponent;
