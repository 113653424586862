import styled from 'styled-components';

export const StylesHorizontalInfoCard = styled.div`
  flex: 1;
  gap: 28px;
  width: 100%;
  max-height: 92px;
  display: flex;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.surfaceOne};
`;

export const StyledIconContainer = styled.aside`
  border-radius: 8px 0 0 8px;
  width: 80px;
  max-height: 100%;
  display: grid;
  place-items: center;
  background-color: ${({ theme }) => theme.colors.mintFrost};
`;

export const StyledIcon = styled.img`
  width: 46px;
  height: 46px;
  object-fit: contain;
`;

export const StyledInformationContainer = styled.div`
  display: flex;
  padding: 18px 0;
  align-items: center;
`;

export const StyledInformationContainerDivider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 32px;
  background-color: ${({ theme }) => theme.colors.seafoamGray};
`;

export const StyledInformationItem = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: column;
`;

export const StyledInformationItemTitle = styled.div`
  gap: 4px;
  display: flex;
  align-items: baseline;
`;

export const StyledInformationItemTooltipIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;
