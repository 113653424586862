import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';
import { nanoid } from 'nanoid';
import getTokenHelper from './hooks/useToken';
import * as Sentry from '@sentry/react';
import { IMethod, ISuccessResponse } from './types';
import store from '../redux/store';
import { selectIsDemoMode } from '../redux/slices/hrdRevampRedux';
import { accessDemoAPI, isDemoEnabledForURL } from '../utils/demo';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appVerificationDisabledForTesting:
    process.env.REACT_APP_DISABLED_FIREBASE_CAPTCHA,
};

const authToken = getTokenHelper();
let FirebaseProvider;
if (firebase.apps.length === 0) {
  FirebaseProvider = firebase.initializeApp(firebaseConfig);
} else {
  FirebaseProvider = firebase.app();
}
const appVerificationDisabledForTesting =
  process.env.REACT_APP_DISABLED_FIREBASE_CAPTCHA === 'true' ? true : false;
if (appVerificationDisabledForTesting) {
  firebase.auth().settings.appVerificationDisabledForTesting = true;
}
export const Firestore = FirebaseProvider.firestore();
export const FirebaseStorage = FirebaseProvider.storage();
export const FirebaseFunctions = FirebaseProvider.functions('asia-south1');
export const FirebaseCaptcha = firebase.auth;
export const FirebaseAuth = firebase.auth();
export const FirebaseTimestamp = firebase.firestore.Timestamp;
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export default FirebaseProvider;

export const getContentType = (res: Response): string => {
  const isJSON =
    res.headers.get('Content-Type')?.startsWith('application/json') || false;

  if (isJSON) {
    return 'JSON';
  }

  const isText = res.headers.get('Content-Type')?.startsWith('text') || false;
  if (isText) {
    return 'Text';
  }

  return 'Unsupported';
};

export const processResponse = async <ResData>(
  res: Response,
): Promise<ResData> => {
  const contentType = getContentType(res);

  if (res.ok) {
    if (contentType === 'JSON') {
      return (await res.json()) as ResData;
    }
  }
  return res as ResData;
};

export const accessAPI = async <ReqData, ResData>(
  method: IMethod,
  url: string,
  body?: ReqData,
): Promise<ResData> => {
  const isDemoMode = selectIsDemoMode(store.getState());
  if (isDemoMode && isDemoEnabledForURL(method, url)) {
    return await accessDemoAPI(method, url, body);
  }
  try {
    let baseUrl = process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL;
    const token = await authToken.generateToken();

    const isFormdata = body instanceof FormData;
    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
      'loop-source': process.env.REACT_APP_NAME ?? '',
      'trace-id': nanoid(),
    };
    if (!isFormdata) {
      headers['Content-Type'] = 'application/json';
    }

    const result = await fetch(baseUrl + url, {
      method: method,
      body: isFormdata ? body : JSON.stringify(body),
      headers: headers,
      keepalive: isFormdata,
    });
    if (result.ok) {
      return await processResponse<ResData>(result);
    } else {
      const errorMessage = (await result.json()).message;
      // if result is such tht token is expired then process new token
      if (authToken.tokenIsExpired(errorMessage)) {
        try {
          const isTokenAvailable = await authToken.generateToken();
          if (isTokenAvailable) {
            return await accessAPI(method, url, body);
          }
        } catch (e) {
          throw new Error('error occurred while generating token', { cause: e });
        }
      }
      if (Array.isArray(errorMessage)) {
        throw new Error(JSON.stringify(errorMessage));
      }
      throw new Error(errorMessage);
    }
  } catch (e) {
    throw e;
  }
};

export const get = async <ResData = ISuccessResponse<Record<string, unknown>>>(
  url: string,
): Promise<ResData> => {
  try {
    return await accessAPI('GET', url);
  } catch (e) {
    Sentry.captureException(new Error('error occurred in get call', {
      cause: e
    }));

    throw e;
  }
};

export const post = async <
  ResData = ISuccessResponse<null>,
  ReqData = Record<string, unknown>,
>(
  url: string,
  body: ReqData,
): Promise<ResData> => {
  try {
    return await accessAPI<ReqData, ResData>('POST', url, body);
  } catch (e) {
    Sentry.captureException(new Error('error occurred in post call', {
      cause: e
    }));

    throw e;
  }
};

export const put = async <
  ResData = ISuccessResponse<null>,
  ReqData = Record<string, unknown>,
>(
  url: string,
  body: ReqData,
): Promise<ResData> => {
  try {
    return await accessAPI('PUT', url, body);
  } catch (e) {
    Sentry.captureException(new Error('error occurred in put call', {
      cause: e
    }));

    throw e;
  }
};

export const del = async <
  ResData = ISuccessResponse<null>,
  ReqData = Record<string, unknown>,
>(
  url: string,
  body?: ReqData,
): Promise<ResData> => {
  try {
    return await accessAPI('DELETE', url, body);
  } catch (e) {
    Sentry.captureException(new Error('error occurred in delete call', {
      cause: e
    }));

    throw e;
  }
};
