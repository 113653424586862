import { createAsyncThunk } from '@reduxjs/toolkit';
import LoopApiService from '../../../adaptars/LoopApiService';
import { IFetchHrUser, IUpdateHrUser } from './types';
import { HRD_PERMISSION } from '../../../utils/constants';
import { formatMemberName } from '../../../utils/common/common';

export const fetchHrUser = createAsyncThunk(
  'userdata/fetchHrUser',
  async (
    { userId, methodType, loginMethod }: IFetchHrUser,
    { rejectWithValue },
  ) => {
    const result = await LoopApiService.getHRDetails(
      userId,
      methodType,
      loginMethod,
    );

    if (
      !(result.iamPermission as string[]).includes(HRD_PERMISSION as string)
    ) {
      throw rejectWithValue({
        error: {
          loginMethod,
          message: userId,
        },
      });
    }
    const company = await LoopApiService.getCompanyById(
      result.employer as string,
    );

    return {
      data: {
        id: result?.employeeId,
        name: formatMemberName(result?.firstName, result?.lastName),
        email: result?.email,
        mobile: result?.mobile,
        userId: result?.userId,
        companyId: result?.employer,
        companyName: company?.company?.name,
      },
      methodType,
      loginMethod,
    };
  },
);

export const fetchHrUserVerify = createAsyncThunk(
  'userdata/fetchHrUserVerify',
  async ({ userId, methodType, loginMethod }: IFetchHrUser) => {
    const result = await LoopApiService.getHRDetails(
      userId,
      methodType,
      loginMethod,
    );
    return {
      data: result,
      methodType,
      loginMethod,
    };
  },
);

export const updateHrData = createAsyncThunk(
  'userdata/updateHrData',
  async (data: IUpdateHrUser) => {
    return await LoopApiService.updateHrData(data);
  },
);
