import { Card, Typography } from '@loophealth/loop-ui-web-library';
import React, { FC } from 'react';
import {
  StyledZeroCardSubtitle,
  StyledZeroCardSubtitleNote,
  StyledZeroCardSubtitleNoteIcon,
  StyledZeroStateCard,
  StyledZeroStateCardContent,
  StyledZeroStateCardImage,
  StyledZeroStateCardImageContainer,
} from './styles';
import { IZeroStateCardProps } from './types';
import { GreyInfoIcon } from '../../../assets/images';

const ZeroStateCard: FC<IZeroStateCardProps> = ({
  title,
  subtitle,
  image,
  note,
  noteType = 'note',
}) => {
  return (
    <Card>
      <StyledZeroStateCard>
        <StyledZeroStateCardContent>
          <Typography variant="medium" weight="semiBold" color="emerald">
            {title}
          </Typography>
          <StyledZeroCardSubtitle>
            <Typography variant="small">{subtitle}</Typography>
            {note && (
              <StyledZeroCardSubtitleNote>
                {noteType === 'info' ? (
                  <StyledZeroCardSubtitleNoteIcon src={GreyInfoIcon} />
                ) : null}
                <Typography
                  variant="extraSmall"
                  color={noteType === 'error' ? 'error' : 'secondary'}
                  weight={noteType === 'error' ? 'semiBold' : 'medium'}
                >
                  {note}
                </Typography>
              </StyledZeroCardSubtitleNote>
            )}
          </StyledZeroCardSubtitle>
        </StyledZeroStateCardContent>

        <StyledZeroStateCardImageContainer data-testid="graphic">
          <StyledZeroStateCardImage src={image} />
        </StyledZeroStateCardImageContainer>
      </StyledZeroStateCard>
    </Card>
  );
};

export default ZeroStateCard;
