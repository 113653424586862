import { FirebaseAuth } from '../providers';
import {useHistory} from "react-router-dom";

export interface IToken {
  generateToken(): Promise<string>;
  tokenIsExpired(err: string): boolean;
}

const useToken = (): IToken => {
  const generateToken = async (): Promise<string> => {
    return await new Promise((resolve, reject) => {
      FirebaseAuth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken();
          resolve(token);
        }else{

        }
        reject(new Error('Unable to generate token!'));
      });
    });
  };

  const tokenIsExpired = (message: string): boolean => {
    if (message === 'Failed to verify user') {
      return true;
    }
    return false;
  };

  return {
    generateToken,
    tokenIsExpired,
  };
};

export default useToken;
