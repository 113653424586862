import {
  Form,
  LoopButton,
  Modal,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { useEffect, useState } from 'react';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import {
  getEmployeeDeleteSummaryInfo,
  validateDateOfLeavingForEmployee,
} from '../../../pages/Endorsements/SingleDeleteMembers/utils';
import {
  StyledDateOfLeavingModalWrapper,
  StyledDOLModalBody,
  StyledDOLModalBodyContent,
  StyledDOLModalBodyContentInfo,
  StyledDOLModalFooter,
  StyledDOLModalHeader,
  StyledDOLModalSubHeader,
} from './styles';
import { IDateOfLeavingModal } from './types';
import { validateDeleteData } from '../../../pages/Endorsements/BulkDeleteMembers/utils';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import useDebouncedValue from '../../../../utils/hooks/useDebouncedValue';
import moment from 'moment';

const DateOfLeavingModal: React.FC<IDateOfLeavingModal> = ({
  isVisible,
  selectedUser,
  onClose,
  onProceed,
  dependents,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDateTouched, setIsDateTouched] = useState(false);
  const [dateOfLeaving, setDateOfLeaving] = React.useState<Date | null>(null);
  const [errorText, setErrorText] = useState('');
  const [apiErrorText, setApiErrorText] = useState('');
  const debouncedErrorText = useDebouncedValue(errorText, 100);

  const companyId = useSelector(selectSelectedCompany)?.id ?? '';
  const summaryInfo = getEmployeeDeleteSummaryInfo(selectedUser, dependents);

  const validateDateOfLeaving = () => {
    try {
      validateDateOfLeavingForEmployee(selectedUser, dateOfLeaving);
      setErrorText('');
    } catch (error) {
      setErrorText((error as Error).message);
    }
  };

  const validateDeleteRequest = async () => {
    if (!(selectedUser && dateOfLeaving)) return;
    setIsLoading(true);
    const payload = {
      employeeId: selectedUser.employeeId,
      dateOfLeaving: moment(dateOfLeaving).format('DD/MM/YYYY'),
      reason: 'Employee Left',
    };
    try {
      const response = await validateDeleteData([payload], companyId);
      if (response.rejectedLives.length) {
        const errors = JSON.parse(response.rejectedLives[0].error);
        throw new Error(Object.values<string>(errors)[0]);
      }
      onProceed(dateOfLeaving);
      setApiErrorText('');
    } catch (error) {
      setApiErrorText((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isDateTouched) validateDateOfLeaving();
    if (dateOfLeaving) setApiErrorText('');
  }, [dateOfLeaving]);

  useEffect(() => {
    if (!isVisible) {
      setErrorText('');
      setApiErrorText('');
    };
  }, [isVisible]);

  useEffect(() => {
    setDateOfLeaving(null);
    setErrorText('');
    setApiErrorText('');
    setIsDateTouched(false);
  }, [selectedUser?.employeeId, isVisible]);

  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={onClose}
      disablePadding
      overflow="visible"
      closeOnBlur={false}
    >
      <WithThemeProvider>
        <StyledDateOfLeavingModalWrapper>
          <StyledDOLModalBody>
            <StyledDOLModalHeader>
              <Typography variant="title2" weight="medium">
                Add a Date of Leaving
              </Typography>
              <StyledDOLModalSubHeader>
                <Typography variant="small" color="secondary">
                  Please provide the employee’s last working date.
                </Typography>
                <Typography variant="small" color="secondary">
                  Note: The date cannot be in the future
                </Typography>
              </StyledDOLModalSubHeader>
            </StyledDOLModalHeader>
            <StyledDOLModalBodyContent
              $errored={!!(debouncedErrorText || apiErrorText)}
            >
              <div
                onBlur={validateDateOfLeaving}
                onClick={() => setIsDateTouched(true)}
                tabIndex={0}
              >
                <Form.DatePicker
                  maxDate={new Date()}
                  value={dateOfLeaving}
                  onChange={setDateOfLeaving}
                  placeholder="Enter date (DD-MM-YYYY)"
                  iconOrder="left"
                  error={debouncedErrorText || apiErrorText}
                  displayFormat="DD-MM-YYYY"
                  typable
                />
              </div>
              <StyledDOLModalBodyContentInfo>
                <Typography variant="small" weight="medium" color="emerald">
                  {summaryInfo}
                </Typography>
              </StyledDOLModalBodyContentInfo>
            </StyledDOLModalBodyContent>
          </StyledDOLModalBody>
          <StyledDOLModalFooter>
            <LoopButton onClick={onClose} variant="outline">
              Cancel
            </LoopButton>
            <LoopButton
              onClick={validateDeleteRequest}
              isLoading={isLoading}
              variant={
                dateOfLeaving && !debouncedErrorText ? 'filled' : 'disabled'
              }
            >
              Review Refund
            </LoopButton>
          </StyledDOLModalFooter>
        </StyledDateOfLeavingModalWrapper>
      </WithThemeProvider>
    </Modal>
  );
};

export default DateOfLeavingModal;
