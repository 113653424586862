import styled from 'styled-components';

export const PoliciesContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 40px 0 21px;
`;

export const StyledEmptyStateContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 56px;
  margin-top: 15px;
`;
