import { WorkSheet, read, utils, write } from 'xlsx';

import { IEmpTable } from '../../../containers/Employees/ActiveRoasterTable/types';
import {capitalizeFirstLetterOfEachWord, toUnderscoreName} from '../../../../utils/common/Utilities';
import { ICategorizedData } from '../../../pages/Employees/types';
interface IExcelSheet {
  sheetName: string;
  data: any[];
}
interface ActiveRoasterDataExcelEntity {
  'Emp Number'?: string;
  'Member Name'?: string;
  'UHID Number'?: string;
  Relation?: string;
  'Employee Name'?: string;
  'Coverage Status'?: string;
  'Date of Birth (DD/MMM/YYYY)'?: string;
  Gender?: string;
  'Sum Insured'?: string;
  'Coverage Start Date'?: string;
  'Coverage End Date'?: string;
  'Claim Confirmation'?: string;
  Designation?: string;
  'Mobile no.'?: string;
  'Email ID'?: string;
  'Loop ID'?: string;
  claimConfirmation?: string;
}

export const jsonToExcel = (sheets: IExcelSheet[]): string => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const workbook = utils.book_new();
  sheets.map((sheet) => {
    if (sheet.data) {
      const worksheet = utils.json_to_sheet(sheet.data, {
        cellDates: true,
        dateNF: 'dd mmm yyyy',
      });
      const wscols = getWorksheetColumnConfig(worksheet, sheet.data);
      worksheet['!cols'] = wscols;
      utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
    }
  });
  const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
  const exelBlob = new Blob([excelBuffer], { type: fileType });
  return URL.createObjectURL(exelBlob);
};

const getWorksheetColumnConfig = (
  ws: WorkSheet,
  json: Record<string, unknown>[],
) => {
  const objectMaxLength: number[] = [];
  const minWidth = 12;
  for (let i = 0; i < json.length; i++) {
    const value = <any>Object.values(json[i]);
    for (let j = 0; j < value.length; j++) {
      let columnWidth = (value[j] || '').length || 0;
      if (columnWidth < minWidth) {
        columnWidth = minWidth;
      }
      objectMaxLength[j] =
        objectMaxLength[j] >= columnWidth ? objectMaxLength[j] : columnWidth;
    }
  }
  const wscols = objectMaxLength.map((v) => ({ width: v }));
  return wscols;
};

export const roasterExcelDownload = (
  roasterData: ICategorizedData,
  name: string | undefined,
): void => {
  const active = roasterData.Active.map(transformAddMemberDataExcelRow);
  const deleted = roasterData.Deleted.map(transformAddMemberDataExcelRow);
  const rejected = roasterData.Employees.map(transformAddMemberDataExcelRow);
  const fileLink = jsonToExcel([
    {
      sheetName: 'Total Employees',
      data: rejected,
    },
    {
      sheetName: 'Total Lives',
      data: active,
    },
    {
      sheetName: 'Deleted Lives',
      data: deleted,
    },
  ]);
  const link = document.createElement('a');
  link.href = fileLink;
  link.download = `Active_Roster_${toUnderscoreName(name)}_${new Date().toLocaleDateString()}`;
  link.click();
  link.remove();
};
// 
export const transformAddMemberDataExcelRow = (
  d: IEmpTable,
): ActiveRoasterDataExcelEntity => ({
  'Emp Number': d['Employee ID'],
  'Member Name': capitalizeFirstLetterOfEachWord(d['Member name']),
  'UHID Number': d.uhid ?? '',
  Relation: d.Relationship,
  'Employee Name': capitalizeFirstLetterOfEachWord(d['Employee Name']),
  'Coverage Status': d['Insurance Status'],
  'Date of Birth (DD/MMM/YYYY)': d.dob,
  Gender: capitalizeFirstLetterOfEachWord(d.gender),
  'Sum Insured': d['Sum Insured'],
  'Coverage Start Date': d['Coverage Start Date'],
  'Coverage End Date': d['Coverage End Date'],
  'Claim Confirmation': d.claimConfirmation || '',
  Designation: '',
  'Mobile no.': d.mobile ?? '',
  'Email ID': d.email ?? '',
  'Loop ID': d.userId,
});
