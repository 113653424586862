import styled from 'styled-components';

export const StyledLoginPage = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const StyledLoginPageControlsSection = styled.section`
  flex: 0.6;
  display: grid;
  place-items: center;
  position: relative;
`;

export const StyledLoginPageControls = styled.div`
  gap: 42px;
  width: 550px;
  display: flex;
  flex-direction: column;
`;

export const StyledLoginPageHeader = styled.header`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const StyledLoginPageErrorAndSSOContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const StyledLoginPageErrorComponent = styled.div`
  border-radius: 8px;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.lavenderBlush};
`;

export const StyledLoginPageSSOControls = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: stretch;
`;

export const StyledLoginPageControlsSectionDivider = styled.div`
  height: 1px;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.seafoamGray};

  > span {
    top: 0;
    left: 50%;
    padding: 0 5px;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.surface0};
  }
`;

export const StyledLoginPageControlsMobileForm = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const StyledOtpContainer = styled.div`
  position: absolute;
`;