import styled from "styled-components";

export const StyledAlertZeroState = styled.div`
    display: flex;
    flex-direction: column;
    padding: 33px 24px;
    gap: 32px;
`;

export const StyledAlertZeroStateText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;