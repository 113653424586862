import React, { useEffect, useState } from 'react';
import { parseResponse } from '../../../../utils/common/Utilities';
import PolicyApi from '../../../../adaptars/policyApi';
import { IPolicyDetail } from '../types';

const useFetchPolicyDetails = (policyId: string) => {
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [policyDetail, setPolicyDetail] = React.useState<IPolicyDetail | null>(
    null,
  );
  const [isLoadingBasePol, toggleLoadingBasePol] = useState<boolean>(false);
  const [basePolicyDetail, setBasePolicyDetail] =
    React.useState<IPolicyDetail | null>(null);

  useEffect(() => {
    if (policyId) fetchPolicyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId]);

  const fetchPolicyDetails = async (): Promise<void> => {
    toggleLoading(true);

    const [error, result] = await parseResponse(
      PolicyApi.getPolicyDetail(policyId),
    );

    toggleLoading(false);

    if (error) {
      console.error(error);
      return;
    }
    const polData = result?.data?.policyPlans;
    setPolicyDetail(polData);

    if (polData.isTopUpPolicy && polData.basePolicyPlanId) {
      toggleLoadingBasePol(true);
      const [error, result] = await parseResponse(
        PolicyApi.getPolicyDetail(polData.basePolicyPlanId),
      );
      toggleLoadingBasePol(false);
      if (error) {
        console.error(error);
        return;
      }
      const basePolData = result?.data?.policyPlans;
      setBasePolicyDetail(basePolData);
    }
  };
  return { isLoading, policyDetail, basePolicyDetail, isLoadingBasePol };
};
export default useFetchPolicyDetails;
