import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { initialState } from './initialState';

const applicationStateSlice = createSlice({
  name: 'applicationState',
  initialState: initialState,
  reducers: {
    addCdBalanceTooltipShownUserId: (state, action) => {
      state.cdBalanceTooltipShownUserIds = (
        state.cdBalanceTooltipShownUserIds ?? []
      ).concat(action.payload);
    },
    addCDAccountCompletedPopupClosedUserIds(state, action) {
      state.cdAccountCompletedPopupClosedUserIds = (
        state.cdAccountCompletedPopupClosedUserIds ?? []
      ).concat(action.payload);
    },
    addWelcomeModalShownUserId(state, action) {
      state.welcomeModalShownUserIds = (
        state.welcomeModalShownUserIds ?? []
      ).concat(action.payload);
    },
    addSingleDeleteFaqShownUserId(state, action) {
      state.singleDeleteFaqShownUserIds = (
        state.singleDeleteFaqShownUserIds ?? []
      ).concat(action.payload);
    },
  },
});

export const selectCdBalanceTooltipShownUserIds = (state: RootState) =>
  state.applicationState.cdBalanceTooltipShownUserIds;

export const selectCDAccountCompletedPopupClosedUserIds = (state: RootState) =>
  state.applicationState.cdAccountCompletedPopupClosedUserIds || [];

export const selectWelcomeModalSeenUserIds = (state: RootState) =>
  state.applicationState.welcomeModalShownUserIds || [];

export const selectSingleDeleteFaqShownUserIds = (state: RootState) =>
  state.applicationState.singleDeleteFaqShownUserIds || [];

export const {
  addCdBalanceTooltipShownUserId,
  addCDAccountCompletedPopupClosedUserIds,
  addWelcomeModalShownUserId,
  addSingleDeleteFaqShownUserId,
} = applicationStateSlice.actions;

export default applicationStateSlice.reducer;
