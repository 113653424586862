import styled from 'styled-components';

export const StyledLoginPageLeftSection = styled.aside`
  flex: 0.4;
  gap: 56px;
  height: 100%;
  display: flex;
  padding: 52px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.loopEmerald};
`;

export const StyledLoginPageLeftSectionLogo = styled.img`
  width: 85px;
  height: 40px;
  object-fit: contain;
`;

export const StyledLoginPageLeftSectionGraphic = styled.img`
  width: 100%;
  height: 275px;
  object-fit: contain;
`;

export const StyledLoginPageLeftSectionText = styled.div`
  gap: 32px;
  display: flex;
  text-align: center;
  flex-direction: column;
`;
