import { Typography } from '@loophealth/loop-ui-web-library';
import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { EndoAddCostIcon, InfoDarkIcon } from '../../../../assets/images';
import { formatCurrencyAmount } from '../../../../utils/common/Utilities';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import Loader from '../../../atoms/Spinner';
import {
  StyledContainer,
  StyledDescription,
  StyledDottedSeparator,
  StyledEndoCostContainer,
  StyledEndoDataRow,
  StyledHighlightedContainer,
  StyledInfoIcon,
  StyledKnowMoreSection,
  StyledPolicyListContainer,
  StyledRightContainer,
  StyledText,
  StyledTotalCostHeader,
} from './styles';
import { ISubmitEndo } from './types';
import { getSubmitEventType } from './utils';
import EndoCDAccountWiseCost from './EndoCDAccountWiseList';
import { LOADING_CONSTANTS, SUBMIT_SCREEN_COST_REFUND } from '../constants';
import FullPageLoader from '../../../atoms/Loader';
import { trackTaskEvent } from '../../../../utils/segment/utils';
import KnowMoreModal from '../KnowMoreModal';

const SubmitEndo: React.FC<ISubmitEndo> = ({
  endoCostList,
  totalCost,
  totalLives,
  mode,
  isLoading,
  totalCostIncuringLives,
}) => {
  const trackTask = useSegment('track');
  const [isKnowMoreModalVisible, setKnowMoreModalVisible] =
    React.useState(false);
  const location = useLocation();

  const toShowIncurringCost =
    mode === 'EDIT' &&
    totalCostIncuringLives &&
    totalCostIncuringLives !== totalLives;

  const openKnowMore = () => {
    setKnowMoreModalVisible(true);
  };

  useEffect(() => {
    if (endoCostList.length) {
      const submitEventType = getSubmitEventType(endoCostList);
      trackTask(trackTaskEvent(submitEventType, location.pathname));
    }
  }, [endoCostList]);

  if (
    mode === 'EDIT' &&
    endoCostList.every((endoCost) =>
      endoCost.policyData.every((policy) => policy.costOfEndorsement === 0),
    )
  )
    return <FullPageLoader {...LOADING_CONSTANTS.EDITS_ARE_FREE} />;
  return (
    <StyledContainer>
      <StyledPolicyListContainer>
        {!isLoading ? (
          endoCostList.map((endoCostData, index) => (
            <EndoCDAccountWiseCost
              mode={mode}
              key={endoCostData.balance + index}
              endoCostData={endoCostData}
            />
          ))
        ) : (
          <Loader />
        )}
      </StyledPolicyListContainer>
      <StyledEndoCostContainer>
        <StyledTotalCostHeader>
          <img src={EndoAddCostIcon} />
          <Typography variant="medium" weight="medium">
            Total Endorsement{' '}
            {totalCost < 0
              ? 'Refund'
              : SUBMIT_SCREEN_COST_REFUND[mode].costText}
          </Typography>
        </StyledTotalCostHeader>
        <StyledDottedSeparator />
        <StyledEndoDataRow>
          <Typography variant={'small'} color={'secondary'}>
            Total Lives {SUBMIT_SCREEN_COST_REFUND[mode].operationText}
          </Typography>
          <Typography variant={'medium'} weight="medium">
            {totalLives}
          </Typography>
        </StyledEndoDataRow>
        {toShowIncurringCost ? (
          <>
            <StyledDottedSeparator $margin="16px 24px" />
            <StyledEndoDataRow>
              <Typography variant={'small'} color={'secondary'}>
                Edited Lives that Require Cost
              </Typography>
              <Typography variant={'medium'} weight="medium">
                {totalCostIncuringLives}
              </Typography>
            </StyledEndoDataRow>
          </>
        ) : null}
        <StyledEndoDataRow>
          <Typography variant={'small'} color={'secondary'}>
            Total Endorsement{' '}
            {totalCost < 0
              ? 'Refund'
              : SUBMIT_SCREEN_COST_REFUND[mode].costText}
          </Typography>
          <Typography variant={'medium'} weight="medium">
            {formatCurrencyAmount(Math.abs(totalCost))}
          </Typography>
        </StyledEndoDataRow>
        {['DELETE', 'SINGLE_DELETE'].includes(mode) && (
          <StyledHighlightedContainer>
            <Typography variant="small" color="secondary">
              Refunds are issued only if employees and their dependants have no
              past or ongoing claims during the policy term
            </Typography>
            <StyledKnowMoreSection onClick={openKnowMore}>
              <Typography variant="small" weight="bold" color="emerald">
                Know More
              </Typography>
            </StyledKnowMoreSection>
          </StyledHighlightedContainer>
        )}
        <StyledDescription>
          <StyledInfoIcon src={InfoDarkIcon} />
          <StyledText>
            <Typography variant="small" color="secondary">
              {toShowIncurringCost
                ? `Editing ${totalCostIncuringLives} out of ${totalLives} lives incurs a cost, inclusive of GST. However, this cost may vary once the endorsement is finalised.`
                : 'Final amount may change once endorsement is processed.'}
            </Typography>
          </StyledText>
        </StyledDescription>
      </StyledEndoCostContainer>
      <StyledRightContainer />
      <KnowMoreModal
        isOpen={isKnowMoreModalVisible}
        onClose={() => setKnowMoreModalVisible(false)}
      />
    </StyledContainer>
  );
};
export default memo(SubmitEndo);
