import React from 'react';
import Loader from '../Spinner';
import { StyledBackdrop } from './styles';
import { IBackdrop } from './types';

const Backdrop: React.FC<IBackdrop> = ({
  children = <Loader />,
  visible = true,
}) => <>{visible ? <StyledBackdrop>{children}</StyledBackdrop> : children}</>;

export default Backdrop;
