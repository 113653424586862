import React from 'react';

import { Refetch } from '../../../assets/images';
import { Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledCalendarIcon,
  StyledInfoContainer,
  StyledMainContainer,
} from './styles';

const EndoSubmitBanner: React.FC = () => {
  return (
    <>
      <StyledMainContainer>
        <StyledCalendarIcon src={Refetch} />
        <StyledInfoContainer>
          <Typography variant="large" color="emerald" weight="semiBold">
            Your submitted data is flowing through our system.
          </Typography>
          <Typography variant="small">
            {`This can take up to 20 mins. Once done, you'll see your updated endorsement buckets below!`}
          </Typography>
        </StyledInfoContainer>
      </StyledMainContainer>
    </>
  );
};

export default EndoSubmitBanner;
