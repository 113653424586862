import ChoosePolicyItem from './ChoosePolicyItem';
import { StyledChoosePolicyContainer } from './styles';
import { IChoosePolicyProps } from './types';

const ChoosePolicy: React.FC<IChoosePolicyProps> = ({ policiesList }) => {
  return (
    <StyledChoosePolicyContainer>
      {policiesList.map((policy, index) => (
        <ChoosePolicyItem policy={policy} key={index} />
      ))}
    </StyledChoosePolicyContainer>
  );
};

export default ChoosePolicy;
