import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
  selectPoliciesList,
  selectSelectedCompany,
} from '../../../../redux/slices/hrdRevampRedux';
import { fetchAllActivePolicies } from '../../../../redux/slices/hrdRevampRedux/thunk';
import useFetchCDAccounts from '../../../pages/CDAccounts/hooks/useFetchCDAccounts';

const useFetchPoliciesFromRedux = () => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectSelectedCompany);
  const { cdAccounts } = useFetchCDAccounts();

  const { data, error, filters, status } = useSelector(selectPoliciesList);
  const isLoading = status === 'loading';

  const policyList = useMemo(
    () =>
      data.map((policyData) => {
        const account = cdAccounts.find((account) =>
          account.policies.includes(policyData.id),
        );
        return {
          ...policyData,
          cdBalance: account ? account.netBalance : policyData.cdBalance,
        };
      }),
    [cdAccounts, data],
  );

  useEffect(() => {
    if (selectedCompany) {
      dispatch(fetchAllActivePolicies(selectedCompany.id));
    }
  }, [selectedCompany, dispatch]);

  return {
    policies: policyList,
    error,
    filters,
    status,
    isLoading,
  };
};
export default useFetchPoliciesFromRedux;
