import styled from 'styled-components';

export const StyledEmployeesPage = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
`;

export const StyledEmployeesPageHeader = styled.header`
  gap: 32px;
  display: flex;
  padding: 40px 56px;
  flex-direction: column;
`;

export const StyledSummaryAndStatisticsSection = styled.section`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0px 56px 52px 56px;
`;

export const StyledSummaryAndStatisticsColumn = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;
