import React from 'react';
import { IGenericIconProps } from './types';

const CrossOutlineNewIcon: React.FC<IGenericIconProps> = ({
  width = 6,
  height = 6,
  fill = '#EB5757',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.88411 2.99999L5.80078 1.08332C6.04245 0.841654 6.04245 0.441654 5.80078 0.199988C5.55911 -0.0416789 5.15911 -0.0416789 4.91745 0.199988L3.00078 2.11665L1.08411 0.199988C0.842447 -0.0416789 0.442448 -0.0416789 0.200781 0.199988C-0.0408854 0.441654 -0.0408854 0.841654 0.200781 1.08332L2.11745 2.99999L0.200781 4.91665C-0.0408854 5.15832 -0.0408854 5.55832 0.200781 5.79999C0.325781 5.92499 0.484114 5.98332 0.642448 5.98332C0.800781 5.98332 0.959114 5.92499 1.08411 5.79999L3.00078 3.88332L4.91745 5.79999C5.04245 5.92499 5.20078 5.98332 5.35911 5.98332C5.51745 5.98332 5.67578 5.92499 5.80078 5.79999C6.04245 5.55832 6.04245 5.15832 5.80078 4.91665L3.88411 2.99999Z"
      fill={fill}
    />
  </svg>
);

export { CrossOutlineNewIcon };
