import {
  Form,
  Loader,
  LoopBadge,
  LoopButton,
  Shadow,
  Table,
  Typography,
} from '@loophealth/loop-ui-web-library';
import moment from 'moment';
import React, {
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { LoopLogo, SearchIcon } from '../../../../assets/images';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { useFetchGlobalSearchData } from '../../../../utils/hooks/business-hooks/useFetchGlobalSearchData';
import useDebouncedValue from '../../../../utils/hooks/useDebouncedValue';
import { ISearchResultResponseUserType } from '../../TopNavigation/types';
import { IRelationship } from '../types';
import DropdownEmptyState from './DropdownEmptyState';
import {
  StyledDropdownLoaderContainer,
  StyledLoopHeader,
  StyledLoopIcon,
  StyledMemberDropdownItem,
  StyledMemberDropdownItemContainer,
  StyledMemberSearch,
  StyledMemberSearchBody,
  StyledMemberSearchContainer,
  StyledMemberSearchHeaderText,
  StyledSearchedDependentsList,
  StyledSearchedDependentsTable,
} from './styles';
import { IMemberSearch } from './types';
import {
  convertSearchResultsToDropdown,
  getEmpDependentsTableColumns,
} from './utils';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
  trackPageEvent,
} from '../../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';
import useDebouncedFunction from '../../../../utils/hooks/useDebouncedFunction';
import { formatMemberName } from '../../../../utils/common/common';

const MemberSearch: React.FC<IMemberSearch> = ({
  handleModalClose,
  setEditStep,
  setSelectedUser,
  editedUserIds,
}) => {
  const companyId = useSelector(selectSelectedCompany)?.id || '';
  const [memberSearchText, setMemberSearchText] = useState('');
  const [selectedMember, setSelectedMember] =
    useState<ISearchResultResponseUserType>();
  const [enterClicked, setEnterClicked] = useState(false);
  const trackPage = useSegment('page');
  const trackClick = useSegment('click');
  const location = useLocation();
  const debouncedSearchString = useDebouncedValue(memberSearchText, 2000);
  const { searchResults: allSearchResults, loading: isLoading } =
    useFetchGlobalSearchData(
      companyId,
      debouncedSearchString,
      false,
      true,
      true,
    );
  const searchResults = useMemo(
    () =>
      allSearchResults.filter(
        (member) => !editedUserIds.includes(member.userId),
      ),
    [allSearchResults, editedUserIds],
  );
  const dropdownSearchResults = convertSearchResultsToDropdown(searchResults);
  const dependents = useMemo(
    () =>
      selectedMember
        ? searchResults.filter(
            (result) => result.employeeId === selectedMember.employeeId,
          )
        : [],
    [selectedMember],
  );

  const handleTrackEmptyState = useDebouncedFunction(() => {
    trackPage(trackPageEvent('/empty-edit-search-result-page'));
  }, 2000);

  const handleSelectUserForEdit = (
    searchResult: ISearchResultResponseUserType,
  ) => {
    trackClick(
      trackClickEvent(
        'Edit_Search_Results_Member_Search_Modal_edit',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    const selfUser = searchResults.find(
      (result) =>
        result.employeeId === searchResult.employeeId &&
        result.relationship.toLowerCase() === 'self',
    );
    setSelectedUser({
      userId: searchResult.userId,
      employeeId: searchResult.employeeId,
      companyId: searchResult.employer,
      name: formatMemberName(searchResult.firstName, searchResult.lastName),
      firstName: searchResult.firstName,
      lastName: searchResult.lastName,
      selfName: selfUser?.firstName || '',
      relationship: searchResult.relationship as IRelationship,
      gender: searchResult.gender,
      dob: moment(searchResult.dob).format('DD/MMM/YYYY'),
      email: searchResult.email,
      mobile: searchResult.mobile,
      ctc: searchResult.ctc,
      policies: searchResult.userPolicies || [],
      isDocumentRequired: !searchResult.isDraftMember,
      edits: {},
      isNew: true,
    });
    setEditStep(2);
  };

  const handleResultClick = (value: string) => {
    trackClick(
      trackClickEvent(
        'Search_Result_Member_Search_Modal_edit',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    const selectedResult = searchResults.find(
      (result) => result.userId === value,
    );
    if (!selectedResult) return;
    if (!(selectedResult.isEditable && selectedResult.userPolicies?.length))
      return;
    if (selectedResult.relationship.toLowerCase() === 'self') {
      if (enterClicked) setEnterClicked(false);
      setSelectedMember(selectedResult);
    } else {
      handleSelectUserForEdit(selectedResult);
      setSelectedMember(undefined);
    }
  };

  const handleOnEnterClick: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter') {
      if (debouncedSearchString.length >= 3 && !isLoading) {
        setSelectedMember(undefined);
        setEnterClicked(true);
      }
    }
  };

  const handleOnSearchClick: React.MouseEventHandler<HTMLDivElement> = () => {
    trackClick(
      trackClickEvent(
        'Search_Bar_Member_Search_Modal_edit',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const dependentTableColumns = getEmpDependentsTableColumns(
    handleSelectUserForEdit,
  );

  useEffect(() => {
    trackPage(trackPageEvent('/member-search-page'));
  }, []);

  useEffect(() => {
    if (debouncedSearchString.length >= 3) setEnterClicked(false);
  }, [debouncedSearchString]);

  return (
    <StyledMemberSearch>
      <StyledLoopHeader>
        <StyledLoopIcon src={LoopLogo} />
        <div>
          <LoopButton
            variant="outline"
            textColor="tertiary"
            onClick={handleModalClose}
            size="small"
          >
            Cancel
          </LoopButton>
        </div>
      </StyledLoopHeader>
      <StyledMemberSearchBody>
        <Shadow variant="nav">
          <WithThemeProvider>
            <StyledMemberSearchContainer>
              <StyledMemberSearchHeaderText>
                <Typography variant="large" weight="medium">
                  Search and Edit Members
                </Typography>
                <Typography variant="medium" color="secondary">
                  Search by employee or dependant name or ID. Every edit will
                  reflect in all policies.
                </Typography>
              </StyledMemberSearchHeaderText>
              <div onKeyDown={handleOnEnterClick} onClick={handleOnSearchClick}>
                <Form.Dropdown
                  listItems={dropdownSearchResults}
                  selectedItem={selectedMember?.userId || ''}
                  placeholder="Search by Employee/Dependant Name or ID"
                  searchable
                  icon={SearchIcon}
                  hideArrow
                  maxHeight="470px"
                  groupBy="memberType"
                  onClick={handleResultClick}
                  onSearchChange={setMemberSearchText}
                  searchFilter={(list) => list}
                  isLoading={isLoading}
                  emptyState="Search by Employee/Dependant Name or ID"
                  noResultsState={(searchText) => {
                    if (
                      searchText.length > 3 &&
                      searchText === debouncedSearchString
                    ) {
                      handleTrackEmptyState();
                    }
                    return searchText.length < 3 ? (
                      'Enter at least 3 characters to search'
                    ) : searchText !== debouncedSearchString ? (
                      <StyledDropdownLoaderContainer>
                        <Loader />
                      </StyledDropdownLoaderContainer>
                    ) : (
                      <DropdownEmptyState
                        showSearchText={enterClicked}
                        searchText={debouncedSearchString}
                      />
                    );
                  }}
                  renderItem={(data) => (
                    <StyledMemberDropdownItem>
                      <StyledMemberDropdownItemContainer>
                        <Typography
                          variant="small"
                          weight="medium"
                          color={data.isEditable && !data.isExpired ? 'primary' : 'secondary'}
                        >
                          {data.name as string}
                        </Typography>
                        <Typography variant="extraSmall" color="secondary">
                          {data.employeeId as string}
                        </Typography>
                      </StyledMemberDropdownItemContainer>
                      {data.isExpired ? (
                        <LoopBadge
                          variant="overflow"
                          badgeType="errorFill"
                          text={
                            data.isDeleting
                              ? 'Deletion In Progress'
                              : 'Deleted Member'
                          }
                          size="small"
                        />
                      ) : !data.isEditable ? (
                        <LoopBadge
                          variant="overflow"
                          badgeType="errorFill"
                          text="Endorsement In Progress"
                          size="small"
                        />
                      ) : null}
                    </StyledMemberDropdownItem>
                  )}
                />
              </div>
            </StyledMemberSearchContainer>
          </WithThemeProvider>
        </Shadow>
        {selectedMember ||
        (enterClicked && !isLoading && searchResults.length) ? (
          <StyledSearchedDependentsList>
            <Typography variant="medium" color="secondary">
              Showing results for{' '}
              <Typography variant="medium" weight="medium">
                “
                {enterClicked
                  ? memberSearchText
                  : (selectedMember?.employeeId as string)}
                ”
              </Typography>
            </Typography>
            <Shadow variant="nav">
              <WithThemeProvider>
                <StyledSearchedDependentsTable>
                  <Table
                    data={enterClicked ? searchResults : dependents}
                    columns={dependentTableColumns}
                    fullWidth
                  />
                </StyledSearchedDependentsTable>
              </WithThemeProvider>
            </Shadow>
          </StyledSearchedDependentsList>
        ) : null}
      </StyledMemberSearchBody>
    </StyledMemberSearch>
  );
};

export default MemberSearch;
