import { LoopBadge, Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledCDContainer,
  StyledCDContainerLeft,
  StyledCDContainerRight,
  StyledCDContent,
  StyledCDCrossIconContainer,
  StyledCDIcon,
  StyledCDIconContainer,
  StyledCDProgress,
  StyledCDWrapper,
} from './styles';
import { LikeShapesIcon } from '../../../assets/images';
import theme from '../../../theme';
import CrossOutlineThinIcon from '../../../assets/icons/CrossOutlineThinIcon';
import { ICDAccountCompletedCardProps } from './types';

const CDAccountCompletedCard: React.FC<ICDAccountCompletedCardProps> = ({
  onPopupClosed,
}) => {
  return (
    <StyledCDWrapper>
      <StyledCDContainer isCompleted>
        <StyledCDContainerLeft>
          <StyledCDIconContainer isCompleted>
            <StyledCDIcon src={LikeShapesIcon} />
          </StyledCDIconContainer>
          <StyledCDContent>
            <Typography variant="medium" weight="semiBold" color="emerald">
              All CD accounts created!
            </Typography>
            <Typography variant="small" weight="regular" color="secondary">
              All your CD accounts have been successfully created. Please reach
              out to your CSM for any questions.
            </Typography>
          </StyledCDContent>
        </StyledCDContainerLeft>
        <StyledCDContainerRight>
          <StyledCDProgress>
            <LoopBadge
              badgeType="success"
              variant="overflow"
              text="Completed"
            />
          </StyledCDProgress>
          <StyledCDCrossIconContainer onClick={onPopupClosed}>
            <CrossOutlineThinIcon fill={theme.colors.beyondGrey} />
          </StyledCDCrossIconContainer>
        </StyledCDContainerRight>
      </StyledCDContainer>
    </StyledCDWrapper>
  );
};

export default CDAccountCompletedCard;
