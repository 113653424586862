import styled from 'styled-components';

export const StyledCDWrapper = styled.div`
  padding: 0px 56px 30px 56px;
  margin-top: -10px;
  width: 100%;
`;

export const StyledCDContainer = styled.div<{ isCompleted?: boolean }>`
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  padding: 20px;
  background-color: ${(p) =>
    p.isCompleted ? p.theme.colors.mintFrost : p.theme.colors.white};
  border-radius: 8px;
  align-items: flex-start;
`;
export const StyledCDContainerLeft = styled.div`
  width: 75%;
  display: flex;
  gap: 20px;
`;
export const StyledCDContainerRight = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-end;
`;
export const StyledCDIconContainer = styled.div<{ isCompleted?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isCompleted }) =>
    isCompleted ? theme.colors.loopEmerald : theme.colors.lightSilver};
  border-radius: 50%;
  height: 35px;
  width: 35px;
`;
export const StyledCDIcon = styled.img`
  height: 22px;
  width: 22px;
`;
export const StyledCDContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 55px);
  text-align: left;
  gap: 10px;
`;
export const StyledCDProgress = styled.div`
  display: flex;
  width: fit-content;
  gap: 5px;
  justify-self: flex-end;
`;
export const StyledCDCrossIconContainer = styled.div`
  cursor: pointer;
  width: 20px;
  margin-left: 30px;
`;
