import { Dispatch, SetStateAction } from 'react';
import { IEmpTable } from '../../containers/Employees/ActiveRoasterTable/types';

export enum IEmployeeFilterType {
  Active,
  Deleted,
  Rejected,
}

export interface IEmployeeProps {
  filters: IEmployeeFilterType[];
  activeFilter: IEmployeeFilterType;
  setActiveFilter: Dispatch<SetStateAction<IEmployeeFilterType>>;
}

export interface IEmployeeData {
  lastName: string;
  firstName: string;
  ctc: string | number | null;
  gender: string;
  customForms?: any;
  dob: string;
  mobile: string | null;
  employer: string;
  employeeId: string;
  relationship: string;
  userId: string;
  email: string;
  policyId: string;
  policyNickName: string;
  policyEndDate: string;
  policyStartDate: string;
  isException: boolean;
  uhid: string;
  sumInsured: number;
  insuranceStatus: string;
  claimConfirmation: boolean;
  employee_name?: string;
  parentId?: string;
  endorsmentStatus: string | null;
}

export interface ICategorizedData {
  Active: IEmpTable[];
  Deleted: IEmpTable[];
  Employees: IEmpTable[];
}

export enum EndoState {
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  SENT_TO_INSURER = 'SENT_TO_INSURER',
  ENDO_DECLINED = 'ENDO_DECLINED',
  ENDO_COMPLETE = 'ENDO_COMPLETE',
  READY_FOR_TPA = 'READY_FOR_TPA',
  RECEIVED_UHIDS = 'RECEIVED_UHIDS',
  ENDO_RECEIVED = 'ENDO_RECEIVED',
}

export interface InsuranceStats {
  total_lives_all: number;
  insured_lives_all: number;
  total_lives_emp: number;
  insured_lives_emp: number;
  available_ecards: number;
  employee: number;
  parent: number;
  'parent-in-law': number;
  spouse: number;
  child: number;
  endorsementStatus: number;
  ecardInProgress: number;
  employee_with_ecard: number;
  insured_employees: number;
  in_progress_employees: number;
}
