import React, { FC } from 'react';
import {
  StyledPieChartLegendPoint,
  StyledPieChartLegendText,
  StyledPieChartLegendTextContainer,
  StyledPieChartLegendTextValue,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { ICustomLegend } from './types';

const CustomLegend: FC<ICustomLegend> = ({
  color,
  count,
  label,
  percentage,
  subLabel = '',
}) => {
  return (
    <StyledPieChartLegendTextContainer>
      <StyledPieChartLegendPoint color={color} />
      <StyledPieChartLegendText>
        <StyledPieChartLegendTextValue>
        <Typography variant="extraSmall">{label}</Typography>
        <Typography
          variant="small"
          weight="medium"
        >{`${count} ${subLabel}`}</Typography>
        </StyledPieChartLegendTextValue>
          <Typography variant="small" color="secondary">
            &#40;{percentage}%&#41;
          </Typography>
      </StyledPieChartLegendText>
    </StyledPieChartLegendTextContainer>
  );
};

export default CustomLegend;
