export const getErrorText = (errorInput: string) => {
  const isEmail = errorInput.includes('@');
  const isMobileNumber = errorInput.includes('+91-');

  if (!isEmail && !isMobileNumber) return '';

  return ` is not registered to log into HR dashboard. Please check the ${
    isEmail ? 'email ID' : isMobileNumber ? 'phone number' : ''
  } or contact Customer Success Manager for help`;
};
