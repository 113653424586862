import React from 'react';
import { IGenericIconProps } from './types';

const WarningAlertIcon: React.FC<IGenericIconProps> = ({
  width = 20,
  height = 20,
  fill = '#EB5757',
  strokeWidth = '1.5',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.99996"
        cy="10.0003"
        r="7.73333"
        stroke={fill}
        strokeWidth={strokeWidth}
      />
      <path
        d="M10 5.83301V10.833M10 12.4997V13.333"
        stroke={fill}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export { WarningAlertIcon };
