import styled from 'styled-components';

export const StyledOverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  border-radius: 16px;
  gap: 20px;
  flex: 1;
  background-color: ${(p) => p.theme.colors.white};
  min-height: 170px;
`;
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledMainContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 60px 52px;
  flex-direction: column;
  gap: 27px;
`;

export const StyledBackBtnContainer = styled.div`
  width: 100%;
  gap: 20px;
  display: flex;
  align-items: center;
`;

export const StyledContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

export const StyledPolStartDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 20px;
  align-items: center;
`;
export const StyledBackIcon = styled.img`
  cursor: pointer;
`;

export const StyledTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
export const StyledTabsContentContainer = styled.div`
  display: flex;
  width: 100%;
`;
export const StyledTabsHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
  > div {
    width: 100%;
    justify-content: space-around;
    > div {
      flex: 1;
      justify-content: center;
      padding: 16px 0;
      display: flex;
      align-items: center;
      
      > span {
        width: fit-content;
        text-align: center;
      }
    }
  }
`;
