import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { useState } from 'react';
import {
  ArrowDownEmerald,
  ArrowUpEmerald,
  CrossCircleIcon,
  TickCircleLightIcon,
} from '../../../../assets/images';
import { IPolicyCoverageIncExcListItem } from '../types';
import {
  StyledPolicyCoverageIncExcItem,
  StyledPolicyCoverageIncExcItemBody,
  StyledPolicyCoverageIncExcItemHeader,
  StyledPolicyCoverageIncExcItemHeaderText,
  StyledPolicyCoverageIncExcItemLogo,
} from './styles';

const PolicyCoverageIncExcItem: React.FC<IPolicyCoverageIncExcListItem> = ({
  term,
  isExclusion,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded((isExpanded) => !isExpanded);
  const isExpandable = !!(!isExclusion && term.value);

  return (
    <StyledPolicyCoverageIncExcItem>
      <StyledPolicyCoverageIncExcItemHeader>
        <StyledPolicyCoverageIncExcItemLogo
          src={isExclusion ? CrossCircleIcon : TickCircleLightIcon}
        />
        <StyledPolicyCoverageIncExcItemHeaderText>
          <Typography variant="small">{term.label}</Typography>
          {isExpandable && (
            <LoopButton
              variant="text"
              size="small"
              onClick={toggleExpanded}
              iconSrc={isExpanded ? ArrowUpEmerald : ArrowDownEmerald}
              iconOrder="right"
            >
              {isExpanded ? "Hide T&C's" : "View T&C's"}
            </LoopButton>
          )}
        </StyledPolicyCoverageIncExcItemHeaderText>
      </StyledPolicyCoverageIncExcItemHeader>
      {isExpandable && isExpanded && (
        <StyledPolicyCoverageIncExcItemBody>
          <Typography variant="extraSmall">{term.value}</Typography>
        </StyledPolicyCoverageIncExcItemBody>
      )}
    </StyledPolicyCoverageIncExcItem>
  );
};

export default PolicyCoverageIncExcItem;
