import {
  StyledDependentsList,
  StyledDetailsHeader,
  StyledEmptyContainer,
  StyledMainContainer,
} from './styles';
import {
  EmptyStateSixGif,
  HappyEmployeesGraphic,
  TableContentYellowIcon,
  WomanIcon,
} from '../../../assets/images';
import { Typography } from '@loophealth/loop-ui-web-library';
import DependentCardItem from './DependentCardItem';
import { IDepPolicies } from './types';
import ZeroStateCard from '../../atoms/ZeroStateCard';
import { ITransformedData } from '../../pages/EmployeeDetails/types';
import { filterPoliciesDependents } from '../EmpPolicies/utils';

const EmpDependents: React.FunctionComponent<IDepPolicies> = ({
  policiesDetails,
}) => {
  const dependentPolicies: ITransformedData[] =
    filterPoliciesDependents(policiesDetails);
  return (
    <StyledMainContainer>
      <StyledDetailsHeader>
        <img src={WomanIcon} alt="" />
        <Typography variant="medium" weight="medium" color="primary">
          Dependant’s E-Card and Policies
        </Typography>
      </StyledDetailsHeader>
      <StyledDependentsList>
        {dependentPolicies?.length ? (
          dependentPolicies.map((item: ITransformedData, index: number) => (
            <DependentCardItem data={item} key={index} />
          ))
        ) : (
          <StyledEmptyContainer>
            <ZeroStateCard
              title="No dependants listed for this employee!"
              subtitle="You’re just insuring the employee, that’s all"
              image={EmptyStateSixGif}
            />
          </StyledEmptyContainer>
        )}
      </StyledDependentsList>
    </StyledMainContainer>
  );
};
export default EmpDependents;
