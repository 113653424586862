import React, { FC } from "react";
import LoopApiService from "../../../../adaptars/LoopApiService";
import { parseResponse } from "../../../../utils/common/Utilities";
import ChatbotLoader from '../../../atoms/ChatbotElements/ChatbotLoader';
import { IActionProviderProps } from "./types";

export const ActionProvider: FC<IActionProviderProps> = ({
  createChatBotMessage,
  setState,
  children,
}) => {
  const removeLoadingMessage = (prevstateArray: { messages: string[]; }, removeLoading: boolean) => {
    if (removeLoading) {
      prevstateArray?.messages?.splice(
        prevstateArray?.messages?.length - 1,
        1,
      );
      return prevstateArray;
    } else {
      return prevstateArray;
    }
  };

  const addMessageToState = (message: any, removeLoading = false) => {
    setState((prevstate: { messages: any; }) => ({
      ...removeLoadingMessage(prevstate, removeLoading),
      messages: [...prevstate.messages, message],
    }));
  };

  const sendMessageToLLM = async (message: any) => {
    const inputBox = document.querySelector('.react-chatbot-kit-chat-input');
    if (inputBox) (inputBox as any).disabled = true;
    addMessageToState(message);
    greet(<ChatbotLoader />);
    const [error, response] = await parseResponse(
      LoopApiService.askPolicy(message),
    );
     greet(response.answer, true);
     if (inputBox) (inputBox as any).disabled = false;
  };

  const greet = (botMessage: string | React.ReactElement, removeLoading = false) => {
    const message = createChatBotMessage(botMessage);
    addMessageToState(message, removeLoading);
  };

  return (
    <div>
      {React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          actions: {
            sendMessageToLLM,
          },
        });
      })}
    </div>
  );
};
