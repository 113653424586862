import {
  Form,
  LoopBadge,
  LoopButton,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { ChangeEvent, useState } from 'react';
import { IEditedField } from '../../../../pages/Endorsements/BulkEditMembers/types';
import { StyledFitContentWrapper } from '../../../EmpPolicies/styles';
import {
  StyledFieldBody,
  StyledFieldBodyContainer,
  StyledFieldButton,
  StyledFieldButtonsContainer,
  StyledFieldContainer,
  StyledFieldDocumentsWrapper,
  StyledFieldHeaderContainer,
  StyledFieldBadgeContainer,
  StyledFieldEditContainer,
} from '../styles';
import { IMobileEditContainer } from '../types';
import { regexValidations } from '../../../../../utils/hooks/useFormInput';
import { formatValuesByField } from '../utils';

const MobileEditContainer: React.FC<IMobileEditContainer> = ({
  handleEditFieldSave,
  isBeingEdited,
  oldValue,
  edit,
  setSelectedField,
  updateEditedField,
  validateFieldSave,
  emailValue,
}) => {
  const oldValueFormat = oldValue ? '+91 | ' + oldValue : '';
  const [value, setValue] = useState(oldValueFormat);
  const [fieldError, setFieldError] = useState<string>('');
  const [isLoading, toggleLoading] = useState(false);

  const handleMobileNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldError('');
    let value = e.target.value;
    if (value.startsWith('+91 | ')) value = value.substring(6);
    if (value === '') setValue('');
    else if (value.length > 10) return;
    else if (value.match(/^[0-9]*$/g)) setValue(`+91 | ${value}`);
  };

  const handleEditAndCancel = () => {
    setSelectedField(isBeingEdited ? null : 'mobile');
    setFieldError('');
    setValue(edit ? edit.after || '' : oldValueFormat);
  };

  const handleSave = async (clearValue = false) => {
    setFieldError('');
    toggleLoading(true);
    const newValue = value.substring(6);
    const error = await validateFieldSave(
      'mobile',
      clearValue ? null : newValue,
    );
    if (error) {
      setFieldError(error);
      toggleLoading(false);
      return;
    }
    handleEditFieldSave('mobile');

    if (edit) {
      const newEdit = {
        ...edit,
        after: clearValue ? null : newValue,
      };
      updateEditedField(newEdit);
    } else {
      const newEdit: IEditedField = {
        field: 'mobile',
        before: oldValue,
        after: clearValue ? null : newValue,
        documents: [],
      };
      updateEditedField(newEdit);
    }
    setSelectedField(null);
    setFieldError('');
    toggleLoading(false);
  };

  const saveEnabled =
    value &&
    oldValueFormat !== value &&
    regexValidations.phone.test(value.slice(-10)) &&
    !fieldError;

  return (
    <StyledFieldContainer>
      <StyledFieldHeaderContainer>
        <StyledFieldBadgeContainer>
          <Typography variant="medium" weight="medium">
            Mobile
          </Typography>
          {edit ? (
            <LoopBadge
              variant="overflow"
              text="Edited"
              badgeType="success3"
              size="small"
            />
          ) : null}
        </StyledFieldBadgeContainer>
        <StyledFieldButton>
          <LoopButton variant="text" size="large" onClick={handleEditAndCancel}>
            {isBeingEdited ? 'Cancel' : !oldValue && !value ? 'Add' : 'Edit'}
          </LoopButton>
        </StyledFieldButton>
      </StyledFieldHeaderContainer>
      <StyledFieldBodyContainer>
        {isBeingEdited ? (
          <StyledFieldBody>
            <StyledFieldEditContainer>
              <Form.Input
                value={value}
                placeholder="Enter mobile number"
                type="tel"
                onChange={handleMobileNumberChange}
                error={fieldError}
              />
            </StyledFieldEditContainer>
            <StyledFieldButtonsContainer $errored={!!fieldError}>
              <StyledFitContentWrapper>
                <LoopButton
                  variant={saveEnabled ? 'filled' : 'disabled'}
                  onClick={() => handleSave()}
                  isLoading={isLoading}
                >
                  Save
                </LoopButton>
              </StyledFitContentWrapper>
              {emailValue && oldValue && (
                <StyledFitContentWrapper>
                  <LoopButton
                    variant={isLoading ? "disabled": "outline"}
                    textColor="error"
                    onClick={() => handleSave(true)}
                  >
                    Clear
                  </LoopButton>
                </StyledFitContentWrapper>
              )}
            </StyledFieldButtonsContainer>
          </StyledFieldBody>
        ) : (
          <StyledFieldDocumentsWrapper>
            <Typography variant="medium" color="secondary">
              {formatValuesByField('mobile', edit ? edit.after : oldValue)}
            </Typography>
          </StyledFieldDocumentsWrapper>
        )}
      </StyledFieldBodyContainer>
    </StyledFieldContainer>
  );
};

export default MobileEditContainer;
