const NICKNAMES: Record<string, { nickName: string }> = {
  '5': {
    nickName: 'QED',
  },
  '6': {
    nickName: 'LOOP HEALTH',
  },
  '23': {
    nickName: 'Enveda',
  },
  '24': {
    nickName: 'Suryalogix',
  },
  'LPE-P931OB': {
    nickName: 'Sheraton Grand - BramhaCorp',
  },
  'LPE-O6MRFJ': {
    nickName: 'Assimilate Solutions',
  },
  'LPE-7LYDF5': {
    nickName: 'Transparency Market Research',
  },
  'LPE-65LAAE': {
    nickName: 'V Ensure Pharma',
  },
  'LPE-PK5VT0': {
    nickName: 'ROHM Semiconductor India',
  },
  'LPE-8G1RCU': {
    nickName: 'Future Market Insights Global',
  },
  'LPE-GMNLFN': {
    nickName: 'Brantford - BramhaCorp',
  },
  'LPE-H47JK8': {
    nickName: 'Theobroma',
  },
  gLF3UGSudvXbHLKkBKPl: {
    nickName: 'Polyworks',
  },
  'LPE-U8SKE9': {
    nickName: 'Myaashiana',
  },
  'LPE-1K23SE': {
    nickName: 'Infinite Uptime',
  },
  'LPE-9L14HN': {
    nickName: 'Zee Learn',
  },
  'LPE-9K0TXY': {
    nickName: 'Hackerearth Technologies',
  },
  'LPE-FHU0RB': {
    nickName: 'Encube',
  },
  '9jvqLfA2FFvhAU2SJIdA': {
    nickName: 'Livio',
  },
  'LPE-RC0FEU': {
    nickName: 'Criticalriver Technologies',
  },
  'LPE-ZUZ48P': {
    nickName: 'Persistence Market Research',
  },
  'LPE-UA9WJO': {
    nickName: 'Tango IT Solutions',
  },
  'LPE-M2PKU9': {
    nickName: 'Tech4Billion Media',
  },
  'LPE-0TXG6H': {
    nickName: 'Eminent Research And Advisory',
  },
  'LPE-TGTTHY': {
    nickName: 'Montran Corporation',
  },
  'LPE-15X4HX': {
    nickName: 'Suchalis',
  },
  'LPE-BLYULA': {
    nickName: 'SLN technologies',
  },
  'LPE-GE51WS': {
    nickName: 'Virohan',
  },
  'LPE-PLKKE9': {
    nickName: 'CDATA Software India',
  },
  'LPE-4UOLX2': {
    nickName: 'Naiknavare Developers',
  },
  'LPE-LO4SVU': {
    nickName: 'Desk Nine',
  },
  'LPE-64BRWO': {
    nickName: 'ACTUALIZE CONSULTING ENGINEERS (INDIA)',
  },
  vUZjTxAPSkJ675LtyuWf: {
    nickName: 'Voestalpine Bohler Welding India',
  },
  'LPE-SG5ME7': {
    nickName: 'Voestalpine Bohler Welding India Technology',
  },
  'LPE-7FOGXV': {
    nickName: 'Edvoy Educational Services',
  },
  'LPE-HZV509': {
    nickName: 'Edvoy Technologies Pvt Ltd',
  },
  'LPE-5UPKQM': {
    nickName: 'EFC Prime',
  },
  'LPE-500KIL': {
    nickName: 'EFC Limited',
  },
  'LPE-7FZZ03': {
    nickName: 'ACTIOHX SOLUTIONS PRIVATE LIMITED',
  },
  'LPE-5XK4R0': {
    nickName: 'Vilas Javdekar Eco Housing LLP',
  },
  'LPE-0BZ8EF': {
    nickName: 'Globalstep Services Pvt Ltd',
  },
  'LPE-DRPG38': {
    nickName: 'Inmorphis Services Private Limited',
  },
  'LPE-QV3GL5': {
    nickName: 'Paloma Turning Co Pvt Ltd',
  },
  'LPE-LEX6TU': {
    nickName: 'Applied Systems India Private Limited',
  },
  '86733b3e-a2ec-4e18-a293-3aaf44ecf00a': {
    nickName: 'Techsera',
  },
  'LPE-P123VA': {
    nickName: 'ABSYZ SOFTWARE CONSULTING PRIVATE LIMITED',
  },
  'LPE-II7NLR': {
    nickName: 'SAMBHAV FOUNDATION',
  },
  'LPE-SJ1W3C': {
    nickName: 'IKSULA SERVICES PRIVATE LIMITED',
  },
  'LPE-R8K9TY': {
    nickName: 'KEAN CONSTRUCTION LLP',
  },
  'LPE-LOXM7Y': {
    nickName: 'DOCQUITY SERVICES PRIVATE LIMITED',
  },
  'LPE-FV0RWJ': {
    nickName: 'NEEL NAYAN PHARMA PRIVATE LIMITED',
  },
  'LPE-T57SFO': {
    nickName: 'HRMS Consulting India Private Limited',
  },
  'LPE-86P336': {
    nickName: 'Pantson Pharma Private Limited',
  },
  'LPE-J31PFP': {
    nickName: 'SCHMERSAL INDIA PRIVATE LIMITED',
  },
  'LPE-9WIXIQ': {
    nickName: 'SHAIP.AI DATA (INDIA) LLP',
  },
  'LPE-L7YMFV': {
    nickName: 'SCHMERSAL GLOBAL COMPETENCE CENTER PRIVATE LIMITED',
  },
  'LPE-0VCSMV': {
    nickName: 'Adloid Technologies - Metadome',
  },
  'LPE-GMWORC': {
    nickName: 'RECROSOFT TECHNOLOGIES PRIVATE LIMITED',
  },
  'LPE-DVU5NF': {
    nickName: 'Meero Technologies Private Limited',
  },
  'LPE-5NHX5B': {
    nickName: 'Nvizion Solutions Private Limited',
  },
  'LPE-R1D480': {
    nickName: 'Scan-IT Solutions (India) Private Limited',
  },
  'LPE-IE3C0E': {
    nickName: 'Netmonastery Network Security Pvt Ltd - DNIF',
  },
  DBbK8AUm5MvzZemuZl6D: {
    nickName: 'Appiness Interactive Pvt Ltd',
  },
  'LPE-6T8LRB': {
    nickName: 'RESEARCH TRIANGLE INSTITUTE GLOBAL INDIA PRIVATE',
  },
  Qy7CWb3UWPHomu0PSv5e: {
    nickName: 'LUMEN DATA SOLUTIONS INDIA PVT LTD',
  },
  'LPE-KXV28H': {
    nickName: 'Sigma Infosolutions Limited',
  },
  'LPE-VHBCJN': {
    nickName: 'HOFFMANN QUALITY TOOLS INDIA PRIVATE LIMITED',
  },
  'LPE-EXQHH5': {
    nickName: 'Avocation Educational Services Pvt Ltd',
  },
  'LPE-5JH16E': {
    nickName: 'Heads Up Ventures Ltd',
  },
  'LPE-X38KP1': {
    nickName: 'CAPRIHANS INDIA LIMITED',
  },
  'LPE-9GM4ZW': {
    nickName: 'NOVORISE FINTECH INDIA PRIVATE LIMITED',
  },
  'LPE-9QCE5M': {
    nickName: 'DATAORB (INDIA) LLP',
  },
  'LPE-6SF6O3': {
    nickName: 'Researchmoz Global Private Limited',
  },
  'LPE-X2RWDA': {
    nickName: 'YOP Digital Developments India Private Limted',
  },
  'LPE-3V4LRZ': {
    nickName: 'FEEDBACK BUSINESS INSIGHTS PRIVATE LIMITED',
  },
  'LPE-ZS2YNR': {
    nickName: 'FEEDBACK ADVISORY SERVICES PRIVATE LIMITED',
  },
  'LPE-UE7HAA': {
    nickName: 'CLOUDSYNAPPS INDIA PRIVATE LIMITED',
  },
  'LPE-9INSBF': {
    nickName: 'ENTYTLE INDIA PRIVATE LIMITED',
  },
  ow2ekHJo3dImM4VyMjGv: {
    nickName: 'NCFE',
  },
  'LPE-O3U6DV': {
    nickName: 'Swach Bharat',
  },
  '931ea297-6034-498d-8a33-c202c6d08c48': {
    nickName: 'Impact Infotech',
  },
  'LPE-3X47WZ': {
    nickName: 'Everi India Pvt Ltd',
  },
  iuykAUpgaRyuPfDeXdMm: {
    nickName: 'Pride Purple Infrastructure',
  },
  'LPE-B1DFEM': {
    nickName: 'USEREADY TECHNOLOGY PRIVATE LIMITED',
  },
  'LPE-L9CWM9': {
    nickName: 'Shoonya Software Services Pvt Ltd',
  },
  'LPE-GGOGQ1': {
    nickName: 'OROCORP TECHNOLOGIES PRIVATE LIMITED',
  },
  'LPE-YDQD7R': {
    nickName: 'Billennium India Private Limited',
  },
  'LPE-X7XMA9': {
    nickName: 'Valley Point India Private Limited',
  },
  'LPE-12VNYD': {
    nickName: 'VELOCITY IT PRIVATE LIMITED',
  },
  'LPE-38CJLD': {
    nickName: 'Maiden Marketing India Pvt Ltd',
  },
  C5KTgX2Xkcu6SG47IK8d: {
    nickName: 'Ajmal and Sons',
  },
  XRq87D31axMFBIt2t6WI: {
    nickName: 'Time Zone Entertainment Pvt Ltd',
  },
  'LPE-W4QMYX': {
    nickName: 'SCREEN-MAGIC MOBILE MEDIA PRIVATE LIMITED',
  },
  'LPE-2CYAMI': {
    nickName: 'VEERSA TECHNOLOGIES INDIA PRIVATE LIMITED',
  },
  'LPE-996XMT': {
    nickName: 'Donyati India Pvt Ltd',
  },
  'LPE-WOJIT0': {
    nickName: 'Inspirext Consulting India Private Limited',
  },
  'LPE-I83J7C': {
    nickName: 'Valantic Supply Chain Excellence India Pvt Ltd',
  },
  Ee35RSjQDlXmBGTGFwG4: {
    nickName: 'Loylogic Technologies India Private Limited',
  },
};
const PHASE_1_AND_2_COMPANIES = {
  'LPE-TH3G46': {
    nickName: 'Kovan Technology Labs India Pvt Ltd',
  },
  'LPE-WJ4R3T': {
    nickName: 'AQ Mechanical and Electrical Manufacturing India Private',
  },
  '12': {
    nickName: 'Arcatron',
  },
  '7bebm0DZQ8bZJo0jf2Ke': {
    nickName: 'Egon Software Pvt Ltd',
  },
  'LPE-X9LP5F': {
    nickName: 'Infracloud',
  },
  // 'LPE-2VF1QZ': {
  //   nickName: 'Podar Education Trust',
  // },
  'LPE-PGZDVJ': {
    nickName: 'Acxiom Technologies',
  },
  // 'LPE-UAQ7N9': {
  //   nickName: 'Unified Workplace',
  // },
  'LPE-OZATAG': {
    nickName: 'Cimb Ai Labs Private Limited',
  },
  'LPE-BCNAHZ': {
    nickName: 'Zip Infosolutions Private Limited',
  },
  AS2oSgR5JGEMij0Zmcwp: {
    nickName: 'Fennys India Private Limited',
  },
  'LPE-KVOXJJ': {
    nickName: 'Vneighborhood Hospitality and Resort Private Limited',
  },
  '7LTCoDw4RWB7Hf0UJtvR': {
    nickName: 'Community India Hospitality and Resorts Pvt Ltd',
  },
  // Ez6ScvKShpVdD7JUAitK: {
  //   nickName: 'Zebu Animation',
  // },
  'LPE-KHV9SA': {
    nickName: 'The Akanksha Foundation',
  },
  'LPE-5LY8Z1': {
    nickName: 'I Exceed Technology Solutions',
  },
  'LPE-1DYQ9R': {
    nickName: 'Think Future Technologies',
  },
  'LPE-UA9WJO': {
    nickName: 'Tango IT Solutions',
  },
  '23': {
    nickName: 'Enveda',
  },
  '24': {
    nickName: 'Suryalogix',
  },
  gLF3UGSudvXbHLKkBKPl: {
    nickName: 'Polyworks Software',
  },
  'LPE-ZUZ48P': {
    nickName: 'Persistence Market Research',
  },
  'LPE-6SF6O3': {
    nickName: 'Researchmoz Global',
  },
  'LPE-7LYDF5': {
    nickName: 'Transparency Market Research',
  },
  'LPE-8G1RCU': {
    nickName: 'Future Market Insights',
  },
  'LPE-0TXG6H': {
    nickName: 'Eminent Research',
  },
  'LPE-1K23SE': {
    nickName: 'Infinite Uptime',
  },
  'LPE-FHU0RB': {
    nickName: 'Encube Ethicals',
  },
  'LPE-9L14HN': {
    nickName: 'ZEE LEARN',
  },
  'LPE-PK5VT0': {
    nickName: 'ROHM SEMICONDUCTOR',
  },
  'LPE-U8SKE9': {
    nickName: 'MyAashiana',
  },
  'LPE-R1D480': {
    nickName: 'Scan-IT Solutions',
  },
  'LPE-LO4SVU': {
    nickName: 'Desk Nine',
  },
  'LPE-15X4HX': {
    nickName: "Suchali's",
  },
  'LPE-4UOLX2': {
    nickName: 'Naiknavare Developers',
  },
  'LPE-BLYULA': {
    nickName: 'SLN Technologies',
  },
  '9jvqLfA2FFvhAU2SJIdA': {
    nickName: 'Livio Building Systems Pvt Ltd',
  },
  'LPE-500KIL': {
    nickName: 'EFC Limited',
  },
  'LPE-5UPKQM': {
    nickName: 'EFC Prime',
  },
  'LPE-HZV509': {
    nickName: 'Edvoy Technologies Pvt Ltd',
  },
  'LPE-7FOGXV': {
    nickName: 'Edvoy Educational Services',
  },
};
const DEV_NICKNAMES: Record<string, { nickName: string }> = {
  'LPE-60MHYH': {
    nickName: 'SITUSAMC INDIA PVT LTD',
  },
  'LPP-762OVH': {
    nickName: 'Loop Health',
  },
};
export const COMPANY_NICKNAMES: Record<string, { nickName: string }> =
  process.env.REACT_APP_ENV === 'production'
    ? PHASE_1_AND_2_COMPANIES
    : DEV_NICKNAMES;
