import { useEffect, useState } from 'react';
import LoopApiService from '../../../../adaptars/LoopApiService';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import { toast } from 'react-toastify';
import {
  prepareCostEndoData,
  transformEditDataForEstimateRequest,
} from '../SubmitEndo/utils';
import { IUploadedPolicywiseData } from '../../../pages/Endorsements/BulkAddMembers/types';
import { IBulkDeleteData } from '../../../pages/Endorsements/BulkDeleteMembers/types';
import { ICostEstimation, IEndoCostItem } from '../SubmitEndo/types';
import { IBulkOperation } from '../types';
import { IBulkEditEntity } from '../../../pages/Endorsements/BulkEditMembers/types';
import useFetchCDAccounts from '../../../pages/CDAccounts/hooks/useFetchCDAccounts';
import { ISingleDeleteEntry } from '../../../pages/Endorsements/SingleDeleteMembers/types';

const useEstimateEndorsementCost = (
  correctData:
    | IUploadedPolicywiseData
    | IBulkDeleteData[]
    | IBulkEditEntity[]
    | ISingleDeleteEntry[],
  currentStep: number,
  mode: IBulkOperation,
) => {
  const selectedCompany = useSelector(selectSelectedCompany);
  const { cdAccounts } = useFetchCDAccounts();
  const [endoCostList, setEndoCostList] = useState<IEndoCostItem[]>([]);
  const [totalLives, setTotalLives] = useState<number>(0);
  const [totalCostIncuringLives, setTotalCostIncuringLives] =
    useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const getEstimateRequestData = () => {
    const premiumPromises: Promise<ICostEstimation>[] = [];
    Object.keys(correctData as IUploadedPolicywiseData).forEach((policy) => {
      const membersList = (correctData as IUploadedPolicywiseData)[policy].map(
        (member) => {
          return {
            employeeId: member.employee_id,
            name: member.name,
            relationship: member.relationship_to_account_holders,
            dob: moment.utc(member.date_of_birth, 'DD/MM/YYYY').toDate(),
            slabId: member.slabId,
            coverageStartDate: moment(member.policy_start_date, 'DD/MM/YYYY')
              .toDate()
              .toISOString(),
            ctc: member.ctc,
          };
        },
      );
      const memberDataPayload = {
        companyId: selectedCompany?.id,
        ADD: { policyId: policy, membersList },
      };
      premiumPromises.push(LoopApiService.estimateEndoCost(memberDataPayload));
    });
    return premiumPromises;
  };

  const getEditEstimateData = () => {
    const policyWiseMembers = transformEditDataForEstimateRequest(
      structuredClone(correctData as IBulkEditEntity[]),
    );
    return Object.keys(policyWiseMembers).map(
      (policyId) =>
        LoopApiService.estimateEndoCost({
          companyId: selectedCompany?.id,
          EDIT: {
            policyId,
            membersList: policyWiseMembers[policyId],
          },
        }) as Promise<ICostEstimation>,
    );
  };

  const getOffboardEstimateRequestData = () => {
    const premiumPromises: Promise<ICostEstimation>[] = [];
    const membersList =
      mode === 'SINGLE_DELETE'
        ? correctData
        : (correctData as IBulkDeleteData[]).map((member) => ({
            employeeId: member.employeeId,
            dateOfLeaving: moment
              .utc(member.dateOfLeaving, 'DD/MM/YYYY')
              .toDate(),
          }));
    const memberDataPayload = {
      companyId: selectedCompany?.id,
      OFFBOARD: { membersList },
    };
    premiumPromises.push(
      LoopApiService.estimateEndoCost(
        memberDataPayload,
      ) as Promise<ICostEstimation>,
    );
    return premiumPromises;
  };

  const getCostEstimate = async () => {
    try {
      const promises =
        mode === 'ADD'
          ? getEstimateRequestData()
          : mode === 'EDIT'
          ? getEditEstimateData()
          : getOffboardEstimateRequestData();
      const response = await Promise.all(promises);
      return response ?? [];
    } catch (e) {
      toast.error('Endo Cost estimation failed');
      return [];
    }
  };

  useEffect(() => {
    const estimateCost = async () => {
      setIsLoading(true);
      if (Object.keys(correctData).length && selectedCompany?.id) {
        const costEstimations = await getCostEstimate();
        const { endoCostList, totalCost, totalLives, totalCostIncuringLives } =
          prepareCostEndoData(
            cdAccounts,
            costEstimations,
            mode,
            mode === 'ADD' ? 0 : (correctData as IBulkEditEntity[]).length,
          );
        setEndoCostList(endoCostList);
        setTotalCost(totalCost);
        setTotalLives(totalLives);
        setTotalCostIncuringLives(totalCostIncuringLives);
      }
      setIsLoading(false);
    };
    if (
      (currentStep === 3 && !['EDIT', 'SINGLE_DELETE'].includes(mode)) ||
      (currentStep === 2 && ['EDIT', 'SINGLE_DELETE'].includes(mode))
    )
      estimateCost();
  }, [currentStep]);

  return {
    endoCostList,
    totalCost,
    totalLives,
    isLoading,
    totalCostIncuringLives,
  };
};
export default useEstimateEndorsementCost;
