import { finalClaimStatus } from '../ClaimsTimeline/constant';
import { ITimelineElement } from '../ClaimsTimeline/types';
import { IClaimTimelineStatus, IClaimTypes } from './types';

export const processTimelineDataUI = (
  timelineElements: ITimelineElement[],
  claimStatuses: IClaimTimelineStatus[],
): ITimelineElement[] => {
  let defaultTimelineCopy = JSON.parse(JSON.stringify(timelineElements));
  defaultTimelineCopy.forEach(
    (item: ITimelineElement, index: number, object: ITimelineElement[]) => {
      claimStatuses.forEach((rspItem: IClaimTimelineStatus) => {
        if (item.status === rspItem.status) {
          item.active = true;
          item.displayDefault = true;
          item.date = rspItem.date;
          item.url = rspItem?.url;
          if (rspItem.text !== '') {
            item.description = rspItem.text;
          }
        }

        if (rspItem.status === 'Processed') {
          if (item.id === 'cup') {
            item.active = true;
          }
          if (item.id === 'cuq') {
            item.active = false;
            item.displayDefault = false;
          }
        }
        if (rspItem.status === 'Rejected') {
          let processedIndex = object.findIndex(
            (item: ITimelineElement) => item.id === 'processed',
          );
          if (processedIndex > -1) {
            object.splice(processedIndex, 1);
          }
          let claimSettledIndex = object.findIndex(
            (item: ITimelineElement) => item.id === 'claim_settled',
          );
          if (claimSettledIndex > -1) {
            object.splice(claimSettledIndex, 1);
          }
          if (item.id === 'cup') {
            item.active = true;
          }
        }

        if (rspItem.status === 'Claim Paid') {
          if (item.id === 'cup' || item.id === 'processed') {
            item.active = true;
          }

          if (item.id === 'cuq') {
            item.active = false;
            item.displayDefault = false;
          }
        }

        const lastClaimStatus = claimStatuses[claimStatuses.length - 1];
        if (
          (lastClaimStatus.status === 'Under progress - query' ||
            lastClaimStatus.status === 'Rejected') &&
          rspItem.status === 'Under progress - query'
        ) {
          let cupIndex = object.findIndex(
            (item: ITimelineElement) => item.status === 'Under progress',
          );
          if (cupIndex > -1) {
            object.splice(cupIndex, 1);
          }
        }
      });
    },
  );
  return defaultTimelineCopy.filter(
    (item: ITimelineElement) => item.displayDefault === true,
  );
};

export const processClaimStatus = (
  status: finalClaimStatus,
  claimType: string,
): finalClaimStatus => {
  if (claimType === IClaimTypes.cashless) {
    if (status && status !== 'Rejected') {
      return 'Claim Paid';
    }
  }
  return status;
};
