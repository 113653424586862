import styled from 'styled-components';

export const StyledSubContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledUploaderContainer = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex-shrink: 0;
  border-radius: 20px;
  background: ${(p) => p.theme.colors.white};
`;

export const StyledUploadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 180px;
`;

export const StyledFooter = styled.div`
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  height: 72px;
  padding: 12px 56px 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
`;

export const StyledDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 48px;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  margin-bottom: 16px;
`;

export const StyledUploadContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  padding-bottom: 16px;
  border-bottom: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledUploadInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
