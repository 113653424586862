import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectHrUser } from '../../redux/slices/usersSlice';
import useQuery from '../hooks/useQuery';
import { SEGMENT_ACTIONS } from '../segment/SegmentActionConstants';
import useSegment from '../segment/hooks/useSegment';
import { IRedirectAuthRoute } from './types';

const RedirectAuthRoute = ({
  component: Component,
  path,
  exact = undefined,
}: IRedirectAuthRoute) => {
  const userData = useSelector(selectHrUser);
  const query = useQuery();
  const redirectTo = query.get('next') || '/';
  const trackLogin = useSegment('identify');

  useEffect(() => {
    if (userData.state === 'succeeded') {
      const splittedName = (userData.data?.name ?? '').split(' ');
      const first_name = splittedName[0] ?? '';
      const last_name = splittedName[1] ?? '';

      trackLogin({
        name: SEGMENT_ACTIONS.IDENTIFY.USER_LOGIN.name,
        properties: {
          email: userData.data?.email,
          employer_name: userData.data?.companyName,
          first_name,
          last_name,
          name: userData.data?.name,
          phone: userData.data?.mobile,
          platform: 'hrd',
          user_id: userData.data?.userId,
          user_type: 'HR',
          employer_id: userData.data?.companyId,
          company_id: userData.data?.companyId,
          company_name: userData.data?.companyName,
        },
      });
    }
  }, [userData.state]);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        userData &&
        userData.data &&
        userData.data.email &&
        userData.data.mobile ? (
          <Redirect to={redirectTo} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default RedirectAuthRoute;
