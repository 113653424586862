import {
  Dialog,
  LoopButton,
  Modal,
  Shadow,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ChevronLeftLightIcon,
  ExitErrorIcon,
  TrashErrorIcon,
  TrashIcon,
} from '../../../../assets/images';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { capitalizeFirstLetter } from '../../../../utils/common/Utilities';
import HightlightHOC from '../../../atoms/HighlightHOC';
import {
  IEditableFields,
  IEditedField,
} from '../../../pages/Endorsements/BulkEditMembers/types';
import { EDIT_FIELD_NAME_MAP, MEMBER_EDIT_REVIEW_ICONS } from '../constants';
import DOBEditContainer from './DOBEditContainer';
import EmailEditContainer from './EmailEditContainer';
import GenderEditContainer from './GenderEditContainer';
import MobileEditContainer from './MobileEditContainer';
import NameEditContainer from './NameEditContainer';
import RelationshipEditContainer from './RelationshipEditContainer';
import {
  StyledArrowBack,
  StyledEditedFieldBody,
  StyledEditedFieldBodyText,
  StyledEditedFieldIcon,
  StyledEditedFieldsEmptyState,
  StyledEditedFieldsList,
  StyledEditedFieldText,
  StyledEditedFieldTrashIcon,
  StyledEditedFieldWrapper,
  StyledEditSectionWrapper,
  StyledMemberEditInfoBody,
  StyledMemberEditInfoBodyWrapper,
  StyledMemberEditInfoHeader,
  StyledMemberEditInfoWrapper,
  StyledReviewHorizontalPadding,
  StyledReviewHorizontalTextPadding,
  StyledReviewSection,
  StyledReviewSectionWrapper,
  StyledSubmitEditButton,
  StyleEditedFieldHeader,
} from './styles';
import { IMemberEditInfo } from './types';
import {
  formatValuesByField,
  getIndividualTrackingFieldsObject,
  validateEditedUser,
} from './utils';
import { transformEditEntityIntoData } from '../../../pages/Endorsements/BulkEditMembers/utils';
import { ErrorKeyMap } from '../../ValidationsScreen/constants';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
  trackPageEvent,
} from '../../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';

const MemberEditInfo: React.FC<IMemberEditInfo> = ({
  setEditStep,
  selectedUser,
  setSelectedUser,
  finalizeEdit,
  handleModalClose,
}) => {
  const trackPage = useSegment('page');
  const trackClick = useSegment('click');
  const location = useLocation();
  const toast = Toast.useToast();
  const [selectedField, setSelectedField] = useState<IEditableFields | null>(
    null,
  );
  const [deleteEditIndex, setDeleteEditIndex] = useState<IEditableFields>();
  const [leaveWithoutSubmitModalVisible, setLeaveWithoutSubmitModalVisible] =
    useState(false);
  const [submitWithoutUnsavedModal, setSubmitWithoutUnsavedModal] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const trackSegmentClickEvent = (
    eventName: string,
    properties?: Record<string, any>,
  ) => {
    trackClick(
      trackClickEvent(
        eventName,
        location?.pathname,
        extractPathName(location?.pathname),
        {
          clickProperties: properties,
        },
      ),
    );
  };

  const handleBackClick = () => {
    trackSegmentClickEvent('Back_Member_Edit_Modal_Screen_edit');
    if (currentEdits.length) setLeaveWithoutSubmitModalVisible(true);
    else handleConfirmGoBack();
  };

  const handleConfirmGoBack = () => {
    if (!selectedUser) return;
    if (selectedUser.isNew) {
      setSelectedUser(null);
      setEditStep(1);
    } else handleModalClose();
  };

  const updateEditedField = (edit: IEditedField) => {
    if (!selectedUser) return;
    const updatedUser = { ...selectedUser };
    const prevEdit = updatedUser.edits[edit.field];
    if (prevEdit) {
      updatedUser.edits[prevEdit.field] = edit;
    } else {
      updatedUser.edits[edit.field] = edit;
    }
    setSelectedUser(updatedUser);
  };

  const confirmDeleteEditedField = () => {
    if (!selectedUser || !deleteEditIndex) return;
    const updatedUser = { ...selectedUser };
    delete updatedUser.edits[deleteEditIndex];
    setSelectedUser(updatedUser);
    setDeleteEditIndex(undefined);
  };

  const validateFieldSave = async (
    field: IEditableFields,
    value: string | null,
  ): Promise<string> => {
    if (!selectedUser) return 'User Not Selected';
    const payloadUser = transformEditEntityIntoData(
      structuredClone(selectedUser),
    );
    payloadUser[field] = value as string;

    try {
      const errors = await validateEditedUser(payloadUser);
      for (let errorKey of ErrorKeyMap[field] || [field]) {
        if (errors[errorKey]) return errors[errorKey];
      }
      return '';
    } catch (e) {
      return (e as Error).message;
    }
  };

  const handleSubmit = () => {
    if (selectedField) {
      setSubmitWithoutUnsavedModal(true);
      return;
    }
    const editedFields = Object.keys(selectedUser?.edits || []);
    trackSegmentClickEvent('Submit_Member_Edit_Modal_Screen_edit', {
      'Employee Id': selectedUser?.employeeId,
      ...getIndividualTrackingFieldsObject(editedFields as IEditableFields[]),
    });
    validateUserAndSubmit();
  };

  const confirmSubmit = () => {
    setSubmitWithoutUnsavedModal(false);
    validateUserAndSubmit();
  };

  const validateUserAndSubmit = async () => {
    if (!selectedUser) return 'User Not Selected';
    setIsSubmitting(true);
    const payloadUser = transformEditEntityIntoData(
      structuredClone(selectedUser),
    );

    let errorObject: Record<string, string> = {};
    try {
      errorObject = await validateEditedUser(payloadUser);
    } catch (e) {
      errorObject.global = (e as Error).message;
    }
    const errors = Object.values(errorObject);
    setIsSubmitting(false);
    if (errors.length) errors.forEach((error) => toast?.error(error));
    else {
      finalizeEdit();
      setSelectedField(null);
    }
  };

  const currentEdits = Object.values(selectedUser?.edits || {});
  const commonDoc = useMemo(() => {
    for (let field of ['name', 'gender', 'dob'] as IEditableFields[]) {
      if (selectedUser?.edits[field]) {
        return selectedUser.edits[field]?.documents[0];
      }
    }
  }, [currentEdits]);

  const handleEditFieldSave = (field: IEditableFields) => {
    trackSegmentClickEvent('Save_Field_Member_Edit_Modal_Screen_edit', {
      'Employee Id': selectedUser?.employeeId,
      ...getIndividualTrackingFieldsObject(field),
    });
  };

  const handleEditFieldSelect = (field: IEditableFields | null) => {
    if (field) {
      trackSegmentClickEvent('Edit_Field_Member_Edit_Modal_Screen_edit', {
        'Employee Id': selectedUser?.employeeId,
        ...getIndividualTrackingFieldsObject(field),
      });
    }
    setSelectedField(field);
  };

  const handleDeleteEditIndex = (field: IEditableFields) => {
    trackSegmentClickEvent('Bin_Review_Member_Edit_Modal_Screen_edit', {
      'Employee Id': selectedUser?.employeeId,
      ...getIndividualTrackingFieldsObject(field),
    });
    setDeleteEditIndex(field);
  };

  useEffect(() => {
    trackPage(trackPageEvent('/member-edit-page'));
  }, []);

  return selectedUser ? (
    <StyledMemberEditInfoWrapper>
      <StyledMemberEditInfoHeader>
        <StyledArrowBack onClick={handleBackClick}>
          <img src={ChevronLeftLightIcon} />
        </StyledArrowBack>
        <Typography variant="large" weight="medium" color="tertiary">
          Edit {capitalizeFirstLetter(selectedUser.firstName)}’s Information
        </Typography>
      </StyledMemberEditInfoHeader>
      <StyledMemberEditInfoBodyWrapper>
        <Shadow variant="nav">
          <WithThemeProvider>
            <StyledMemberEditInfoBody>
              <StyledEditSectionWrapper>
                <HightlightHOC
                  isHighlighted={['name', null].includes(selectedField)}
                >
                  <NameEditContainer
                    handleEditFieldSave={handleEditFieldSave}
                    firstName={selectedUser.firstName}
                    setSelectedField={handleEditFieldSelect}
                    isBeingEdited={selectedField === 'name'}
                    oldValue={selectedUser.name}
                    edit={selectedUser.edits.name}
                    updateEditedField={updateEditedField}
                    validateFieldSave={validateFieldSave}
                    commonDoc={commonDoc}
                    isDocumentRequired={selectedUser.isDocumentRequired}
                  />
                </HightlightHOC>
                <HightlightHOC
                  isHighlighted={['gender', null].includes(selectedField)}
                >
                  <GenderEditContainer
                    handleEditFieldSave={handleEditFieldSave}
                    firstName={selectedUser.firstName}
                    setSelectedField={handleEditFieldSelect}
                    isBeingEdited={selectedField === 'gender'}
                    oldValue={selectedUser.gender}
                    edit={selectedUser.edits.gender}
                    updateEditedField={updateEditedField}
                    validateFieldSave={validateFieldSave}
                    commonDoc={commonDoc}
                    isDocumentRequired={selectedUser.isDocumentRequired}
                  />
                </HightlightHOC>
                <HightlightHOC
                  isHighlighted={['dob', null].includes(selectedField)}
                >
                  <DOBEditContainer
                    handleEditFieldSave={handleEditFieldSave}
                    firstName={selectedUser.firstName}
                    setSelectedField={handleEditFieldSelect}
                    isBeingEdited={selectedField === 'dob'}
                    oldValue={selectedUser.dob}
                    edit={selectedUser.edits.dob}
                    updateEditedField={updateEditedField}
                    validateFieldSave={validateFieldSave}
                    commonDoc={commonDoc}
                    isDocumentRequired={selectedUser.isDocumentRequired}
                  />
                </HightlightHOC>
                <HightlightHOC
                  isHighlighted={['relationship', null].includes(selectedField)}
                >
                  <RelationshipEditContainer
                    handleEditFieldSave={handleEditFieldSave}
                    firstName={selectedUser.firstName}
                    selfName={selectedUser.selfName}
                    setSelectedField={handleEditFieldSelect}
                    isBeingEdited={selectedField === 'relationship'}
                    oldValue={selectedUser.relationship}
                    edit={selectedUser.edits.relationship}
                    updateEditedField={updateEditedField}
                    validateFieldSave={validateFieldSave}
                    isDocumentRequired={selectedUser.isDocumentRequired}
                  />
                </HightlightHOC>
                <HightlightHOC
                  isHighlighted={['mobile', null].includes(selectedField)}
                >
                  <MobileEditContainer
                    handleEditFieldSave={handleEditFieldSave}
                    setSelectedField={handleEditFieldSelect}
                    isBeingEdited={selectedField === 'mobile'}
                    oldValue={selectedUser.mobile}
                    edit={selectedUser.edits.mobile}
                    emailValue={
                      selectedUser.edits.email
                        ? selectedUser.edits.email.after
                        : selectedUser.email
                    }
                    updateEditedField={updateEditedField}
                    validateFieldSave={validateFieldSave}
                  />
                </HightlightHOC>
                <HightlightHOC
                  isHighlighted={['email', null].includes(selectedField)}
                >
                  <EmailEditContainer
                    handleEditFieldSave={handleEditFieldSave}
                    setSelectedField={handleEditFieldSelect}
                    isBeingEdited={selectedField === 'email'}
                    oldValue={selectedUser.email}
                    edit={selectedUser.edits.email}
                    mobileValue={
                      selectedUser.edits.mobile
                        ? selectedUser.edits.mobile.after
                        : selectedUser.mobile
                    }
                    updateEditedField={updateEditedField}
                    validateFieldSave={validateFieldSave}
                  />
                </HightlightHOC>
              </StyledEditSectionWrapper>
              <StyledReviewSectionWrapper>
                <StyledReviewSection>
                  <StyledReviewHorizontalTextPadding>
                    <Typography
                      variant="small"
                      weight="medium"
                      color="secondary"
                    >
                      REVIEW EDITS ({currentEdits.length})
                    </Typography>
                  </StyledReviewHorizontalTextPadding>
                  {currentEdits.length ? (
                    <StyledReviewHorizontalPadding>
                      <StyledEditedFieldsList>
                        {currentEdits.map((edit) => (
                          <StyledEditedFieldWrapper key={edit.field}>
                            <StyleEditedFieldHeader>
                              <StyledEditedFieldText>
                                <StyledEditedFieldIcon
                                  src={MEMBER_EDIT_REVIEW_ICONS[edit.field]}
                                />
                                <Typography
                                  variant="extraSmall"
                                  weight="medium"
                                >
                                  {EDIT_FIELD_NAME_MAP[edit.field]}
                                </Typography>
                              </StyledEditedFieldText>
                              <StyledEditedFieldTrashIcon
                                src={TrashIcon}
                                onClick={() =>
                                  handleDeleteEditIndex(edit.field)
                                }
                              />
                            </StyleEditedFieldHeader>
                            <StyledEditedFieldBody>
                              <StyledEditedFieldBodyText>
                                <Typography variant="small" color="secondary">
                                  Before
                                </Typography>
                                <Typography variant="small" weight="medium">
                                  {formatValuesByField(edit.field, edit.before)}
                                </Typography>
                              </StyledEditedFieldBodyText>
                              <StyledEditedFieldBodyText>
                                <Typography variant="small" color="secondary">
                                  Updated
                                </Typography>
                                <Typography variant="small" weight="medium">
                                  {formatValuesByField(edit.field, edit.after)}
                                </Typography>
                              </StyledEditedFieldBodyText>
                            </StyledEditedFieldBody>
                          </StyledEditedFieldWrapper>
                        ))}
                      </StyledEditedFieldsList>
                    </StyledReviewHorizontalPadding>
                  ) : (
                    <StyledReviewHorizontalTextPadding>
                      <StyledEditedFieldsEmptyState>
                        <Typography variant="small" color="secondary">
                          No edits made. Changes that you’ll do on the left will
                          be visible here.
                        </Typography>
                      </StyledEditedFieldsEmptyState>
                    </StyledReviewHorizontalTextPadding>
                  )}
                </StyledReviewSection>
                <StyledSubmitEditButton>
                  <LoopButton
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    variant={currentEdits.length ? 'filled' : 'disabled'}
                  >
                    Submit Edits
                  </LoopButton>
                </StyledSubmitEditButton>
              </StyledReviewSectionWrapper>
            </StyledMemberEditInfoBody>
          </WithThemeProvider>
        </Shadow>
      </StyledMemberEditInfoBodyWrapper>
      <Modal
        isVisible={deleteEditIndex !== undefined}
        setIsVisible={() => setDeleteEditIndex(undefined)}
      >
        <Dialog
          variant="vertical"
          title={`Deleting “${capitalizeFirstLetter(
            EDIT_FIELD_NAME_MAP[deleteEditIndex!],
          )}” related changes?`}
          description="Once deleted, these changes will not be implemented. Are you sure you want to delete the changes?"
          icon={TrashErrorIcon}
          primaryButtonText="Yes, Delete"
          onPrimaryButtonClicked={confirmDeleteEditedField}
          onSecondaryButtonClicked={() => setDeleteEditIndex(undefined)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
      <Modal
        isVisible={leaveWithoutSubmitModalVisible}
        setIsVisible={setLeaveWithoutSubmitModalVisible}
      >
        <Dialog
          variant="vertical"
          title="Leaving without submitting?"
          description="If you leave now your progress will not be saved. "
          icon={ExitErrorIcon}
          primaryButtonText="Yes, Leave"
          onPrimaryButtonClicked={handleConfirmGoBack}
          onSecondaryButtonClicked={() =>
            setLeaveWithoutSubmitModalVisible(false)
          }
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
      <Modal
        isVisible={submitWithoutUnsavedModal}
        setIsVisible={setSubmitWithoutUnsavedModal}
      >
        <Dialog
          variant="vertical"
          title="Missed saving changes!"
          description="Some changes are not saved. This means you will only be submitting the saved edits. Continue?"
          icon={TrashErrorIcon}
          primaryButtonText="Yes, Continue"
          onPrimaryButtonClicked={confirmSubmit}
          onSecondaryButtonClicked={() => setSubmitWithoutUnsavedModal(false)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
    </StyledMemberEditInfoWrapper>
  ) : (
    <StyledMemberEditInfoWrapper>
      <Typography variant="medium" color="tertiary">
        No user selected to edit
      </Typography>
    </StyledMemberEditInfoWrapper>
  );
};

export default MemberEditInfo;
