import { Chip, Typography } from '@loophealth/loop-ui-web-library';
import { IHRDashboardFilterType, IPolicyHeaderProps } from './types';
import { FC } from 'react';
import { StyledPolicyFilterContainer, StyledPolicyFilters } from './styles';
import { FILTER_ACRONYM, FILTER_TEXT } from './constants';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { trackClickEvent } from '../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';

const PolicyFilter: FC<IPolicyHeaderProps> = ({
  filters,
  activeFilter,
  setActiveFilter,
}) => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const handlePolicyFilterChange = (filter: IHRDashboardFilterType) => {
    trackClick(
      trackClickEvent(
        FILTER_ACRONYM[filter] + '_POLICY',
        location?.pathname,
        'HRD Policy Page Events',
      ),
    );
    setActiveFilter(filter);
  };
  return (
    <StyledPolicyFilterContainer data-testid="policies-filter">
      <Typography variant="title3" weight="medium">
        Policies
      </Typography>
      {filters?.length > 1 && (
        <StyledPolicyFilters data-testid="policies-filter-chip-container">
          {filters.map((filter) => {
            const isActive = activeFilter === filter;
            return (
              <Chip
                key={filter}
                label={FILTER_TEXT[filter]}
                isActive={isActive}
                onClick={() => handlePolicyFilterChange(filter)}
              />
            );
          })}
        </StyledPolicyFilters>
      )}
    </StyledPolicyFilterContainer>
  );
};

export default PolicyFilter;
