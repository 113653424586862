import { Typography } from '@loophealth/loop-ui-web-library';
import { GroupIcon } from '../../../../assets/images';
import { StyledBanner, StyledBannerImage, StyledBannerInfo } from './styles';

const Banner = () => {
  return (
    <StyledBanner>
      <StyledBannerImage src={GroupIcon} />
      <StyledBannerInfo>
        <Typography variant="small" weight="medium" color="secondary">
          Dependants not applicable
        </Typography>
        <Typography variant="small" color="secondary">
          GPA and GTL policies do not allow for the addition of dependants
        </Typography>
      </StyledBannerInfo>
    </StyledBanner>
  );
};

export default Banner;
