import React, { FC, Fragment, useMemo } from 'react';
import {
  StyledAccountsContainer,
  StyledPoliciesContainer,
  StyledPolicyCards,
  StyledLinkagePolicyWrapper,
  StyledVerticalSpacer,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { ICDAccountPolicyData } from '../../pages/CDAccounts/types';
import { YellowSheetIcon } from '../../../assets/images';
import CDAccountInfo from '../CDAccountInfo';
import PolicyCard from '../PolicyCard';
import { useSelector } from 'react-redux';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import { ICDAccountInfo } from '../CDBalanceStatus/types';

const CDAccountCard: FC<ICDAccountInfo> = ({ data, isFirstOngoing }) => {
  const { data: policies } = useSelector(selectPoliciesList);

  const policyList = useMemo(() => {
    return data.policyData.filter(
      (policy) => !!policies.find((policyData) => policyData.id === policy.id),
    );
  }, [policies, data]);

  return (
    <StyledAccountsContainer id={data.accountNumber}>
      <CDAccountInfo data={data} isFirstOngoing={isFirstOngoing}/>
      <StyledPoliciesContainer>
        <>
          <StyledLinkagePolicyWrapper>
            <img src={YellowSheetIcon} alt="linked-policies" />
            <Typography variant="small" weight="medium" color="primary">
              Policies Linked ↓
            </Typography>
          </StyledLinkagePolicyWrapper>

          <StyledPolicyCards>
            {policyList.map((row: ICDAccountPolicyData, index) => (
              <Fragment key={index}>
                <PolicyCard data={row} key={index} />
                {index !== data.policyData.length - 1 && (
                  <StyledVerticalSpacer />
                )}
              </Fragment>
            ))}
          </StyledPolicyCards>
        </>
      </StyledPoliciesContainer>
    </StyledAccountsContainer>
  );
};

export default CDAccountCard;
