import {
  HandlingFilesDeck,
  SittingCalmGraphic,
} from '../../../../assets/images';
import { IZeroStatesData } from './types';

export const ZeroStatesData: IZeroStatesData = {
  Ongoing: {
    title: 'No ongoing endorsements',
    subtitle:
      'You haven’t raised any endorsements yet. Once you do, you’ll be able to track their status here.',
    image: SittingCalmGraphic,
    note: 'Note: Inception endorsements cannot be tracked',
    noteType: 'error',
  },
  Completed: {
    title: 'No completed endorsements',
    subtitle:
      'You don’t have any completed endorsements at the moment. Once an endorsement is fully processed, it will appear here.',
    image: HandlingFilesDeck,
    note: 'Note: Inception endorsements cannot be tracked',
    noteType: 'error',
  },
};
