import {
  Dialog,
  Form,
  LoopBadge,
  LoopButton,
  Modal,
  Toast,
  Typography,
  UploadPillSelection,
} from '@loophealth/loop-ui-web-library';
import React, { useMemo, useState } from 'react';
import {
  BookmarkEmeraldIcon,
  TrashErrorIcon,
} from '../../../../../assets/images';
import { openFileInNewTab } from '../../../../../utils/common/common';
import { IEditedField } from '../../../../pages/Endorsements/BulkEditMembers/types';
import { StyledFitContentWrapper } from '../../../EmpPolicies/styles';
import { RELATIONSHIP_DROPDOWN_ITEMS } from '../../constants';
import { IRelationship } from '../../types';
import {
  StyledDocumentItem,
  StyledDocumentsList,
  StyledFieldBadgeContainer,
  StyledFieldBody,
  StyledFieldBodyContainer,
  StyledFieldButton,
  StyledFieldContainer,
  StyledFieldDocumentsWrapper,
  StyledFieldEditContainer,
  StyledFieldHeaderContainer,
  StyledFileSelectionFooter,
  StyledFileSelectionList,
  StyledRelationshipFileSelectionContainer,
} from '../styles';
import { IEditFieldWithFirstAndSelfName } from '../types';
import { getFileUploadRequiredforRelationship } from '../utils';
const RelationshipEditContainer: React.FC<IEditFieldWithFirstAndSelfName> = ({
  handleEditFieldSave,
  firstName,
  isBeingEdited,
  oldValue,
  edit,
  setSelectedField,
  updateEditedField,
  validateFieldSave,
  selfName,
  isDocumentRequired,
}) => {
  const toast = Toast.useToast();
  const [value, setValue] = useState(oldValue);
  const [selectedFiles, setSelectedFiles] = useState(
    (edit?.documents.map((document) => [document]) || []) as unknown as Record<
      string,
      unknown
    >[][],
  );
  const [deleteFileIndex, setDeleteFileIndex] = useState<number>();
  const [fieldError, setFieldError] = useState<string>('');
  const [isLoading, toggleLoading] = useState(false);

  const isSelf = oldValue === 'self' || true; // relationship not editable for now
  const requiredFiles = useMemo(
    () =>
      isDocumentRequired
        ? getFileUploadRequiredforRelationship(
            firstName,
            selfName,
            value as IRelationship,
          )
        : [],
    [value],
  );

  const handleEditAndCancel = () => {
    setSelectedField(isBeingEdited ? null : 'relationship');
    setFieldError('');
    setValue(edit?.after || oldValue);
  };

  const handleChange = (newValue: string) => {
    setFieldError('');
    setValue(newValue);
  };

  const handleFileSelection = (
    index: number,
    files: Record<string, unknown>[],
  ) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1, files);
    setSelectedFiles(newFiles);
  };

  const handleSave = async () => {
    setFieldError('');
    toggleLoading(true);
    const error = await validateFieldSave('relationship', value);
    if (error) {
      setFieldError(error);
      toggleLoading(false);
      return;
    }
    handleEditFieldSave('relationship');

    const newEdit: IEditedField = {
      ...edit,
      field: 'relationship',
      before: oldValue,
      after: value,
      documents: selectedFiles.flat() as unknown as File[],
    };
    updateEditedField(newEdit);
    setSelectedField(null);
    toggleLoading(false);
  };

  const confirmDocumentDelete = () => {
    const newFiles = [...selectedFiles];
    newFiles.splice(deleteFileIndex!, 1, []);
    setSelectedFiles(newFiles);
    setDeleteFileIndex(undefined);
  };

  const saveEnabled =
    value &&
    oldValue !== value &&
    selectedFiles.length >= requiredFiles.length &&
    !fieldError &&
    requiredFiles.every((_, index) => !!selectedFiles[index].length);

  return (
    <StyledFieldContainer>
      <StyledFieldHeaderContainer>
        <StyledFieldBadgeContainer>
          <Typography variant="medium" weight="medium">
            Relationship
          </Typography>
          {edit ? (
            <LoopBadge
              variant="overflow"
              text="Edited"
              badgeType="success3"
              size="small"
            />
          ) : null}
          {isSelf ? (
            <LoopBadge
              variant="overflow"
              text="Uneditable"
              badgeType="errorFill"
              size="small"
            />
          ) : null}
        </StyledFieldBadgeContainer>
        {!isSelf && (
          <StyledFieldButton>
            <LoopButton
              variant="text"
              size="large"
              onClick={handleEditAndCancel}
            >
              {isBeingEdited ? 'Cancel' : 'Edit'}
            </LoopButton>
          </StyledFieldButton>
        )}
      </StyledFieldHeaderContainer>
      <StyledFieldBodyContainer>
        {isBeingEdited ? (
          <StyledFieldBody>
            <StyledFieldEditContainer>
              <Form.Dropdown
                placeholder="Select Relationship"
                selectedItem={value}
                listItems={RELATIONSHIP_DROPDOWN_ITEMS}
                onClick={handleChange}
                error={fieldError}
              />
              {isDocumentRequired && (
                <StyledFileSelectionList $errored={!!fieldError}>
                  {requiredFiles.map((selection, index) => {
                    return (
                      <StyledRelationshipFileSelectionContainer
                        $isLast={index === requiredFiles.length - 1}
                        key={selection.title + index}
                      >
                        <UploadPillSelection
                          onUpload={(files) =>
                            handleFileSelection(index, files)
                          }
                          formats={['.png', '.jpg', '.jpeg', '.pdf']}
                          selected={selectedFiles[index] ?? []}
                          maxFiles={1}
                          maxSize={25000000}
                          displayTooltip={false}
                          onError={(e) => toast?.error(e.message)}
                          onDelete={() => setDeleteFileIndex(index)}
                          variant="inline"
                          title={selection.title}
                          subtitle={selection.subtitle}
                        />
                        {index === requiredFiles.length - 1 && (
                          <StyledFileSelectionFooter>
                            <Typography variant="extraSmall" color="secondary">
                              Supported Formats: PNG, JPG, PDF
                            </Typography>
                            <Typography variant="extraSmall" color="secondary">
                              Maximum Size: 25MB
                            </Typography>
                          </StyledFileSelectionFooter>
                        )}
                      </StyledRelationshipFileSelectionContainer>
                    );
                  })}
                </StyledFileSelectionList>
              )}
            </StyledFieldEditContainer>
            <StyledFitContentWrapper>
              <LoopButton
                variant={saveEnabled ? 'filled' : 'disabled'}
                onClick={handleSave}
                isLoading={isLoading}
              >
                Save
              </LoopButton>
            </StyledFitContentWrapper>
          </StyledFieldBody>
        ) : (
          <StyledFieldDocumentsWrapper>
            <Typography variant="medium" color="secondary">
              {edit?.after || oldValue}
            </Typography>
            {edit?.documents?.length ? (
              <StyledDocumentsList>
                {edit.documents.map((document) => (
                  <StyledDocumentItem
                    key={document.name}
                    onClick={() => openFileInNewTab(document)}
                  >
                    <img src={BookmarkEmeraldIcon} alt="document-icon" />
                    <Typography variant="small">{document.name}</Typography>
                  </StyledDocumentItem>
                ))}
              </StyledDocumentsList>
            ) : null}
          </StyledFieldDocumentsWrapper>
        )}
      </StyledFieldBodyContainer>
      <Modal
        isVisible={deleteFileIndex !== undefined}
        setIsVisible={() => setDeleteFileIndex(undefined)}
      >
        <Dialog
          variant="vertical"
          title={`Deleting “${selectedFiles[deleteFileIndex!]?.[0].name}”?`}
          description="Are you sure you want to delete this file? You’ll have to re-upload it."
          icon={TrashErrorIcon}
          primaryButtonText="Yes, Delete"
          onPrimaryButtonClicked={confirmDocumentDelete}
          onSecondaryButtonClicked={() => setDeleteFileIndex(undefined)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
    </StyledFieldContainer>
  );
};

export default RelationshipEditContainer;
