import theme from '../../../../theme';

export const getECardStatistics = (
  insuredCount: number,
  eCardsAvailableCount: number,
  eCardsInProgressCount: number,
  endorsementInProgressCount: number,
  isGPAorGTL: boolean,
  insuredEmp: number,
  inProgressEmp: number,
) => {
  const totalECardCount = isGPAorGTL
    ? insuredEmp + inProgressEmp
    : eCardsAvailableCount + eCardsInProgressCount;
  const eCardData = [
    {
      color: theme.colors.loopEmerald,
      label: isGPAorGTL ? 'Insured Employees' : 'Lives with E-Cards',
      value: (isGPAorGTL ? insuredEmp : eCardsAvailableCount) ?? 0,
    },
    {
      color: theme.colors.coverYellow,
      label: isGPAorGTL ? 'Employees In Progress' : 'Lives In Progress',
      value: isGPAorGTL ? inProgressEmp : endorsementInProgressCount ?? 0,
    },
  ];

  const percentage =
    Math.fround(
      ((isGPAorGTL ? insuredEmp : eCardsAvailableCount) * 100) /
        totalECardCount,
    ) || 0;
  return {
    data: eCardData,
    count: totalECardCount.toString(),
    percentage: `${percentage ? percentage.toFixed(0) : percentage}%`,
  };
};

export const getTotalInsuredLivesStatistics = (
  insuredChildrenCount: number,
  insuredEmployeesCount: number,
  insuredParentsCount: number,
  insuredParentsInLawCount: number,
  insuredSpousesCount: number,
  isGPAorGTL: boolean,
) => {
  if (isGPAorGTL) return undefined;

  const totalInsuredLivesCount =
    insuredChildrenCount +
      insuredEmployeesCount +
      insuredParentsCount +
      insuredParentsInLawCount +
      insuredSpousesCount || 0;

  const totalInsuredLivesData = [
    {
      color: theme.colors.turquoise,
      label: 'Employees',
      value: insuredEmployeesCount ?? 0,
    },
    {
      color: theme.colors.coverYellow,
      label: 'Spouses',
      value: insuredSpousesCount ?? 0,
    },
    {
      color: theme.colors.seafoamGray,
      label: 'Parents',
      value: insuredParentsCount ?? 0,
    },
    {
      color: theme.colors.consultViolet,
      label: 'Parents-In-Law',
      value: insuredParentsInLawCount ?? 0,
    },
    {
      color: theme.colors.planRed,
      label: 'Children',
      value: insuredChildrenCount ?? 0,
    },
  ];

  return {
    data: totalInsuredLivesData,
    count: totalInsuredLivesCount.toString(),
  };
};
