import styled from 'styled-components';

export const StyledHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 56px;
`;

export const StyledIconDescription = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 15px;
  align-items: center;
`;

export const StyledEmptyStateContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 56px;
`;
