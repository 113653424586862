import { Typography } from '@loophealth/loop-ui-web-library';
import React, { FC } from 'react';
import { StyledPieChartCenteredTextContainer } from './styles';
import { ICenteredText, ICenteredTextType } from './types';

const CenteredText: FC<ICenteredText> = ({
  label,
  value,
  type = ICenteredTextType.doughnut,
}) => {
  return (
    <StyledPieChartCenteredTextContainer type={type}>
      <Typography variant="title2" weight="medium">
        {value}
      </Typography>
      <Typography variant="small">{label}</Typography>
    </StyledPieChartCenteredTextContainer>
  );
};

export default CenteredText;
