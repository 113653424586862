import styled from 'styled-components';
import { GreyBackgroundPattern } from '../../../assets/images';

export const StyledValidationSummaryCard = styled.div<{ $bg: string }>`
  border-radius: 12px;
  min-height: 220px;
  width: 342px;
  background: url(${(p) => p.$bg});
  background-size: cover;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledCardHeader = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const StyledCardHeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
`;

export const StyledCardPolicyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledPolicyCountItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 4px;
  padding: 8px 12px;
`;

export const StyledProfileIcon = styled.img`
  width: 36px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 8px;
  background-color: ${(p) => p.theme.colors.white};
  align-self: flex-start;
`;
