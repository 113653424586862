import styled from 'styled-components';

export const StyledOverviewSection = styled.section`
  display: flex;
  align-items: center; 
  border-radius: 12px;
  padding: 38px 32px;
  gap: 75px;
  background-color: ${({ theme }) => theme.colors.surfaceOne};
`;
