import { SINGLE_ADD_STEPS } from './constants';
import { StyledSingleAddContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSingleAddState,
  selectAddSelectedPolicyDetails,
  selectSingleAddCurrentStep,
} from '../../../../redux/slices/singleAddSlice';
import SingleAddMembersHeader from '../../../containers/EndoSingleAdd/SingleAddMembersHeader';
import SingleAddMembersFooter from '../../../containers/EndoSingleAdd/SingleAddMembersFooter';
import ChoosePolicy from '../../../containers/EndoSingleAdd/ChoosePolicy';
import AddEmployeeDetails from '../../../containers/EndoSingleAdd/AddEmployeeDetails';
import AddDependantDetails from '../../../containers/EndoSingleAdd/AddDependantDetails';
import { selectPoliciesList } from '../../../../redux/slices/hrdRevampRedux';
import { useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';

const SingleAddMembers = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectSingleAddCurrentStep);
  const policies = useSelector(selectPoliciesList).data;
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);

  const isEndoInProgress = useMemo(
    () =>
      Boolean(
        Object.keys(selectedPolicies).length ||
          currentStep > SINGLE_ADD_STEPS.CHOOSE_POLICY,
      ),
    [currentStep, selectedPolicies],
  );

  useEffect(() => {
    dispatch(resetSingleAddState());
  }, []);

  return (
    <StyledSingleAddContainer>
      <Prompt
        when={isEndoInProgress}
        message="Wait! Are you sure you want to leave? if you leave from here, you'll lose this complete data."
      />
      <SingleAddMembersHeader />
      {currentStep === SINGLE_ADD_STEPS.CHOOSE_POLICY ? (
        <ChoosePolicy policiesList={policies} />
      ) : currentStep === SINGLE_ADD_STEPS.ADD_EMPLOYEE ? (
        <AddEmployeeDetails />
      ) : currentStep === SINGLE_ADD_STEPS.ADD_DEPENDANTS ? (
        <AddDependantDetails />
      ) : null}
      <SingleAddMembersFooter />
    </StyledSingleAddContainer>
  );
};

export default SingleAddMembers;
