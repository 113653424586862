import styled from 'styled-components';

export const StyledPolicyDetailsCoverage = styled.div`
  display: flex;
  padding: 28px 56px;
  flex-direction: column;
  gap: 42px;
  background: ${(p) => p.theme.colors.surfaceOne};
`;

export const StyledPolicyCoverageColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const StyledPolicyCoverageHeader = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.seafoamGray};
`;

export const StyledPolicyCoverageHeaderText = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledPolicyCoverageIcon = styled.img`
  height: 36px;
  width: 36px;
  aspect-ratio: 1;
  background: ${(p) => p.theme.colors.turquoise};
  border-radius: 50%;
  padding: 6px;
`;

export const StyledTopUpHeader = styled.section`
  width: 100%;
  display: flex;
`;

export const StyledTopUpHeaderBasePolicy = styled.div`
  gap: 16px;
  display: flex;
`;

export const StyledTopUpHeaderBasePolicyText = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  min-width: 166px;
`;

export const StyledTopUpHeaderDivider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 24px;
  background-color: ${({ theme }) => theme.colors.seafoamGray};
`;

export const StyledTopUpHeaderDeductibleTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledPolicyName = styled.div`
  & > span {
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
  }
`;

export const StyledTopUpInfoIcon = styled.img`
  margin-top: -6px;
`;
