import React, { useEffect } from 'react';
import { IUserSelectModal } from './types';
import { StyledUserSelectModalWrapper } from './styles';
import { Modal } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../../../theme/WithThemeProvider';
import MemberSearch from '../../../../containers/Bulk/MemberSearch';
import MemberEditInfo from '../../../../containers/Bulk/MemberEditInfo';
import useSegment from '../../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';

const UserSelectModal: React.FC<IUserSelectModal> = ({
  selectedUser,
  setSelectedUser,
  isModalVisible,
  setIsModalVisible,
  setEditedUsers,
  editedUsers,
}) => {
  const [editStep, setEditStep] = React.useState(1);
  const trackClick = useSegment('click');
  const location = useLocation();
  const handleModalClose = () => {
    trackClick(
      trackClickEvent(
        'Cancel_Member_Search_Modal_edit',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    setIsModalVisible(false);
    setSelectedUser(null);
  };

  const finalizeEdit = () => {
    if (!selectedUser) return;
    setEditedUsers((prevUsers) => {
      const newUsers = [...prevUsers];
      const prevIndex = newUsers.findIndex(
        (user) => selectedUser?.userId === user.userId,
      );
      const newUser = { ...selectedUser, isNew: false };
      if (prevIndex !== -1) {
        newUsers.splice(prevIndex, 1, newUser);
      } else {
        newUsers.push(newUser);
      }
      return newUsers;
    });

    handleModalClose();
    setEditStep(1);
  };

  useEffect(() => {
    if (isModalVisible && selectedUser) {
      setEditStep(2);
    } else {
      setEditStep(1);
    }
  }, [isModalVisible, selectedUser]);

  return (
    <Modal
      isVisible={isModalVisible}
      setIsVisible={handleModalClose}
      disablePadding
      isModalClosable={false}
      closeOnBlur={false}
    >
      <WithThemeProvider>
        <StyledUserSelectModalWrapper>
          {editStep === 1 && (
            <MemberSearch
              handleModalClose={handleModalClose}
              setEditStep={setEditStep}
              setSelectedUser={setSelectedUser}
              editedUserIds={editedUsers.map((user) => user.userId)}
            />
          )}
          {editStep === 2 && (
            <MemberEditInfo
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              setEditStep={setEditStep}
              finalizeEdit={finalizeEdit}
              handleModalClose={handleModalClose}
            />
          )}
        </StyledUserSelectModalWrapper>
      </WithThemeProvider>
    </Modal>
  );
};

export default UserSelectModal;
