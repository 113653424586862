import styled from 'styled-components';

export const GreetingContainer = styled.div`
  position: fixed;
  bottom: 95px;
  right: 57px;
  display: flex;
  width: 248px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  border-radius: 8px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 0px 16px 4px rgba(55, 65, 81, 0.06);
`;

export const StyledCrossIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

