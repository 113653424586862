import styled from "styled-components";
import { StarFourWhiteIcon } from "../../../assets/images";
import theme from "../../../theme";

export const StyledDrawerDrawerItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

export const StyledDrawerDrawerItemHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
export const StyledDrawerDrawerItemHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledDrawerDrawerItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 18px;
  border-radius: 8px;
  background: ${theme.colors.lavenderBlush};
`;

export const StyledDrawerDrawerItemContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const StyledDrawerDrawerItemClaimsFooter = styled.div`
  width: fit-content;
`;

export const StyledDrawerDrawerItemCdFooter = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  align-items: start;

  & > div {
    display: flex;
  }
`;

export const StyledTooltipContainer = styled.div`
  justify-content: start;
  display: flex;
  align-items: center;
  gap: 4px;

  & > :last-child{
    display: flex;
  }
`;

export const StyledTooltipListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > p {
      display: flex;
      gap: 7px;
      flex-wrap: nowrap;
      align-items: start;
      margin-bottom: 0;

      ::before{
        content: 'ab';
        color: transparent;
        background: url(${StarFourWhiteIcon});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 12px;
        aspect-ratio: 1;
      }
  }

`;