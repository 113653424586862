import { Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import { IPolicyProps } from './types';

import {
  StyledContainer,
  StyledIcon,
  StyledInfoIcon,
  StyledValueContainer,
  StyledWrapper,
} from './styles';
import { GreyInfoIcon } from '../../../../assets/images';

const PolicyItem: React.FC<IPolicyProps> = ({
  label,
  value,
  icon,
  tooltipText,
}) => {
  return (
    <StyledWrapper>
      <StyledIcon src={icon} />
      <StyledContainer>
        <Typography variant="extraSmall">{label}</Typography>
        <StyledValueContainer>
          <Typography variant="small" weight="semiBold">
            {value}
          </Typography>
          {tooltipText && (
            <Tooltip beak="top-center" type="informative" text={tooltipText}>
              <StyledInfoIcon src={GreyInfoIcon} />
            </Tooltip>
          )}
        </StyledValueContainer>
      </StyledContainer>
    </StyledWrapper>
  );
};
export default PolicyItem;
