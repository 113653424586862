import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 50px;
`;

export const StyledDetailsHeader = styled.div`
  display: flex;
  gap: 12px;
  padding: 32px 55px;
  align-items: center;
  background-color: ${(p) => p.theme.colors.white};
`;

export const StyledIconAndNameContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  text-transform: capitalize;
`;
export const StyledPolicyOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledEmptyContainer = styled.div`
  display: flex;
  padding: 40px 56px;
`;

export const StyledDependentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${(p) => p.theme.colors.surfaceOne};
`;

export const BadgeContainer = styled.div`
  padding-right: 10px;
`;