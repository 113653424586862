import { getAmountInLacs } from '../../../utils/common/Utilities';
import { IPolicyDetail } from '../../pages/PolicyDetails/types';

export const getSlabBasedDeductibleText = (
  policyDetails: IPolicyDetail,
  isCtcMultiplier: boolean,
) => {
  const sumInsured = policyDetails.sumAssuredSlabs[0].sumInsured;
  return !!isCtcMultiplier ? `${sumInsured}x CTC` : getAmountInLacs(sumInsured);
};
