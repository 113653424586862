import { combineReducers } from 'redux';
import companySlice from './companySlice';
import usersSlice from './usersSlice';
import hrdRevampReduxSlice from './hrdRevampRedux';
import applicationStateSlice from './applicationStateSlice';
import singleAddSlice from './singleAddSlice';

const rootReducer = combineReducers({
  users: usersSlice,
  companyProfile: companySlice,
  hrdRevamp: hrdRevampReduxSlice,
  applicationState: applicationStateSlice,
  singleAdd: singleAddSlice,
});

export default rootReducer;
