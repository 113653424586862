import { IPolicyWiseFamilyDetails } from '../components/pages/EmployeeDetails/types';
import LoopEndpoints from './LoopEndpoints';
import { get } from './providers';
import { ISuccessResponse } from './types';

const EmployeeApi = {
  getEmpDependents: (employeeId: string) =>
    get(LoopEndpoints.fetchEmpDependents(employeeId)),

  getEmployeeDetails: (employeeId: string, inactiveRequired = true) =>
    get<ISuccessResponse<IPolicyWiseFamilyDetails>>(
      LoopEndpoints.fetchEmpFamilyDetails(employeeId, inactiveRequired),
    ),

  fetchECard: (userId: string, policyId: string) =>
    get(LoopEndpoints.fetchECardDetails(userId, policyId)),
};

export default EmployeeApi;
