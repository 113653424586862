import styled from 'styled-components';

export const StyledPolicyListItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 56px;
  background-color: ${({ theme }) => theme.colors?.surfaceOne};
  cursor: pointer;
`;

export const StyledPolicyListItemHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledPolicyListItemHeaderInsurerInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 16px;
`;

export const StyledPolicyListItemHeaderInsurerDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const StyledPolicyListItemHeaderMonetaryInfo = styled.div`
  gap: 48px;
  display: flex;
`;

export const StyledHeaderButtonContainer = styled.div`
  height: 48px;
  width: 167px;
`;

export const StyledLowBalanceAlertContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 100%;
  left: 0;
  margin-top: 5px;
`;

export const StyledLowBalanceAlertInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
`;

export const StyledLowBalanceAlertIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

export const StyledPolicyListItemFooter = styled.footer`
  display: flex;
  gap: 40px;
`;

export const HorizontalSeparator = styled.div`
  background: ${(p) => p.theme.colors?.lightSilver};
  height: 1px;
  width: 100%;
`;

export const StyledBullet = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors?.seafoamGray};
`;

export const StyledPolicyItemAlertsContainer = styled.div`
  margin-bottom: 10px;
`;
