import styled from 'styled-components';

export const ChatbotIconContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
`;

export const StyledChatbotIcon = styled.img`
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 27px;
  right: 57px;
`;

export const ChatbotModalContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 40px;
`;
