import React from 'react';
import {
  StyledLoginPageLeftSection,
  StyledLoginPageLeftSectionGraphic,
  StyledLoginPageLeftSectionLogo,
  StyledLoginPageLeftSectionText,
} from './styles';
import { LoginPageGraphic, LoopLogo } from '../../../../../assets/images';
import { Typography } from '@loophealth/loop-ui-web-library';

const SideLayout = () => {
  return (
    <StyledLoginPageLeftSection>
      <StyledLoginPageLeftSectionLogo src={LoopLogo} />

      <StyledLoginPageLeftSectionGraphic src={LoginPageGraphic} />

      <StyledLoginPageLeftSectionText>
        <Typography variant="title1" color="tertiary">
          Take Control Of Employee
          <br />
          Insurance In One Place
        </Typography>

        <Typography variant="large" color="tertiary">
          Stay informed, offer support and save time
          <br />
          for what matters
        </Typography>
      </StyledLoginPageLeftSectionText>
    </StyledLoginPageLeftSection>
  );
};

export default SideLayout;
