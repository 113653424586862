import styled from 'styled-components';
import theme from '../../../../theme';
import { Link } from 'react-router-dom';
export const StyledClaimDetailsAccordion = styled.div``;

export const StyledClaimDetailsAccordionBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
`;

export const StyledClaimDetailsAccordionBodyList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
`;

export const StyledClaimDetailsAccordionBodyListItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  & :first-child{
    white-space: nowrap;
  }
  & :last-child{
    text-align: right;
  }

`;

export const StyledClaimDetailsAccordionBodySeparator = styled.div`
  width: 1px;
  background-color: ${theme.colors.seafoamGray};
  margin: 0 28px;
`;

export const StyledCustomAccordionTitle = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 4px 0;
`;

export const StyledLink = styled(Link)`
  display: flex;
  color: ${theme.colors.loopEmerald};
  font-size: ${theme.fontSizes.body};
  text-underline-offset: 3px;
  font-family: 'Work Sans';
  &:hover {
    color: ${theme.colors.loopEmerald};
  }
`;
