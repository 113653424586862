import { Chip, Form, Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledChipContainer,
  StyledEmployeeContainer,
  StyledEmployeeFormContainer,
  StyledEmployeeFormItem,
} from './styles';
import { IEmployeeFormData, IEmployeeFormErrorData } from './types';
import { useState } from 'react';
import {
  initialEmployeeFormData,
  initialEmployeeFormErrorData,
} from './constants';
import { GENDER_DROPDOWN_ITEMS } from '../../Bulk/constants';

const AddEmployeeDetails = () => {
  const [formData, setFormData] = useState<IEmployeeFormData>(
    initialEmployeeFormData,
  );
  const [errorData, setErrorData] = useState<IEmployeeFormErrorData>(
    initialEmployeeFormErrorData,
  );
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };
  const handleGenderChange = (gender: string) => {
    setFormData({
      ...formData,
      gender,
    });
  };
  const handleDateChange = (key: string, value: Date) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  return (
    <StyledEmployeeContainer>
      <Typography variant="large">Employee Information</Typography>
      <StyledEmployeeFormContainer>
        <StyledEmployeeFormItem>
          <Form.FormLabel id="employeeId" label="Employee ID" required />
          <Form.Input
            type="text"
            id="employeeId"
            value={formData.employeeId}
            error={errorData.employeeId}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem>
          <Form.FormLabel id="firstName" label="First Name" required />
          <Form.Input
            type="text"
            id="firstName"
            error={errorData.firstName}
            value={formData.firstName}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem>
          <Form.FormLabel id="firstName" label="Last Name" required />
          <Form.Input
            type="text"
            id="lastName"
            error={errorData.lastName}
            value={formData.lastName}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem>
          <Form.FormLabel id="gender" label="Gender" required />
          <StyledChipContainer>
            {GENDER_DROPDOWN_ITEMS.map((item) => (
              <Chip
                label={item.name}
                key={item.value}
                isActive={formData.gender === item.value}
                onClick={() => handleGenderChange(item.value)}
              />
            ))}
          </StyledChipContainer>
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem>
          <Form.FormLabel id="dateOfBirth" label="Date of Birth" required />
          <Form.DatePicker
            placeholder="DD MMM YY"
            value={formData.dateOfBirth}
            onChange={(value) => handleDateChange('dateOfBirth', value)}
            error={errorData.dateOfBirth}
            displayFormat="DD MMM YY"
            iconOrder="right"
            maxDate={new Date()}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem>
          <Form.FormLabel id="dateOfJoining" label="Date of Joining" required />
          <Form.DatePicker
            placeholder="DD MMM YY"
            value={formData.dateOfJoining}
            onChange={(value) => handleDateChange('dateOfJoining', value)}
            error={errorData.dateOfJoining}
            displayFormat="DD MMM YY"
            iconOrder="right"
            maxDate={new Date()}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem>
          <Form.FormLabel id="email" label="Work Email" required />
          <Form.Input
            type="text"
            id="email"
            error={errorData.email}
            value={formData.email}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem>
          <Form.FormLabel id="mobile" label="Mobile Number" required />
          <Form.Input
            type="text"
            id="mobile"
            error={errorData.mobile}
            value={formData.mobile}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem></StyledEmployeeFormItem>
      </StyledEmployeeFormContainer>
    </StyledEmployeeContainer>
  );
};

export default AddEmployeeDetails;
