import { LoopBadge, Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledCDContainer,
  StyledCDContainerLeft,
  StyledCDContainerRight,
  StyledCDContent,
  StyledCDIcon,
  StyledCDIconContainer,
  StyledCDProgress,
  StyledCDWrapper,
} from './styles';
import { RoutingIcon } from '../../../assets/images';
import { useSelector } from 'react-redux';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';

const CDAccountInProgressCard = () => {
  const { data: policies } = useSelector(selectPoliciesList);
  const policiesWithCdAccountCount =
    policies?.filter((p) => p.isCdAccountPresent)?.length || 0;
  const policiesWithNoCdAccountCount =
    policies?.filter((p) => !p.isCdAccountPresent)?.length || 0;

  return (
    <StyledCDWrapper>
      <StyledCDContainer>
        <StyledCDContainerLeft>
          <StyledCDIconContainer>
            <StyledCDIcon src={RoutingIcon} />
          </StyledCDIconContainer>
          <StyledCDContent>
            <Typography variant="medium" weight="semiBold">
              CD account created for {policiesWithCdAccountCount}{' '}
              {policiesWithCdAccountCount > 1 ? 'policies' : 'policy'},{' '}
              {policiesWithNoCdAccountCount} more in progress
            </Typography>
            <Typography variant="small" weight="regular" color="secondary">
              We&apos;re in the process of creating your remaining CD accounts.
              If there are any delays or have questions, please contact your
              CSM.
            </Typography>
          </StyledCDContent>
        </StyledCDContainerLeft>
        <StyledCDContainerRight>
          <StyledCDProgress>
            <LoopBadge
              badgeType="warning"
              variant="overflow"
              text="In Progress"
            />
          </StyledCDProgress>
        </StyledCDContainerRight>
      </StyledCDContainer>
    </StyledCDWrapper>
  );
};

export default CDAccountInProgressCard;
