import {
  LoopButton,
  OutsideClickWrapper,
  Shadow,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  CardSlashIcon,
  CrossOutlineDarkIcon,
  FlagRoundedOutlinedIcon,
  ProfileDeleteDarkIcon,
  SmsIcon
} from '../../../../assets/images';
import { addSingleDeleteFaqShownUserId } from '../../../../redux/slices/applicationStateSlice';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { StyledCloseIcon } from '../../RecommendedCdBreakdownModal/styles';
import {
  StyledSingleDeleteFaq,
  StyledSingleDeleteFaqContent,
  StyledSingleDeleteFaqContentWrapper,
  StyledSingleDeleteFaqHeader,
  StyledSingleDeleteFaqHeaderText,
  StyledSingleDeleteFaqIcon,
  StyledSingleDeleteFaqIconWrapper,
  StyledSingleDeleteFaqImportantBadge,
  StyledSingleDeleteFaqList,
  StyledSingleDeleteFaqListItem,
  StyledSingleDeleteFaqNote,
  StyledSingleDeleteFaqSeperator,
  StyledSingleDeleteFaqWrapper,
} from './styles';
import { ISingleDeleteFaq, ISingleDeleteFaqIcon } from './types';

const SingleDeleteFaqIcon: React.FC<ISingleDeleteFaqIcon> = ({ src }) => {
  return (
    <Shadow variant="nav">
      <WithThemeProvider>
        <StyledSingleDeleteFaqIconWrapper>
          <StyledSingleDeleteFaqIcon
            src={src}
            alt="single-delete-popup-icons"
          />
        </StyledSingleDeleteFaqIconWrapper>
      </WithThemeProvider>
    </Shadow>
  );
};

const SingleDeleteFaq: React.FC<ISingleDeleteFaq> = ({
  userId,
  isFaqAlreadyShown,
}) => {
  const dispatch = useDispatch();
  const [isFaqVisible, setIsFaqVisible] = React.useState(!isFaqAlreadyShown);
  const onFaqClick = () => {
    setIsFaqVisible(true);
  };
  const handleClose = () => {
    setIsFaqVisible(false);
    if (!isFaqAlreadyShown) dispatch(addSingleDeleteFaqShownUserId(userId));
  };
  const onOutsideClick = () => {
    if (isFaqAlreadyShown) handleClose();
  };
  return (
    <WithThemeProvider>
      <StyledSingleDeleteFaqWrapper>
        <LoopButton onClick={onFaqClick} variant="text" fontWeight="semiBold">
          FAQs
        </LoopButton>
        {isFaqVisible && (
          <Shadow variant="modal">
            <OutsideClickWrapper onClickOutside={onOutsideClick}>
              <WithThemeProvider>
                <StyledSingleDeleteFaq>
                  <StyledSingleDeleteFaqContentWrapper>
                    <StyledSingleDeleteFaqImportantBadge>
                      <Typography
                        variant="extraSmall"
                        weight="medium"
                        color="tertiary"
                      >
                        Important!
                      </Typography>
                    </StyledSingleDeleteFaqImportantBadge>
                    <StyledSingleDeleteFaqContent>
                      <StyledSingleDeleteFaqHeader>
                        <StyledSingleDeleteFaqHeaderText>
                          <Typography variant="medium" weight="medium">
                            What happens when you delete an employee?
                          </Typography>
                        </StyledSingleDeleteFaqHeaderText>
                        <StyledCloseIcon
                          src={CrossOutlineDarkIcon}
                          onClick={handleClose}
                        />
                      </StyledSingleDeleteFaqHeader>
                      <StyledSingleDeleteFaqList>
                        <StyledSingleDeleteFaqListItem>
                          <SingleDeleteFaqIcon src={CardSlashIcon} />
                          <Typography variant="small">
                            Deleting an employee also removes their dependants
                          </Typography>
                        </StyledSingleDeleteFaqListItem>
                        <StyledSingleDeleteFaqListItem>
                          <SingleDeleteFaqIcon src={ProfileDeleteDarkIcon} />
                          <Typography variant="small">
                            Employee and dependants will be removed from&nbsp;
                            <Typography variant="small" weight="semiBold">
                              all insurance policies
                            </Typography>
                          </Typography>
                        </StyledSingleDeleteFaqListItem>
                        <StyledSingleDeleteFaqListItem>
                          <SingleDeleteFaqIcon src={FlagRoundedOutlinedIcon} />
                          <Typography variant="small">
                            Deletions are finalized after insurer review
                          </Typography>
                        </StyledSingleDeleteFaqListItem>
                      </StyledSingleDeleteFaqList>
                    </StyledSingleDeleteFaqContent>
                    <StyledSingleDeleteFaqSeperator />
                    <StyledSingleDeleteFaqNote>
                      <SingleDeleteFaqIcon src={SmsIcon} />
                      <Typography variant="small">
                        For&nbsp;
                        <Typography variant="small" weight="semiBold">
                          dependant-only&nbsp;
                        </Typography>
                        deletions, contact your CSM
                      </Typography>
                    </StyledSingleDeleteFaqNote>
                  </StyledSingleDeleteFaqContentWrapper>
                </StyledSingleDeleteFaq>
              </WithThemeProvider>
            </OutsideClickWrapper>
          </Shadow>
        )}
      </StyledSingleDeleteFaqWrapper>
    </WithThemeProvider>
  );
};

export default SingleDeleteFaq;
