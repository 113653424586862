import { Dialog, Modal } from '@loophealth/loop-ui-web-library';
import { FC } from 'react';
import { ExitErrorIcon } from '../../../../assets/images';
import { IEndoAddExitModalProps } from './types';

const EndoSingleExitModal: FC<IEndoAddExitModalProps> = ({
  isVisible,
  onExit,
  onClose,
}) => {
  return (
    <Modal isVisible={isVisible} setIsVisible={onClose}>
      <Dialog
        variant="vertical"
        title="Are you sure you want to exit?"
        description="If you leave now, this action will be canceled, and no changes will be made."
        icon={ExitErrorIcon}
        primaryButtonText="Yes, Leave"
        onPrimaryButtonClicked={onExit}
        onSecondaryButtonClicked={onClose}
        secondaryButtonText="Cancel"
        buttonVariant="error"
      />
    </Modal>
  );
};
export default EndoSingleExitModal;
