import styled from 'styled-components';

export const StyledStepsContainer = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.colors.mintFrost};
  border-radius: 50px;
  padding: 6px 14px;
  margin-top: 10px;
  align-items: center;
  gap: 8px;
  max-width: 90%;
  width: fit-content;
`;
