import React, { useMemo } from 'react';
import {
  IDeleteDocumentState,
  IMidTermAdditionModal,
  ITableEntry,
} from './types';
import {
  Dialog,
  LoopButton,
  Modal,
  Table,
  Typography,
} from '@loophealth/loop-ui-web-library';
import {
  StyledMidTermBody,
  StyledMidTermFooter,
  StyledMidTermHeader,
  StyledMidTermModalWrapper,
} from './styles';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
  deleteMidtermDocument,
  deleteZombieDocs,
  getMidTermTableColumns,
  getTableDataChecks,
  formatTableData,
  uploadMidtermDocument,
  validateMidTerms,
  updateFilePathForMidTerms,
  getRejectedEntriesOnSkip,
} from './utils';
import {
  ExitErrorIcon,
  QuestionMarkIcon,
  TrashErrorIcon,
} from '../../../assets/images';
import { toast } from 'react-toastify';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { LOADING_CONSTANTS } from '../Bulk/constants';
import { trackTaskEvent } from '../../../utils/segment/utils';

const MidTermAdditionModal = ({
  isVisible,
  setIsVisible,
  identifedMidterms,
  setRejectedEntries,
  uploadedData,
  validateData,
  setLoading,
}: IMidTermAdditionModal) => {
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const location = useLocation();
  const [tableData, setTableData] = React.useState<ITableEntry[]>([]);
  const [deleteDocumentState, setDeleteDocumentState] =
    React.useState<IDeleteDocumentState | null>();
  const [skipModalVisible, setSkipModalVisible] = React.useState(false);
  const [submitPartialModalVisible, setSubmitPartialModalVisible] =
    React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [documentProcessing, toggleDocumentProcessing] = React.useState<
    boolean[]
  >([]);
  const handleDocumentProcessing = (index: number, state: boolean) =>
    toggleDocumentProcessing((prevStates) => {
      const prev = [...prevStates];
      prev[index] = state;
      return prev;
    });
  const isDocumentProcessing = useMemo(
    () => documentProcessing.some((state) => state),
    [documentProcessing],
  );

  const { isAllMidtermCompleted, isAnyMidtermCompleted, missingDataEntries } =
    useMemo(() => getTableDataChecks(tableData), [tableData]);

  React.useEffect(() => {
    setTableData(formatTableData(identifedMidterms));
  }, [identifedMidterms]);

  const handleDateChange = (index: number, newDate: Date) => {
    setTableData((prevTableData) =>
      prevTableData.map((tableEntry, i) =>
        index === i ? { ...tableEntry, dateOfMarriage: newDate } : tableEntry,
      ),
    );
  };

  const handleFileChange = async (index: number, file: File | null) => {
    handleDocumentProcessing(index, true);
    let filePath: string | undefined;
    try {
      if (file) {
        filePath = await uploadMidtermDocument(
          tableData[index].employee_id,
          tableData[index].name,
          file,
        );
      } else {
        filePath = tableData[index].filePath;
        await deleteMidtermDocument(filePath);
        filePath = undefined;
      }
    } catch (e) {
      toast.error((e as Error).message);
      handleDocumentProcessing(index, false);
      return;
    }
    setTableData((prevTableData) =>
      prevTableData.map((tableEntry, i) =>
        index === i
          ? { ...tableEntry, marriageCertificate: file, filePath }
          : tableEntry,
      ),
    );
    handleDocumentProcessing(index, false);
  };

  const onDocumentDeleteClick = (index: number) => {
    setIsVisible(false);
    setDeleteDocumentState({ visible: true, index });
    trackClick(
      trackClickEvent(
        'Trashcan_Icon_Midterm_File_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const onConfirmDelete = () => {
    if (deleteDocumentState) {
      handleFileChange(deleteDocumentState.index, null);
      setIsVisible(true);
      setDeleteDocumentState(null);
      trackClick(
        trackClickEvent(
          'Yes_Delete_Midterm_File_add',
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
    }
  };

  const onCancelDelete = () => {
    setIsVisible(true);
    setDeleteDocumentState(null);
    trackClick(
      trackClickEvent(
        'Go_Back_Midterm_File_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const onSkipClick = () => {
    trackClick(
      trackClickEvent(
        'Skip_Midterm_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    setIsVisible(false);
    if (!isAnyMidtermCompleted) {
      setSubmitPartialModalVisible(true);
    } else {
      setSkipModalVisible(true);
    }
  };

  const onConfirmSkipClick = async () => {
    if (processing) return;
    setProcessing(true);
    const docUplodedEntries = tableData.filter((tableEntry) =>
      Boolean(tableEntry.marriageCertificate && tableEntry.filePath),
    );
    if (docUplodedEntries.length) {
      await deleteZombieDocs(docUplodedEntries);
    }

    const rejectedEntries = getRejectedEntriesOnSkip(identifedMidterms);
    setRejectedEntries(rejectedEntries);
    validateData(rejectedEntries, uploadedData);

    setSkipModalVisible(false);
    setIsVisible(false);
    setLoading(LOADING_CONSTANTS.VALIDATE_MID_TERMS);
    setProcessing(false);
    trackClick(
      trackClickEvent(
        'Skip_Post_Midterm_Upload_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const onCancelSkipClick = () => {
    if (processing) return;
    setIsVisible(true);
    setSkipModalVisible(false);
    trackClick(
      trackClickEvent(
        'Go_Back_Post_Midterm_Upload_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const onSubmitClick = () => {
    trackClick(
      trackClickEvent(
        'Submit_Details_Midterm_add',
        location?.pathname,
        extractPathName(location?.pathname),
        {
          clickProperties: {
            no_of_lives: tableData.length,
          },
        },
      ),
    );
    if (isAllMidtermCompleted) onConfirmPartialSubmit();
    else {
      setSubmitPartialModalVisible(true);
      setIsVisible(false);
    }
  };

  const onConfirmPartialSubmit = async () => {
    if (processing) return;
    setProcessing(true);
    setLoading(LOADING_CONSTANTS.VALIDATE_MID_TERMS);
    const partialDocsUploaded = missingDataEntries.filter((tableEntry) =>
      Boolean(tableEntry.marriageCertificate),
    );
    if (partialDocsUploaded.length) {
      await deleteZombieDocs(partialDocsUploaded);
    }
    const { rejectedEntries, updatedAcceptedEntries } = await validateMidTerms(
      identifedMidterms,
      tableData,
    );

    const updatedUploadedData = updateFilePathForMidTerms(
      uploadedData,
      updatedAcceptedEntries,
    );
    setRejectedEntries(rejectedEntries);
    validateData(rejectedEntries, updatedUploadedData);

    setSubmitPartialModalVisible(false);
    setIsVisible(false);
    setProcessing(false);
    trackClick(
      trackClickEvent(
        'Yes_Leave_Post_Midterm_Upload_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const onCancelPartialSubmit = () => {
    if (processing) return;
    setIsVisible(true);
    setSubmitPartialModalVisible(false);
  };

  const handleFileUploadError = (error: Error) => {
    toast.error(error.message, {
      position: 'bottom-left',
      theme: 'colored',
    });
    if (error.message.includes('file formats')) {
      trackTask(
        trackTaskEvent('Load_Incorrect_Format_Midterm_add', location.pathname),
      );
    } else if (error.message.includes('file greater than')) {
      trackTask(
        trackTaskEvent(
          'Load_Incorrect_File_Size_Midterm_add',
          location.pathname,
        ),
      );
    }
  };

  const TABLE_COLUMNS = getMidTermTableColumns(
    handleDateChange,
    handleFileChange,
    onDocumentDeleteClick,
    documentProcessing,
    isDocumentProcessing,
    handleFileUploadError,
  );
  return (
    <>
      {/* PRIMARY MID TERM MODAL */}
      <Modal
        isVisible={isVisible}
        setIsVisible={onSkipClick}
        disablePadding
        isModalClosable={false}
      >
        <WithThemeProvider>
          <StyledMidTermModalWrapper>
            <StyledMidTermHeader>
              <Typography variant="medium" weight="medium">
                Need attention on spouse additions! 🫣
              </Typography>
              <Typography variant="small" color="secondary">
                For existing employees, we require marriage date and
                certificates to add their spouse.
              </Typography>
            </StyledMidTermHeader>
            <StyledMidTermBody>
              <Table columns={TABLE_COLUMNS} data={tableData} />
            </StyledMidTermBody>
            <StyledMidTermFooter>
              <LoopButton
                variant={
                  processing || isDocumentProcessing ? 'disabled' : 'outline'
                }
                size="large"
                onClick={onSkipClick}
              >
                Skip for Now
              </LoopButton>
              <LoopButton
                variant={
                  isAnyMidtermCompleted && !isDocumentProcessing
                    ? 'filled'
                    : 'disabled'
                }
                isLoading={processing}
                size="large"
                onClick={onSubmitClick}
              >
                Submit Details
              </LoopButton>
            </StyledMidTermFooter>
          </StyledMidTermModalWrapper>
        </WithThemeProvider>
      </Modal>

      {/* DOCUMENT DELETION MODAL */}
      <Modal
        isVisible={!!deleteDocumentState?.visible}
        setIsVisible={onCancelDelete}
      >
        {deleteDocumentState && (
          <Dialog
            variant="vertical"
            title={`Deleting “${tableData[deleteDocumentState.index].name}”?`}
            description="Are you sure you want to delete this file? You’ll have to re-upload it."
            icon={TrashErrorIcon}
            primaryButtonText="Yes, Delete"
            isLoading={processing}
            onPrimaryButtonClicked={onConfirmDelete}
            onSecondaryButtonClicked={onCancelDelete}
            secondaryButtonText="Go Back"
            buttonVariant="error"
          />
        )}
      </Modal>

      {/* SKIP MID TERM MODAL */}
      <Modal isVisible={skipModalVisible} setIsVisible={onCancelSkipClick}>
        <Dialog
          variant="vertical"
          title="Wait! Your Data will be Lost!"
          description="If you leave now, you'll lose all the data and files you've provided. Are you sure you want to exit and lose progress?"
          icon={ExitErrorIcon}
          isLoading={processing}
          primaryButtonText="Yes, Leave"
          onPrimaryButtonClicked={onConfirmSkipClick}
          onSecondaryButtonClicked={onCancelSkipClick}
          secondaryButtonText="Go Back"
          buttonVariant="error"
        />
      </Modal>

      {/* SUBMIT PARTIAL MID TERM MODAL */}
      <Modal
        isVisible={submitPartialModalVisible}
        setIsVisible={onCancelPartialSubmit}
      >
        <Dialog
          variant="vertical"
          title={`Marriage information is missing for ${missingDataEntries.length} spouse entries!`}
          description="Endorsements won’t be processed for these entries if complete info is not provided. Are you sure you want to proceed further?"
          icon={QuestionMarkIcon}
          isLoading={processing}
          primaryButtonText="Yes, Proceed"
          onPrimaryButtonClicked={onConfirmPartialSubmit}
          onSecondaryButtonClicked={onCancelPartialSubmit}
          secondaryButtonText="Go Back"
          buttonVariant="error"
        />
      </Modal>
    </>
  );
};

export default MidTermAdditionModal;
