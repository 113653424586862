import { useState } from 'react';

import { toast } from 'react-toastify';
import LoopApiService from '../../../../adaptars/LoopApiService';
import {
  capitalizeFirstLetterOfEachWord,
  parseResponse,
} from '../../../../utils/common/Utilities';
import { IBulkOperation } from '../types';

function useTemplateDownload(
  name: string,
  companyId: string,
  operation: IBulkOperation,
) {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadTemplate = async () => {
    setIsDownloading(true);
    try {
      const [error, result] = await parseResponse(
        operation === 'ADD'
          ? LoopApiService.downloadBulkAddTemplate(companyId)
          : LoopApiService.downloadBulkDeleteTemplate(companyId),
      );
      if (result?.ok) {
        const blob = await result.blob();
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${name}.xlsx`;
        link.click();
        URL.revokeObjectURL(blobUrl);
        toast.success(
          `Bulk ${capitalizeFirstLetterOfEachWord(
            operation,
          )} Template” has been successfully saved in your device.`,
          {
            position: toast.POSITION.BOTTOM_LEFT,
          },
        );
      } else {
        toast.error(error, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (error) {
      toast.error(
        `An error occurred while fetching Bulk ${capitalizeFirstLetterOfEachWord(
          operation,
        )} Template`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        },
      );
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    startDownload: downloadTemplate,
    isDownloading,
  };
}

export default useTemplateDownload;
