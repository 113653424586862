import { Typography } from '@loophealth/loop-ui-web-library';
import React, { FC, useState } from 'react';
import { ChatbotCrossIcon } from '../../../../assets/images';
import { GreetingContainer, HeaderContainer, StyledCrossIcon } from './styles';
import { IChatbotGreetingCardProps } from './types';

const ChatbotGreetingCard: FC<IChatbotGreetingCardProps> = ({
  message,
  isVisible,
  setIsVisible,
}) => {
  return (
    <>
      {isVisible && (
        <GreetingContainer>
          <HeaderContainer>
            <Typography variant="small">Hello!👋</Typography>
            <StyledCrossIcon
              src={ChatbotCrossIcon}
              onClick={() => setIsVisible(false)}
            />
          </HeaderContainer>
          <Typography variant="small">{message}</Typography>
        </GreetingContainer>
      )}
    </>
  );
};

export default ChatbotGreetingCard;
