import { IMethod } from '../../adaptars/types';
import {
  BulkAddTemplateDoc,
  BulkDeleteTemplateDoc,
  CdStatementGMCDoc,
  CdStatementGPADoc,
} from '../../assets/docs';
import { isProduction } from '../featureFlag';
import {
  DEMO_CD_ACCOUNTS_RESPONSE,
  DEMO_CLAIM_TIMELINE_1,
  DEMO_CLAIM_TIMELINE_2,
  DEMO_CLAIMS_RESPONSE,
  DEMO_COMPANY_CONFIG,
  DEMO_COMPANY_DETAILS,
  DEMO_EDIT_ESTIMATE,
  DEMO_EMPLOYEE_ALL_FAMILY_DETAILS,
  DEMO_EMPLOYEE_CLAIMS,
  DEMO_EMPLOYEE_DETAILS,
  DEMO_ENDO_DETAILS_1,
  DEMO_ENDO_DETAILS_2,
  DEMO_ENDO_DETAILS_3,
  DEMO_ENDO_DETAILS_4,
  DEMO_ENDO_DETAILS_5,
  DEMO_ENDORSEMENTS_LIST_RESPONSE,
  DEMO_GMC_ADD_ESTIMATE,
  DEMO_GMC_MEMBERS_LIST,
  DEMO_GMC_POLICY_PLAN,
  DEMO_GPA_ADD_ESTIMATE,
  DEMO_GPA_MEMBERS_LIST,
  DEMO_GPA_POLICY_PLAN,
  DEMO_IDENTIFY_MIDTERM_ADDITIONS,
  DEMO_MEMBER_SEARCH_RESPONSE,
  DEMO_OFFBOARD_ESTIMATE,
  DEMO_POLICY_PLANS_RESPONSE,
  DEMO_SELF_ECARD,
  DEMO_SKIPPED_URLS,
  DEMO_SPOUSE_ECARD,
  DEMO_SUBMIT_ADD_MEMBERS,
  DEMO_SUBMIT_DELETE_MEMBERS,
  DEMO_VALIDATE_ADD_MEMBERS,
  DEMO_VALIDATE_EDIT_ERRORS,
  DEMO_VALIDATE_NO_ERRORS,
  DEMO_VALIDATE_OFFBOARD_MEMBERS,
} from './constants';

const formatURL = (method: string, url: string) => {
  const rawUrl = url.split('?');
  return `${method}:${rawUrl[0]}`;
};

export const ALPHABET_ID = isProduction ? 'LPE-IAPQJ8' : 'LPE-LHVPHZ';

export const accessDemoAPI = async <ReqData, ResData>(
  method: IMethod,
  url: string,
  body?: ReqData,
): Promise<ResData> => {
  const startTime = Date.now();
  const endpoint = formatURL(method, url);
  let response: unknown;

  if (endpoint.startsWith('GET:/userPolicies/allFamilyDetails/'))
    response = DEMO_EMPLOYEE_ALL_FAMILY_DETAILS;
  else if (
    endpoint.match(/^GET:\/user\/[^\/\s]+$/) &&
    !endpoint.includes('/v2')
  )
    response = DEMO_EMPLOYEE_DETAILS;
  else if (url === `/claims?companyId=${ALPHABET_ID}&employeeId=Alpha05`)
    response = DEMO_EMPLOYEE_CLAIMS;
  else if (endpoint === 'POST:/premiumRater/estimate')
    response = getDemoEstimateResponse(body);
  else {
    switch (endpoint) {
      case `GET:/policyPlan/active/${ALPHABET_ID}`:
        response = DEMO_POLICY_PLANS_RESPONSE;
        break;
      case 'GET:/cdAccount':
        response = DEMO_CD_ACCOUNTS_RESPONSE;
        break;
      case 'GET:/claims':
        response = DEMO_CLAIMS_RESPONSE;
        break;
      case `GET:/endorsement/listAllEndorsementsForHRD/${ALPHABET_ID}`:
        response = DEMO_ENDORSEMENTS_LIST_RESPONSE;
        break;
      case `GET:/endorsement/getEndorsementEndDate/${ALPHABET_ID}`:
        response = getDemoEndoDateResponse();
        break;
      case `GET:/user/fetchMembersByPolicy/${ALPHABET_ID}/LPP-0KKF7U`:
        response = DEMO_GMC_MEMBERS_LIST;
        break;
      case `GET:/user/fetchMembersByPolicy/${ALPHABET_ID}/LPP-Q3584W`:
        response = DEMO_GPA_MEMBERS_LIST;
        break;
      case 'GET:/policyPlan/LPP-0KKF7U':
        response = DEMO_GMC_POLICY_PLAN;
        break;
      case 'GET:/policyPlan/LPP-Q3584W':
        response = DEMO_GPA_POLICY_PLAN;
        break;
      case 'GET:/endorsement/hrd/cm1oj2c990009fsd169ed5v58':
        response = DEMO_ENDO_DETAILS_1;
        break;
      case 'GET:/endorsement/hrd/cm1oj2aaf0000fsd19c160i64':
        response = DEMO_ENDO_DETAILS_2;
        break;
      case 'GET:/endorsement/hrd/cm1en6dan00jt52dqooy6gkts':
        response = DEMO_ENDO_DETAILS_3;
        break;
      case 'GET:/endorsement/hrd/cm0ckvany00045190xwh7c9v9':
        response = DEMO_ENDO_DETAILS_4;
        break;
      case 'GET:/endorsement/hrd/cm0ckerwi001w14e45dt6xdck':
        response = DEMO_ENDO_DETAILS_5;
        break;
      case 'GET:/userPolicies/generateECard/lOXs65AxcF2ANzsmntWg/LPP-0KKF7U':
        response = DEMO_SELF_ECARD;
        break;
      case 'GET:/userPolicies/generateECard/1tAu0QerkoxRt3b5uClo/LPP-0KKF7U':
        response = DEMO_SPOUSE_ECARD;
        break;
      case 'GET:/claims/timeline/p843vlc2zvdjlthto8jt3ps4':
        response = DEMO_CLAIM_TIMELINE_1;
        break;
      case 'GET:/claims/timeline/anojt773ds9gqtubwh56xhqk':
        response = DEMO_CLAIM_TIMELINE_2;
        break;
      case `GET:/registration/hrd/downloadADDTemplate/${ALPHABET_ID}`:
        response = await fetch(BulkAddTemplateDoc);
        break;
      case `GET:/registration/hrd/downloadDELETETemplate/${ALPHABET_ID}`:
        response = await fetch(BulkDeleteTemplateDoc);
        break;
      case 'POST:/midTermAdditions/identifyMidTermAdditions':
        response = DEMO_IDENTIFY_MIDTERM_ADDITIONS;
        break;
      case 'POST:/hrd/validate/addMembers':
        response = DEMO_VALIDATE_ADD_MEMBERS;
        break;
      case 'POST:/hrd/validate/offboardMembers':
        response = DEMO_VALIDATE_OFFBOARD_MEMBERS;
        break;
      case 'POST:/registration/hrd/add':
        response = DEMO_SUBMIT_ADD_MEMBERS;
        break;
      case 'POST:/registration/hrd/offboard':
        response = DEMO_SUBMIT_DELETE_MEMBERS;
        break;
      case 'GET:/user/v2':
        response = DEMO_MEMBER_SEARCH_RESPONSE;
        break;
      case 'POST:/hrd/validate/editMember':
        response = getDemoValidateEditMember(body);
        break;
      case `GET:/company/${ALPHABET_ID}`:
        response = DEMO_COMPANY_DETAILS;
        break;
      case `GET:/company/config/${ALPHABET_ID}`:
        response = DEMO_COMPANY_CONFIG;
        break;
      default:
        response = {};
    }
  }

  const endTime = Date.now();
  const executionTime = endTime - startTime;
  const paddingTime = 500 - executionTime;

  if (paddingTime > 0) {
    await new Promise((resolve) => setTimeout(resolve, paddingTime));
  }

  return response as ResData;
};

export const isDemoEnabledForURL = (method: string, url: string) => {
  return !DEMO_SKIPPED_URLS.includes(formatURL(method, url));
};

const getDemoEndoDateResponse = () => {
  const date = new Date();
  let month = date.toLocaleString('default', { month: 'short' });
  return {
    name: 'SuccessResponse',
    status: 200,
    message: 'Succesfully fetched endorsement endDate by companyId',
    data: {
      endDate: `20 ${month} ${date.getFullYear()}`,
    },
  };
};

export const getDemoCdStatements = (cdAccountId: string) => {
  switch (cdAccountId) {
    case 'LCD-DVA0TU':
      return window.open(CdStatementGPADoc, '_self');
    case 'LCD-L1W0DX':
      return window.open(CdStatementGMCDoc, '_self');
  }
};

const getDemoEstimateResponse = <ReqData>(reqData?: ReqData) => {
  const body = reqData as Record<string, Record<string, string>>;
  if (body?.ADD) {
    switch (body.ADD.policyId) {
      case 'LPP-Q3584W':
        return DEMO_GPA_ADD_ESTIMATE;
      case 'LPP-0KKF7U':
        return DEMO_GMC_ADD_ESTIMATE;
    }
  } else if (body?.OFFBOARD) {
    return DEMO_OFFBOARD_ESTIMATE;
  } else if (body?.EDIT) {
    return DEMO_EDIT_ESTIMATE;
  }
  return {};
};

const getDemoValidateEditMember = <ReqData>(reqData?: ReqData) => {
  const body = reqData as Record<string, Record<string, string>>;
  if (body?.memberData?.userId === '1tAu0QerkoxRt3b5uClo')
    return DEMO_VALIDATE_EDIT_ERRORS;
  else return DEMO_VALIDATE_NO_ERRORS;
};
