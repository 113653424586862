import { Dispatch, MutableRefObject, SetStateAction } from 'react';

export const runOTPTimer = (
  timerRef: MutableRefObject<HTMLSpanElement | null>,
  setShowResendButton: Dispatch<SetStateAction<boolean>>,
) => {
  let timerSeconds = 44;
  const timerInterval = setInterval(() => {
    if (timerRef.current) {
      timerRef.current.innerText = `${
        timerSeconds < 10 ? '0' : ''
      }${timerSeconds}`;
    }

    if (--timerSeconds < 0) {
      clearInterval(timerInterval);
      setShowResendButton(true);
    }
  }, 1000);

  return timerInterval;
};
