import React, { FC, PropsWithChildren } from 'react';
import { StyledCard } from './styles';
import { ICardProps } from './types';

const Card: FC<PropsWithChildren<ICardProps>> = ({
  joined,
  rounded = true,
  children,
}) => {
  return (
    <StyledCard joined={joined} rounded={rounded}>
      {children}
    </StyledCard>
  );
};

export default Card;
