import styled from 'styled-components';

export const StyledCDBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledCDBreakdownWrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCDIcon = styled.img`
  width: 42px;
  aspect-ratio: 1;
`;

export const StyledCloseIcon = styled.img`
  cursor: pointer;
`;

export const StyledCDBreakdownWrapperHeaderTitle = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledCdBreakdownBody = styled.div`
  display: flex;
  gap: 32px;
`;

export const StyledCdBreakdownDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledCdBreakdownFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
  background: ${(p) => p.theme.colors.surface0};
  padding: 12px 24px;
`;

export const StyledCdBreakdownBodySeperator = styled.div`
  width: 1px;
  border-right: 1px solid ${(p) => p.theme.colors.seafoamGray};
  border-right-style: dashed;
`;

export const StyledCdBreakdownFAQ = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 28px 24px;
  background-color: ${(p) => p.theme.colors.mintFrost};
  border-radius: 12px;
  position: relative;
  padding-bottom: 180px;
  max-width: 300px;
`;

export const StyledCdBreakdownFAQHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledCdBreakdownFAQItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: start;
`;

export const StyledPresentationImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const StyledBreakdownDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledBreakdownDetailsBodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 28px;
  background-color: ${(p) => p.theme.colors.surface0};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const StyledBreakdownDetailsBodyFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background-color: ${(p) => p.theme.colors.loopEmerald};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const StyledTextBadgeAndBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`;

export const StyledTextAndBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledHorizontalSeperator = styled.div`
  height: 1px;
  border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
  border-top-style: dashed;
`;

export const StyledBreakdownCostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledViewHideButton = styled.div`
  display: flex;
  align-self: start;
`;

export const StyledBreakdownList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledBreakdownListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledInfoIcon = styled.img`
  width: 20px;
  aspect-ratio: 1;
`;

