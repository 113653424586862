import { Typography } from '@loophealth/loop-ui-web-library';
import React, { FC } from 'react';
import { ChatbotCrossIcon, ChatbotIcon } from '../../../../assets/images';
import {
  HeaderContainer,
  StyledChatbotIcon,
  StyledCrossIcon,
  TitleContainer,
} from './styles';
import { IChatbotHeaderProps } from './types';

const ChatbotHeader: FC<IChatbotHeaderProps> = ({ heading , subHeading, onClick }) => {
  return (
    <HeaderContainer>
      <StyledChatbotIcon src={ChatbotIcon} />
      <TitleContainer>
        <Typography variant="medium" color="tertiary" weight="bold">
          {heading}
        </Typography>
        <Typography variant="extraSmall" color="tertiary">
          {subHeading}
        </Typography>
      </TitleContainer>
      <StyledCrossIcon src={ChatbotCrossIcon} onClick={onClick} />
    </HeaderContainer>
  );
};

export default ChatbotHeader;
