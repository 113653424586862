import React, { FC } from "react";
import { IMessageParserProps } from "./types";

export const MessageParser: FC<IMessageParserProps> = ({
  children,
  actions,
}) => {
  const parse = async (message: string) => {
    await actions.sendMessageToLLM(message);
  };

  return (
    <div>
      {React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};
