import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useSegment from './useSegment';
import { trackPageEvent } from '../utils';

const useTrackPage = (
  page_data: Record<string, unknown> = {},
  condition: boolean = true,
) => {
  const trackPage = useSegment('page');
  const location = useLocation();
  const isTriggered = useRef<boolean>(false);

  useEffect(() => {
    if (condition && !isTriggered.current) {
      trackPage(trackPageEvent(location.pathname, page_data));
      isTriggered.current = true;
    }
  }, [condition]);
};

export default useTrackPage;
