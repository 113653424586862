import React from 'react';
import { IGenericIconProps } from './types';

const CheckOutlineNewIcon: React.FC<IGenericIconProps> = ({
  height = 6,
  width = 10,
  fill = '#025F4C',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.81719 5.98335C3.65052 5.98335 3.49219 5.91669 3.37552 5.80002L1.01719 3.44169C0.775521 3.20002 0.775521 2.80002 1.01719 2.55835C1.25885 2.31669 1.65885 2.31669 1.90052 2.55835L3.81719 4.47502L8.10052 0.191687C8.34219 -0.0499796 8.74219 -0.0499796 8.98386 0.191687C9.22552 0.433354 9.22552 0.833353 8.98386 1.07502L4.25885 5.80002C4.14219 5.91669 3.98385 5.98335 3.81719 5.98335Z"
      fill={fill}
    />
  </svg>
);

export { CheckOutlineNewIcon };
