import LoopApiService from "../../../../adaptars/LoopApiService";
import { FEEDBACK_VALUE } from "./constants";
import { IFeedbackRequestBody, IFeedbackRequestBodyKey, IFormdata, IFormdataKey } from "./types";

export const triggerFeedbackdMail = async (formData: IFormdata, email?: string) => {
    if(!email) return;
    const requestPayload: IFeedbackRequestBody = {};
    Object.keys(formData).forEach((key: string) => {
        if(key === 'feedbackOrSuggestions') {
        requestPayload[key as IFeedbackRequestBodyKey] = formData[key as IFormdataKey].toString();
        } else {
        requestPayload[key as IFeedbackRequestBodyKey] = FEEDBACK_VALUE[formData[key as IFormdataKey] as number];
        }
    });
    requestPayload['email'] = email;
    try {
        await LoopApiService.triggerFeedbackSubmitMail(requestPayload);
    } catch (error) {
        console.error('Error while sending feedback mail');
    }
}
