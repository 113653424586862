import React, { FC } from 'react';
import {
  StyledButtonContainer,
  StyledContentContainer,
  StyledImage,
  StyledMainContainer,
} from './styles';
import { IEndoInfoCardProps } from './types';
import {
  LoopButton,
  MediaModal,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { InfoCardIcon, VideoIcon } from '../../../assets/images';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { TEXT_CONSTANTS } from './constants';

const EndoInfoCard: FC<IEndoInfoCardProps> = ({
  onClick,
  isDownloading,
  videoUrl,
  operation,
}) => {
  const [visibleModal, setVisibleModal] = React.useState(false);
  const trackClick = useSegment('click');
  const location = useLocation();
  const handleModalClick = () => {
    setVisibleModal(true);
    if (location.pathname === '/bulk-delete') {
      trackClick(
        trackClickEvent(
          'Watch_Tutorial_del',
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
    } else if (location.pathname === '/bulk-add') {
      trackClick(
        trackClickEvent(
          'Add_Watch_Tutorial_add',
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
    }
  };
  const handleDownloadTemplate = () => {
    if (location.pathname === '/bulk-add') {
      trackClick(
        trackClickEvent(
          'Download_Template_add',
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
    } else if (location.pathname === '/bulk-delete') {
      trackClick(
        trackClickEvent(
          'Download_Template_del',
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
    }

    onClick();
  };

  const texts = TEXT_CONSTANTS[operation];
  return (
    <StyledMainContainer>
      <StyledContentContainer>
        <Typography variant="medium" color="tertiary" weight="medium">
          Get going in Seconds!
        </Typography>
        <Typography variant="small" weight="medium" color="whiteFill">
          {texts.SUBTITLE}
        </Typography>
        <StyledButtonContainer>
          <LoopButton
            variant="filled"
            onClick={handleDownloadTemplate}
            size="small"
            isLoading={isDownloading ? true : false}
          >
            <Typography variant="small" weight="medium" color={'emerald'}>
              Download Template
            </Typography>
          </LoopButton>
          <LoopButton
            variant="white-outline"
            onClick={handleModalClick}
            iconOrder="left"
            iconSrc={VideoIcon}
            size="small"
          >
            <Typography variant="small" weight="medium" color={'tertiary'}>
              Watch Tutorial
            </Typography>
          </LoopButton>
        </StyledButtonContainer>
      </StyledContentContainer>
      <StyledImage src={InfoCardIcon} />
      {videoUrl ? (
        <MediaModal
          src={videoUrl}
          type="video"
          height={500}
          width={800}
          isVisible={visibleModal}
          setIsVisible={setVisibleModal}
        />
      ) : null}
    </StyledMainContainer>
  );
};

export default EndoInfoCard;
