import React from 'react';
import { Accordion, Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledClaimDetailsAccordion,
  StyledClaimDetailsAccordionBody,
  StyledClaimDetailsAccordionBodyList,
  StyledClaimDetailsAccordionBodyListItem,
  StyledClaimDetailsAccordionBodySeparator,
  StyledCustomAccordionTitle,
} from './styles';
import {
  IClaimDetailsAccordion,
  IClaimDetailsAccordionBody,
  IClaimDetailsAccordionBodyList,
  IClaimsAccordionExtraData,
  IClaimsAccordionObject,
} from './types';
import { getClaimAccordionObjectFromClaimData } from './utils';
import { BulletIcon } from '../../../../assets/images';
import theme from '../../../../theme';

const ClaimDetailsAccordion = ({
  claimData,
  policyData,
}: IClaimDetailsAccordion) => {
  const accordionObjectList = getClaimAccordionObjectFromClaimData(
    claimData,
    policyData,
  );
  const customAccordionTitle = (
    data: IClaimsAccordionObject,
    isOpen: boolean,
  ) => (
    <StyledCustomAccordionTitle>
      <img src={BulletIcon} alt="" />
      <Typography variant="medium" weight="medium">
        {data.title}
      </Typography>
    </StyledCustomAccordionTitle>
  );

  const customAccordionDescription = (data: IClaimsAccordionObject) => (
    <ClaimDetailsAccordionBody
      data={data.data as unknown as IClaimsAccordionExtraData}
    />
  );

  return (
    <StyledClaimDetailsAccordion>
      <Accordion
        defaultOpen={0}
        accordionObjectList={accordionObjectList}
        renderTitle={customAccordionTitle}
        renderDescription={customAccordionDescription}
        isBorderCurved={false}
        paddedBorder={true}
        hasGap
        background={theme.colors.surfaceOne}
      />
    </StyledClaimDetailsAccordion>
  );
};

const ClaimDetailsAccordionBody = ({ data }: IClaimDetailsAccordionBody) => {
  return (
    <StyledClaimDetailsAccordionBody>
      <ClaimDetailsAccordionBodyList list={data.left} />
      <StyledClaimDetailsAccordionBodySeparator />
      <ClaimDetailsAccordionBodyList list={data.center} />
      {Object.keys(data.right).length ? (
        <StyledClaimDetailsAccordionBodySeparator />
      ) : null}
      <ClaimDetailsAccordionBodyList list={data.right} />
    </StyledClaimDetailsAccordionBody>
  );
};

const ClaimDetailsAccordionBodyList = ({
  list,
}: IClaimDetailsAccordionBodyList) => {
  return (
    <StyledClaimDetailsAccordionBodyList>
      {Object.entries(list).map((item) => (
        <StyledClaimDetailsAccordionBodyListItem key={item[0]}>
          <Typography variant="extraSmall" weight="medium" color="secondary">
            {item[0]}
          </Typography>
          {typeof item[1] === 'string' ? (
            <Typography variant="small" weight="medium">
              {item[1]}
            </Typography>
          ) : (
            item[1]
          )}
        </StyledClaimDetailsAccordionBodyListItem>
      ))}
    </StyledClaimDetailsAccordionBodyList>
  );
};

export default ClaimDetailsAccordion;
