import { INoteType } from '../../../atoms/ZeroStateCard/types';
import { ICategorizedData } from '../../../pages/Employees/types';

export interface IActiveRoasterTableProps {
  selectedPolicyType: string;
  tableData: ICategorizedData;
}

export interface IEmpTable {
  id?: string;
  userId?: string;
  'Member name'?: string;
  'Employee ID': string;
  'Employee Name': string;
  'Sum Insured': string;
  'Insurance Status': string;
  'Coverage Start Date': string;
  'Coverage End Date': string;
  Relationship?: string;
  uhid?: string | null;
  email?: string | null;
  dob?: string;
  mobile?: string | null;
  endorsmentStatus?: string | null;
  gender?: string;
  ctc?: string | number;
  claimConfirmation?: string;
  isPolicyValid?: boolean;
}

export enum IEmpTableTabs {
  Active = 'Active',
  Employees = 'Employees',
  Deleted = 'Deleted',
}

export interface IZeroState {
  [key: string]: {
    title: string;
    subtitle: string;
    image: string;
    note: string;
    noteType: INoteType;
  };
}
