import { ICDAccount } from "../../pages/CDAccounts/types";
import { getRecommendedCDBalance } from "../../pages/CDAccounts/utils";
import { IEndorsements } from "../../pages/Endorsements/ListingPage/types";

export const transformCdEndoAlerts = (
  cdAccount: ICDAccount,
  endorsements: IEndorsements[]
) => {
  const endos = endorsements.filter((endo) => ['DRAFT', 'IN_PROGRESS'].includes(endo.status) && cdAccount.policies.includes(endo.policyId));
  const endoCost = endos.reduce((prev, currentEndo) => prev + currentEndo.endoCost, 0);
  const recommendedCost = getRecommendedCDBalance(cdAccount);
  const totalRecommendedCost = endoCost + recommendedCost;
  const isSufficient = endoCost <= cdAccount.balance;
  const isCdLow = totalRecommendedCost > cdAccount.balance;
  
  return {
    endoCost, 
    endos,
    recommendedCost,
    totalRecommendedCost,
    isSufficient,
    isCdLow
  }
}