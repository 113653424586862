import {
  ICDAccountsListData,
  IClaimListData,
  IEndorsementListData,
  IHRDRevampSlice,
  IPolicyListData,
} from './types';

export const policiesInitialState: IPolicyListData = {
  data: [],
  error: null,
  status: 'idle',
  filters: [],
  selectedCompany: null,
};
export const cdAccountsInitialState: ICDAccountsListData = {
  data: [],
  error: null,
  status: 'idle',
};
export const claimsInitialState: IClaimListData = {
  data: [],
  error: null,
  status: 'idle',
};
export const endorsementsInitialState: IEndorsementListData = {
  data: [],
  error: null,
  status: 'idle',
};

export const initialState: IHRDRevampSlice = {
  policies: {...policiesInitialState},
  cdAccounts: {...cdAccountsInitialState},
  claims: {...claimsInitialState},
  endorsements: {...endorsementsInitialState}
};
