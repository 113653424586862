import { StyledWrapper } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { IEmpInfoProps } from './types';

const EmpInfoItem: React.FC<IEmpInfoProps> = ({ label, value }) => {
  return (
    <StyledWrapper>
      <Typography variant="extraSmall" weight="medium" color="secondary">
        {label}
      </Typography>
      <Typography variant="small" color="primary">
        {value}
      </Typography>
    </StyledWrapper>
  );
};
export default EmpInfoItem;
