import { IHRDashboardFilterType } from '../../../components/containers/PolicyFilter/types';
import { IPolicyListItemType } from './types';

export const getFiltersArray = (policiesData: IPolicyListItemType[]) => {
  const filterSet = new Set<IHRDashboardFilterType>();

  policiesData.forEach((policy) => {
    if (policy.policyType === 'GMC') filterSet.add(IHRDashboardFilterType.GMC);
    else if (policy.policyType === 'GPA')
      filterSet.add(IHRDashboardFilterType.GPA);
    else if (policy.policyType === 'GTL')
      filterSet.add(IHRDashboardFilterType.GTL);
  });

  if (filterSet.size !== 1) {
    filterSet.add(IHRDashboardFilterType.All);
  }

  const filterArray = Array.from(filterSet);
  filterArray.sort();

  return filterArray;
};
