import React from 'react';
import TabWithDetail from '../../atoms/TabWithDetail';
import { CLAIMS_LIST_HEADER_TABS } from './constants';
import {
  StyledClaimListHeader,
  StyledClaimListHeaderTabsContainer,
} from './styles';
import { IClaimListHeader } from './types';
import ClaimsHeaderDropdown from '../ClaimsHeaderDropdown';

const ClaimListHeader = ({
  selectedTab,
  selectedFilter,
  setSelectedFilter,
  setSelectedTab,
  claimCounts,
  policiesOptions,
  subtitle,
}: IClaimListHeader) => {
  return (
    <StyledClaimListHeader>
      <ClaimsHeaderDropdown
        title="Claims Details"
        policiesOptions={policiesOptions}
        selectedPolicyFilter={selectedFilter}
        setSelectedPolicyFilter={setSelectedFilter}
        subtitle={subtitle}
        showLearnMore
      />
      <StyledClaimListHeaderTabsContainer>
        {CLAIMS_LIST_HEADER_TABS.map((item) => (
          <TabWithDetail
            key={item.label}
            color={item.color}
            label={item.label}
            count={claimCounts[item.status]}
            isSelected={selectedTab === item.status}
            onClick={() => setSelectedTab(item.status)}
            tooltip={item.tooltip}
          />
        ))}
      </StyledClaimListHeaderTabsContainer>
    </StyledClaimListHeader>
  );
};

export default ClaimListHeader;
