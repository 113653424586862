import { Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectSelectedCompany } from '../../../redux/slices/hrdRevampRedux';
import { StyledCDBalanceStatus } from './styles';
import { ICDBalanceStatusProps } from './types';
import { selectHrUser } from '../../../redux/slices/usersSlice';
import { addCdBalanceTooltipShownUserId, selectCdBalanceTooltipShownUserIds } from '../../../redux/slices/applicationStateSlice';

const CDBalanceStatus: React.FC<ICDBalanceStatusProps> = ({
  cdBalanceDetails,
  isFirstOngoing,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectSelectedCompany);
  const hrData = useSelector(selectHrUser).data;
  const tooltipShownUserIds = useSelector(selectCdBalanceTooltipShownUserIds);
  const isEndoEnabled = selectedCompany?.isEndoEnabled;
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { redirectType, statusText, subTitle, title } = cdBalanceDetails;

  const navigateToCompletedEndos = () => {
    if (!selectedCompany?.isEndoEnabled) return;
    history.push(`/endorsements?type=${redirectType}`);
  };

  useEffect(() => {
    if (hrData && isFirstOngoing && !tooltipShownUserIds.includes(hrData.userId)){
      tooltipRef.current?.children
        .item(0)
        ?.dispatchEvent(new MouseEvent('click', { bubbles: true }));
      dispatch(addCdBalanceTooltipShownUserId(hrData.userId));
    }
  }, []);

  return (
    <StyledCDBalanceStatus>
      <Typography variant="extraSmall" color="secondary">
        {statusText}
      </Typography>
      <div ref={tooltipRef}>
        <Tooltip
          type="notice"
          beak="left-center"
          title={title}
          text={subTitle}
          ctaCount={isEndoEnabled ? 1 : undefined}
          primaryButtonAction={navigateToCompletedEndos}
          primaryButtonTitle={`View ${redirectType} Endorsements`}
          showOnFocus
        >
          <Typography
            variant="extraSmall"
            weight="semiBold"
            color="emerald"
            data-testId="hello"
          >
            Know More
          </Typography>
        </Tooltip>
      </div>
    </StyledCDBalanceStatus>
  );
};

export default CDBalanceStatus;
