import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import { IPolicyListItemType } from '../../../redux/slices/hrdRevampRedux/types';
import {
  IPolicyTypes,
  PolicyTypeConstants,
  RelationConstants,
} from '../../../utils/constants';
import { ICategorizedData, IEmployeeData, InsuranceStats } from './types';
import { IEmpTable } from '../../containers/Employees/ActiveRoasterTable/types';
import {
  getAmountInLacs,
  textPlaceholder,
} from '../../../utils/common/Utilities';
import moment from 'moment';
import { InsuranceStatusValues } from '../EmployeeDetails/utils';
import { formatMemberName } from '../../../utils/common/common';

export const checkIfGPAorGTLPolicy = (selectedPolicyFilter: string) =>
  selectedPolicyFilter === PolicyTypeConstants.GPA ||
  selectedPolicyFilter === PolicyTypeConstants.GTL;

export const getPolicyListDropDown = (
  policies: IPolicyListItemType[],
): IDropdownItem[] => {
  const sortedPolicies = [...policies].sort((a, b) => {
    if (
      a.policyType === PolicyTypeConstants.GMC &&
      b.policyType !== PolicyTypeConstants.GMC
    ) {
      return -1;
    } else if (
      a.policyType !== PolicyTypeConstants.GMC &&
      b.policyType === PolicyTypeConstants.GMC
    ) {
      return 1;
    } else {
      return a.policyType.localeCompare(b.policyType);
    }
  });
  return sortedPolicies.map((item: IPolicyListItemType) => {
    return {
      name: `${
        IPolicyTypes[item.policyType as keyof typeof IPolicyTypes]
      } | Policy No: ${textPlaceholder(item.policyNumber)} `,
      value: item.id,
      data: {
        policyType: item.policyType,
        nickName: item.nickName,
        name: IPolicyTypes[item.policyType as keyof typeof IPolicyTypes],
        policyNo: textPlaceholder(item.policyNumber),
        insurer: item.insurerName,
      },
    };
  });
};

export const isPolicyEndDateValid = (policyEndDate: string): boolean => {
  const currentDate = new Date();
  const policyEndDateDate = new Date(policyEndDate);
  return policyEndDateDate > currentDate;
};

export const getCategorizedList = (data: IEmployeeData[]): ICategorizedData => {
  const categorizedData: ICategorizedData = {
    Active: [],
    Deleted: [],
    Employees: [],
  };

  const employeeNames = new Map<string, string>();

  data.forEach((user) => {
    const employeeName = formatMemberName(user.firstName, user.lastName);
    employeeNames.set(user.userId, employeeName);
  });

  data.forEach((user) => {
    if (user.parentId) {
      const parentEmployeeName = employeeNames.get(user.parentId);
      if (parentEmployeeName) {
        user.employee_name = parentEmployeeName;
      }
    } else {
      user.employee_name = formatMemberName(user.firstName, user.lastName);
    }
    const checkIsInsuredOrInProgress =
      user.insuranceStatus === InsuranceStatusValues.INSURED ||
      user.insuranceStatus === InsuranceStatusValues.IN_PROGRESS;

    const checkIsEndDateValid = isPolicyEndDateValid(user.policyEndDate);
    const empTable: IEmpTable = {
      id:
        user.relationship.toLowerCase() === RelationConstants.self
          ? user.userId
          : user.parentId,
      userId: user.userId,
      'Member name': formatMemberName(user.firstName, user.lastName),
      'Employee ID': user.employeeId,
      'Employee Name':
        user.employee_name ?? formatMemberName(user.firstName, user.lastName),
      'Sum Insured': getAmountInLacs(user.sumInsured || 0).toString(),
      'Insurance Status': user.insuranceStatus,
      'Coverage Start Date': moment(user.policyStartDate).format('DD/MMM/YYYY'),
      'Coverage End Date': moment(user.policyEndDate).format('DD/MMM/YYYY'),
      Relationship: user.relationship,
      ctc: user.ctc ?? 0,
      dob: moment(user?.dob).format('DD/MMM/YYYY'),
      email: user.email,
      mobile: user.mobile,
      endorsmentStatus: user.endorsmentStatus,
      gender: user.gender,
      uhid: user.uhid,
      claimConfirmation: user.claimConfirmation ? 'Yes' : 'No',
      isPolicyValid: checkIsEndDateValid ?? false,
    };

    if (
      user.insuranceStatus === InsuranceStatusValues.DELETED ||
      !isPolicyEndDateValid(user.policyEndDate)
    ) {
      empTable['Insurance Status'] = InsuranceStatusValues.DELETED;
      categorizedData.Deleted.push(empTable);
    } else if (
      checkIsInsuredOrInProgress &&
      user.relationship === RelationConstants.self
    ) {
      categorizedData.Employees.push(empTable);
    }
    if (checkIsInsuredOrInProgress && checkIsEndDateValid) {
      categorizedData.Active.push(empTable);
    }
  });

  return categorizedData;
};

export const initialStats: InsuranceStats = {
  total_lives_all: 0,
  insured_lives_all: 0,
  total_lives_emp: 0,
  insured_lives_emp: 0,
  available_ecards: 0,
  employee: 0,
  parent: 0,
  'parent-in-law': 0,
  spouse: 0,
  child: 0,
  endorsementStatus: 0,
  ecardInProgress: 0,
  employee_with_ecard: 0,
  insured_employees: 0,
  in_progress_employees: 0,
};

export const isUHIdAvailable = (uhId: string) => {
  return uhId !== null && uhId !== '';
};

export const calculateInsuranceCounts = (
  data: IEmployeeData[],
  counts: InsuranceStats,
): InsuranceStats => {
  const initialCounts = { ...counts };

  data.forEach((entry) => {
    const {
      insuranceStatus,
      relationship,
      uhid,
      endorsmentStatus,
      policyEndDate,
    } = entry;

    const isInsuredOrInProgress =
      insuranceStatus === InsuranceStatusValues.INSURED ||
      insuranceStatus === InsuranceStatusValues.IN_PROGRESS;

    const isEndDateValid = isPolicyEndDateValid(policyEndDate);

    if (isInsuredOrInProgress && isEndDateValid) {
      initialCounts.total_lives_all++;

      if (relationship.toLowerCase() === RelationConstants.self) {
        initialCounts.total_lives_emp++;

        if (isInsuredOrInProgress) {
          initialCounts.employee++;

          if (isUHIdAvailable(uhid)) {
            initialCounts.employee_with_ecard++;
          }
        }
      } else if (relationship.toLowerCase() === RelationConstants.parent) {
        initialCounts.parent++;
      } else if (
        relationship.toLowerCase() === RelationConstants['parent-in-law']
      ) {
        initialCounts['parent-in-law']++;
      } else if (relationship.toLowerCase() === RelationConstants.spouse) {
        initialCounts.spouse++;
      } else if (relationship.toLowerCase() === RelationConstants.child) {
        initialCounts.child++;
      }

      if (isUHIdAvailable(uhid)) {
        initialCounts.available_ecards++;
      }
      // TODO: Temp Removed endorsmentStatus
      // if (endorsmentStatus && !isUHIdAvailable(uhid)) {
      //   initialCounts.ecardInProgress++;
      // }
      if (!isUHIdAvailable(uhid)) {
        initialCounts.ecardInProgress++;
      }
    }

    if (insuranceStatus === InsuranceStatusValues.INSURED && isEndDateValid) {
      initialCounts.insured_employees++;
    }

    if (
      insuranceStatus === InsuranceStatusValues.IN_PROGRESS &&
      isEndDateValid
    ) {
      initialCounts.in_progress_employees++;
    }
  });

  return initialCounts;
};
