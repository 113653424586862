import React from 'react';
import { StyledOverviewSection } from './styles';
import OverviewCard from '../OverviewCard';
import {
  CdBalRedIcon,
  ClaimsValueIcon,
  LifeCountIcon,
  PremiumValueIcon,
} from '../../../assets/images';
import { getCurrencyString } from '../../../utils/common/Utilities';
import { IAnalyticsStats } from './types';
import { StyledTooltipListContainer } from '../../containers/ClaimAlertItem/styles';

const AnalyticsOverview = ({
  totalClaimed,
  csr,
  claimValue,
}: IAnalyticsStats) => {
  const customTooltip = (
    <StyledTooltipListContainer>
      <p>Calculated as (No. of claims settled)/(Total no. of claims raised)</p>
      <p>Industry Settlement Ratio is 86%</p>
    </StyledTooltipListContainer>
  );

  return (
    <StyledOverviewSection data-testid="overview">
      <OverviewCard
        image={LifeCountIcon}
        title="Total Claims Raised"
        stats={totalClaimed}
      />
      <OverviewCard
        image={ClaimsValueIcon}
        title="Claims Settlement Ratio"
        stats={csr || 'N/A'}
        tooltipInfo={customTooltip}
      />
      <OverviewCard
        image={PremiumValueIcon}
        title="Total Claim Amount"
        stats={claimValue && getCurrencyString(claimValue)}
        tooltipInfo="This is the total claimed amount approved by the insurer"
      />
    </StyledOverviewSection>
  );
};

export default AnalyticsOverview;
