import React from 'react';
import { ICashlessClaim } from './types';
import {
  StyledDivider,
  StyledWrapper,
  StyledContainer,
  StyledWrapperDate,
} from './styles';
import { PersonHeartIcon, TickCircleDarkIcon } from '../../../../assets/images';
import { DateFormatUtils, Typography } from '@loophealth/loop-ui-web-library';

const CashlessClaim: React.FunctionComponent<ICashlessClaim> = ({
  dischargeDate,
}) => {
  const formatDate = new DateFormatUtils().formatDate;
  return (
    <StyledContainer>
      <StyledWrapper>
        <img src={PersonHeartIcon} alt="" />
        <Typography variant="medium">Claim has been settled!</Typography>
      </StyledWrapper>
      <StyledDivider />
      <StyledWrapperDate>
        <img src={TickCircleDarkIcon} alt="" />
        <Typography variant="medium" color="secondary">
          Discharge date:
        </Typography>
        <Typography variant="medium">
          {dischargeDate ? formatDate(dischargeDate) : ''}
        </Typography>
      </StyledWrapperDate>
    </StyledContainer>
  );
};
export default CashlessClaim;
