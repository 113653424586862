import styled from 'styled-components';

export const StyledStepsOuterContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const StyledStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 226px;
`;
export const StyledStatusLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;
export const StyledTimeLine = styled.div<{ $backgroundColor?: string }>`
  height: 2px;
  width: 100%;
  background-color: ${(p) =>
    p.$backgroundColor ? p.$backgroundColor : p.theme.colors.seafoamGray};
`;

export const StyledSuccessIcon = styled.img``;

export const StyledStepDescriptionContainer = styled.div`
  display: flex;
  width: 75%;
  flex-direction: column;
  gap: 8px;
`;

export const StyledClaimsTimelineContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  padding: 28px 56px 48px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 90%;
  margin-top: 20px;
`;
