import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-self: stretch;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  background: ${(p) => p.theme.colors.loopEmerald};
  box-shadow: 0px 2px 8px 0px rgba(55, 65, 81, 0.06);
  justify-content: center;
  align-items: center;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 291px;
`;

export const StyledChatbotIcon = styled.img`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const StyledCrossIcon = styled.img`
  display: flex;
  width: 20px;
  height: 20px;
`;