import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(p) => p.theme.colors.surface0};
`;

export const StyledOverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 55px;
  border-radius: 16px;
  gap: 20px;
  flex: 1;
`;
export const StyledOverWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
`;

export const StyledNameContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledEmpDetailsContainer = styled.div`
  display: flex;
  gap: 32px;
  text-transform: capitalize;
`;

export const StyledNameDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  gap: 4px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    width: auto;
  }
`;

export const StyledSeparator = styled.span`
  background-color: ${(p) => p.theme.colors.seafoamGray};
  width: 1px;
  height: 100%;
`;

export const StyledTableContainer = styled.div`
  padding-bottom: 32px;
  & tbody > tr:last-child {
    border-bottom: none;
  }
`;
