import styled from 'styled-components';

export const StyledPieChartLegendTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 43px;
  background: ${(p: any) => p.theme.colors.surface.zero};
  width: 100%;
`;

export const StyledPieChartLegendPoint = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;

export const StyledPieChartLegendText = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  & > :first-child{
    flex: 6;
  }
  & > :last-child{
    flex: 2;
  }
`;

export const StyledPieChartLegendTextValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
