import { Redirect, Switch } from 'react-router-dom';
import RedirectAuthRoute from '../../utils/hoc/RedirectAuthRoute';
import { PrivateRoute } from '../../utils/hoc/PrivateRoute';
import Login from './Auth/Login';
import OTPScreen from './Auth/OTPScreen';
import Dashboard from './Dashboard';

const MainRouter: React.FC = () => {
  return (
    <Switch>
      <RedirectAuthRoute path="/login" component={Login} />
      <RedirectAuthRoute path="/enter-otp" component={OTPScreen} />
      {/* <Route path={'/waitlist'} component={WaitListPage} /> */}
      {/* <RedirectAuthRoute path="/enter-id" component={EnterAuth} /> */}
      {/* // TODO: uncomment if want old flow */}
      <PrivateRoute path="/" component={Dashboard} />
      <Redirect to="/" />
    </Switch>
  );
};

export default MainRouter;
