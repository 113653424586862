import {
  Avatar,
  DateFormatUtils,
  Typography,
} from '@loophealth/loop-ui-web-library';
import {
  StyledActionButtonImage,
  StyledSectionCard,
  StyledSectionCardFooter,
  StyledSectionCardFooterItem,
  StyledSectionCardHeader,
  StyledSectionCardHeaderActionButtons,
  StyledSectionCardHeaderProfile,
  StyledSectionCardHeaderProfileDetails,
} from './styles';
import {
  DeleteOutlinedIcon,
  EditOutlinedIcon,
} from '../../../../assets/images';
import { IFilledDependantCardProps } from './types';
import {
  capitalizeFirstLetter,
  textPlaceholder,
} from '../../../../utils/common/Utilities';
import moment from 'moment';
import { formatMemberName } from '../../../../utils/common/common';

const FilledDependantCard: React.FC<IFilledDependantCardProps> = ({
  dependant,
}) => {
  const { firstName, lastName, relationship, dateOfBirth, gender, mobile } =
    dependant;
  const name = formatMemberName(firstName, lastName);
  return (
    <StyledSectionCard>
      <StyledSectionCardHeader>
        <StyledSectionCardHeaderProfile>
          <Avatar
            users={[
              {
                name,
                imageURL: '',
              },
            ]}
            size="large"
            shape="square"
          />
          <StyledSectionCardHeaderProfileDetails>
            <Typography variant="extraSmall" color="secondary">
              Name
            </Typography>
            <Typography variant="small" weight="semiBold">
              {name}
            </Typography>
          </StyledSectionCardHeaderProfileDetails>
        </StyledSectionCardHeaderProfile>
        <StyledSectionCardHeaderActionButtons>
          <StyledActionButtonImage src={DeleteOutlinedIcon} alt="Delete" />
          <StyledActionButtonImage src={EditOutlinedIcon} alt="Edit" />
        </StyledSectionCardHeaderActionButtons>
      </StyledSectionCardHeader>
      <StyledSectionCardFooter>
        <StyledSectionCardFooterItem>
          <Typography variant="extraSmall" color="secondary">
            Relationship
          </Typography>
          <Typography variant="small" weight="semiBold">
            {capitalizeFirstLetter(relationship)}
          </Typography>
        </StyledSectionCardFooterItem>
        <StyledSectionCardFooterItem>
          <Typography variant="extraSmall" color="secondary">
            DOB
          </Typography>
          <Typography variant="small" weight="semiBold">
            {moment(dateOfBirth).format(DateFormatUtils.DEFAULT_UI_DATE_FORMAT)}
          </Typography>
        </StyledSectionCardFooterItem>
        <StyledSectionCardFooterItem>
          <Typography variant="extraSmall" color="secondary">
            Gender
          </Typography>
          <Typography variant="small" weight="semiBold">
            {capitalizeFirstLetter(gender)}
          </Typography>
        </StyledSectionCardFooterItem>
        <StyledSectionCardFooterItem>
          <Typography variant="extraSmall" color="secondary">
            Mobile
          </Typography>
          <Typography variant="small" weight="semiBold">
            {textPlaceholder(mobile)}
          </Typography>
        </StyledSectionCardFooterItem>
      </StyledSectionCardFooter>
    </StyledSectionCard>
  );
};

export default FilledDependantCard;
