import React, { useState } from 'react';

const useSearchQuery = <T = string>(queryKey: string, defaultValue?: T) => {
  const searchParams = new URLSearchParams(window.location.search);

  const [queryValue, setQueryValue] = useState<T>(
    (searchParams.get(queryKey) ?? defaultValue) as T,
  );

  React.useEffect(() => {
    setQueryValue((searchParams.get(queryKey) ?? defaultValue) as T);
  }, [defaultValue, queryKey, searchParams]);

  const setSearchParams = (value: T) => {
    if (value === queryValue) return;
    if (value) {
      searchParams.set(queryKey, value as string);
      setQueryValue(value);
    } else {
      searchParams.delete(queryKey);
      if (defaultValue) setQueryValue(defaultValue);
    }
    const newUrl = [window.location.pathname, searchParams.toString()]
      .filter(Boolean)
      .join('?');
    window.history.replaceState(null, '', newUrl);
  };

  return [queryValue, setSearchParams] as const;
};

export default useSearchQuery;
