import {
  LoopButton,
  SideDrawer,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useMemo, useState } from 'react';
import { IFeedbackSidebar, IFormdata, IFormdataKey } from './types';
import { FeedbackSidebarContent, PreloadImage } from './styles';
import {
  BadSmileyGif,
  ExcellentSmileyGif,
  GoodSmileyGif,
  TerribleSmileyGif,
} from '../../../../assets/gifs';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import { extractPathName } from '../../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';
import { trackTaskEvent } from '../../../../utils/segment/utils';
import { useSelector } from 'react-redux';
import { selectHrUser } from '../../../../redux/slices/usersSlice';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import FeedbackStepOne from '../FeedbackStepOne';
import theme from '../../../../theme';
import { triggerFeedbackdMail } from './utils';
import { FeedbackOptionalFieldNames } from '../FeedbackStepOne/constants';
import { initialFormData } from './constants';

const FeedbackSidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
}: IFeedbackSidebar) => {
  const trackTask = useSegment('track');
  const [formData, setFormData] = useState<IFormdata>(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const hrUser = useSelector(selectHrUser);
  const companyInfo = useSelector(selectSelectedCompany);
  const toast = Toast.useToast();
  const location = useLocation();

  const commonInfo = {
    hr_user_id: hrUser?.data?.userId,
    hr_name: hrUser?.data?.name,
    hr_email: hrUser?.data?.email,
    company_id: companyInfo?.id,
    company_name: companyInfo?.name,
  };

  const isFormValid = useMemo(() => {
    // check if all the values are greater than 0 or ''
    let _isFormValid = true;
    Object.keys(formData).forEach((key: string) => {
      if (FeedbackOptionalFieldNames.includes(key)) return;
      if ([0, 6].includes(formData[key as IFormdataKey] as number)) {
        _isFormValid = false;
      }
    });
    return _isFormValid;
  }, [formData]);

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const handleOnNextClick = async () => {
    if (!isFormValid || isLoading) return;
    setIsLoading(true);
    const stepOneProperties: Record<string, unknown> = {
      claims_and_support: formData.claimsAndSupport,
      endorsements: formData.endorsements,
      medical_advisors: formData.medicalAdvisors,
      csm: formData.csm,
      loophealth: formData.loophealth,
      feedback_or_suggestions: formData.feedbackOrSuggestions,
      ...commonInfo,
    };
    if (![0, 5].includes(formData.wellnessServices)) {
      stepOneProperties['wellness_services'] = formData.wellnessServices;
    }
    trackTask(
      trackTaskEvent(
        'PULSE_SCORE',
        location?.pathname,
        extractPathName(location?.pathname),
        stepOneProperties,
      ),
    );
    await triggerFeedbackdMail(formData, hrUser?.data?.email);
    toast?.success('Your feedback has been successfully submitted.', '', {
      closeOnClick: false,
      variant: 'light',
    });
    setFormData(initialFormData);
    setIsLoading(false);
    setIsSidebarOpen(false);
  };

  const handleCancelClick = () => {
    setIsSidebarOpen(false);
  };

  return (
    <SideDrawer
      visible={isSidebarOpen}
      onClose={handleSidebarClose}
      size="medium"
      position="right"
      title="Rate our Services"
      primaryFooterButton={
        <LoopButton
          variant={isFormValid && !isLoading ? 'filled' : 'disabled'}
          onClick={handleOnNextClick}
          isLoading={isLoading}
          size="medium"
        >
          <Typography
            variant="small"
            color={isFormValid ? 'emerald' : 'primary'}
            weight="semiBold"
          >
            Submit
          </Typography>
        </LoopButton>
      }
      secondaryFooterButton={
        <WithThemeProvider>
          <LoopButton
            variant="outline"
            borderColor={theme.colors.seafoamGray}
            onClick={handleCancelClick}
            isLoading={false}
            size="medium"
          >
            <Typography variant="small" color="primary" weight="semiBold">
              Cancel
            </Typography>
          </LoopButton>
        </WithThemeProvider>
      }
    >
      <WithThemeProvider>
        <FeedbackSidebarContent>
          <FeedbackStepOne formData={formData} setFormData={setFormData} />
          <PreloadImage src={TerribleSmileyGif} />
          <PreloadImage src={BadSmileyGif} />
          <PreloadImage src={GoodSmileyGif} />
          <PreloadImage src={ExcellentSmileyGif} />
        </FeedbackSidebarContent>
      </WithThemeProvider>
    </SideDrawer>
  );
};

export default FeedbackSidebar;
