import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  StyledLoginPage,
  StyledLoginPageControls,
  StyledLoginPageControlsSection,
  StyledLoginPageHeader,
} from '../Login/styles';
import SideLayout from '../Login/SideLayout';
import { Link, useHistory } from 'react-router-dom';
import {
  StyledButtonContainer,
  StyledControls,
  StyledDivider,
  StyledOTPContainer,
  StyledLabelledButtonContainer,
  StyledSubtitleContainer,
  StyledHeaderTitle,
} from './styles';
import {
  Loader,
  LoopButton,
  Typography,
} from '@loophealth/loop-ui-web-library';
import OtpInput from 'react-otp-input';
import './OTPScreenStyles.css';
import LoopApiServiceContext from '../../../../contexts/LoopApiServiceContext';
import useOTPValidation from './hooks/useOTPAuth';
import { runOTPTimer } from './utils';
import useTrackPage from '../../../../utils/segment/hooks/useTrackPage';

const OTPScreen = () => {
  const history = useHistory();
  const timerRef = useRef<HTMLSpanElement | null>(null);
  const firebaseContext = useContext(LoopApiServiceContext);
  const [showResendButton, setShowResendButton] = useState(false);

  const {
    error,
    mobile,
    loading,
    otpCode,
    invalidOtp,
    resendingCode,
    resendOTP,
    handleChange,
  } = useOTPValidation();

  const navigateToLogin = () => {
    history.push('/login');
  };

  useEffect(() => {
    const timerInterval = runOTPTimer(timerRef, setShowResendButton);

    return () => {
      clearInterval(timerInterval);
    };
  }, [timerRef]);

  useEffect(() => {
    if (firebaseContext) {
      window.recaptchaVerifier = new firebaseContext.captcha.RecaptchaVerifier(
        'otp',
        {
          size: 'invisible',
        },
      );
    }
  }, [firebaseContext?.captcha.RecaptchaVerifier]);

  useTrackPage();

  return (
    <StyledLoginPage>
      <SideLayout />

      <StyledLoginPageControlsSection>
        <StyledLoginPageControls>
          <StyledLoginPageHeader>
            <StyledHeaderTitle>
              <Typography variant="title1">
                {loading ? 'Verifying OTP' : 'Enter 6 digit OTP'}
              </Typography>
              {loading && <Loader />}
            </StyledHeaderTitle>

            <StyledSubtitleContainer>
              <div>
                <Typography variant="medium">
                  We've sent a one-time password to&nbsp;
                </Typography>
                <Typography variant="medium" weight="medium">
                  +91-{mobile}.
                </Typography>
              </div>
              <Link
                to={'/login'}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography variant="small" weight="medium" color="emerald">
                  Change
                </Typography>
              </Link>
            </StyledSubtitleContainer>
          </StyledLoginPageHeader>

          <StyledControls>
            <StyledOTPContainer>
              <OtpInput
                value={otpCode}
                onChange={handleChange}
                numInputs={6}
                inputStyle="hrd-otp-input"
                containerStyle="hrd-otp-container"
                errorStyle="hrd-otp-error"
                hasErrored={!!error || invalidOtp}
                shouldAutoFocus={true}
                isInputNum={true}
                isDisabled={loading}
              />
              <StyledLabelledButtonContainer>
                <Typography
                  variant="small"
                  color={error || invalidOtp ? 'error' : 'primary'}
                >
                  {error || invalidOtp ? (
                    error || 'Incorrect OTP, please try again'
                  ) : (
                    <>
                      Didn't receive it?{' '}
                      {!showResendButton && (
                        <>
                          You can request another code in 00:
                          <span ref={timerRef}>45</span>
                        </>
                      )}
                    </>
                  )}
                </Typography>
                {showResendButton && (
                  <StyledButtonContainer>
                    <LoopButton
                      variant="filled"
                      size="medium"
                      onClick={resendOTP}
                      isLoading={resendingCode}
                    >
                      Resend OTP
                    </LoopButton>
                  </StyledButtonContainer>
                )}
              </StyledLabelledButtonContainer>
            </StyledOTPContainer>

            <StyledDivider />

            <StyledLabelledButtonContainer>
              <Typography variant="small">Trouble logging in?</Typography>
              <StyledButtonContainer>
                <LoopButton
                  variant="secondary"
                  size="medium"
                  onClick={navigateToLogin}
                >
                  Sign-in another way
                </LoopButton>
              </StyledButtonContainer>
            </StyledLabelledButtonContainer>
          </StyledControls>
        </StyledLoginPageControls>
      </StyledLoginPageControlsSection>

      <div id="otp"></div>
    </StyledLoginPage>
  );
};

export default OTPScreen;
