import {
  Dialog,
  LoopButton,
  Modal,
  Table,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { useCallback, useMemo, useState } from 'react';
import { AddEmeraldIcon, TrashErrorIcon } from '../../../../../assets/images';
import {
  StyledEditInfoAlert,
  StyledEditsTableWrapper,
  StyledEmployeeEditSelectionTable,
  StyledSelectMemberButton,
} from './styles';
import { IEmployeeEditSelectionTable } from './types';
import { getEditTableColumns } from './utils';
import { IBulkEditEntity } from '../types';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../../utils/segment/utils';
import useSegment from '../../../../../utils/segment/hooks/useSegment';
import { useLocation } from 'react-router-dom';

const EmployeeEditSelectionTable: React.FC<IEmployeeEditSelectionTable> = ({
  editedUsers,
  setEditedUsers,
  setSelectedUser,
  setIsModalVisible,
}) => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const [deleteEditIndex, setDeleteEditIndex] = useState<number | null>(null);

  const onConfirmDeleteClick = () => {
    if (deleteEditIndex == null) return;
    setEditedUsers((prevUsers) => {
      const newUsers = [...prevUsers];
      newUsers.splice(deleteEditIndex, 1);
      return newUsers;
    });
    setDeleteEditIndex(null);
  };

  const onAddMemberClick = () => {
    trackClick(
      trackClickEvent(
        'Select_Members_Bulk_Edit_Page_edit',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    setIsModalVisible(true);
  };

  const onEditClick = useCallback(
    (index: number) => {
      const editingUser: IBulkEditEntity = structuredClone(editedUsers[index]);
      setSelectedUser(editingUser);
      setIsModalVisible(true);
    },
    [editedUsers],
  );

  const onDeleteClick = useCallback((index: number) => {
    setDeleteEditIndex(index);
  }, []);

  const editTableColumns = useMemo(
    () => getEditTableColumns(onEditClick, onDeleteClick),
    [onEditClick, onDeleteClick],
  );

  return (
    <StyledEmployeeEditSelectionTable>
      <StyledEditInfoAlert>
        <Typography variant="small" weight="medium" color="consultViolet">
          Select members you'd like to edit in all policies. The new data will
          show up after our review, except for contact details—it'll be updated
          instantly.
        </Typography>
      </StyledEditInfoAlert>
      <div>
        <StyledEditsTableWrapper>
          <Table fullWidth data={editedUsers} columns={editTableColumns} />
        </StyledEditsTableWrapper>
        <StyledSelectMemberButton>
          <LoopButton
            onClick={onAddMemberClick}
            size="small"
            variant="filled"
            iconSrc={AddEmeraldIcon}
          >
            Select Members
          </LoopButton>
        </StyledSelectMemberButton>
      </div>
      <Modal
        isVisible={deleteEditIndex !== null}
        setIsVisible={() => setDeleteEditIndex(null)}
      >
        <Dialog
          variant="vertical"
          title={`Deleting “${
            editedUsers[deleteEditIndex as number]?.firstName
          }”?`}
          description="Are you sure you wish to delete this edit?"
          icon={TrashErrorIcon}
          primaryButtonText="Yes, Delete"
          onPrimaryButtonClicked={onConfirmDeleteClick}
          onSecondaryButtonClicked={() => setDeleteEditIndex(null)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
    </StyledEmployeeEditSelectionTable>
  );
};

export default EmployeeEditSelectionTable;
