import theme from '../../../../theme';

export const getCashlessClaimsStatistics = (
  cashlessClaimsSettledCount: number,
  cashlessClaimsClosedCount: number,
) => {
  const totalCashlessClaimsCount = (
    cashlessClaimsSettledCount + cashlessClaimsClosedCount
  ).toLocaleString();

  const cashlessClaimsAnalysisData = [
    {
      color: theme.colors.supportingPlaneGreenDark,
      label: 'Settled', 
      value: cashlessClaimsSettledCount,
    },
    {
      color: theme.colors.planRed,
      label: 'Rejected',
      value: cashlessClaimsClosedCount,
    },
  ];

  return {
    data: cashlessClaimsAnalysisData,
    count: totalCashlessClaimsCount,
  };
};
