import styled from 'styled-components';

export const StyledModalContainer = styled.div`
  width: 520px;
`;

export const StyledImageContainer = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.colors.coverUpGreen};
  position: relative;
`;
export const StyledMainImage = styled.img`
  width: 100%;
`;
export const StyledCrossIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`;
export const StyledBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
`;
export const StyledSubtitleContainer = styled.div`
  margin-bottom: 14px;
`;
export const StyledHighlightNoteContainer = styled.div`
  background-color: ${(p) => p.theme.colors.lightSilver};
  padding: 8px 12px;
  border-radius: 4px;
`;
