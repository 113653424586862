import styled from "styled-components";

export const StyledPolicyCoverageDocuments = styled.div`
    display: flex;
    gap: 12px;
    padding: 12px 16px;
    align-items: center;
    border-radius: 12px;
    background: ${({theme}) => theme.colors.surface0};
    position: relative;
`;

export const StyledPolicyCoverageDocumentsText = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    & > :last-child{
        white-space: nowrap;
        flex: 1;
    }
`;

export const StyledPolicyCoverageDocumentsModal = styled.div`
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 548px;
`;
