import styled from 'styled-components';
import theme from '../../../../theme';

export const StyledTableHeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 55px;
  background-color: ${theme.colors.surfaceOne};
  border-bottom: 1px solid ${theme.colors.seafoamGray};
`;

export const StylesTabContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledLoopButtonContainer = styled.div`
  width: 250px;
`;

export const StyledTableWrapper = styled.div`
  text-transform: capitalize;
  margin-bottom: 70px;
`;

export const StyledEmptyContainer = styled.div`
  display: flex;
  padding: 40px 56px;
`;
