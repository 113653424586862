import styled from 'styled-components';

export const StyledPolicyCoverageIncExc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledPolicyIncExcHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

export const StyledStarIcon = styled.img`
  width: fit-content;
  height: fit-content;
  margin-top: 4px;
`;

export const StyledPolicyIncExcHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledPolicyIncExcLogo = styled.img`
  background: ${(p) => p.theme.colors.consultViolet};
  border-radius: 50%;
  padding: 5px;
  height: 32px;
  width: 32px;
  aspect-ratio: 1;
`;

export const StyledPolicyIncExcBody = styled.div`
  display: flex;
  gap: 48px;
  padding: 0 24px;
`;

export const StyledPolicyIncExcExpansion = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${(p) => p.theme.colors.surface0};
`;

export const StyledPolicyIncExcBodyItem = styled.div`
  padding: 24px 0 32px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  flex: 1;
`;

export const StyledPolicyIncExcBodyItemHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledPolicyIncExcTitleIcon = styled.img`
  width: 32px;
  height: 32px;
  aspect-ratio: 1;
`;

export const SryledPolicyIncExcBodySeperator = styled.span`
  width: 1px;
  height: 100%;
  background: ${(p) => p.theme.colors.lightSilver};
`;

export const StyledPolicyIncExcList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledPolicyCoverageIncExcItem = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(p) => p.theme.colors.lightSilver};
  padding-bottom: 16px;
  gap: 16px;
`;

export const StyledPolicyCoverageIncExcItemHeaderText = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  & > :first-child {
    flex: 1;
  }
  & > :nth-child(2) {
    width: fit-content;
    cursor: pointer;
    padding: 0px 16px;
  }
`;

export const StyledPolicyCoverageIncExcItemLogo = styled.img`
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
`;

export const StyledPolicyIncExcFooter = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.lightSilver};
  padding: 20px 24px 24px;
  display: flex;
  justify-content: center;
`;

export const StyledPolicyCoverageIncExcItemHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 2px;
`;

export const StyledPolicyCoverageIncExcItemBody = styled.div`
  padding: 16px 28px;
  background: ${(p) => p.theme.colors.surfaceOne};
  border-radius: 12px;
`;
