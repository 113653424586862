import React from 'react';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import {
  IconContainer,
  StyledSidebarFooter,
  StyledSidebarFooterButton,
} from './styles';
import { useLocation } from 'react-router-dom';
import { IClickAction } from '../../../pages/Auth/types';
import { trackClickEvent } from '../../../../utils/segment/utils';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import { selectHrUser } from '../../../../redux/slices/usersSlice';
import { Typography } from '@loophealth/loop-ui-web-library';
import { ChevronRightIcon } from '../../../../assets/icons';
import { IFeedbackFooter } from './types';
import theme from '../../../../theme';
import useSegment from '../../../../utils/segment/hooks/useSegment';

const FeedbackFooter: React.FC<IFeedbackFooter> = ({ setIsSidebarOpen }) => {
  const hrUser = useSelector(selectHrUser);
  const companyInfo = useSelector(selectSelectedCompany);
  const location = useLocation();
  const trackClick = useSegment('click');
  const callTrackClick = (
    action: IClickAction,
    record: Record<string, string | number | boolean | null | undefined>,
  ) =>
    trackClick(
      trackClickEvent(action, location?.pathname, 'Give Feedback Sidebar', record),
    );
  const userInfo = {
    record: null,
    hr_user_id: hrUser?.data?.userId,
    hr_name: hrUser?.data?.name,
    hr_email: hrUser?.data?.email,
    company_id: companyInfo?.id,
    company_name: companyInfo?.name,
  };

  const openPulseSidebar = () => {
    callTrackClick('GIVE_FEEDBACK', userInfo);
    setIsSidebarOpen(true);
  };

  return (
    <WithThemeProvider>
      <StyledSidebarFooter>
        <StyledSidebarFooterButton onClick={openPulseSidebar}>
          <Typography color="green" variant="small">
            Give Feedback
          </Typography>
          <IconContainer>
            <ChevronRightIcon
              color={theme.colors.coverUpGreen}
              strokeWidth="1.5"
            />
          </IconContainer>
        </StyledSidebarFooterButton>
      </StyledSidebarFooter>
    </WithThemeProvider>
  );
};

export default FeedbackFooter;
