import { Modal, Typography } from '@loophealth/loop-ui-web-library';
import React, { FC, useEffect, useState } from 'react';
import {
  AnnounceIcon,
  CrossOutlineDarkIcon,
  EndoAddIcon,
  EndoDeleteIcon,
  EndoEditIcon,
} from '../../../assets/images';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
  StyledCrossIcon,
  StyledEndoActionContainer,
  StyledEndoActionImage,
  StyledEndoActions,
  StyledHeader,
  StyledNotificationContainer,
} from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import useSegment from '../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
  trackTaskEvent,
} from '../../../utils/segment/utils';
import { extractDate } from '../../pages/Endorsements/ListingPage/utils';
import { capitalizeFirstLetter } from '../../../utils/common/Utilities';
import {
  isEndoBulkEditEnabled,
  isSingleAddEnabled,
  isSingleDeleteEnabled,
} from '../../../utils/featureFlag';
import { IEndoAddEditDeleteModalProps } from './types';
import EndoDeleteOptionsModal from '../EndoDeleteOptionsModal';
import EndoAddOptionsModal from '../EndoAddOptionsModal';

const EndoAddEditDeleteModal: FC<IEndoAddEditDeleteModalProps> = ({
  isVisible,
  setIsVisible,
  dateText,
}) => {
  const history = useHistory();
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const location = useLocation();

  const [isSingleDeleteModalVisible, setIsSingleDeleteModalVisible] =
    useState(false);
  const [isSingleAddModalVisible, setIsSingleAddModalVisible] = useState(false);

  const onAddClicked = () => {
    if (isSingleAddEnabled) {
      setIsVisible(false);
      setIsSingleAddModalVisible(true);
    } else {
      history.push('/bulk-add');
      trackClick(
        trackClickEvent(
          `Add_Lives_${capitalizeFirstLetter(
            location.pathname.substring(1),
          )}_add`,
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
    }
  };
  const onEditClick = () => {
    history.push('/bulk-edit');
    trackClick(
      trackClickEvent(
        `Edit_Lives_${capitalizeFirstLetter(
          location.pathname.substring(1),
        )}_edit`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };
  const handleCrossIconClick = () => {
    setIsVisible(false);
    trackClick(
      trackClickEvent(
        `X_Add_Edit_Delete_Modal_${capitalizeFirstLetter(
          location.pathname.substring(1),
        )}`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };
  const onDeleteClicked = () => {
    if (isSingleDeleteEnabled) {
      setIsVisible(false);
      setIsSingleDeleteModalVisible(true);
    } else {
      trackClick(
        trackClickEvent(
          `Delete_Lives_${capitalizeFirstLetter(
            location.pathname.substring(1),
          )}_del`,
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
      history.push('/bulk-delete');
    }
  };

  useEffect(() => {
    if (isVisible)
      trackTask(
        trackTaskEvent(
          `Load_Add_Edit_Delete_Modal_${capitalizeFirstLetter(
            location.pathname.substring(1),
          )}`,
          location.pathname,
        ),
      );
  }, [isVisible]);

  return (
    <>
      <Modal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        isModalClosable={false}
      >
        <WithThemeProvider>
          <StyledHeader>
            <Typography variant={'large'}>
              Hi! Would you like to add{isEndoBulkEditEnabled ? ', edit' : ''}{' '}
              or delete lives today?
            </Typography>
            <StyledCrossIcon
              src={CrossOutlineDarkIcon}
              onClick={handleCrossIconClick}
            />
          </StyledHeader>
          <StyledNotificationContainer>
            <img src={AnnounceIcon} alt="alert" />
            <Typography variant={'small'} color={'secondary'}>
              While you can submit your data with us, Loop will share the data
              with the insurer on the{' '}
              <Typography variant={'small'} color={'emerald'} weight={'bold'}>
                {extractDate(dateText)} of every month!
              </Typography>
            </Typography>
          </StyledNotificationContainer>
          <StyledEndoActionContainer>
            <StyledEndoActions onClick={onAddClicked}>
              <StyledEndoActionImage src={EndoAddIcon} />
              <Typography variant={'large'} weight={'medium'}>
                Add Lives
              </Typography>
              <Typography
                variant={'small'}
                weight={'regular'}
                color={'secondary'}
              >
                Add multiple employees or dependants through{' '}
                <Typography
                  variant={'small'}
                  weight={'bold'}
                  color={'secondary'}
                >
                  excel upload
                </Typography>
              </Typography>
            </StyledEndoActions>
            {isEndoBulkEditEnabled && (
              <StyledEndoActions onClick={onEditClick}>
                <StyledEndoActionImage src={EndoEditIcon} />
                <Typography variant={'large'} weight={'medium'}>
                  Edit Lives
                </Typography>
                <Typography
                  variant={'small'}
                  weight={'medium'}
                  color={'secondary'}
                >
                  Edit the details of a single employee or dependant from the
                  dashboard
                </Typography>
              </StyledEndoActions>
            )}
            <StyledEndoActions onClick={onDeleteClicked}>
              <StyledEndoActionImage src={EndoDeleteIcon} />
              <Typography variant={'large'} weight={'medium'}>
                Delete Lives
              </Typography>
              <Typography variant="small" weight="medium" color="secondary">
                {isSingleDeleteEnabled ? (
                  'Delete employees and dependants from the dashboard or through excel'
                ) : (
                  <>
                    Bulk delete multiple employees or dependants through&nbsp;
                    <Typography variant="small" weight="bold" color="secondary">
                      excel upload
                    </Typography>
                  </>
                )}
              </Typography>
            </StyledEndoActions>
          </StyledEndoActionContainer>
        </WithThemeProvider>
      </Modal>
      <EndoDeleteOptionsModal
        isVisible={isSingleDeleteModalVisible}
        setIsVisible={setIsSingleDeleteModalVisible}
        dateText={dateText}
      />
      <EndoAddOptionsModal
        isVisible={isSingleAddModalVisible}
        setIsVisible={setIsSingleAddModalVisible}
        dateText={dateText}
      />
    </>
  );
};
export default EndoAddEditDeleteModal;
