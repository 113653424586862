import { IRelationship } from './../../components/containers/Bulk/types';
import { IPolicyType } from '../../components/containers/PolicyDetailsCoverage/types';
import { PolicyTypeFullForms } from '../constants';

export interface IMappings {
  totalDependents: number;
  value: string;
  dependents: { relation: string; number: number }[] | null;
}

export const familyDefinition: { [familyStructure: string]: IMappings } = {
  Self: {
    totalDependents: 0,
    value: 'Self',
    dependents: null,
  },
  'Self + Spouse': {
    totalDependents: 1,
    value: 'Self + Spouse',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
    ],
  },
  'Self + Spouse + 1 Kid': {
    totalDependents: 3,
    value: 'Self + Spouse + 1 Kid',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 1,
      },
    ],
  },
  'Self + Spouse + 2 Kids': {
    totalDependents: 3,
    value: 'Self + Spouse + 2 Kids',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 2,
      },
    ],
  },
  'Self + Spouse + 3 Kids': {
    totalDependents: 4,
    value: 'Self + Spouse + 3 Kids',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 3,
      },
    ],
  },
  'Self + Spouse + 4 Kids': {
    totalDependents: 5,
    value: 'Self + Spouse + 4 Kids',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 4,
      },
    ],
  },
  'Self + Spouse + 2 Kids + Parents': {
    totalDependents: 5,
    value: 'Self + Spouse + 2 Kids + Parents',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 2,
      },
      {
        relation: 'Parent',
        number: 2,
      },
    ],
  },
  'Self + Spouse + 3 Kids + Parents': {
    totalDependents: 6,
    value: 'Self + Spouse + 3 Kids + Parents',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 3,
      },
      {
        relation: 'Parent',
        number: 2,
      },
    ],
  },
  'Self + Spouse + 4 Kids + Parents': {
    totalDependents: 7,
    value: 'Self + Spouse + 4 Kids + Parents',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 4,
      },
      {
        relation: 'Parent',
        number: 2,
      },
    ],
  },
  'Self + Spouse + 2 Kids + Parents (including in-laws)': {
    totalDependents: 7,
    value: 'Self + Spouse + 2 Kids + Parents (including in-laws)',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 2,
      },
      {
        relation: 'Parent',
        number: 2,
      },
      {
        relation: 'Parent-in-law',
        number: 2,
      },
    ],
  },
  'Self + Spouse + 3 Kids + Parents (including in-laws)': {
    totalDependents: 8,
    value: 'Self + Spouse + 3 Kids + Parents (including in-laws)',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 3,
      },
      {
        relation: 'Parent',
        number: 2,
      },
      {
        relation: 'Parent-in-law',
        number: 2,
      },
    ],
  },
  'Self + Spouse + 4 Kids + Parents (including in-laws)': {
    totalDependents: 9,
    value: 'Self + Spouse + 4 Kids + Parents (including in-laws)',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 4,
      },
      {
        relation: 'Parent',
        number: 2,
      },
      {
        relation: 'Parent-in-law',
        number: 2,
      },
    ],
  },
  'Self + Spouse + 2 Kids + Parents OR parent-in-laws': {
    totalDependents: 7,
    value: 'Self + Spouse + 2 Kids + Parents OR parent-in-laws',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 2,
      },
      {
        relation: 'Parent',
        number: 2,
      },
      {
        relation: 'Parent-in-law',
        number: 2,
      },
    ],
  },
  'Self + Spouse + 3 Kids + Parents OR parent-in-laws': {
    totalDependents: 7,
    value: 'Self + Spouse + 3 Kids + Parents OR parent-in-laws',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 3,
      },
      {
        relation: 'Parent',
        number: 2,
      },
      {
        relation: 'Parent-in-law',
        number: 2,
      },
    ],
  },
  'Self + Spouse + 4 Kids + Parents OR parent-in-laws': {
    totalDependents: 7,
    value: 'Self + Spouse + 4 Kids + Parents OR parent-in-laws',
    dependents: [
      {
        relation: 'Spouse',
        number: 1,
      },
      {
        relation: 'Child',
        number: 4,
      },
      {
        relation: 'Parent',
        number: 2,
      },
      {
        relation: 'Parent-in-law',
        number: 2,
      },
    ],
  },
  'Parents Only': {
    totalDependents: 2,
    value: 'Parents Only',
    dependents: [
      {
        relation: 'Parent',
        number: 2,
      },
    ],
  },
  'Parents (including in-laws)': {
    totalDependents: 4,
    value: 'Parents (including in-laws)',
    dependents: [
      {
        relation: 'Parent-in-law',
        number: 2,
      },
      {
        relation: 'Parent',
        number: 2,
      },
    ],
  },
};

export const EXTENTION_TO_MIME_TYPE: Record<string, string> = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
};

export const mapToPolicyTypeFullForm = (policyTypeAbbr: IPolicyType) =>
  PolicyTypeFullForms[policyTypeAbbr];

export const formatRelationship = (
  relation: string | undefined,
): IRelationship => {
  const relationship = (relation || '').toString().toLowerCase().trim();
  switch (relationship) {
    case 'mother':
    case 'father':
    case 'parent':
      return 'parent';
    case 'mother-in-law':
    case 'mother in law':
    case 'father in law':
    case 'father-in-law':
    case 'parent-in-law':
      return 'parent-in-law';
    case 'son':
    case 'daughter':
    case 'child':
    case 'kid':
      return 'child';
    case 'wife':
    case 'husband':
    case 'spouse':
      return 'spouse';
    case 'self':
    case 'employee':
      return 'self';
    default:
      return relationship as IRelationship;
  }
};

export const formatGender = (genderString: string | undefined): string => {
  const gender = (genderString || '').toString().toLowerCase().trim();
  switch (gender) {
    case 'm':
    case 'male':
      return 'male';
    case 'f':
    case 'female':
      return 'female';
    default:
      return gender;
  }
};

export const MemberDataSourceMap = new Map([
  ['HRD', 'HRD'],
  ['ENROLMENT_PORTAL', 'Enrolment Portal'],
  ['GENOME', 'Added by CSM'],
  ['HRMS', 'HRMS'],
]);
