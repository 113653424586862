import styled from 'styled-components';

export const StyledMemberEditInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  gap: 36px;
  height: 100vh;
  overflow: hidden;
`;

export const StyledMemberEditInfoHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledArrowBack = styled.div`
  width: 32px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${(p) => p.theme.colors.supportingPlaneGreenDark};
`;

export const StyledMemberEditInfoBody = styled.div`
  display: flex;
  padding-right: 64px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 12px;
  height: 100%;
`;

export const StyledReviewSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.surface0};
  flex: 4;
`;

export const StyledReviewSection = styled.div`
  padding: 48px 0px;
  border-bottom: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  overflow: hidden;
`;

export const StyledEditedFieldsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  `;

export const StyledReviewHorizontalPadding = styled.div`
  padding: 0 40px;
  overflow: scroll;
`;
export const StyledReviewHorizontalTextPadding = styled.div`
  padding: 0 40px;
`;

export const StyledEditedFieldsEmptyState = styled.div`
  border-radius: 12px;
  border: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
  padding: 12px 16px;
  background: ${(p) => p.theme.colors.white};
  font-style: italic;
`;

export const StyledSubmitEditButton = styled.div`
  padding: 32px 40px;
`;

export const StyledEditedFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${(p) => p.theme.colors.surface0};
  border: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyleEditedFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 11px 16px;
`;

export const StyledEditedFieldText = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const StyledEditedFieldIcon = styled.img`
  width: 16px;
  aspect-ratio: 1;
`;

export const StyledEditedFieldTrashIcon = styled.img`
  width: 16px;
  aspect-ratio: 1;
  cursor: pointer;
`;

export const StyledEditedFieldBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 16px;
  border-radius: 12px;
  background: ${(p) => p.theme.colors.white};
  border: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledEditedFieldBodyText = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const StyledEditSectionWrapper = styled.div`
  padding: 42px 64px;
  flex: 7;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: scroll;
`;

export const StyledFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledFieldHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFieldBodyContainer = styled.div`
  display: flex;
`;

export const StyledFieldButton = styled.div`
  text-decoration: underline;
  text-underline-offset: 2px;
  color: ${(p) => p.theme.colors.loopEmerald};
`;

export const StyledFieldBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 24px;
  width: 100%;
`;

export const StyledFieldEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledFileSelectionContainer = styled.div<{ $errored: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 120px;
  margin-top: ${(p) => (p.$errored ? '20px' : '0')};
`;

export const StyledFileSelectionFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledFieldBadgeContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledFieldDocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledDocumentsList = styled.div`
  display: flex;
  gap: 20px;
`;

export const StyledDocumentItem = styled.div`
  border-radius: 30px;
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
  padding: 8px 16px;
  gap: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;

export const StyledFieldButtonsContainer = styled.div<{ $errored: boolean }>`
  display: flex;
  gap: 12px;
  margin-top: ${(p) => (p.$errored ? '10px' : '0')};
`;

export const StyledRelationshipFileSelectionContainer = styled.div<{
  $isLast: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: ${(p) => (p.$isLast ? '120px' : '100px')};
`;

export const StyledFileSelectionList = styled.div<{ $errored: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${(p) => (p.$errored ? '20px' : '0')};
`;

export const StyledEmptySpace = styled.div`
  height: 10px;
`;

export const StyledMemberEditInfoBodyWrapper = styled.div`
  flex: 1;
  overflow: hidden;

  & > div {
    height: 100%;
  }
`;
