import styled from 'styled-components';

export const StyledEndoListingShadowBoxWrapper = styled.div`
  border-radius: 1rem;
  padding: 36px 56px;
  background: ${(p) => p.theme.colors.surfaceOne};
  margin-bottom: 16px;
`;
export const StyledEndoListingItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 36px;
`;

export const StyledEndoListItemHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledEndoListItemHeaderInsurerInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-left: 16px;
`;
export const StyledEndoListItemHeaderInsurerDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
export const StyledBullet = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors?.seafoamGray};
`;
export const HorizontalSeparator = styled.div`
  background: ${(p) => p.theme.colors?.lightSilver};
  height: 1px;
  width: 100%;
`;
export const StyledEndoListItemFooter = styled.footer`
  display: flex;
  gap: 40px;
`;
