import { Typography } from '@loophealth/loop-ui-web-library';
import EndoListingItem from '../../../containers/EndoListingItem';
import {
  StyledEmptyContainer,
  StyledEndorsementsContainer,
  StyledContainer,
  StyledEndoListHeader,
  StyledEndoFilterSection,
  StyledEndoStateContainer,
  StyledEndoStateDot,
  StyledEndoStatusCount,
  StyledAccordian,
  StyledMonthContainer,
  StyledChevron,
} from './styles';
import { EndoType, IEndorsements } from './types';
import useEndorsements from '../../../../utils/hooks/business-hooks/useEndorsements';
import Loader from '../../../atoms/Spinner';
import EndoInfoBanner from '../../../containers/EndoInfoBanner';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { ArrowDownDarkIcon, EndoCalendar } from '../../../../assets/images';
import useTrackPage from '../../../../utils/segment/hooks/useTrackPage';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';
import useSearchQuery from '../../Employees/hooks/useSearchQuery';
import ZeroStateCard from '../../../atoms/ZeroStateCard';
import { ZeroStatesData } from './constants';

export default function Endorsements() {
  const trackClick = useSegment('click');
  const location = useLocation();
  const [endoType, setEndoType] = useSearchQuery<EndoType>(
    'type',
    EndoType.Ongoing,
  );
  const isCompletedEndo = endoType?.toLowerCase() === 'completed';
  const setIsCompletedEndo = (value: boolean) =>
    setEndoType(value ? EndoType.Completed : EndoType.Ongoing);
  const [openMonths, setOpenMonths] = useState<string[]>([]);
  const { isLoading, pendingEndo, completedEndo } = useEndorsements();
  const endorsements = isCompletedEndo ? completedEndo : pendingEndo;
  const monthlyData: Record<string, IEndorsements[]> = useMemo(() => {
    const data: Record<string, IEndorsements[]> = {};
    endorsements?.forEach((endoData) => {
      const month = moment(endoData.createdAt).format("MMM' YYYY");
      if (data[month]) data[month].push(endoData);
      else data[month] = [endoData];
    });
    setOpenMonths([Object.keys(data)[0]]);
    return data;
  }, [endorsements]);

  const onMonthSelected = (month: string) => {
    const selectedMonths = [...openMonths];
    if (selectedMonths.includes(month))
      selectedMonths.splice(selectedMonths.indexOf(month), 1);
    else selectedMonths.push(month);
    setOpenMonths(selectedMonths);
  };

  const toggleIsCompletedEndo = (state: boolean) => {
    trackClick(
      trackClickEvent(
        state ? 'Completed_Endorsements' : 'Ongoing Endorsements',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    setIsCompletedEndo(state);
  };

  useTrackPage();

  return (
    <StyledContainer>
      <EndoInfoBanner />
      <StyledEndoListHeader>
        <Typography variant="title3" weight="semiBold">
          Endorsements
        </Typography>
        <Typography variant="small" color="secondary">
          Add, edit, delete lives from policies and track status for ongoing
          endorsements!
        </Typography>
      </StyledEndoListHeader>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <StyledEndoFilterSection>
            <StyledEndoStateContainer
              isSelected={!isCompletedEndo}
              onClick={() => toggleIsCompletedEndo(false)}
            >
              <StyledEndoStateDot isCompleted={false} />
              <StyledEndoStatusCount>
                <Typography
                  variant="small"
                  color={!isCompletedEndo ? 'tertiary' : 'primary'}
                >
                  Ongoing Endorsements
                </Typography>
                <Typography
                  variant="large"
                  color={!isCompletedEndo ? 'tertiary' : 'primary'}
                  weight="semiBold"
                >
                  {pendingEndo?.length}
                </Typography>
              </StyledEndoStatusCount>
            </StyledEndoStateContainer>
            <StyledEndoStateContainer
              isSelected={isCompletedEndo}
              onClick={() => toggleIsCompletedEndo(true)}
            >
              <StyledEndoStateDot isCompleted={true} />
              <StyledEndoStatusCount>
                <Typography
                  variant="small"
                  color={isCompletedEndo ? 'tertiary' : 'primary'}
                >
                  Completed Endorsements
                </Typography>
                <Typography
                  variant="large"
                  color={isCompletedEndo ? 'tertiary' : 'primary'}
                  weight="semiBold"
                >
                  {completedEndo?.length}
                </Typography>
              </StyledEndoStatusCount>
            </StyledEndoStateContainer>
          </StyledEndoFilterSection>
          <StyledEndorsementsContainer>
            {Object.keys(monthlyData).length ? (
              Object.keys(monthlyData).map((month: string) => (
                <div key={month}>
                  <StyledAccordian onClick={() => onMonthSelected(month)}>
                    <StyledMonthContainer>
                      <img src={EndoCalendar} />
                      <Typography variant="medium">
                        {month} ({monthlyData[month].length}{' '}
                        {monthlyData[month].length > 1
                          ? 'Endorsements'
                          : 'Endorsement'}
                        )
                      </Typography>
                    </StyledMonthContainer>
                    <StyledChevron src={ArrowDownDarkIcon} />
                  </StyledAccordian>
                  {openMonths.includes(month) &&
                    monthlyData[month].map((item) => (
                      <EndoListingItem endorsement={item} key={item.id} />
                    ))}
                </div>
              ))
            ) : (
              <StyledEmptyContainer>
                <ZeroStateCard
                  title={ZeroStatesData[endoType].title}
                  subtitle={ZeroStatesData[endoType].subtitle}
                  image={ZeroStatesData[endoType].image}
                  note={
                    completedEndo?.length ? '' : ZeroStatesData[endoType].note
                  }
                  noteType={ZeroStatesData[endoType].noteType}
                />
              </StyledEmptyContainer>
            )}
          </StyledEndorsementsContainer>
        </>
      )}
    </StyledContainer>
  );
}
