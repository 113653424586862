import { CurrencyTypography, Typography } from '@loophealth/loop-ui-web-library';
import { StyledStepsContainer } from './styles';
import { IAmountBadgeProps } from './types';
import { RoundCheckIcon } from '../../../../assets/images';
import { formatCurrencyAmount } from '../../../../utils/common/Utilities';

export const AmountBadge: React.FC<IAmountBadgeProps> = ({
  approvedAmount,
}) => {
  return (
    <StyledStepsContainer>
      <img src={RoundCheckIcon} alt="" />
      <Typography variant="extraSmall" color={'emerald'}>
        {'Paid'}
      </Typography>
      <Typography variant="extraSmall" color={'emerald'} weight="bold">
        {` Rs. ${formatCurrencyAmount(approvedAmount, '')}`}
      </Typography>
    </StyledStepsContainer>
  );
};
