import { SideDrawer, Typography } from '@loophealth/loop-ui-web-library';
import React, { useMemo } from 'react';
import {
  AlertZeroState as AlertZeroStateImage,
  AmbulanceBgIcon,
} from '../../../assets/images';
import ClaimAlertItem from '../ClaimAlertItem';
import { IAlertData } from '../ClaimAlertItem/types';
import { StyledAlertZeroState, StyledAlertZeroStateText } from './styles';
import { IAlertSidebar } from './types';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { useLocation } from 'react-router-dom';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';

const AlertSidebar = ({
  isAlertsOpen,
  setIsAlertsOpen,
  alerts,
}: IAlertSidebar) => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const alertCount = useMemo(() => {
    let count = 0;
    alerts.forEach((data) => {
      count += data.claimAlerts;
      if (data.CDAccountAlerts) count++;
    });
    return count;
  }, [alerts]);
  const handleAlertsClose = () => {
    setIsAlertsOpen(false);
    trackClick(
      trackClickEvent(
        'Specific_Alert_track_dismiss',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const renderAlert = (drawerItem: Record<string, unknown>) => (
    <ClaimAlertItem alertData={drawerItem as unknown as IAlertData} />
  );

  const AlertZeroState = (
    <StyledAlertZeroState>
      <StyledAlertZeroStateText>
        <Typography variant="large" weight="semiBold" color="emerald">
          No pending alerts!
        </Typography>
        <Typography variant="small">
          Everything is running smoothly for your company :&#41;
        </Typography>
      </StyledAlertZeroStateText>
      <img src={AlertZeroStateImage} />
    </StyledAlertZeroState>
  );

  return (
    <SideDrawer
      visible={isAlertsOpen}
      icon={AmbulanceBgIcon}
      onClose={handleAlertsClose}
      position="right"
      title={alertCount + ' Alerts'}
      // @ts-ignore
      drawerItems={alerts as Record<string, unknown>[]}
      renderDrawerItem={renderAlert}
      ZeroState={AlertZeroState}
    />
  );
};

export default AlertSidebar;
