import styled from 'styled-components';

export const StyledLearnMoreModalContainer = styled.div`
  width: 600px;
  padding: 0 30px 30px 30px;
`;
export const StyledLearnMoreModalList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.surface0};
  border-radius: 12px;
  padding: 28px 24px;
  gap: 24px;
`;
export const StyledLearnMoreModalListItem = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
export const StyledLearnMoreModalListItemIndexContainer = styled.div`
  width: 8%;
`;
export const StyledLearnMoreModalListItemIndex = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledLearnMoreModalListItemContent = styled.div`
  width: 92%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const StyledLearnMoreModalFooter = styled.div`
  display: flex;
  padding: 20px 30px;
  border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;
