import { Typography } from '@loophealth/loop-ui-web-library';
import React, { useState, useEffect } from 'react';
import { ChatbotMessageContainer } from './styles';

const CustomBotMessage = ({ message }: { message: string }) => {
  const [typedMessage, setTypedMessage] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < message.length) {
      const timer = setTimeout(() => {
        setTypedMessage((prev) => prev + message.charAt(index));
        setIndex((prev) => prev + 1);
      }, 10); // Adjust typing speed here
      return () => clearTimeout(timer);
    }
  }, [index, message]);

  const parseMessage = (msg: string) => {
    return msg?.split('\n').map((line, index) => {
      if (line.trim().startsWith('-')) {
        return <li key={index}>{parseBoldText(line.substring(1).trim())}</li>;
      } else {
        return (
          <React.Fragment key={index}>
            {parseBoldText(line)}
            <br />
          </React.Fragment>
        );
      }
    });
  };

  const parseBoldText = (text: string) => {
    return text
      .split(/(\*\*[^*]+\*\*|\bhttps?:\/\/\S+\b)/g)
      .map((segment, i) => {
        if (segment.startsWith('**') && segment.endsWith('**')) {
          return (
            <Typography variant="small" color="emerald" key={i} weight="bold">
              {segment.slice(2, -2)}
            </Typography>
          );
        } else if (/^https?:\/\//.test(segment)) {
          return (
            <a href={segment} target="_blank" rel="noopener noreferrer" key={i}>
              <Typography variant="small" color="emerald">
                {segment}
              </Typography>
            </a>
          );
        }
        return (
          <Typography variant="small" color="emerald" key={i}>
            {segment}
          </Typography>
        );
      });
  };

  return (
    <ChatbotMessageContainer>
      <ul
        style={{
          listStyleType: 'none',
          padding: 0,
          margin: 0
        }}
      >
        {typeof message == 'string' ? (
          <Typography variant="small" color="emerald">
            {parseMessage(typedMessage)}
          </Typography>
        ) : (
          message
        )}
      </ul>
    </ChatbotMessageContainer>
  );
};

export default CustomBotMessage;
