import React, { useContext, useState, useEffect, ReactNode } from 'react';

import { auth } from '../firebase';

import { AppConstants } from '../utils/common/AppConstants';
import LoopApiService from '../adaptars/LoopApiService';
import { IUser } from '../redux/slices/usersSlice/types';

interface IAuthContext {
  currentUser: IUser | null;
  company: string;
  signup: (email: string, password: string) => void;
  login: () => void;
  logout: () => void;
  resetPassword: (email: string) => void;
  setLoggedInUser: (user: IUser | null) => void;
  setHrCompany: (companyName: string) => void;
}

const AuthContext = React.createContext<IAuthContext | null>(null);

export function useAuth() {
  return useContext(AuthContext) as IAuthContext;
}

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);

  const [company, setCompany] = useState('');

  function signup(email: string, password: string) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login() {
    // return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email: string) {
    return auth.sendPasswordResetEmail(email);
  }

  function setLoggedInUser(user: IUser | null) {
    return setCurrentUser(user);
  }

  function setHrCompany(companyName: string) {
    return setCompany(companyName);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      // setCurrentUser(user);
      let emailId = window.localStorage.getItem(AppConstants.EMAIL_FOR_SIGNIN);
      if (emailId) {
        const email = user?.providerData[0]?.email || null;
        if (email) {
          try {
            const response = await LoopApiService.getHRDetails(
              email,
              'email',
              'email',
            );
            if (response) setCurrentUser((current) => ({ ...current, ...user, email } as IUser));
          } catch (error) {
            throw error;
          }
        }
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    company,
    signup,
    login,
    logout,
    resetPassword,
    setLoggedInUser,
    setHrCompany,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
