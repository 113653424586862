import { IStyledPolicyCoverageNavigationIcon } from './../types';
import styled from "styled-components";

export const StyledPolicyCoverageSlabs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledPolicyCoverageSlabsHeader = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledPolicyCoverageSlabsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: nowrap;
    gap: 16px;
    flex: 1;
`;

export const StyledPolicyCoverageSlabItem = styled.div`
    padding: 24px;
    background: ${(p) => p.theme.colors.surface0};
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 12px;
`;

export const StyledPolicySlabDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledPolicySlabDetailsItem = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const StyledPolicySlabDetailsItemText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledPolicySlabDetailsItemLogo = styled.div`
    background: ${(p) => p.theme.colors.consultViolet};
    border-radius: 50%;
    height: 32px;
    width: 32px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledPolicyCoveragePagination = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`;

export const StyledPolicyCoverageNavigation = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    align-self: stretch;
`;

export const StyledPolicyCoverageNavigationIcon = styled.img<IStyledPolicyCoverageNavigationIcon>`
    cursor: pointer;
    transform-origin: center;
    transform: rotate(${(p) => p.flip ? '180deg' : '0'});
`;