import React from 'react';
import {
  StyledCardBody,
  StyledCardHeader,
  StyledCardInfoContainer,
  StyledCardInfoWrapper,
  StyledIcon,
  StyledOverviewCard,
  SyledHighlightDescription,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { ContentIcon, EditIcon, UploadIcon } from '../../../assets/images';
import { IBulkUploadInfoCard } from './types';

const UploadInfoCard: React.FC<IBulkUploadInfoCard> = ({ mode }) => {
  return (
    <StyledOverviewCard>
      <StyledCardHeader>
        <Typography variant="small" weight="bold" color="secondary">
          INSTRUCTIONS
        </Typography>
      </StyledCardHeader>
      <StyledCardBody>
        {mode === 'DELETE' && (
          <SyledHighlightDescription>
            <Typography variant="small" weight="regular" color="secondary">
              Enter only employee details on the sheet. Deleting an employee
              deletes their whole family from the policy.
            </Typography>
          </SyledHighlightDescription>
        )}
        <StyledCardInfoWrapper>
          <StyledIcon src={ContentIcon} />
          <StyledCardInfoContainer>
            <Typography variant="small" weight="semiBold">
              Download Template
            </Typography>
            <Typography variant="small" weight="regular" color="secondary">
              Not sure where to begin? Download our template.
            </Typography>
          </StyledCardInfoContainer>
        </StyledCardInfoWrapper>
        <StyledCardInfoWrapper>
          <StyledIcon src={EditIcon} />
          <StyledCardInfoContainer>
            <Typography variant="small" weight="semiBold">
              Fill the Sheet
            </Typography>
            <Typography variant="small" weight="regular" color="secondary">
              Stuck? Watch our step-by-step video tutorial.
            </Typography>
          </StyledCardInfoContainer>
        </StyledCardInfoWrapper>
        <StyledCardInfoWrapper>
          <StyledIcon src={UploadIcon} />
          <StyledCardInfoContainer>
            <Typography variant="small" weight="semiBold">
              Upload and Relax
            </Typography>
            <Typography variant="small" weight="regular" color="secondary">
              Done? Great! Upload your sheet and you’re done.
            </Typography>
          </StyledCardInfoContainer>
        </StyledCardInfoWrapper>
      </StyledCardBody>
    </StyledOverviewCard>
  );
};

export default UploadInfoCard;
