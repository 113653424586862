import styled from 'styled-components';

export const StyledSidebarFooter = styled.div`
  position: absolute;
  bottom: 65px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSidebarFooterButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 86%;
  height: 40px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.coverUpGreen};
  background-color: ${(p) => p.theme.colors.supportingPlaneGreenDark};
  border-radius: 8px;
  padding: 0 12px;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
