export const REIMBURSMENT_STATUS_COUNT_LABEL: Record<string, string> = {
  Settled: 'settlementSettledCount',
  Closed: 'settlementClosedCount',
  Ongoing: 'settlementOnGoingCount',
  Rejected: 'settlementRejectedCount',
  'Under Query': 'settlementUnderQueryCount',
};
// For cashless claims if claim is not closed or rejected, we are considering it as settled -
export const CASHLESS_STATUS_COUNT_LABEL: Record<string, string> = {
  Settled: 'cashlessClaimsSettledCount',
  Closed: 'cashlessClaimsClosedCount',
  Rejected: 'cashlessClaimsClosedCount',
};
export const getClaimAnalysisStatus = (
  claimType: string,
  statusTag: string,
) => {
  return claimType === 'cashless'
    ? CASHLESS_STATUS_COUNT_LABEL[statusTag] || 'cashlessClaimsSettledCount'
    : REIMBURSMENT_STATUS_COUNT_LABEL[statusTag];
};
