import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.mintFrost};
  justify-content: space-between;
  align-items: center;
  padding: 20px 56px 0 34px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.seafoamGray};
`;
export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
`;

export const StyledCalendarIcon = styled.img``;

export const StyledInfoCardButtonContainer = styled.div`
  max-width: fit-content;
`;
