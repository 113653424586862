import React, { useEffect } from 'react';
import {
  StyledMainContainer,
  StyledBackIcon,
  StyledBackBtnContainer,
  StyledContainerWrapper,
  StyledTabsWrapper,
  StyledTabsHeaderContainer,
  StyledTabsContentContainer,
  StyledOverviewWrapper,
} from './styles';
import { ArrowLeftIcon } from '../../../assets/images';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs, Typography } from '@loophealth/loop-ui-web-library';
import EmployeeDetailsOverview from '../../containers/EmployeeDetailsOverview';
import { ITabItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Tabs/types';
import { IEmpTabs } from './utils';
import ClaimDetails from '../../containers/ClaimDetails';
import EmpPolicies from '../../containers/EmpPolicies';
import EmpDependents from '../../containers/EmpDependents';
import { useFetchEmployeeDetails } from './hooks/useFetchEmployeeDetails';
import useFetchEmployeeClaims from './hooks/useFetchEmployeeClaims';
import { LocationState } from './types';
import { RelationConstants } from '../../../utils/constants';
import Loader from '../../atoms/Spinner';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import useTrackPage from '../../../utils/segment/hooks/useTrackPage';

const EmployeeDetails = () => {
  const trackClick = useSegment('page');
  const trackView = useSegment('page');
  const location = useLocation<LocationState>();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = React.useState<ITabItem>({
    id:
      location?.state?.referrerPage === IEmpTabs.Claims
        ? IEmpTabs.Claims
        : location?.state?.showTab === RelationConstants.self
        ? IEmpTabs.Policies
        : IEmpTabs.Dependents,
    value: '',
  });

  const { isLoading, employeeData, employeeId } = useFetchEmployeeDetails(
    location?.state?.referrerPage,
  );

  const { claimsLoading, claimData, employeeTabs } = useFetchEmployeeClaims(
    employeeId as string,
  );

  useTrackPage(
    {
      employee_id:
        employeeId ?? new URLSearchParams(window.location.search).get('id'),
      referrer_page: location?.state?.referrerPage,
      selected_tab: selectedTab.id,
      claims_count: claimData?.length,
    },
    !!employeeData && !claimsLoading,
  );

  const onBackClick = () => {
    history.goBack();
  };
  const handleClick = (e: ITabItem) => {
    const empProperties: Record<string, unknown> = {
      selected_emp_id: employeeId,
    };
    trackClick(
      trackClickEvent(
        'EMP_' + e.id + '_TAB',
        location.pathname,
        'HRD EMP Details Page Events',
        empProperties,
      ),
    );
    setSelectedTab(e);
  };

  useEffect(() => {
    if (selectedTab.id === IEmpTabs.Policies) {
      trackView({
        name: 'employee_ecards_' + extractPathName(location.pathname),
        properties: {
          employeeId,
        },
      });
    }
  }, [selectedTab.id]);

  return (
    <>
      <StyledMainContainer>
        <StyledBackBtnContainer>
          <StyledBackIcon src={ArrowLeftIcon} onClick={onBackClick} />
          <Typography variant="medium">Back</Typography>
        </StyledBackBtnContainer>
        <StyledContainerWrapper>
          <StyledOverviewWrapper>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {employeeData ? (
                  <EmployeeDetailsOverview
                    empDetail={employeeData.empOverviewDetails}
                  />
                ) : null}
              </>
            )}
          </StyledOverviewWrapper>
        </StyledContainerWrapper>
      </StyledMainContainer>
      {!isLoading ? (
        <StyledTabsWrapper>
          <StyledTabsHeaderContainer>
            <Tabs.OutlineTab
              tabItems={employeeTabs}
              onClick={(e: ITabItem) => handleClick(e)}
              selectedTab={selectedTab}
            />
          </StyledTabsHeaderContainer>
          <StyledTabsContentContainer>
            {selectedTab?.id === IEmpTabs.Policies && employeeData ? (
              <EmpPolicies policiesDetails={employeeData.policiesDetails} />
            ) : null}
            {selectedTab?.id === IEmpTabs.Claims ? (
              <ClaimDetails
                claimDetails={claimData ?? []}
                claimsLoading={claimsLoading || isLoading}
              />
            ) : null}
            {selectedTab?.id === IEmpTabs.Dependents && employeeData ? (
              <EmpDependents policiesDetails={employeeData.policiesDetails} />
            ) : null}
          </StyledTabsContentContainer>
        </StyledTabsWrapper>
      ) : null}
    </>
  );
};
export default EmployeeDetails;
