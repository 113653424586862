import styled from 'styled-components';

export const StyledEmployeeEditSelectionTable = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 48px;
  background-color: ${(p) => p.theme.colors.white};
  gap: 24px;
`;

export const StyledEditInfoAlert = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.consultViolet};
  border-bottom: 1px solid ${(p) => p.theme.colors.consultViolet};
  background-color: ${(p) => p.theme.colors.surface0};
  padding: 14px 24px;
`;

export const StyledSelectMemberButton = styled.div`
  padding: 12px 24px;
  border-bottom: 1px solid ${(p) => p.theme.colors.seafoamGray};
  margin-bottom: 30px;
  & > * {
    width: fit-content;
  }
`;

export const StyledMenuContainer = styled.div`
  position: relative;
`;

export const StyledActionsMenu = styled.div`
  position: absolute;
  top: 100%;
  background-color: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
  border-radius: 8px;
  cursor: pointer;
  z-index: 2;
`;

export const StyledActionsMenuItem = styled.div`
  padding: 12px 20px;
  gap: 8px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(p) => p.theme.colors.surface0};
  }
`;

export const StyledActionsMenuIcon = styled.img`
  width: 24px;
  aspect-ratio: 1;
`;

export const StyledMoreActionsIcon = styled.img`
  width: 20px;
  aspect-ratio: 1;
  cursor: pointer;
`;

export const StyledEditsTableWrapper = styled.div`
  text-transform: capitalize;
  & > div {
    overflow: visible;
  }
`;
