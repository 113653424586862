import { IStyledDropdownItem } from './types';
import styled from 'styled-components';

export const StyledAnalyticsHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex: 1;
  height: 100%;
`;

export const StyledTitleText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  height: 100%;
`;

export const StyledFilter = styled.div`
  flex: 1;
  max-width: 500px;
`;

export const StyledDropdownItem = styled.div<IStyledDropdownItem>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: ${(p) => (p.$isVisible ? '4px 0' : '0')};
`;

export const StyledDropdownItemDetails = styled.div`
  display: flex;
`;

export const StyledSubtitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledLearnMore = styled.div`
  cursor: pointer;
`;
export const StyledLastUpdated = styled.div``;
