import React, { useEffect, useState } from 'react';
import PolicyFilter from '../../containers/PolicyFilter';
import { IHRDashboardFilterType } from '../../containers/PolicyFilter/types';
import { Policies } from '../../containers/Policies';
import { PoliciesContainer, StyledEmptyStateContainer } from './styles';
import { FILTER_ACRONYM } from '../../containers/PolicyFilter/constants';
import useFetchPoliciesFromRedux from '../../containers/Policies/hooks/useFetchPoliciesFromRedux';
import Loader from '../../atoms/Spinner';
import useTrackPage from '../../../utils/segment/hooks/useTrackPage';
import 'react-chatbot-kit/build/main.css';
import './chatbotConfig/index.css';
import ChatbotComponent from '../../containers/Chatbot';
import { getConfig } from './chatbotConfig/config';
import { MessageParser } from './chatbotConfig/MessageParser';
import { ActionProvider } from './chatbotConfig/ActionProvider';
import {
  fetchRemoteConfig,
  isPolicyPalEnabled,
} from '../../../utils/featureFlag';
import { useDispatch, useSelector } from 'react-redux';
import { selectHrUser } from '../../../redux/slices/usersSlice';
import { DeliveryBoyGraphic } from '../../../assets/images';
import ZeroStateCard from '../../atoms/ZeroStateCard';
import WelcomeModal from '../../containers/WelcomeModal';
import {
  addWelcomeModalShownUserId,
  selectWelcomeModalSeenUserIds,
} from '../../../redux/slices/applicationStateSlice';

const PoliciesDashboard = () => {
  const dispatch = useDispatch();
  const { filters, policies, isLoading } = useFetchPoliciesFromRedux();
  const welcomeModalShownUserIds = useSelector(selectWelcomeModalSeenUserIds);

  const [activeFilter, setActiveFilter] = useState<IHRDashboardFilterType>(
    IHRDashboardFilterType.All,
  );
  const [policyPalHrAccess, setPolicyPalHrAccess] = useState(false);
  const hrData = useSelector(selectHrUser);
  const isWelcomeModalOpen = !welcomeModalShownUserIds.includes(
    hrData.data?.userId || '',
  );

  const closeModal = () => {
    if (hrData?.data?.userId)
      dispatch(addWelcomeModalShownUserId(hrData.data.userId));
  };

  const policyPlans =
    activeFilter === IHRDashboardFilterType.All
      ? policies ?? []
      : (policies ?? []).filter(
          (policyPlan) =>
            policyPlan.policyType === FILTER_ACRONYM[activeFilter],
        );

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const configData = await fetchRemoteConfig('policyPalHrAccess');
        if (configData.hrList.includes(hrData.data?.userId)) {
          setPolicyPalHrAccess(true);
        } else {
          setPolicyPalHrAccess(false);
        }
      } catch (error) {
        setPolicyPalHrAccess(false);
      }
    };

    fetchConfig();
    if (filters.length && !filters.includes(activeFilter)) {
      setActiveFilter(filters[0]);
    }
  }, [filters]);

  useTrackPage();

  return (
    <PoliciesContainer data-testid="policies-page">
      <PolicyFilter
        filters={filters}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      {isLoading && !policies.length ? (
        <Loader />
      ) : (
        <Policies policyPlans={policyPlans} />
      )}
      {!isLoading && !policies.length ? (
        <StyledEmptyStateContainer>
          <ZeroStateCard
            title="Your policy details are on the way!"
            subtitle="Your policies will be available here shortly. In the meantime, reach out to your CSM for any questions."
            image={DeliveryBoyGraphic}
          />
        </StyledEmptyStateContainer>
      ) : null}
      {policyPalHrAccess && isPolicyPalEnabled && (
        <ChatbotComponent
          config={getConfig}
          MessageParser={MessageParser}
          ActionProvider={ActionProvider}
          headerText={'PolicyPal'}
          placeholderText={"What's your query?"}
          greetingMessage="I’m PolicyPal here to answer any questions related to your Loop policy!"
        />
      )}
      <WelcomeModal onClose={closeModal} isOpen={isWelcomeModalOpen} />
    </PoliciesContainer>
  );
};

export default PoliciesDashboard;
